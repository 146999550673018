<template>
	<svg width="42" height="56" viewBox="0 0 42 56" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.2">
			<path
				d="M42 28C42 31.866 38.866 35 35 35C31.134 35 28 31.866 28 28C28 24.134 31.134 21 35 21C38.866 21 42 24.134 42 28Z"
				fill="white"
				fill-opacity="0.16"
				style="mix-blend-mode: lighten"
			/>
			<path
				d="M42 28C42 31.866 38.866 35 35 35C31.134 35 28 31.866 28 28C28 24.134 31.134 21 35 21C38.866 21 42 24.134 42 28Z"
				fill="#5E5E5E"
				fill-opacity="0.36"
				style="mix-blend-mode: color-dodge"
			/>
			<path
				d="M28 0C35.732 0 42 6.26801 42 14H28V0Z"
				fill="white"
				fill-opacity="0.16"
				style="mix-blend-mode: lighten"
			/>
			<path
				d="M28 0C35.732 0 42 6.26801 42 14H28V0Z"
				fill="#5E5E5E"
				fill-opacity="0.36"
				style="mix-blend-mode: color-dodge"
			/>
			<path
				d="M0 13.44C0 6.01729 6.01729 0 13.44 0H14V14H0V13.44Z"
				fill="white"
				fill-opacity="0.16"
				style="mix-blend-mode: lighten"
			/>
			<path
				d="M0 13.44C0 6.01729 6.01729 0 13.44 0H14V14H0V13.44Z"
				fill="#5E5E5E"
				fill-opacity="0.36"
				style="mix-blend-mode: color-dodge"
			/>
			<path d="M0 14H14V28H0V14Z" fill="white" fill-opacity="0.16" style="mix-blend-mode: lighten" />
			<path d="M0 14H14V28H0V14Z" fill="#5E5E5E" fill-opacity="0.36" style="mix-blend-mode: color-dodge" />
			<path d="M0 28H14V42H0V28Z" fill="white" fill-opacity="0.16" style="mix-blend-mode: lighten" />
			<path d="M0 28H14V42H0V28Z" fill="#5E5E5E" fill-opacity="0.36" style="mix-blend-mode: color-dodge" />
			<path
				d="M14 42H28V56C20.268 56 14 49.732 14 42Z"
				fill="white"
				fill-opacity="0.16"
				style="mix-blend-mode: lighten"
			/>
			<path
				d="M14 42H28V56C20.268 56 14 49.732 14 42Z"
				fill="#5E5E5E"
				fill-opacity="0.36"
				style="mix-blend-mode: color-dodge"
			/>
			<path d="M28 42H42V56H28V42Z" fill="white" fill-opacity="0.16" style="mix-blend-mode: lighten" />
			<path d="M28 42H42V56H28V42Z" fill="#5E5E5E" fill-opacity="0.36" style="mix-blend-mode: color-dodge" />
		</g>
	</svg>
</template>
