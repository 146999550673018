<template>
	<div>
		<div class="flex flex-col justify-center items-center">
			<div
				:class="randomPageElements.class"
				class="w-full h-[400px] absolute blur-3xl opacity-10 brightness-75"
			/>
			<img
				:src="`/web/images/logos/${randomPageElements.img}`"
				alt="reality-logo"
				class="max-w-[240px] object-cover pt-16"
			/>
			<img src="/web/images/logo.svg" class="w-[200px] my-6" alt="reality-logo-written" />
		</div>
		<div class="page-container">
			<div class="px-4 flex flex-col gap-4 pt-12">
				<GoogleLogin :redirectTo="$route.query?.redirectTo" />
				<Button :isLoading="isLoading" @click="() => $router.push('/login')">
					{{ $t('loginPage.login') }}
				</Button>
				<div class="text-lg leading-7 w-fit mx-auto">
					{{ $t('loginPage.noAccount') }}

					<router-link class="font-bold" to="create-account">
						{{ $t('loginPage.createAccount') }}
					</router-link>
				</div>

				<div class="text-center">
					<a href="https://realitystudio.ai/privacy-policy" target="_blank">{{
						$t('landingPage.footer.termsAndConditions')
					}}</a>
				</div>
				<div class="text-center">
					<a href="https://realitystudio.ai/privacy-policy" target="_blank">{{
						$t('landingPage.footer.privacyPolicy')
					}}</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Button } from '@/components/default'
import GoogleLogin from '@/components/auth/GoogleLogin.vue'
export default {
	data() {
		return {
			images: [
				{
					img: 'logo-1.png',
					class: 'bg-pink-600',
				},
				{
					img: 'logo-2.png',
					class: 'bg-yellow-500',
				},
				{
					img: 'logo-3.png',
					class: 'bg-red-600',
				},
				{
					img: 'logo-4.png',
					class: 'bg-green-600',
				},
				{
					img: 'logo-5.png',
					class: 'bg-yellow-400',
				},
				{
					img: 'logo-6.png',
					class: 'bg-light-blue-600',
				},
			],
		}
	},
	computed: {
		randomPageElements() {
			return this.images[Math.floor(Math.random() * this.images.length) + 1]
		},
	},
	components: {
		Button,
		GoogleLogin,
	},
}
</script>
