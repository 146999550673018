<template>
	<Checkbox
		id="newsletterSubscription"
		:modelValue="isSubscribedToNewsletter"
		@update:modelValue="onChange"
		:checked="isSubscribedToNewsletter"
	>
		{{$t('newsletter.receiveNewsletter')}}
	</Checkbox>
</template>

<script>
import { Checkbox } from '@/components/default'
export default {
	props: {
		isSubscribedToNewsletter: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		onChange(value) {
			this.$emit('onChange', value)
		},
	},

	emits: ['onChange'],

	components: { Checkbox },
}
</script>
