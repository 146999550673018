<template>
	<div class="flex">
		<input
			class="mr-2"
			:id="id"
			:value="modelValue"
			type="checkbox"
			@input="onChange"
			:required="required"
			:checked="checked"
		/>
		<label :for="id">
			<slot></slot>
		</label>
	</div>
</template>
<script>
export default {
	props: {
		checked: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			required: true,
		},
		modelValue: {
			type: Boolean,
		},
		required: {
			type: Boolean,
		},
	},
	emits: ['update:modelValue'],
	methods: {
		onChange(event) {
			this.$emit('update:modelValue', event.target.checked)
		},
	},
}
</script>
