<template>
	<div
		class="flex items-center flex-col border rounded-xl"
		:class="{
			'border-green-700': data.published,
			'border-radial-2-card': !data.published,
		}"
	>
		<div class="relative w-full">
			<div class="group">
				<div
					class="hidden group-hover:flex items-center justify-center absolute bg-black/80 h-[336px] w-full top-0 left-0 rounded-t-xl"
				>
					<a
						target="_blank"
						:href="`/new-avatar/${data._id}`"
						class="material-icons text-5xl hover:text-blue-600"
						>visibility</a
					>
					<router-link
						:to="`/admin/presets/${data._id}`"
						class="material-icons text-5xl ml-4 hover:text-blue-600"
					>
						edit
					</router-link>
				</div>
				<LazyImage
					imgClass="object-cover rounded-t-xl h-[336px] w-full"
					:height="336"
					:src="data.images[0]"
					alt="Preset Image"
				/>
			</div>
			<button
				v-if="!isDevelopment"
				@click="handleTogglePublished"
				class="h-12 w-12 rounded-full absolute top-4 right-4 flex items-center justify-center"
				:class="{
					'bg-green-500': data.published,
					'bg-gray-700': !data.published,
				}"
			>
				<span class="material-icons text-2xl">
					{{ data.published ? 'toggle_on' : 'toggle_off' }}
				</span>
			</button>
		</div>
		<p class="mt-6 font-bold">{{ data.name }}</p>
		<button class="my-4 flex items-center justify-center" @click="handleCopyId">
			<span
				class="material-icons text-2xl mr-2"
				:class="{
					'text-blue-600': isCopiedId,
				}"
			>
				{{ !isCopiedId ? 'content_copy' : 'check' }}
			</span>
			<p class="font-bold text-xl">ID: {{ truncateText(data._id, 12) }}</p>
		</button>
		<span>
			<strong>{{ data.avatarsCount }}</strong>
			{{ $t('admin.presetsPage.presetsCard.avatarsCreated') }}
		</span>
		<div
			class="mt-6 w-full text-center rounded-b-xl py-2"
			:class="{
				'bg-green-600': data.published,
				'bg-radial-2-card': !data.published,
			}"
		>
			<span class="font-bold text-white">
				{{
					data.published
						? $t('admin.presetsPage.presetsCard.published')
						: $t('admin.presetsPage.presetsCard.draft')
				}}
			</span>
		</div>
	</div>
</template>

<script>
import { LazyImage } from '../default'

const COPY_ID_TIMEOUT = 1000

export default {
	props: {
		data: {
			type: Object,
		},
	},
	data() {
		return {
			isPublishing: false,
			isCopiedId: false,
		}
	},
	computed: {
		isDevelopment() {
			return this.data?.name?.includes('Development')
		},
	},
	methods: {
		async handleCopyId() {
			this.isCopiedId = true
			this.copyToClipboard(this.data._id)

			setTimeout(() => {
				if (this.isCopiedId) {
					this.isCopiedId = false
				}
			}, COPY_ID_TIMEOUT)
		},
		async handleTogglePublished() {
			this.isPublishing = true
			try {
				await this.axios.put(`/presets/${this.data._id}/toggle-publish`)
				this.data.published = !this.data.published
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.isPublishing = false
		},
	},
	components: { LazyImage },
}
</script>
