<template>
	<div class="page-container mt-9">
		<div id="checkout"></div>
	</div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { loadStripe } from '@stripe/stripe-js'

export default {
	async setup() {
		const route = useRoute()
		const router = useRouter()

		if (!route.query || !route.query.clientSecret || !route.query.id) {
			router.push('/checkout/plans')
		}

		return {
			clientSecret: route.query.clientSecret,
			planId: route.query.id,
			redirect: route.query.redirect,
		}
	},
	data() {
		return {
			stripeCheckout: null,
		}
	},
	async mounted() {
		const stripePublicKey =
			typeof APP_ENV === 'undefined' ? import.meta.env.VITE_STRIPE_PUBLIC_KEY : APP_ENV?.VITE_STRIPE_PUBLIC_KEY
		const stripe = await loadStripe(stripePublicKey)

		this.stripeCheckout = await stripe.initEmbeddedCheckout({
			clientSecret: this.clientSecret,
			onComplete: () => {
				this.captureEvent('checkout_express_success')
				const redirect = this.redirect ? `&redirect=${this.redirect}` : ''
				this.$router.push(`/checkout/processing?id=${this.planId}${redirect}`)
			},
		})

		this.stripeCheckout.mount('#checkout')
	},
	beforeUnmount() {
		if (this.stripeCheckout) {
			this.stripeCheckout.destroy()
		}
	},
	head: {
		title: `RealityStudio - Checkout`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Checkout`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
