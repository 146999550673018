<template>
	<div class="py-6">
		<span class="text-red-600 hover:underline cursor-pointer" @click="confirm = true"> Deactivate my account </span>

		<Modal :displayModal="confirm" title="Deactivate my account">
			<h1 class="mb-6 text-2xl text-center">Are you sure you want to deactivate your account?</h1>
			<Button @click="handleDisableAccount">
				{{ loading ? 'Deactivating...' : 'Deactivate' }}
			</Button>
			<Button @click="confirm = false" color="outline" class="mt-4"> Cancel </Button>
		</Modal>
	</div>
</template>

<script>
import { Modal, Button } from '@/components/default'

export default {
	data() {
		return {
			confirm: false,
			loading: false,
		}
	},
	methods: {
		async handleDisableAccount() {
			this.loading = true
			try {
				await this.axios.delete('/users/disable')

				this.$store.commit('setUser', null)
				this.$router.push('/')
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.loading = false
		},
	},
	components: { Modal, Button },
}
</script>
