<template>
	<div class="flex h-full">
		<img
			@mousemove="e => allowZoom && onMouseMove(e)"
			@mouseleave="e => allowZoom && onMouseLeave(e)"
			@touchstart="e => allowZoom && onTouchStart(e)"
			@touchmove="e => allowZoom && onTouchMove(e)"
			@touchend="onTouchEnd"
			:class="[
				imgClass,
				{
					'transition-transform duration-300': allowZoom && !isMobile && isZoomed,
					'cursor-zoom-in': allowZoom && !isZoomed,
					'cursor-zoom-out': allowZoom && isZoomed,
				},
			]"
			:src="src"
			:alt="alt"
			v-show="isLoaded && !hasLoadError"
			@load="onImageLoaded"
			@error="onLoadError"
			ref="image"
		/>
		<div
			v-if="hasLoadError || !isLoaded"
			class="w-full flex items-center justify-center"
			:class="lazyWrapperClass"
			:style="{
				height: `${height}px`,
			}"
		>
			<p class="material-icons" v-if="hasLoadError">broken_image</p>
			<RealityAvatarIcon :class="['animate-pulse-with-scale h-[50%] w-full', realityStudioIconClass]" v-else />
		</div>
	</div>
</template>

<script>
import { RealityAvatarIcon } from '@/components/icons'

export default {
	components: { RealityAvatarIcon },
	emits: ['onImageLoaded', 'onLoadError', 'onZoomIn', 'onZoomOut'],

	data() {
		return {
			isLoaded: false,
			hasLoadError: false,
			isZoomed: false,
			zoomScale: 2,
			distanceData: {},
		}
	},

	methods: {
		onMouseMove(event) {
			this.isZoomed = true
			const image = this.$refs.image
			const rect = image.getBoundingClientRect()
			const x = event.clientX - rect.left
			const y = event.clientY - rect.top
			const percentX = (x / rect.width) * 100
			const percentY = (y / rect.height) * 100

			image.style.transformOrigin = `${percentX}% ${percentY}%`
			image.style.transform = `scale(${this.zoomScale})`
		},

		onMouseLeave() {
			this.isZoomed = false
			const image = this.$refs.image
			image.style.transformOrigin = 'center center'
			image.style.transform = 'scale(1)'
		},

		onTouchStart(event) {
			if (event.touches.length === 2) {
				this.isZoomed = true
				event.preventDefault()

				this.distanceData.x = (event.touches[0].pageX + event.touches[1].pageX) / 2
				this.distanceData.y = (event.touches[0].pageY + event.touches[1].pageY) / 2
				this.distanceData.distance = this.getDistance(event)
			}
		},

		onTouchMove(event) {
			if (event.touches.length === 2) {
				this.$emit('onZoomIn')
				event.preventDefault()
				let scale

				// Safari provides event.scale as two fingers move on the screen
				// For other browsers we need calculate the scale manually
				if (event.scale) {
					scale = event.scale
				} else {
					const deltaDistance = this.getDistance(event)
					scale = deltaDistance / this.distanceData
				}

				this.zoomScale = Math.min(Math.max(1, scale), 4)

				const deltaX = ((event.touches[0].pageX + event.touches[1].pageX) / 2 - this.distanceData.x) * 2
				const deltaY = ((event.touches[0].pageY + event.touches[1].pageY) / 2 - this.distanceData.y) * 2

				const transform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${this.zoomScale})`
				this.$refs.image.style.transform = transform
				this.$refs.image.style.WebkitTransform = transform
				this.$refs.image.style.zIndex = '9999'
			}
		},

		getDistance(event) {
			return Math.hypot(
				event.touches[0].pageX - event.touches[1].pageX,
				event.touches[0].pageY - event.touches[1].pageY
			)
		},

		onTouchEnd() {
			if (!this.$refs.image) {
				return
			}

			this.isZoomed = false
			this.$emit('onZoomOut')
			this.$refs.image.style.transform = ''
			this.$refs.image.style.WebkitTransform = ''
			this.$refs.image.style.zIndex = ''
		},

		onImageLoaded() {
			this.isLoaded = true
			this.$emit('onImageLoaded')
		},

		onLoadError() {
			this.hasLoadError = true
			this.$emit('onLoadError')
		},
	},
	props: {
		allowZoom: {
			type: Boolean,
			default: false,
		},
		imgClass: {
			default: 'w-full h-full cursor-zoom-in',
		},
		lazyWrapperClass: {
			default: 'w-full h-full bg-radial-1-card rounded-xl flex items-center justify-center',
		},
		realityStudioIconClass: {
			type: String,
			default: '',
		},
		src: {
			type: String,
			required: true,
		},
		alt: {
			type: String,
			required: true,
		},
		height: {
			type: Number,
		},
	},
	computed: {
		isMobile() {
			return this.$store.getters.getIsMobile
		},
	},
}
</script>
