<template>
	<div class="rounded-xl bg-transparent border border-gray-900 my-6">
		<div class="flex items-center justify-start p-6 cursor-pointer" @click.prevent="handleOpen">
			<img :src="selectedImage" alt="selected-image" class="w-[32px] h-[32px] object-cover mr-2 rounded-md" />
			<h1 class="text-base mr-4">
				{{ selectedTitle }}
			</h1>

			<div class="flex ml-auto">
				<span class="material-symbols-outlined text-2xl mr-4" @click="handleReset" v-if="!!value && !hideClear">
					close
				</span>
				<span
					class="material-symbols-outlined text-2xl transform transition-transform duration-500"
					:class="{
						'rotate-180': isOpen,
					}"
				>
					expand_more
				</span>
			</div>
		</div>

		<div v-if="isOpen" class="bg-black/80 fixed w-screen h-screen top-0 left-0 z-10" @click="isOpen = false"></div>

		<transition
			enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 translate-y-48"
			enter-to-class="transform opacity-100"
		>
			<div
				v-if="isOpen"
				class="fixed md:rounded-t-xl max-w-[592px] md:left-1/2 md:-translate-x-1/2 w-full bottom-0 left-0 h-full bg-[#02070D] border border-gray-900 pb-16 z-[60] overflow-auto"
			>
				<div class="flex items-center mb-9 mx-6 my-4">
					<button type="button" @click="isOpen = false" class="mr-3">
						<span class="material-symbols-outlined text-2xl"> chevron_left </span>
					</button>
					<h1 class="text-xl font-bold">
						{{ menuTitle }}
					</h1>
				</div>
				<div class="grid grid-cols-2 gap-4 mx-4">
					<div
						v-for="(option, index) in options"
						class="relative h-[250px] md:h-[400px] w-full"
						@click="() => option.disabled || handleSelect(option.value)"
						:key="`option-${index}`"
						:class="{
							'cursor-pointer': !option.disabled,
							'cursor-not-allowed opacity-50': option.disabled,
						}"
					>
						<img
							:src="option.image"
							:alt="`select-image-${index + 1}`"
							class="h-full w-full object-cover rounded-xl"
						/>
						<div
							class="absolute bottom-0 w-full h-1/4 bg-gradient-to-t from-app-blue border-none opacity-100"
						/>
						<strong class="absolute bottom-4 text-center w-full">{{ option.label }}</strong>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		options: {
			type: Array,
			required: true,
		},
		value: {
			type: String,
		},
		menuTitle: {
			type: String,
			required: true,
		},
		hideClear: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			isOpen: false,
		}
	},
	methods: {
		handleOpen() {
			this.isOpen = !this.isOpen
		},
		handleReset() {
			this.$emit('onChange', null)

			// TODO: improve this code
			setTimeout(() => {
				this.isOpen = false
			}, 1)
		},
		handleSelect(optionValue) {
			this.isOpen = false

			this.$emit('onChange', optionValue)
		},
	},
	computed: {
		selectedTitle() {
			return this.options.find(option => option.value === this.value)?.label || this.$t('select.emptyText')
		},
		selectedImage() {
			return this.options.find(option => option.value === this.value)?.image || ''
		},
	},
	watch: {
		isOpen(newValue) {
			if (newValue) {
				document.body.classList.add('!overflow-hidden')
			} else {
				document.body.classList.remove('!overflow-hidden')
			}
		},
	},
	beforeUnmount() {
		document.body.classList.remove('!overflow-hidden')
	},
	emits: ['onChange'],
}
</script>
