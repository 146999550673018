<template>
	<div>
		<div class="page-container pt-6 px-4 relative">
			<div v-show="!isImagesModalOpened">
				<Header class="mb-6" :title="$t(`freeAvatarTool.pageTitle`)" />
				<div>
					<p
						class="text-lg leading-7 color-white text-center my-4"
						v-html="$t(`freeAvatarTool.uploadImage`)"
					/>
					<video
						class="mb-6 mx-auto w-[230px] h-[300px] object-cover rounded-xl"
						autoplay
						muted
						loop
						playsinline
						:controls="false"
						v-if="!previewImageBlob"
					>
						<source src="https://cdn.realitystudio.ai/assets/videos/free-avatar.mp4" type="video/mp4" />
					</video>
				</div>
				<div v-if="userHasEnoughPoints">
					<div
						class="relative mx-auto w-[280px] h-[400px] mb-6"
						@click="handleImageUpload"
						v-if="previewImageBlob"
					>
						<img
							class="mb-4 w-full h-full object-cover rounded-xl cursor-pointer"
							:src="previewImageBlob"
						/>
						<button class="bg-red-700 rounded-md h-8 w-8 absolute top-2 right-2 z-10" type="button">
							<span class="material-icons text-base">delete</span>
						</button>
					</div>
					<label
						v-if="userHasEnoughPoints"
						class="cursor-pointer flex items-center border rounded-xl border-radial-1-card w-full px-4 py-5 mx-auto"
						for="userPicturesInput"
						@click="handleImageUpload"
					>
						<div class="flex items-center">
							<span class="material-symbols-outlined text-3xl mr-2">add_circle</span>
							<p class="font-bold">
								{{ $t('common.addPhotos') }}
							</p>
						</div>
					</label>

					<input
						inputId="userPicturesInput"
						ref="fileInput"
						class="hidden"
						type="file"
						name="file"
						accept="image/*"
						@change="event => onFileSelect(event)"
					/>
					<SelectWithImage
						:options="styleOptions"
						:value="selectedStyle"
						menuTitle="Escolha seu estilo"
						@onChange="value => (selectedStyle = value)"
						class="mt-6"
					/>
					<TextArea
						v-model="prompt"
						labelText="Prompt"
						class="my-6"
						subLabelClass="text-gray-300"
						:rows="5"
						:subLabel="`(${$t('TextArea.optional')})`"
						name="prompt"
						id="prompt"
					/>
					<Button
						v-if="!!imageData && selectedStyle"
						class="mt-4"
						@click="handleSubmit"
						:isLoading="isUploading"
						>{{ $t(`freeAvatarTool.submitButton`) }}</Button
					>
				</div>
				<AuthenticationBox
					v-if="!loggedUser"
					:redirectTo="currentPathname"
					pageName="free_avatar_tool"
					class="mt-4"
				/>
				<PlansSwiper v-if="loggedUser && !userHasEnoughPoints" :plans="plans" class="mt-4" />
				<div class="my-4" v-if="!!inferences.length">
					<p class="text-lg leading-7 font-bold mb-4">
						{{ $t(`freeAvatarTool.createdTitle`) }}
					</p>
					<div class="grid grid-cols-2 gap-4">
						<div class="relative" v-for="inference in inferences" :key="inference._id">
							<div class="relative h-[214px] w-full" v-if="inference.outputs.length">
								<LazyImage
									:src="inference.outputs[0]"
									:height="214"
									alt="Image without background"
									@click="handleOpenImage(inference)"
									imgClass="absolute top-0 left-0 rounded-xl h-full w-full object-cover cursor-pointer z-10"
								/>
								<img
									class="absolute top-0 left-0 h-full w-full object-cover rounded-xl"
									src="/web/images/presets-and-tools/transparent.jpg"
									alt="Transparent image"
								/>
							</div>
							<div
								class="rounded-xl h-[214px] w-full bg-radial-2-card flex items-center justify-center"
								v-if="!inference.outputs.length && inference.status === 'processing'"
							>
								<Spinner
									iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
									height="12"
									width="12"
								/>
							</div>
							<div
								class="relative rounded-xl h-[214px] w-full bg-radial-2-card flex items-center justify-center"
								v-if="!inference.outputs.length && inference.status === 'failed'"
							>
								<img
									@click="() => handleOpenImage(inference)"
									src="/web/images/presets-and-tools/tool-failed.png"
									alt="tool-failed"
									class="absolute rounded-xl max-w-[60px] h-full object-contain cursor-pointer z-1"
								/>
							</div>
							<button
								class="bg-red-700 flex items-center justify-center rounded-md h-8 w-8 absolute top-2 left-2 z-10"
								@click="handleDeleteImage(inference)"
								type="button"
							>
								<span v-if="deletingId !== inference._id" class="material-icons text-2xl">delete</span>
								<Spinner
									iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
									height="4"
									width="4"
									v-else
								/>
							</button>
							<button
								class="bg-black bg-opacity-60 flex items-center justify-center rounded-md h-8 w-8 absolute top-2 right-2 z-10"
								@click="downloadImage(inference)"
								type="button"
							>
								<span v-if="deletingId !== inference._id" class="material-icons text-2xl"
									>download</span
								>
								<Spinner
									iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
									height="4"
									width="4"
									v-else
								/>
							</button>
						</div>
					</div>

					<Spinner v-if="isFetchingInferences" iconClass="mx-auto mt-4" height="12" width="12" />
				</div>
			</div>
			<div
				v-show="isImagesModalOpened"
				class="h-full w-full rounded-t-xl md:px-0 top-0 left-0 flex flex-col gap-4"
			>
				<div class="flex justify-between">
					<Button color="transparent" size="fit" @click="() => (isImagesModalOpened = false)">
						<span class="material-icons text-[32px]">close</span>
					</Button>
					<Button
						v-if="currentInference"
						@click="handleDeleteImage(currentInference)"
						color="transparent"
						size="fit"
						:isLoading="deletingId === currentInference._id"
						:disabled="currentInference.status === 'processing'"
					>
						<span class="material-icons text-[32px]">delete</span>
					</Button>
				</div>
				<div class="flex flex-1">
					<div
						class="bg-radial-2-card flex items-center justify-center w-full rounded-xl h-[460px]"
						v-if="!currentInference || !currentInference.outputs.length"
					>
						<Spinner
							v-if="currentInference?.status === 'processing'"
							iconClass="text-gray-200 animate-spin dark:text-gray-600 w-[32px] h-[32px] fill-white"
							height="32"
							width="32"
						/>
						<div
							class="relative h-[460px] w-full text-center flex flex-col items-center justify-center px-8"
							v-if="currentInference?.status === 'failed'"
						>
							<h2 class="text-3xl color-white font-bold mb-8">
								{{ $t(`freeAvatarTool.toolFailed.title`) }}
							</h2>
							<img src="/web/images/presets-and-tools/tool-failed.png" alt="tool-failed" />
							<p
								class="text-md leading-7 color-white my-6"
								v-html="$t(`freeAvatarTool.toolFailed.description`)"
							></p>
							<Button
								:class="{
									'opacity-50': isUploading,
								}"
								@click="handleUploadMore"
								:isLoading="isUploading"
								>{{ $t(`freeAvatarTool.toolFailed.button`) }}</Button
							>
						</div>
					</div>
					<div class="relative h-[460px] w-full" v-else>
						<BeforeAfterSource
							:firstSource="currentInference.input"
							:secondSource="currentInference.outputs[0]"
							sourceClass="rounded-xl h-[460px] w-full object-cover"
						/>
						<img
							v-if="!currentInference.outputs"
							class="absolute h-full w-full object-cover rounded-xl"
							src="/web/images/presets-and-tools/transparent.jpg"
							alt="Transparent image"
						/>
					</div>
				</div>
				<div>
					<div class="swiper inference-outputs-swiper">
						<div class="swiper-wrapper">
							<Button
								color="transparent"
								size="fit"
								@click="handleUploadMore"
								:isLoading="isUploading"
								:class="{
									'opacity-50': isUploading,
								}"
								class="swiper-slide relative !h-[60px] !w-[60px]"
							>
								<div
									class="rounded-xl h-[60px] w-[60px] flex items-center justify-center bg-radial-2-card"
								>
									<span class="material-icons text-2xl">add_photo_alternate</span>
								</div>
							</Button>
							<div
								v-for="inference of inferences"
								:key="inference._id"
								class="swiper-slide relative !h-[60px] !w-[60px]"
							>
								<div
									class="flex items-center justify-center h-[60px] w-[60px] bg-radial-2-card rounded-xl absolute -z-10"
									v-if="!inference.outputs.length && inference.status === 'processing'"
								>
									<Spinner
										iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
										height="6"
										width="6"
									/>
								</div>
								<LazyImage
									v-if="inference.outputs.length && inference.status === 'completed'"
									:src="inference.outputs[0]"
									lazyWrapperClass="absolute text-center top-0 left-0 z-10"
									:imgClass="`rounded-xl h-full w-full object-cover cursor-pointer absolute top-0 left-0 z-10 ${
										inference._id === currentInference?._id
											? 'border-2 border-blue-600'
											: 'border-2 border-transparent'
									}`"
									@click="() => handleOpenImage(inference)"
									alt="Image without background"
									:height="60"
								/>
								<img
									v-if="inference.outputs.length"
									class="absolute top-0 left-0 h-[60px] w-[60px] object-cover rounded-xl"
									src="/web/images/presets-and-tools/transparent.jpg"
									alt="Transparent image"
								/>
								<div
									v-if="inference.status === 'failed'"
									:class="`rounded-xl h-[60px] w-[60px] object-cover cursor-pointer absolute top-0 left-0 z-10 ${
										inference._id === currentInference?._id
											? 'border-2 border-blue-600'
											: 'border-2 border-transparent'
									}`"
								>
									<img
										@click="() => handleOpenImage(inference)"
										src="/web/images/presets-and-tools/tool-failed.png"
										alt="tool-failed"
										class="p-1 h-full w-full"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Button
					v-if="!!previewImageBlob"
					@click="handleSubmit"
					:isLoading="isUploading"
					:disabled="isUploading"
				>
					{{ $t(`freeAvatarTool.submitButton`) }}
				</Button>
				<Button
					v-else
					color="outline"
					@click="downloadImage"
					:isLoading="isDownloading"
					:disabled="!currentInference || currentInference.status !== 'completed'"
				>
					<span class="material-icons text-2xl mr-2">download</span>
					{{ $t(`freeAvatarTool.downloadButton`) }}
				</Button>
			</div>
		</div>
	</div>
</template>

<script>
import { Header, Button, LazyImage, Spinner, TextArea, SelectWithImage, BeforeAfterSource } from '@/components/default'
import { AuthenticationBox } from '@/components/auth'
import { PlansSwiper } from '@/components/new-avatar'
import Swiper from 'swiper'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { inject } from 'vue'

export default {
	name: 'FreeAvatarToolLayout',
	components: {
		Header,
		Button,
		PlansSwiper,
		LazyImage,
		Spinner,
		AuthenticationBox,
		TextArea,
		SelectWithImage,
		BeforeAfterSource,
	},
	async setup() {
		const axios = inject('axios')
		const store = useStore()
		const loggedUser = store.getters.getUser

		const [pricing, plans, inferences] = await Promise.all([
			axios.get('/inferences/pricing').then(res => res.data),
			axios.get('/plans?unit=monthly').then(res => res.data),
			loggedUser && axios.get(`/inferences?type=free-avatar`).then(res => res.data),
		])

		return {
			pointsPrice: pricing.freeAvatar || 0,
			plans,
			inferences: ref((inferences && inferences.results) || []),
			hasNext: ref(inferences?.hasNext),
			next: ref(inferences?.next),
		}
	},
	data() {
		return {
			mockupWindow: null,
			imageData: null,
			previewImageBlob: null,
			isUploading: false,
			deletingId: null,
			getInferencesIntervals: [],
			isImagesModalOpened: false,
			currentInference: null,
			isDownloading: false,
			fetchInferenceIntervalInMs: 5000,
			inferenceOutputsSwiperInstance: null,
			isFetchingInferences: false,
			styleOptions: [
				{
					value: 'Clay',
					label: this.$t('freeAvatarTool.styles.clay'),
					image: '/web/images/presets-and-tools/styles/clay.jpg',
				},
				{
					value: 'Video game',
					label: this.$t('freeAvatarTool.styles.videoGame'),
					image: '/web/images/presets-and-tools/styles/video-game.jpg',
				},
				{
					value: 'Pixels',
					label: this.$t('freeAvatarTool.styles.pixels'),
					image: '/web/images/presets-and-tools/styles/pixels.jpg',
				},
				{
					value: 'Toy',
					label: this.$t('freeAvatarTool.styles.toy'),
					image: '/web/images/presets-and-tools/styles/toy.jpg',
				},
				{
					value: '3D',
					label: this.$t('freeAvatarTool.styles.3d'),
					image: '/web/images/presets-and-tools/styles/3d.jpg',
				},
				{
					value: 'Emoji',
					label: this.$t('freeAvatarTool.styles.emoji'),
					image: '/web/images/presets-and-tools/styles/emoji.jpg',
				},
			],
			selectedStyle: 'Clay',
			prompt: '',
		}
	},
	methods: {
		async handleSubmit() {
			const file = this.imageData

			this.isUploading = true

			this.captureEvent(`free_avatar_tool_submit_tool_intent`)

			try {
				const signedUrlResponse = await this.axios.get(`/files/sign-url/${file.name}`).then(res => res.data)

				await this.axios.put(signedUrlResponse.url, file)

				const inference = await this.axios
					.post('/inferences/free-avatar', {
						image: signedUrlResponse.key,
						prompt: this.prompt,
						style: this.selectedStyle,
					})
					.then(res => res.data)

				this.inferences = [inference, ...this.inferences]

				this.$nextTick(() => {
					this.inferenceOutputsSwiperInstance?.update()
				})

				this.handleOpenImage(inference)

				const poolingInterval = setInterval(
					() => this.fetchInference(inference._id),
					this.fetchInferenceIntervalInMs
				)

				this.getInferencesIntervals.push({
					interval: poolingInterval,
					inferenceId: inference._id,
				})

				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.success'),
					message: this.$t(`freeAvatarTool.successMessage`),
					type: 'success',
				})

				this.captureEvent(`free_avatar_tool_submit_tool_success`)
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent(`free_avatar_tool_submit_tool_error`)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t(`freeAvatarTool.errorMessage`),
					type: 'error',
				})
			}

			this.isUploading = false
		},
		handleImageUpload() {
			if (this.$refs.fileInput) {
				this.$refs.fileInput.click()
			}
		},
		async onFileSelect(event) {
			const file = event.target.files[0]

			if (!file) return
			this.$refs.fileInput.value = ''

			this.captureEvent(`free_avatar_tool_image_upload_click`)
			this.previewImageBlob = URL.createObjectURL(file)
			this.imageData = file
		},
		handleOpenImage(inference) {
			if (!!this.previewImageBlob) {
				this.previewImageBlob = null
				this.imageData = null
			}

			this.captureEvent(`free_avatar_tool_open_image_click`)
			this.currentInference = inference
			this.isImagesModalOpened = true
			window.scrollTo({ top: 0, behavior: 'smooth' })
		},
		initInferencesSwiper() {
			this.inferenceOutputsSwiperInstance = new Swiper('.inference-outputs-swiper', {
				slidesPerView: 'auto',
				freeMode: true,
				spaceBetween: 16,
				grabCursor: true,
				mousewheel: true,
				on: {
					activeIndexChange: () => {
						if (!this.inferenceOutputsSwiperInstance || this.isFetchingInferences) return

						/**
						 * Load more items when the user it's close to the end of the list
						 */
						if (
							this.inferenceOutputsSwiperInstance.activeIndex * 2 > this.inferences.length &&
							this.hasNext
						) {
							this.fetchInferences()
						}
					},
				},
			})
		},
		handleUploadMore() {
			this.captureEvent(`free_avatar_tool_upload_more_click`)

			this.isImagesModalOpened = false
		},
		onScrollPage() {
			let isScrolledToBottom = false

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight
			}

			if (isScrolledToBottom) {
				this.fetchInferences()
			}
		},
		async handleDeleteImage(inference) {
			this.deletingId = inference._id

			try {
				await this.axios.delete(`/inferences/${inference._id}`)

				this.inferences = this.inferences.filter(_inference => _inference._id !== inference._id)

				if (this.currentInference?._id === inference._id) {
					const anotherInference = this.inferences?.[0]

					this.currentInference = anotherInference
				}

				if (this.isImagesModalOpened && this.inferences.length === 0) {
					this.isImagesModalOpened = false
				}

				this.$nextTick(() => {
					this.inferenceOutputsSwiperInstance?.update()
				})
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}

			this.deletingId = null
		},
		async fetchInferences() {
			if (this.isFetchingInferences || !this.hasNext) {
				return
			}

			this.isFetchingInferences = true

			try {
				const { results, hasNext, next } = await this.axios
					.get(`/inferences?${this.hasNext ? `next=${this.next}` : ''}&type=free-avatar`)
					.then(res => res.data)

				const inferenceLength = this.inferences.length

				this.inferences = [...this.inferences, ...results]
				this.hasNext = hasNext
				this.next = next

				if (inferenceLength !== this.inferences.length) {
					this.$nextTick(() => {
						this.inferenceOutputsSwiperInstance?.update()
					})
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetchingInferences = false
		},
		async fetchInference(inferenceId) {
			try {
				const inference = await this.axios.get(`/inferences/${inferenceId}`).then(res => res.data)

				this.inferences = this.inferences.map(_inference => {
					if (_inference._id === inferenceId) {
						return inference
					}

					return _inference
				})

				if (this.currentInference?._id === inferenceId) {
					this.currentInference = inference
				}

				if (inference.status !== 'processing') {
					clearInterval(
						this.getInferencesIntervals.find(interval => interval.inferenceId === inferenceId)?.interval
					)

					const displayOfferModal = this.checkShowSubscribeOfferModal()

					if (displayOfferModal) {
						this.$mitt.emit('openSubscribeOfferModal')
					}
					this.$mitt.emit('openFeedbackModal', inference)
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		async downloadImage(inference) {
			this.isDownloading = true

			if (inference) {
				this.currentInference = inference
			}

			this.captureEvent(`free_avatar_tool_image_download_intent`)

			try {
				await this.$downloadFile(this.currentInference.outputs[0], `${this.currentInference._id}.png`)

				this.captureEvent(`free_avatar_tool_image_download_success`)
			} catch (error) {
				this.captureEvent(`free_avatar_tool_image_download_error`)
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}

			this.isDownloading = false
		},
		checkShowSubscribeOfferModal() {
			if (this.loggedUser && this.loggedUser.plan && this.loggedUser.plan.free) {
				return this.loggedUser.modals.subscriptionOfferModalDisplayedAt
					? this.$dayjs().isAfter(
							this.$dayjs(this.loggedUser.modals.subscriptionOfferModalDisplayedAt).add(30, 'minute')
					  )
					: true
			}

			return !this.loggedUser.hasSubscription
		},
	},
	computed: {
		currentPathname() {
			return this.$route.path
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
		userHasEnoughPoints() {
			if (!this.loggedUser) {
				return false
			}

			return this.loggedUser?.points >= this.pointsPrice
		},
	},
	mounted() {
		this.mockupWindow = this.getWindow()

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScrollPage)

		this.initInferencesSwiper()
	},
	beforeUnmount() {
		this.getInferencesIntervals.forEach(interval => clearInterval(interval.interval))

		if (this.inferenceOutputsSwiperInstance) {
			this.inferenceOutputsSwiperInstance.destroy()
		}

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScrollPage)
	},
	head: {
		title: `RealityStudio - Free Avatar`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Create a free avatar with your customized style with Reality Studio. Upload your image and get a free avatar in seconds',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Free Avatar `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Create a free avatar with your customized style with Reality Studio. Upload your image and get a free avatar in seconds',
			},
		],
	},
}
</script>
