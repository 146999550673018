<template>
	<div
		class="border border-radial-1-card rounded-xl"
		:class="{
			'cursor-pointer': clickable,
		}"
		@click="handleClick"
	>
		<div class="w-full aspect-square relative">
			<img v-if="isImage" class="h-full object-cover rounded-t-xl w-full" :src="tool.image" :alt="tool.title" />
			<video
				v-else
				class="h-full object-cover w-full rounded-t-xl"
				:src="tool.image"
				:alt="tool.title"
				autoplay
				loop
				muted
				playsinline
				:controls="false"
			/>
			<div
				class="min-w-full opacity-80 absolute top-0 -left-px right-0 h-full bg-gradient-to-t from-black to-transparent will-change-transform"
				:class="backdropClass"
				v-tw-merge
			/>
			<div
				v-if="tool.isNew"
				class="absolute left-1/2 -translate-x-1/2 bottom-0 mb-4 bg-blue-600 rounded-xl py-2 w-1/3 text-center text-sm font-bold will-change-transform"
			>
				{{ $t('common.new') }}
			</div>
		</div>
		<div class="pt-4 px-4 pb-6 text-center border-t border-radial-1-card">
			<p class="font-bold text-lg">{{ tool.title }}</p>
			<p class="text-gray-300 mt-4" v-html="tool.description"></p>
			<Button
				class="mt-6"
				v-if="(tool.btnAction || tool.btnHref) && tool.btnText && hasButton"
				:href="tool.btnHref"
				@click="tool.btnAction"
			>
				{{ tool.btnText }}
			</Button>
		</div>
	</div>
</template>

<script>
import { Button } from '@/components/default'
export default {
	components: {
		Button,
	},
	props: {
		backdropClass: {
			type: String,
			default: '',
		},
		tool: {
			required: true,
		},
		hasButton: {
			type: Boolean,
			default: false,
		},
		clickable: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		handleClick() {
			if (this.tool.btnAction && this.clickable) {
				this.tool.btnAction()
			}
		},
	},
	computed: {
		isImage() {
			if (!this.tool) {
				return false
			}

			return this.tool.image.includes('mp4') ? false : true
		},
	},
}
</script>
