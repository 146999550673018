<template>
	<div>
		<Suspense>
			<router-view />
		</Suspense>

		<div v-if="!isSSR" :class="{ 'page-container': isMobile }">
			<FloatingButton class="z-50">
				<div class="flex flex-col gap-4" id="toast-container"></div>
			</FloatingButton>
		</div>
	</div>
</template>

<script>
import { FloatingButton } from '@/components/default'

export default {
	components: {
		FloatingButton,
	},
	computed: {
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		isSSR() {
			return import.meta.env.SSR
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
	async mounted() {
		if (!this.loggedUser) {
			const locale = await this.$getLocale()
			this.$i18n.locale = locale?.split('-')[1]
		} else {
			this.$i18n.locale = this.loggedUser.lang
		}
	},
	watch: {
		'loggedUser.lang'() {
			this.$i18n.locale = this.loggedUser.lang
		},
	},
}
</script>
