<template>
	<!-- TODO: translate -->
	<div
		class="page-container mt-8"
		:class="{
			'window-height': showAnyAvatarPlaceholder,
			'pb-6': !showAnyAvatarPlaceholder,
		}"
	>
		<template v-if="!displayFilterSelection">
			<Header :title="$t('myAccountLibrary.title')" />

			<TabSelection class="mt-6" :tabs="tabs" @onChangeTab="handleChangeTab" />
			<div v-if="tab === 'Library'">
				<!-- <Button @click="displayFilterSelection = true" class="mt-4 text-base font-normal" color="outline-gray">
					<div class="flex justify-between items-center w-full">
						<p>{{ $t('myAccountLibrary.filterBy') }}</p>
						<i class="material-icons text-base">expand_more</i>
					</div>
				</Button>

				<div class="swiper filters-swiper mt-4" v-if="selectedFilters.length">
					<div class="swiper-wrapper">
						<div
							v-for="filter in selectedFilters"
							:key="filter"
							class="swiper-slide w-max bg-gray-600 p-4 rounded-xl text-white"
						>
							<div class="flex items-center">
								<p class="mr-4">{{ filter }}</p>
								<Button @click="handleFilterSelection(filter)" color="transparent" size="fit"
									><i class="material-icons text-base">close</i>
								</Button>
							</div>
						</div>
					</div>
				</div> -->

				<div
					v-if="showAnyAvatarPlaceholder"
					class="flex justify-center items-center flex-col h-[calc(100vh-22rem)]"
				>
					<span class="text-6xl material-icons text-white mb-6">auto_awesome</span>
					<p v-html="$t('imageGeneration.library.emptyInferences')" class="text-xl mb-8 text-center"></p>
					<Button @click="() => $router.push('/images/create')">{{
						$t('imageGeneration.library.creationNow')
					}}</Button>
				</div>
				<div v-if="hasProcessingPredictions" class="grid grid-cols-2 gap-4 mt-4">
					<div class="flex items-center bg-radial-1-card rounded-xl relative" v-for="index in 4" :key="index">
						<RealityAvatarIcon class="animate-pulse-with-scale h-[25%] w-full" />
					</div>
				</div>

				<MasonryGrid class="mt-4" :items="images" v-slot="slotProps">
					<div>
						<LibraryImage
							class="cursor-pointer"
							:image="slotProps.item"
							@onDeleteImage="handleDeleteImage"
						/>
					</div>
				</MasonryGrid>
			</div>

			<div v-if="tab === 'Favorites'">
				<div
					v-if="favorites.results.length === 0 && !isFetchingFavorites"
					class="flex justify-center items-center flex-col h-[calc(100vh-22rem)]"
				>
					<span class="text-6xl material-icons text-gray-300 mb-6">bookmark</span>
					<p class="text-xl mb-8 text-center text-gray-300">
						{{ $t('imageGeneration.library.noFavorites') }}
					</p>
				</div>
				<div class="py-4">
					<MasonryGrid :items="favorites.results" v-slot="slotProps" v-if="!isFetchingFavorites">
						<div>
							<LibraryImage
								class="cursor-pointer"
								:image="slotProps.item.entity"
								@onDeleteImage="handleDeleteImage"
								@onFavoriteImage="handleFavoriteImage"
							/>
						</div>
					</MasonryGrid>
				</div>
			</div>
		</template>

		<Spinner class="flex justify-center my-6" v-if="isFetchingImages || isFetchingFavorites" />

		<!-- <template v-if="displayFilterSelection">
			<div class="flex items-center justify-between">
				<div class="flex items-center">
					<Button @click="displayFilterSelection = false" color="transparent" size="fit">
						<span class="material-symbols-outlined"> arrow_back </span>
					</Button>
					<h1 class="text-xl font-semibold ml-2">{{ $t('myAccountLibrary.filterBy') }}</h1>
				</div>
			</div>

			<Button
				v-for="filter in filters"
				:key="filter.value"
				color="outline-gray"
				:class="['mt-4 text-base font-medium', { 'border-blue-600': selectedFilters.includes(filter.value) }]"
				@click="handleFilterSelection(filter.value)"
			>
				<div class="flex justify-between items-center w-full">
					<p>{{ filter.name }}</p>
					<i class="material-icons">{{
						selectedFilters.includes(filter.value) ? 'check_box' : 'check_box_outline_blank'
					}}</i>
				</div>
			</Button>
		</template> -->

		<!-- <div v-if="hasProcessingActions.model">
			<LibraryCard
				:prediction="{
					preset: { name: '' },
					models: [{ name: $t('myAccountLibrary.pendingJobs') }],
					images: [],
					status: 'processing',
				}"
			/>
		</div>

		<div v-if="predictions?.length" class="divide-y-2 divide-gray-900">
			<LibraryCard
				v-for="(prediction, index) in predictions"
				:key="`prediction-${index}`"
				:prediction="prediction"
				@onSuccessfulChangeName="onSuccessfulChangeName"
			/>
		</div>
		<div v-if="isFetchingPredictions" class="w-full flex items-center justify-center">
			<AnimatedSpinner />
		</div> -->
	</div>
</template>

<script>
import { inject, ref } from 'vue'
import { LibraryCard, LibraryImage } from '@/components/account/library'
import RealityAvatarIcon from '@/components/icons/RealityAvatarIcon.vue'
import {
	Button,
	AnyAvatarPlaceholder,
	AnimatedSpinner,
	LazyImage,
	Header,
	TabSelection,
	MasonryGrid,
	Spinner,
} from '@/components/default'
import { Swiper } from 'swiper'

export default {
	components: {
		AnyAvatarPlaceholder,
		LibraryCard,
		Button,
		AnimatedSpinner,
		LazyImage,
		Header,
		LibraryImage,
		TabSelection,
		MasonryGrid,
		Spinner,
		RealityAvatarIcon,
	},
	async setup() {
		const IMAGES_PER_PAGE = 6
		const axios = inject('axios')
		const { results, hasNext, next } = await axios
			.get(`/users/library?limit=${IMAGES_PER_PAGE}`)
			.then(res => res.data)

		const favorites = await axios.get(`/favorites/image`).then(res => res.data)
		const predictions = await axios.get(`/predictions`).then(res => res.data)
		const hasProcessingPredictions = predictions.results.some(prediction => prediction.status === 'processing')
		const processingPrediction = predictions.results.filter(prediction => prediction.status === 'processing')
		return {
			images: ref(results),
			hasNext: ref(hasNext),
			next: ref(next),
			favorites: ref(favorites),
			limit: IMAGES_PER_PAGE,
			processingPrediction: ref(processingPrediction),
			hasProcessingPredictions: ref(hasProcessingPredictions),
		}
	},
	data() {
		return {
			isFetchingImages: false,
			isFetchingFavorites: false,
			mockupWindow: null,

			tabs: [
				{ name: this.$t('myAccountLibrary.tabs.library'), icon: 'image', value: 'Library' },
				{ name: this.$t('myAccountLibrary.tabs.favorite'), icon: 'bookmark', value: 'Favorites' },
			],

			displayFilterSelection: false,
			filters: [
				{ name: 'Cyberpunk', value: 'cyberpunk' },
				{ name: 'Anime', value: 'anime' },
				{ name: 'PNG', value: 'png' },
			],
			selectedFilters: [],
			swiperInstance: null,
			tab: 'Library',
			getPredictionIntervals: [],
			poolingIntervalInMs: 5000,
		}
	},
	mounted() {
		this.mockupWindow = document.querySelector('#mockupWindow')

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScroll)
		this.$nextTick(() => this.initSwiper())

		if (this.hasProcessingPredictions) {
			this.processingPrediction.map(prediction => {
				this.pooling(prediction._id)
			})
		}
	},

	beforeUnmount() {
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScroll)

		// if (this.swiperInstance) {
		// 	this.swiperInstance.destroy()
		// }
	},

	methods: {
		pooling(predictionId) {
			const poolingInterval = setInterval(
				() => this.fetchProcessingPrediction(predictionId),
				this.poolingIntervalInMs
			)

			this.getPredictionIntervals.push({
				interval: poolingInterval,
				predictionId: predictionId,
			})
		},

		async fetchProcessingPrediction(predictionId) {
			try {
				const prediction = await this.axios.get(`/predictions/${predictionId}`).then(res => res.data)
				if (prediction.status !== 'processing') {
					clearInterval(
						this.getPredictionIntervals.find(interval => interval.predictionId === predictionId)?.interval
					)

					this.$mitt.emit('openFeedbackModal', prediction)

					const { results, hasNext, next } = await this.axios
						.get(`/users/library?limit=${this.limit}`)
						.then(res => res.data)
					this.images = results
					this.hasNext = hasNext
					this.next = next

					this.hasProcessingPredictions = false
					this.processingPredictions = null
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},

		async handleChangeTab(tab) {
			this.tab = tab.value

			if (this.tab === 'Favorites') {
				this.favorites = {
					results: [],
					next: null,
					hasNext: false,
				}
				await this.fetchFavorites(true)
			} else {
				this.images = []
				this.next = null
				this.hasNext = false

				await this.fetchImages(true)
			}
		},

		handleDeleteImage(entityId) {
			this.images = this.images.filter(image => image._id !== entityId)

			if (this.tab === 'Favorites') {
				this.favorites.results = this.favorites.results.filter(image => image.entity._id !== entityId)
			}

			if (this.images.length < this.limit) {
				this.fetchImages()
			}
		},

		handleFavoriteImage({ entityId, value }) {
			if (this.tab === 'Favorites' && !value) {
				this.favorites.results = this.favorites.results.filter(image => image.entity._id !== entityId)
			}
		},

		onSuccessfulChangeName({ modelId, modelName }) {
			this.predictions = this.predictions.map(prediction => {
				if (prediction.models[0]._id === modelId) {
					prediction.models[0].name = modelName
				}

				return prediction
			})
		},

		async fetchFavorites(forceFetch = false) {
			if (this.isFetchingFavorites || (!this.favorites.hasNext && !forceFetch)) return
			this.isFetchingFavorites = true
			try {
				const { results, hasNext, next } = await this.axios
					.get(`/favorites/image`, {
						params: {
							limit: this.limit,
							next: this.favorites.next,
						},
					})
					.then(res => res.data)

				this.favorites.results = [...this.favorites.results, ...results]
				this.favorites.hasNext = hasNext
				this.favorites.next = next
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.isFetchingFavorites = false
		},

		onScroll() {
			let isScrolledToBottom = false

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight
			}

			if (isScrolledToBottom) {
				this.tab === 'Library' ? this.fetchImages() : this.fetchFavorites()
			}
		},

		async fetchImages(forceFetch) {
			if (this.isFetchingImages || (!this.hasNext && !forceFetch)) return
			this.isFetchingImages = true

			try {
				const { results, hasNext, next } = await this.axios
					.get(`/users/library`, {
						params: {
							limit: this.limit,
							next: this.hasNext ? this.next : undefined,
						},
					})
					.then(res => res.data)

				this.images = [...this.images, ...results]
				this.hasNext = hasNext
				this.next = next
			} catch (error) {
				this.sentryCaptureException(error)
			} finally {
				this.isFetchingImages = false
			}
		},

		handleFilterSelection(filterValue) {
			const filterIndex = this.selectedFilters.indexOf(filterValue)
			if (filterIndex === -1) {
				return this.selectedFilters.push(filterValue)
			}

			return this.selectedFilters.splice(filterIndex, 1)
		},

		initSwiper() {
			this.swiperInstance = new Swiper('.filters-swiper', {
				direction: 'horizontal',
				spaceBetween: 16, // in pixels
				grabCursor: true,
				freeMode: true,
				slidesPerView: 'auto',
			})
		},
	},
	computed: {
		showAnyAvatarPlaceholder() {
			if (this.tab === 'Favorites') {
				return !this.favorites.results.length && !this.isFetchingFavorites && !this.hasProcessingActions.model
			}

			return !this.images?.length && !this.isFetchingImages && !this.hasProcessingActions.model
		},
		hasProcessingActions() {
			return this.$store.getters.getHasProcessingActions
		},
		processingPredictions() {
			if (!this.predictions) return []

			return this.predictions.filter(prediction => prediction.status === 'processing')
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
	},

	head: {
		title: `RealityStudio - Library`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Library`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},

	watch: {
		displayFilterSelection() {
			if (!this.displayFilterSelection) {
				this.$nextTick(() => this.initSwiper())
			}
		},
	},
}
</script>
