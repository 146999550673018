<template>
	<div>
		<AdminHeader />
		<div class="page-container my-6">
			<div class="flex item-center mb-8">
				<button @click="$router.push('/admin')" class="material-icons text-[32px]">keyboard_backspace</button>
				<span class="text-3xl font-bold ml-1">{{ $t('admin.planIndex.title') }}</span>
			</div>
			<div class="flex flex-row mt-6 mb-8 justify-start items-center">
				<Select
					:options="currencyOptions"
					:value="selectedCurrency"
					:menuTitle="$t('admin.planIndex.currency')"
					@onChange="value => (selectedCurrency = value)"
					class="mr-8"
				/>
				<Select
					:options="filters"
					:value="selectedFilter"
					:menuTitle="$t('admin.planIndex.filter.title')"
					@onChange="value => (selectedFilter = value)"
					class="mr-8"
				/>
				<Button
					color="primary"
					type="button"
					size="fit"
					class="py-6 px-10"
					@click="$router.push(`/admin/plans/manage-plan`)"
				>
					<span class="material-icons text-2xl mr-2"> add_circle</span>
					<span> {{ $t('admin.planIndex.createPlan') }}</span></Button
				>
			</div>
			<div>
				<table class="table-auto bg-[#2C3843] w-full">
					<thead class="font-bold">
						<tr>
							<th
								v-for="(element, index) in tableHeader"
								:key="index"
								class="border border-gray-900 text-center p-4"
							>
								{{ element }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="plan in computedPlans"
							:key="plan._id"
							class="text-center font-bold"
							:class="{ 'bg-gray-500': plan.disabled }"
						>
							<td
								v-for="(element, index) in generateTableRow(plan)"
								:key="index"
								class="border border-gray-900 p-4"
							>
								<div class="flex items-center justify-center">
									<img
										v-if="index === 0 && plan.image"
										:src="plan.image || `/web/icons/plans/${plan.englishName?.toLowerCase()}.svg`"
										class="w-[24px] h-[24px] mr-1"
										alt="Icon"
									/>
									<span>{{ element }}</span>
								</div>
							</td>
							<td class="border border-gray-900 p-4 mx-auto">
								<div class="flex justify-center">
									<Button
										v-for="(element, index) in generateTableRowOptions(plan)"
										color="transparent"
										:key="index"
										:class="`${element.color}`"
										class="material-icons mx-2 cursor-pointer p-0"
										@click="element.action"
										:href="element.link"
									>
										{{ element.icon }}
									</Button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import { AdminHeader } from '@/components/admin'
import { Button, Select } from '@/components/default'
import { inject, ref } from 'vue'

export default {
	components: { AdminHeader, Button, Select },
	async setup() {
		const axios = inject('axios')
		const plans = await axios.get(`/plans?showDisabled=true`).then(res => res.data)
		return { plans: ref(plans) }
	},
	data() {
		return {
			currencyOptions: [
				{ value: 'brl', label: 'BRL' },
				{ value: 'usd', label: 'USD' },
			],
			planType: {
				monthly: 'Mensal',
				yearly: 'Anual',
			},
			selectedCurrency: 'brl',
			tableHeader: [
				this.$t('admin.planIndex.name'),
				this.$t('admin.planIndex.activeSubscribers'),
				this.$t('admin.planIndex.period'),
				this.$t('admin.planIndex.price'),
				this.$t('admin.planIndex.points'),
				this.$t('admin.planIndex.options'),
			],
			filters: [
				{ value: 'all', label: this.$t('admin.planIndex.filter.all') },
				{ value: 'active', label: this.$t('admin.planIndex.filter.active') },
				{ value: 'inactive', label: this.$t('admin.planIndex.filter.inactive') },
			],
			selectedFilter: 'all',
			loading: {
				popularity: false,
				status: false,
			},
		}
	},
	methods: {
		generateTableRow(plan) {
			return [
				plan.name,
				plan.activeSubscribers,
				this.planType[plan.billingCycle.unit],
				`${this.selectedCurrency.toUpperCase()} ${plan.priceByCurrency[this.selectedCurrency]}`,
				`${plan.points}(${plan.bonusPoints})`,
			]
		},
		generateTableRowOptions(plan) {
			return [
				{
					icon: 'star',
					color: plan.mostPopular ? 'text-yellow-300' : 'text-white',
					action: () => this.editPlanPopularity(plan),
				},
				{
					icon: 'edit',
					link: `/admin/plans/manage-plan?id=${plan._id}`,
				},
				{
					color: plan.disabled ? 'text-red-300' : 'text-green-300 ',
					icon: plan.disabled ? 'toggle_off' : 'toggle_on',
					action: () => this.editPlanStatus(plan),
				},
			]
		},
		async editPlanPopularity(plan) {
			if (this.loading.popularity) return
			this.loading.popularity = true
			try {
				await this.axios.patch(`/plans/popularity/${plan._id}`).then(res => res.data)
				plan.mostPopular = !plan.mostPopular
			} catch (error) {
				console.error(error)
			}
			this.loading.popularity = false
		},
		async editPlanStatus(plan) {
			if (this.loading.status) return

			this.loading.status = true

			try {
				if (!plan.disabled) {
					await this.axios.delete(`/plans/${plan._id}`).then(res => res.data)
				} else {
					await this.axios.patch(`/plans/activate/${plan._id}`).then(res => res.data)
				}

				plan.disabled = !plan.disabled
			} catch (error) {
				console.error(error)
			}

			this.loading.status = false
		},
	},
	computed: {
		computedPlans() {
			if (this.selectedFilter === 'active') {
				return this.plans.filter(plan => !plan.disabled)
			}

			if (this.selectedFilter === 'inactive') {
				return this.plans.filter(plan => plan.disabled)
			}

			return this.plans
		},
	},
	head: {
		title: `RealityStudio - View Plans`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - View Plans`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
