<template>
	<div>
		<div class="relative">
			<router-link :to="`/new-avatar/${selectedMostRecentPreset?._id}`">
				<ShowcaseSlides
					:slides="mostRecentPresets"
					@onSlideChange="slideData => (selectedMostRecentPreset = slideData)"
				/>
				<div class="absolute flex w-full justify-center mt-4 top-0">
					<img src="/web/images/logo.svg" alt="Logo" />
				</div>

				<div class="absolute bottom-0 mb-8 text-center w-full">
					<p class="text-2xl font-bold mb-4">{{ $t('myAccountHome.recentlyAdded') }}</p>
					<p class="text-lg">{{ selectedMostRecentPreset?.name }}</p>
				</div>
			</router-link>
		</div>

		<div class="pt-6 pb-1 page-container mx-4">
			<PointsBalance v-if="loggedUser?.hasSubscription" />
			<PlansSwiper v-else :plans="plans" />

			<div class="mt-6">
				<h1 class="font-bold text-lg">🔥 {{ $t('myAccountHome.popularAvatars') }}</h1>
				<div class="swiper popular-presets mt-4">
					<div class="swiper-wrapper">
						<router-link
							class="swiper-slide"
							v-for="preset in mostPopularPresets"
							:key="preset._id"
							:to="`/new-avatar/${preset._id}`"
						>
							<div class="aspect-square px-2">
								<div class="relative w-full h-full rounded-xl overflow-hidden">
									<img
										class="object-cover w-full h-full"
										:src="preset.images[0]"
										:alt="preset.name"
									/>

									<div
										class="bg-gradient-to-t from-black to-transparent top-0 left-0 absolute h-full w-full"
									/>

									<div class="absolute bottom-2 w-full">
										<p class="font-bold text-center w-full font-lg mb-2">{{ preset.name }}</p>
										<div
											class="bg-white d-flex mx-4 text-sm rounded-xl font-bold text-center text-black"
										>
											{{ $t('myAccountHome.createNow') }}
										</div>
									</div>
								</div>
							</div>
						</router-link>
					</div>

					<!-- <div class="border-radial-1-card border-[1px] mt-6 rounded-xl py-6 px-6 flex align-items-center">
						<img src="/web/icons/invite-friend-icon.svg" alt="Invite Friends" class="mr-2" />
						<p class="font-bold">{{$t('myAccountHome.inviteFriends')}}</p>
					</div>

					<div class="border-radial-1-card border-[1px] mt-4 rounded-xl py-6 px-6 flex align-items-center">
						<span class="mr-2 material-icons">support_agent</span>
						<p class="font-bold">{{$t('myAccountHome.needHelp')}}</p>
					</div> -->

					<div class="border-radial-1-card border-[1px] mt-4 rounded-xl py-4 px-6">
						<p class="text-center font-bold text-lg">{{ $t('myAccountHome.ourSocialNetwork') }}</p>
						<p class="mt-4 text-center text-gray-300">
							{{ $t('myAccountHome.followOurSocials') }}
						</p>

						<div class="flex justify-center w-full items-center mt-4">
							<a href="https://x.com/RealityStudioAI" target="_blank">
								<img src="/web/icons/circle-logo-x.svg" alt="x link" />
							</a>

							<a href="https://www.instagram.com/realitystudio.ai/" target="_blank" class="mx-4">
								<img src="/web/icons/circle-logo-instagram.svg" alt="instagram link" />
							</a>

							<a href="https://www.tiktok.com/@realitystudio" target="_blank">
								<img src="/web/icons/circle-logo-tiktok.svg" alt="tiktok link" />
							</a>
						</div>
					</div>

					<div class="mt-6">
						<h1 class="font-bold text-lg">{{ $t('myAccountHome.moreAvatars') }}</h1>
						<div class="mt-4 grid grid-cols-2">
							<router-link
								class="aspect-square px-2 col-span-1"
								v-for="preset in morePresets"
								:key="preset._id"
								:to="`/new-avatar/${preset._id}`"
							>
								<div class="relative w-full h-full rounded-xl overflow-hidden">
									<img
										class="object-cover w-full h-full"
										:src="preset.images[0]"
										:alt="preset.name"
									/>

									<div
										class="bg-gradient-to-t from-black to-transparent top-0 left-0 absolute h-full w-full"
									/>

									<div class="absolute bottom-4 w-full">
										<p class="font-bold text-center w-full font-lg">{{ preset.name }}</p>
									</div>
								</div>
							</router-link>
						</div>
					</div>

					<Button class="mt-4" href="/">{{ $t('myAccountHome.viewAllAvatars') }}</Button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { PlansSwiper } from '@/components/new-avatar'
import { Button, FollowUs, ShowcaseSlides } from '@/components/default'
import { PointsBalance } from '@/components/subscription'
import { inject, ref } from 'vue'
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'

Swiper.use(Autoplay)

export default {
	components: {
		Button,
		FollowUs,
		PointsBalance,
		ShowcaseSlides,
		PlansSwiper,
	},

	async setup() {
		const mostRecentPresetsLimit = 5
		const mostPopularPresetsLimit = 5
		const morePresetsLimit = 2

		const axios = inject('axios')
		const [mostRecentPresets, mostPopularPresets, morePresets, userSubscription, plans] = await Promise.all([
			axios.get(`/presets/most-recent/${mostRecentPresetsLimit}`).then(res => res.data),

			axios.get(`/presets/most-popular/${mostPopularPresetsLimit}`).then(res => res.data),

			axios.get(`/presets/sample/${morePresetsLimit}`).then(res => res.data),

			axios.get('/plans/active').then(res => res.data),

			axios.get('/plans?unit=monthly').then(res => res.data),
		])

		return {
			plans: ref(plans),
			mostRecentPresets: ref(mostRecentPresets),
			mostPopularPresets: ref(mostPopularPresets),
			morePresets: ref(morePresets),
			userSubscription: ref(userSubscription),
		}
	},
	data() {
		return {
			recentPresetsSwiperInstance: null,
			selectedMostRecentPreset: null,
			presetSlidersIntervalInMS: 3000,
		}
	},
	methods: {
		initSwiper() {
			if (this.recentPresetsSwiperInstance) {
				this.recentPresetsSwiperInstance.destroy()
			}

			this.recentPresetsSwiperInstance = new Swiper('.popular-presets', {
				direction: 'horizontal',
				slidesPerView: 2,
				loop: true,
				autoplay: {
					delay: this.presetSlidersIntervalInMS,
					disableOnInteraction: true,
				},
				grabCursor: true,
			})
		},
	},

	mounted() {
		this.initSwiper()
		// this.mostRecentPresets.unshift({
		// 	images: ['/web/images/presets-and-tools/subscribe-offer-modal.jpg'],
		// 	name: this.$t('freeAvatarTool.pageTitle'),
		// 	btnText: this.$t('freeAvatarTool.submitButton'),
		// 	btnAction: () => this.$router.push('/tools/free-avatar'),
		// })
	},

	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
		hasProcessingActions() {
			return this.$store.getters.getHasProcessingActions
		},
	},
	head: {
		title: `RealityStudio - My Account`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - My Account`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
