import { createApp } from './main.js'
import filePlugin from './plugins/file.js'
import tiktokPlugin from './plugins/tiktok.js'
import hotjarPlugin from './plugins/hotjar.js'
import language from './plugins/language.js'
import camera from './plugins/camera.js'
import posthog from './plugins/posthog.js'
import mitt from './plugins/mitt.js'
import pushNotifications from './plugins/push-notifications.js'
/**
 * initiate the Vue App for a client-side application
 */
const { app } = createApp()

app.use(filePlugin)
app.use(tiktokPlugin)
app.use(hotjarPlugin)
app.use(language)
app.use(camera)
app.use(posthog)
app.use(mitt)
app.use(pushNotifications)

app.mount('#app')
