<template>
	<div>
		<Image2ImageLayout
			localePrefix="colorizeImageTool"
			gtagPrefix="colorize_image_tool"
			toolType="colorize-image"
			apiSubmitEndpoint="/inferences/colorize-image"
			previewVideoSrc="https://cdn.realitystudio.ai/assets/videos/colorize.mp4"
			pricingKey="colorizeImage"
		>
		</Image2ImageLayout>
	</div>
</template>

<script>
import { Image2ImageLayout } from '@/components/tools'

export default {
	components: {
		Image2ImageLayout,
	},
	head: {
		title: `RealityStudio - Colorize image`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Colorize your image easily with RealityStudio. Upload your image and get a colorized image in seconds.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Remove Image Background `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Colorize your image easily with RealityStudio. Upload your image and get a colorized image in seconds.',
			},
		],
	},
}
</script>
