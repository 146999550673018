<template>
	<AdminHeader :title="$t('admin.editPresetPage.pageTitle')" />

	<div class="page-container pb-8">
		<div class="flex items-center gap-2 mt-8">
			<Button color="transparent" size="fit" href="/admin/presets">
				<span class="material-icons text-[32px]">keyboard_backspace</span>
			</Button>
			<p class="text-3xl font-bold">{{ $t('admin.editPresetPage.pageTitle') }}</p>
		</div>

		<div class="grid grid-cols-1 gap-14 mt-8 lg:grid-cols-2" v-if="!!preset">
			<div class="col-span-1 space-y-4">
				<Input
					id="namePortuguese"
					type="text"
					:label="$t('admin.editPresetPage.presetNamePortuguese')"
					:placeholder="$t('admin.editPresetPage.presetNamePortuguesePlaceholder')"
					v-model="preset.name['pt-BR']"
				/>

				<Input
					id="nameEnglish"
					type="text"
					:label="$t('admin.editPresetPage.presetNameEnglish')"
					:placeholder="$t('admin.editPresetPage.presetNameEnglishPlaceholder')"
					v-model="preset.name['en-US']"
				/>

				<Input
					id="nameSpanish"
					type="text"
					:label="$t('admin.editPresetPage.presetNameSpanish')"
					:placeholder="$t('admin.editPresetPage.presetNameSpanishPlaceholder')"
					v-model="preset.name['es-ES']"
				/>

				<Input
					id="credits"
					type="number"
					:label="$t('admin.editPresetPage.presetCredits')"
					:placeholder="$t('admin.editPresetPage.presetCreditsPlaceholder')"
					v-model="preset.price"
				/>

				<TextArea
					id="positivePrompt"
					type="text"
					:placeholder="$t('admin.editPresetPage.positivePromptPlaceholder')"
					v-model="preset.input.prompt"
					:deleteButton="false"
					:labelText="$t('admin.editPresetPage.positivePrompt')"
				/>

				<TextArea
					id="negativePrompt"
					type="text"
					:placeholder="$t('admin.editPresetPage.negativePromptPlaceholder')"
					v-model="preset.input.negativePrompt"
					:deleteButton="false"
					:labelText="$t('admin.editPresetPage.negativePrompt')"
				/>

				<Input
					id="modelVersion"
					type="text"
					:label="$t('admin.editPresetPage.modelVersion')"
					:placeholder="$t('admin.editPresetPage.modelVersionPlaceholder')"
					v-model="preset.input.modelVersion"
				/>

				<Input
					id="loraScales"
					type="text"
					:label="$t('admin.editPresetPage.loraScales')"
					:placeholder="$t('admin.editPresetPage.loraScalesPlaceholder')"
					v-model="preset.input.loraScales"
				/>

				<Input
					id="minSeed"
					type="text"
					:label="$t('admin.editPresetPage.minSeed')"
					:placeholder="$t('admin.editPresetPage.minSeedPlaceholder')"
					v-model="preset.input.minSeed"
				/>

				<Input
					id="maxSeed"
					type="text"
					:label="$t('admin.editPresetPage.maxSeed')"
					:placeholder="$t('admin.editPresetPage.maxSeedPlaceholder')"
					v-model="preset.input.maxSeed"
				/>

				<Input
					id="steps"
					type="text"
					:label="$t('admin.editPresetPage.steps')"
					:placeholder="$t('admin.editPresetPage.stepsPlaceholder')"
					v-model="preset.input.steps"
				/>

				<Input
					id="scheduler"
					type="text"
					:label="$t('admin.editPresetPage.scheduler')"
					:placeholder="$t('admin.editPresetPage.schedulerPlaceholder')"
					v-model="preset.input.scheduler"
				/>

				<Input
					id="cfgScale"
					type="text"
					:label="$t('admin.editPresetPage.cfgScale')"
					:placeholder="$t('admin.editPresetPage.cfgScalePlaceholder')"
					v-model="preset.input.cfgScale"
				/>

				<div class="grid grid-cols-3 lg:grid-cols-5 gap-4">
					<div v-for="image in preset.images" :key="image" class="relative">
						<LazyImage
							:src="image"
							lazyWrapperClass="h-32 w-full"
							imgClass="h-32 w-full object-cover rounded-xl"
							alt="Preset image"
						/>
						<div>
							<Button
								color="transparent"
								size="fit"
								@click="deleteImage(image)"
								:isLoading="loading.deletingImage"
								:disabled="loading.deletingImage"
								class="absolute top-2 right-2"
							>
								<span class="material-icons text-red-400 text-md">delete</span>
							</Button>
						</div>
					</div>
				</div>

				<div>
					<label
						class="cursor-pointer flex flex-col items-center justify-center border border-dashed rounded-xl py-6"
						for="presetImages"
						:class="{
							'border-lime-400': generatedZip,
							'border-gray-800': !generatedZip,
						}"
					>
						<Spinner v-if="loading.uploadingImages" />
						<div class="flex flex-col items-center justify-center" v-else>
							<span class="material-symbols-outlined text-3xl mb-2">
								{{ generatedZip ? 'done' : 'cloud_upload' }}
							</span>
							<p class="font-bold">
								{{
									generatedZip
										? $t('admin.editPresetPage.uploadCompletedText')
										: $t('admin.editPresetPage.uploadImagesText')
								}}
							</p>
						</div>
					</label>
					<FileInput
						inputAccept=".jpg,.jpeg,.png"
						@onFileSelect="files => onFileSelect(Array.from(files))"
						inputId="presetImages"
						:style="{ display: 'none' }"
					/>
				</div>

				<Button @click="savePreset" :isLoading="loading.savingPreset" :disabled="loading.savingPreset">
					{{ $t('admin.editPresetPage.saveButton') }}
				</Button>
			</div>

			<div class="col-span-1">
				<Button @click="openTagCreationModal" color="outline">
					{{ $t('admin.editPresetPage.createTagButton') }}
				</Button>

				<div class="mt-4 space-y-4">
					<div
						v-for="item in tags.results"
						:key="item._id"
						class="rounded-xl p-6 flex items-center justify-between border"
						:class="{
							'border-[#1E293B]': !isChecked(item),
							'border-blue-600': isChecked(item),
						}"
					>
						<div>
							<Checkbox
								:id="item._id"
								:checked="isChecked(item)"
								@update:modelValue="$event => onUpdateChecked($event, item)"
								required
							>
								<p class="font-bold">
									{{ item.name[loggedUser.lang] }}
								</p>
							</Checkbox>
						</div>

						<div class="flex items-center gap-2">
							<Button color="transparent" size="fit" @click="openEditTagModal(item)">
								<span class="material-icons">edit</span>
							</Button>

							<Button
								color="transparent"
								size="fit"
								:isLoading="loading.deletingTag"
								:disabled="loading.deletingTag"
								@click="deleteTag(item._id)"
							>
								<span class="material-icons text-red-400">delete</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<Modal
		:displayModal="modal.createTag"
		:title="$t('admin.editPresetPage.tagNameModalTitle')"
		@onClose="modal.createTag = false"
	>
		<Input
			inputClass="border-[#334155] mb-6"
			id="tagNamePortuguese"
			type="text"
			:label="$t('admin.editPresetPage.tagNamePortuguese')"
			:placeholder="$t('admin.editPresetPage.tagNamePortuguesePlaceholder')"
			v-model="tag.name['en-US']"
		/>

		<Input
			inputClass="border-[#334155] mb-6"
			id="tagNameEnglish"
			type="text"
			:label="$t('admin.editPresetPage.tagNameEnglish')"
			:placeholder="$t('admin.editPresetPage.tagNameEnglishPlaceholder')"
			v-model="tag.name['pt-BR']"
		/>

		<Input
			inputClass="border-[#334155] mb-6"
			id="tagNameSpanish"
			type="text"
			:label="$t('admin.editPresetPage.tagNameSpanish')"
			:placeholder="$t('admin.editPresetPage.tagNameSpanishPlaceholder')"
			v-model="tag.name['es-ES']"
		/>

		<Button
			@click="createTag"
			:isLoading="loading.creatingTag"
			:disabled="loading.creatingTag || !tag.name['en-US'] || !tag.name['pt-BR'] || !tag.name['es-ES']"
		>
			{{ $t('admin.editPresetPage.createTagSubmitButton') }}
		</Button>
	</Modal>

	<Modal
		:displayModal="modal.editTag"
		:title="$t('admin.editPresetPage.editTagModalTitle')"
		@onClose="modal.editTag = false"
	>
		<Input
			inputClass="border-[#334155] mb-6"
			id="tagNamePortuguese"
			type="text"
			:label="$t('admin.editPresetPage.tagNamePortuguese')"
			:placeholder="$t('admin.editPresetPage.tagNamePortuguesePlaceholder')"
			v-model="tag.name['en-US']"
		/>

		<Input
			inputClass="border-[#334155] mb-6"
			id="tagNameEnglish"
			type="text"
			:label="$t('admin.editPresetPage.tagNameEnglish')"
			:placeholder="$t('admin.editPresetPage.tagNameEnglishPlaceholder')"
			v-model="tag.name['pt-BR']"
		/>

		<Input
			inputClass="border-[#334155] mb-6"
			id="tagNameSpanish"
			type="text"
			:label="$t('admin.editPresetPage.tagNameSpanish')"
			:placeholder="$t('admin.editPresetPage.tagNameSpanishPlaceholder')"
			v-model="tag.name['es-ES']"
		/>

		<Button
			@click="handleEditTag"
			:isLoading="loading.editingTag"
			:disabled="loading.editingTag || !tag.name['en-US'] || !tag.name['pt-BR'] || !tag.name['es-ES']"
		>
			{{ $t('admin.editPresetPage.editTagSubmitButton') }}
		</Button>
	</Modal>
</template>

<script>
import { Button, Input, Modal, Checkbox, FileInput, Spinner, LazyImage, TextArea } from '@/components/default'
import { AdminHeader } from '@/components/admin'

export default {
	components: { AdminHeader, Button, Input, Modal, Checkbox, FileInput, Spinner, LazyImage, TextArea },
	data() {
		return {
			generatedZip: false,
			tags: {
				results: [],
				hasNext: false,
				next: null,
			},
			tag: {
				name: {
					'en-US': '',
					'pt-BR': '',
					'es-ES': '',
				},
			},
			preset: null,
			loading: {
				preset: false,
				saving: false,
				creatingTag: false,
				fetchingTags: false,
				deletingTag: false,
				editingTag: false,
				uploadingImages: false,
				deletingImage: false,
			},
			modal: {
				createTag: false,
				editTag: false,
			},
		}
	},
	async mounted() {
		await Promise.all([this.fetchPreset(), this.fetchTags(true)])

		window.addEventListener('scroll', this.onScroll)
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll)
	},
	methods: {
		async onFileSelect(files) {
			if (this.loading.uploadingImages) {
				return
			}

			this.loading.uploadingImages = true

			const imagesKeys = []

			try {
				for await (const file of files) {
					const { url, key } = await this.axios
						.get(`/files/sign-url/${this.loggedUser?._id}.jpg?uploadPresetImage=true`)
						.then(res => res.data)
					await this.axios.put(url, file)

					imagesKeys.push(key)
				}

				const { data } = await this.axios.put(`/presets/${this.$route.params.id}`, {
					imagesKeys,
				})

				this.preset.images = data.images

				this.generatedZip = true
				setTimeout(() => {
					this.generatedZip = false
				}, 2000)
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.uploadingImages = false
		},
		onScroll() {
			if (!this.tags.hasNext) {
				return
			}

			const isReachedEnd = document.documentElement.scrollHeight - window.innerHeight === window.scrollY

			if (isReachedEnd) {
				this.fetchTags()
			}
		},
		async onUpdateChecked(isChecked, tag) {
			try {
				const actionEndpoint = isChecked ? 'add-tag' : 'remove-tag'
				await this.axios.post(`/presets/${this.$route.params.id}/${actionEndpoint}`, {
					tag: tag._id,
				})

				if (isChecked) {
					this.preset.tags.push(tag._id)
				} else {
					this.preset.tags = this.preset.tags.filter(_tag => _tag !== tag._id)
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		async handleEditTag() {
			if (this.loading.editingTag) {
				return
			}

			this.loading.editingTag = true

			try {
				await this.axios.put(`/tags/${this.tag._id}`, this.tag)

				const tagIndex = this.tags.results.findIndex(tag => tag._id === this.tag._id)
				this.tags.results[tagIndex] = this.tag

				this.modal.editTag = false
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.editingTag = false
		},
		async createTag() {
			if (this.loading.creatingTag) {
				return
			}

			this.loading.creatingTag = true

			try {
				const { data } = await this.axios.post(`/tags`, this.tag)

				this.tags.results = [...this.tags.results, data]
				this.tags.next = data.next
				this.tags.hasNext = data.hasNext

				this.modal.createTag = false
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.creatingTag = false
		},
		async savePreset() {
			if (this.loading.savingPresetPreset) {
				return
			}

			this.loading.savingPreset = true

			try {
				await this.axios.put(`/presets/${this.$route.params.id}`, this.preset)

				this.$toast({
					type: 'success',
					title: this.$t('admin.editPresetPage.presetSavedTitle'),
					message: this.$t('admin.editPresetPage.presetSavedSuccess'),
					duration: 5000,
				})
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					type: 'error',
					title: this.$t('admin.editPresetPage.presetSavedErrorTitle'),
					message: this.$t('admin.editPresetPage.presetSavedError'),
					duration: 5000,
				})
			}

			this.loading.savingPreset = false
		},
		async fetchPreset() {
			if (this.loading.preset) {
				return
			}

			this.loading.preset = true

			try {
				const response = await this.axios.get(`/admin/presets/${this.$route.params.id}`)
				this.preset = response.data
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.preset = false
		},
		async fetchTags(forceFetch) {
			if (this.loading.fetchingTags || (!forceFetch && !this.tags.hasNext)) {
				return
			}

			this.loading.fetchingTags = true

			try {
				const { data } = await this.axios.get(`/tags`, {
					params: {
						next: this.tags.hasNext ? this.tags.next : undefined,
					},
				})

				this.tags.results = [...this.tags.results, ...data.results]
				this.tags.next = data.next
				this.tags.hasNext = data.hasNext
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.fetchingTags = false
		},
		async deleteTag(tagId) {
			if (this.loading.deletingTag) {
				return
			}

			this.loading.deletingTag = true

			try {
				await this.axios.delete(`/tags/${tagId}`)
				this.tags.results = this.tags.results.filter(tag => tag._id !== tagId)
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.deletingTag = false
		},
		openEditTagModal(tag) {
			this.tag = tag
			this.modal.editTag = true
		},
		openTagCreationModal() {
			this.tag = {
				name: {
					'en-US': '',
					'pt-BR': '',
					'es-ES': '',
				},
			}
			this.modal.createTag = true
		},
		isChecked(tag) {
			if (!tag || !this.preset) {
				return
			}

			return this.preset.tags?.includes(tag._id)
		},
		async deleteImage(image) {
			if (this.loading.deletingImage) {
				return
			}

			this.loading.deletingImage = true

			try {
				const { data } = await this.axios.put(`/presets/${this.$route.params.id}/delete-image`, {
					image,
				})

				this.preset.images = data.images
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.deletingImage = false
		},
	},
	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
	head: {
		title: `RealityStudio - Edit Preset`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Edit Preset `,
			},
		],
	},
}
</script>
