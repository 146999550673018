<template>
	<div>
		<Image2ImageLayout
			localePrefix="removeBackgroundTool"
			gtagPrefix="remove_background_tool"
			toolType="remove-background"
			apiSubmitEndpoint="/inferences/remove-background"
			previewVideoSrc="https://cdn.realitystudio.ai/assets/videos/remove-background.mp4"
			pricingKey="removeBackground"
			:showTransparentImage="true"
		>
		</Image2ImageLayout>
	</div>
</template>

<script>
import { Image2ImageLayout } from '@/components/tools'

export default {
	components: {
		Image2ImageLayout,
	},
	head: {
		title: `RealityStudio - Remove Image Background`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Remove image background easily with RealityStudio. Upload your image and get a transparent background in seconds.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Remove Image Background `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Remove image background easily with RealityStudio. Upload your image and get a transparent background in seconds.',
			},
		],
	},
}
</script>
