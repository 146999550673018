<template>
	<div>
		<p v-if="tabSelected === 'others'" class="text-base text-white font-bold leading-6 mb-4">
			{{ getPlanStatus }}
		</p>
		<div class="flex items-center">
			<div class="flex flex-1 relative">
				<NotificationCardImage
					v-if="tabSelected === 'avatarProcessing'"
					:image="getNotificationVideo()"
					:tabSelected="tabSelected"
					class="mr-4"
				/>
				<router-link
					v-if="tabSelected === 'avatarCompleted'"
					:to="notification.entity.imageId ? `/images/${notification.entity.imageId}` : '/my-account/library'"
				>
					<NotificationCardImage
						:image="getNotificationImage(notification)"
						:tabSelected="tabSelected"
						class="mr-4"
					/>
				</router-link>
				<NotificationCardImage
					v-if="tabSelected === 'others'"
					:image="getNotificationImage(notification)"
					:tabSelected="tabSelected"
					class="mr-4"
				/>
				<div>
					<div v-if="tabSelected === 'avatarProcessing'">
						<div class="mb-4">
							<strong class="text-base text-white leading-6">{{ notificationTitle }}</strong>
							<p class="text-sm leading-5 text-gray-400">{{ getCreatedDate(notification.createdAt) }}</p>
						</div>
						<p class="text-white">{{ getProcessingStep }}</p>
						<p class="text-yellow-500">{{ getTimeAgoString(notification.createdAt) }}</p>
					</div>

					<div v-if="tabSelected === 'avatarCompleted'">
						<router-link
							:to="
								notification.entity.imageId
									? `/images/${notification.entity.imageId}`
									: '/my-account/library'
							"
						>
							<div class="mb-4">
								<strong class="text-base text-white leading-6">{{ notificationTitle }}</strong>
								<p class="text-sm leading-5 text-gray-400">
									{{ getCreatedDate(notification.createdAt) }}
								</p>
							</div>
							<p class="text-white">{{ $t('myAccountNotifications.avatarCard.avatarCompleted') }}</p>
							<p class="text-base leading-6 text-gray-400">
								({{ $t('myAccountNotifications.avatarCard.completed') }})
							</p>
						</router-link>
					</div>

					<div v-if="tabSelected === 'others'">
						<div class="mb-4">
							<strong class="text-base text-white leading-6">{{ notificationTitle }}</strong>
							<p class="text-sm leading-5 text-gray-400">{{ getCreatedDate(notification.createdAt) }}</p>
						</div>
						<p class="text-base leading-6 font-normal">
							{{ notification.entity.plan.points }} RealityPoints
						</p>

						<p class="text-base leading-6 font-normal">
							{{
								$t('myAccountNotifications.avatarCard.currency', {
									currency: notification.entity.plan.price.toFixed(2),
								})
							}}
							/
							{{
								$t('myAccountNotifications.avatarCard.plan', {
									planType: getPlanType,
								})
							}}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { LazyImage } from '@/components/default'
import { NotificationCardImage } from '@/components/account/notification'
export default {
	components: { LazyImage, NotificationCardImage },
	props: {
		notification: {
			type: Object,
			required: true,
		},
		tabSelected: {
			type: String,
		},
	},
	methods: {
		getNotificationImage(notification) {
			return notification.entity.image
		},

		getNotificationVideo() {
			return '/web/images/temporary/videos/120x120 851KB.mp4'
		},

		getCreatedDate(createdAtDate) {
			if (this.$i18n.locale === 'pt-BR' || this.$i18n.locale === 'es-ES') {
				return this.$dayjs(createdAtDate).format('DD/MM/YYYY - HH:mm')
			}

			if (this.$i18n.locale === 'en-US') {
				return this.$dayjs(createdAtDate).format('MM/DD/YYYY - HH:mm')
			}
		},

		getTimeAgoString(createdAt) {
			const now = this.$dayjs()
			const createdDate = this.$dayjs(createdAt)
			const diffInMinutes = now.diff(createdDate, 'minute')
			const diffInHours = now.diff(createdDate, 'hour')
			const diffInDays = now.diff(createdDate, 'day')

			if (diffInMinutes < 1) return this.$t('myAccountNotifications.avatarCard.now')

			let timeString = ''
			if (diffInMinutes < 60) {
				timeString = `${diffInMinutes} ${
					diffInMinutes === 1
						? this.$t('myAccountNotifications.avatarCard.minute')
						: this.$t('myAccountNotifications.avatarCard.minutes')
				}`
			} else if (diffInHours < 24) {
				timeString = `${diffInHours} ${
					diffInHours === 1
						? this.$t('myAccountNotifications.avatarCard.hour')
						: this.$t('myAccountNotifications.avatarCard.hours')
				}`
			} else {
				timeString = `${diffInDays} ${
					diffInDays === 1
						? this.$t('myAccountNotifications.avatarCard.day')
						: this.$t('myAccountNotifications.avatarCard.days')
				}`
			}

			return timeString
		},
	},
	computed: {
		notificationTitle() {
			const isProcessingAvatar = this.tabSelected === 'avatarProcessing'
			const isCompletedAvatar = this.tabSelected === 'avatarCompleted'

			if (isProcessingAvatar || isCompletedAvatar) {
				return this.notification.entity.preset?.name
			}

			return this.$t('myAccountNotifications.avatarCard.planName', {
				planName: this.notification.entity.plan.name,
			})
		},
		getProcessingStep() {
			if (!this.notification) {
				return
			}

			const [entity, action] = this.notification.type.split('-')
			const capitalizedAction = action.charAt(0).toUpperCase() + action.slice(1)

			return this.$t(`myAccountNotifications.avatarCard.${entity + capitalizedAction}`)
		},
		getPlanStatus() {
			if (!this.notification) {
				return
			}

			const [entity, action] = this.notification.type.split('-')
			const capitalizedAction = action.charAt(0).toUpperCase() + action.slice(1)

			return this.$t(`myAccountNotifications.avatarCard.${entity + capitalizedAction}`)
		},
		getPlanType() {
			return this.$t(`myAccountNotifications.avatarCard.${this.notification.entity.plan.billingCycle.unit}Plan`)
		},
	},
}
</script>
