<template>
	<Button @click="handleLoginWithGoogle" :isLoading="loading">
		<img class="mr-2" src="/web/icons/google.svg" alt="Google" />
		{{ $t('loginPage.loginWithGoogle') }}
	</Button>
</template>
<script>
import Button from '@/components/default/Button.vue'
import { Capacitor } from '@capacitor/core'

export default {
	name: 'GoogleLogin',
	components: {
		Button,
	},
	props: {
		redirectTo: {
			type: String,
		},
	},
	data() {
		return {
			GoogleAuth: null,
			loading: false,
		}
	},
	methods: {
		async handleLoginWithGoogle() {
			try {
				this.loading = true
				if (Capacitor.getPlatform() === 'web') {
					this.captureEvent('login_google_attempt')
					location.href = `/api/auth/google?${this.redirectTo ? `redirectTo=${this.redirectTo}` : ''}`
				} else {
					const response = await this.GoogleAuth.signIn()

					const { user } = await this.axios
						.get(`/auth/google/callback?code=${response.serverAuthCode}&skipRedirect=true`)
						.then(res => res.data)

					this.$store.commit('setUser', user)
					this.captureEvent('login_google_success')

					if (this.redirectTo) {
						this.$router.push(this.redirectTo)
						return
					}

					this.$router.push('/')
					this.$requestPushNotifications()
				}
			} catch (error) {
				this.sentryCaptureException(error)

				this.$toast({
					type: 'error',
					title: 'Oooops!',
					message: this.$t('loginPage.loginError'),
				})

				this.captureEvent('login_google_error')
			}
			this.loading = false
		},
	},
	mounted() {
		if (Capacitor.getPlatform() !== 'web') {
			import('@codetrix-studio/capacitor-google-auth').then(({ GoogleAuth }) => {
				this.GoogleAuth = GoogleAuth

				this.GoogleAuth.initialize({
					clientId: import.meta.env.VITE_GOOGLE_WEB_APP_ID,
					scopes: ['email', 'profile'],
				})
			})
		}
	},
}
</script>
