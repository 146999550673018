<template>
	<div>
		<p for="" class="mb-2" v-if="label">{{ label }}</p>
		<div class="rounded-xl bg-transparent border border-gray-900" v-tw-merge>
			<div class="flex items-center justify-between p-6 cursor-pointer" @click.prevent="handleOpen">
				<h1 class="text-base mr-4 whitespace-nowrap overflow-hidden text-ellipsis">
					{{ selectTitle }}
				</h1>

				<div class="flex">
					<span
						class="material-symbols-outlined text-2xl mr-4"
						@click="handleReset"
						v-if="!!value && !hideClear"
					>
						close
					</span>
					<span
						class="material-symbols-outlined text-2xl transform transition-transform duration-500"
						:class="{
							'rotate-180': isOpen,
						}"
					>
						expand_more
					</span>
				</div>
			</div>

			<div
				v-if="isOpen"
				class="bg-black/80 fixed w-screen h-screen top-0 left-0 z-10"
				@click="isOpen = false"
			></div>

			<transition
				enter-active-class="transition ease-out duration-100"
				enter-from-class="transform opacity-0 translate-y-48"
				enter-to-class="transform opacity-100"
			>
				<div
					ref="dropdown"
					v-if="isOpen"
					class="fixed md:rounded-t-xl max-w-[592px] md:left-1/2 md:-translate-x-1/2 overflow-y-scroll h-max w-full bottom-0 left-0 bg-[#02070D] border border-gray-900 pb-16 z-[60]"
					:class="dropdownClass"
					v-tw-merge
				>
					<div class="flex items-center justify-between p-4">
						<h1 class="text-xl font-bold">
							{{ menuTitle }}
						</h1>
						<button
							type="button"
							class="border border-gray-900 bg-transparent w-20 h-20"
							@click="isOpen = false"
						>
							<span class="material-symbols-outlined text-3xl"> close </span>
						</button>
					</div>
					<div class="bg-gray-900 w-full h-[1px] mb-6"></div>
					<div
						v-for="(option, index) in options"
						class="mx-4 pb-[18px]"
						@click="() => option.disabled || handleSelect(option.value)"
						:key="`option-${index}`"
						:class="{
							'cursor-pointer': !option.disabled,
							'cursor-not-allowed opacity-50': option.disabled,
						}"
					>
						<p>{{ option.label }}</p>
						<div class="bg-gray-900 w-full h-[1px] mt-[18px]"></div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
		},
		options: {
			type: Array,
			required: true,
		},
		value: {
			type: String,
		},
		menuTitle: {
			type: String,
			required: true,
		},
		hideClear: {
			type: Boolean,
			default: true,
		},
		dropdownClass: {
			type: String,
		},
	},
	data() {
		return {
			isOpen: false,
		}
	},
	methods: {
		onScroll() {
			if (!this.isOpen) {
				return
			}

			const { scrollHeight, scrollTop, clientHeight } = this.$refs.dropdown
			if (scrollHeight - scrollTop === clientHeight) {
				this.$emit('onReachEnd')
			}
		},
		handleOpen() {
			this.isOpen = !this.isOpen

			this.$nextTick(() => {
				if (this.isOpen && this.$refs.dropdown) {
					this.$refs.dropdown.addEventListener('scroll', this.onScroll)
				} else {
					this.$refs.dropdown.removeEventListener('scroll', this.onScroll)
				}
			})
		},
		handleReset() {
			this.$emit('onChange', null)

			// TODO: improve this code
			setTimeout(() => {
				this.isOpen = false
			}, 1)
		},
		handleSelect(optionValue) {
			this.isOpen = false

			this.$emit('onChange', optionValue)
		},
	},
	computed: {
		selectTitle() {
			return this.options.find(option => option.value === this.value)?.label || this.$t('select.emptyText')
		},
	},
	watch: {
		isOpen(newValue) {
			if (newValue) {
				document.body.classList.add('!overflow-hidden')
			} else {
				document.body.classList.remove('!overflow-hidden')
			}
		},
	},
	beforeUnmount() {
		document.body.classList.remove('!overflow-hidden')
	},
	emits: ['onChange', 'onReachEnd'],
}
</script>
