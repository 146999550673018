<template>
	<div>
		<div class="flex flex-col pb-4">
			<div class="flex justify-between">
				<div>
					<h1 class="text-xl leading-7 font-semibold mt-4 mb-2">
						{{ prediction.models[0].name }}
					</h1>
					<h2 class="text-base font-normal">
						{{ prediction.preset.name }}
					</h2>
				</div>
				<button @click="showChangeNameModal = true">
					<span class="material-icons">edit</span>
				</button>
			</div>
			<div class="grid grid-cols-4 gap-4 my-4">
				<div
					v-if="prediction.status === 'processing' && prediction?.images?.length"
					:key="`processing-image-${i}`"
					class="w-full h-[110px] flex items-center justify-center bg-gray-400 rounded-xl animate-pulse"
				>
					<p class="font-bold text-xl">+4</p>
				</div>
				<template v-if="prediction?.images?.length">
					<router-link
						v-for="(image, index) in prediction.images"
						class="h-[110px]"
						:key="image._id"
						:to="`/my-account/library`"
					>
						<LazyImage
							imgClass="rounded-xl w-full h-full object-cover"
							:src="image.url"
							alt="Avatar image"
						/>
					</router-link>
				</template>
				<div
					v-if="prediction.status === 'processing' && !prediction?.images?.length"
					v-for="i in 4"
					:key="`processing-image-${i}`"
					class="w-full h-[110px] bg-gray-400 rounded-xl animate-pulse"
				/>

				<div
					v-if="prediction.status === 'failed' && !prediction?.images?.length"
					v-for="i in 4"
					:key="`failed-image-${i}`"
					class="h-[110px]"
				>
					<LazyImage imgClass="rounded-xl w-full h-[110px] object-cover" src="''" alt="Error" />
				</div>
			</div>
			<p v-if="prediction.status === 'failed'" class="text-base">
				{{ $t('myAccountLibrary.failed') }}
			</p>
			<p v-if="prediction.status === 'processing' && !prediction?.images?.length" class="text-base">
				{{ $t('myAccountLibrary.processing') }}
			</p>
			<Button
				:href="`/my-account/library`"
				v-if="prediction?.images?.length"
				class="flex items-center gap-3 !py-4"
				color="outline"
			>
				<span class="text-sm font-bold">
					{{ $t('myAccountLibrary.seeAvatarsBtn') }}
				</span>
				<span class="material-symbols-outlined text-base"> arrow_forward </span>
			</Button>
		</div>

		<Modal
			:title="$t('myAccountLibrary.changeNameModal.title')"
			:displayModal="showChangeNameModal"
			@onClose="showChangeNameModal = false"
		>
			<p class="mb-6 text-gray-300 text-center">
				{{ $t('myAccountLibrary.changeNameModal.description') }}
			</p>
			<Input
				inputClass="!border-[#2C3843]"
				id="name"
				v-model="modelName"
				:label="$t('myAccountLibrary.changeNameModal.label')"
				:placeholder="$t('myAccountLibrary.changeNameModal.placeholder')"
				type="text"
				required
			/>
			<p v-if="!inputValidation" class="text-red-500 text-sm mt-2">
				{{ $t('myAccountLibrary.changeNameModal.nameError') }}
			</p>

			<Button @click="handleChangeName" :disabled="!inputValidation" class="mt-6">
				<Spinner v-if="isChangingModelName" class="mr-2" />
				<span v-else>
					{{ $t('myAccountLibrary.changeNameModal.submitButton') }}
				</span>
			</Button>
		</Modal>
	</div>
</template>

<script>
import { LazyImage, Input, Button, Modal, Spinner } from '@/components/default'

export default {
	components: {
		Modal,
		LazyImage,
		Button,
		Input,
		Spinner,
	},
	props: {
		prediction: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showChangeNameModal: false,
			modelName: '',
			isChangingModelName: false,
		}
	},
	created() {
		this.modelName = this.prediction.models[0].name
	},
	emits: ['onSuccessfulChangeName'],
	methods: {
		handleOpenModal(item) {
			this.selectedId = item.models[0]._id
			this.showChangeNameModal = true
		},
		handleChangeName() {
			this.isChangingModelName = true

			try {
				this.axios.put(`/models/${this.prediction.models[0]._id}`, {
					name: this.modelName,
				})

				this.$emit('onSuccessfulChangeName', {
					modelId: this.prediction.models[0]._id,
					modelName: this.modelName,
				})

				this.$toast({
					type: 'success',
					title: this.$t('common.toastTitle.success'),
					message: this.$t('myAccountLibrary.changeNameModal.successToastMessage'),
				})

				this.showChangeNameModal = false
			} catch (error) {
				this.sentryCaptureException(error)

				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
				})
			}

			this.isChangingModelName = false
		},
	},
	computed: {
		inputValidation() {
			const MIN_LENGTH = 3
			const MAX_LENGTH = 20

			return this.modelName.length > MIN_LENGTH && this.modelName.length <= MAX_LENGTH
		},
	},
}
</script>
