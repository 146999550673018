<template>
	<div
		class="md:left-1/2 md:-translate-x-1/2"
		:class="
			hasBackground
				? [
						'w-full md:!w-[430px] px-4 py-2 fixed background-floating-button-height floating-width mb-6 md:mb-12 z-20 md:left-1/2 md:-translate-x-1/2 md:-ml-px  border-t md:bg-app-blue border-radial-1-card md:border-0',
						backgroundColor,
				  ]
				: 'floating-width fixed bottom-tabs-bar-height md:-ml-px mb-6 md:mb-12 z-10 md:!w-[414px]'
		"
	>
		<slot></slot>
	</div>
</template>
<script>
export default {
	props: {
		hasBackground: {
			type: Boolean,
			default: false,
		},
		backgroundColor: {
			type: String,
			default: 'bg-radial-2-card',
		},
	},
}
</script>
<style>
.floating-width {
	width: calc(100vw - 2rem);
}

.background-floating-button-height {
	bottom: 4.6rem;
}

@media screen and (min-width: 768px) {
	.background-floating-button-height-no-menu {
		margin-bottom: 26px;
	}
}
</style>
