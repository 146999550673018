<template>
	<div>
		<div v-if="!loading.verifyingPictures">
			<div v-if="!loading.creatingModel || (loading.creatingModel && showGenderSelectionMenu)" class="relative">
				<Button
					class="absolute z-10 cursor-pointer w-[64px] h-[64px] rounded-full left-4 top-4"
					color="transparent"
					v-if="canGoBack"
					itemPosition="none"
					size="fit"
					@click="$router.back()"
				>
					<span class="material-symbols-rounded text-white text-2xl"> arrow_back </span></Button
				>
				<Button
					class="absolute z-10 cursor-pointer w-[64px] h-[64px] rounded-full right-4 top-4"
					color="transparent"
					@click="handleSharePreset"
					itemPosition="none"
					size="fit"
				>
					<span class="material-symbols-rounded text-white text-2xl"> share </span></Button
				>

				<div class="absolute bottom-0 w-full h-full bg-gradient-to-t from-app-blue opacity-100" />
				<LazyImage
					v-if="!!presetData"
					class="aspect-square"
					imgClass="object-cover h-full w-full"
					:src="presetData?.images[0]"
					alt="Preset image"
				/>
				<div class="bottom-0 mb-4 absolute w-full text-center">
					<p class="text-2xl text-white font-bold">
						{{ presetData?.name }}
					</p>
					<p class="mt-4" v-if="models.length && !showExamples">
						{{ $t('common.cost') }}
						<strong>{{
							`${showUploadImage ? presetData?.price + presetData?.modelPrice : presetData?.price} ${$t(
								'common.realityPoints'
							)}`
						}}</strong>
					</p>
				</div>
			</div>

			<div v-if="loading.creatingModel && !hasCreatingModelError && !showGenderSelectionMenu" class="relative">
				<ShowcaseSlides
					:slides="tipArr"
					:slideChangeIntervalInMS="8000"
					@onSlideChange="tip => (activeTip = tip)"
				/>

				<div
					class="w-full opacity-80 absolute top-0 left-0 h-full bg-gradient-to-t from-black to-transparent"
				></div>

				<img
					class="absolute left-1/2 -translate-x-1/2 mt-4 top-0"
					src="/web/images/logo.svg"
					alt="RealityStudio"
				/>
				<div class="absolute bottom-0 left-1/2 -translate-x-1/2 mb-4 text-center">
					<p>{{ $t('presetDetails.elapsedTime') }}</p>
					<p class="font-bold text-white text-3xl">
						{{ elapsedTimes }}
					</p>
				</div>
			</div>

			<div v-if="!loggedUser">
				<div class="page-container">
					<AuthenticationBox
						v-if="!loggedUser"
						:redirectTo="`/new-avatar/${$route.params.id}`"
						pageName="new_avatar"
					/>
				</div>
			</div>

			<div class="page-container" v-if="loggedUser && !showExamples">
				<div class="justify-center flex items-center mt-4" v-if="!loading.creatingModel && !showExamples">
					<p class="align-middle text-sm" v-html="$t('presetDetails.madeByRealityAvatar')"></p>

					<span class="material-icons text-yellow-400 ml-1 text-base">verified</span>
				</div>

				<div
					v-if="
						showUploadImage &&
						loggedUser?.hasSubscription &&
						userHasEnoughPoints &&
						!showGenderSelectionMenu &&
						!showExamples
					"
				>
					<div v-if="!loading.creatingModel">
						<Input
							class="mt-6"
							id="modelName"
							v-model="modelName"
							:label="$t('presetDetails.uploadPage.avatarNameInputLabel')"
							:placeholder="$t('presetDetails.uploadPage.avatarNameInputPlaceholder')"
							type="text"
							required
						/>
						<p class="text-red-500 text-sm mt-2" v-if="!modelNameValidation">
							{{ modelName.length < 4 ? 'The name must be at least 4 characters long' : '' }}
							{{ modelName.length > 36 ? 'The name must be less than 36 characters long' : '' }}
						</p>

						<button
							class="mt-6 font-bold text-white text-lg"
							v-if="loggedUser?.viewedInstructions"
							@click="showInstructionsModal = true"
						>
							{{ $t('presetDetails.uploadPage.howToTakePhotos') }}
						</button>
						<p
							class="text-lg mb-4 mt-6 flex items-center"
							:class="{
								'text-red-400': pictures.length < totalPicturesNeeded,
								'text-lime-500': pictures.length === totalPicturesNeeded,
							}"
						>
							<span class="material-icons mr-2">{{
								pictures.length < totalPicturesNeeded ? 'error' : 'check_circle'
							}}</span>
							<span class="font-bold mr-1">{{ pictures.length === 0 ? `${pictures.length}%` : `${pictures.length}0%` }}</span>
							<p>{{ $t('presetDetails.uploadPage.photosAdded') }}</p>
						</p>
						<div>
							<div class="w-full mb-2 h-2 bg-neutral-800 rounded-xl overflow-hidden">
								<div
									class="h-full rounded-xl transition-width duration-300 ease-in-out"
									:class="{
										'bg-red-400': pictures.length < totalPicturesNeeded,
										'bg-lime-500': pictures.length === totalPicturesNeeded,
									}"
									:style="{ width: progressPercentage }"
								></div>
							</div>
						</div>
						<div
							:class="[
								'grid grid-cols-2 gap-4',
								{ 'pb-24': totalPicturesValidation && modelNameValidation },
							]"
						>
							<div v-if="pictures.length < totalPicturesNeeded">
								<label
									class="cursor-pointer flex items-center justify-center border border-dashed rounded-xl border-gray-800 h-[260px]"
									for="userPicturesInput"
								>
									<div class="flex flex-col items-center">
										<span class="material-symbols-outlined text-3xl mb-2">add_circle</span>
										<p class="font-bold">
											{{ $t('common.addPhotos') }}
										</p>
										<p
											class="text-lg mb-4 mt-6"
											:class="{
												'text-red-400': pictures.length < totalPicturesNeeded,
												'text-lime-500': pictures.length === totalPicturesNeeded,
											}"
										>
											<span class="font-bold"
												>{{ pictures.length }}/{{ totalPicturesNeeded }}</span
											>
										</p>
									</div>
								</label>
								<FileInput
									inputAccept="image/jpeg,image/png"
									@onFileSelect="files => onFileSelect(Array.from(files))"
									inputId="userPicturesInput"
									:style="{
										display: 'none',
									}"
								/>
							</div>
							<div v-for="(item, index) in pictures" :key="`uploaded-picture-${index}`" class="relative">
								<button
									class="w-[32px] h-[32px] bg-red-700 rounded-full absolute right-4 top-4 flex items-center justify-center cursor-pointer"
									@click="pictures.splice(index, 1)"
								>
									<span class="material-symbols-outlined text-base">delete</span>
								</button>
								<img class="object-cover h-[260px] rounded-xl w-full" :src="item.url" alt="Image" />
							</div>
						</div>
						<FloatingButton v-if="totalPicturesValidation && modelNameValidation">
							<Button @click="verifyPictures">
								{{ $t('presetDetails.uploadPage.verifyPhotosButton') }}
							</Button>
						</FloatingButton>
					</div>

					<div v-if="loading.creatingModel && !hasCreatingModelError">
						<div class="text-center mt-6">
							<h3 class="text-2xl font-bold">{{ activeTip?.title }}</h3>
							<p class="text-base mt-4 text-gray-300" v-html="activeTip?.description"></p>
						</div>
					</div>
					<div
						v-if="hasCreatingModelError"
						class="flex items-center justify-center flex-col text-center overflow-hidden"
						:style="{
							height: fullScreenHeight + 'px',
						}"
					>
						<img src="/web/images/admin/warning.png" alt="Warning Icon" />

						<p class="font-bold text-2xl mt-6 mb-4">
							{{ $t('presetDetails.modelCreationError.title') }}
						</p>

						<p class="text-gray-300 mb-6">
							{{ $t('presetDetails.modelCreationError.description') }}
						</p>

						<Button @click="handleResetStates">{{ $t('common.tryAgain') }}</Button>
					</div>
				</div>

				<div
					v-if="
						!showUploadImage &&
						models &&
						loggedUser?.hasSubscription &&
						userHasEnoughPoints &&
						!showGenderSelectionMenu &&
						!showExamples
					"
					class="divide-y-2 divide-gray-900 space-y-4"
				>
					<div
						@click="() => model.status === 'completed' && selectModel(model._id)"
						v-for="model in models"
						:key="model._id"
						class="cursor-pointer"
					>
						<div
							class="mt-4 gap-4 grid grid-cols-4"
							v-if="model.status === 'completed' && !!model.images.length"
						>
							<LazyImage
								v-for="item in model.images.slice(0, 4)"
								lazyWrapperClass="border border-radial-1-card rounded-xl flex items-center justify-center w-full aspect-square"
								imgClass="rounded-xl object-cover aspect-square w-full border-2 border-radial-1-card"
								:key="item"
								:src="item"
								alt="Model Image"
								class="col-span-1"
							/>
						</div>
						<div
							class="grid grid-cols-4 gap-4 mt-4"
							v-if="model.status === 'completed' && !model.images.length"
						>
							<div
								v-for="i in 4"
								class="w-full aspect-square rounded-xl border border-dashed border-gray-800"
							></div>
						</div>
						<div v-if="model.status === 'processing'" class="grid grid-cols-4 gap-4">
							<div
								v-for="i in 4"
								:key="`${model._id}-processing-${i}`"
								class="animate-pulse col-span-1 bg-radial-2-card border border-white/5 rounded-xl h-[100px]"
							/>
						</div>
						<div class="flex items-center mt-4" v-if="model.status === 'processing'">
							<p class="text-lg font-medium mr-2">
								{{ $t('presetDetails.processingModel') }}
							</p>
						</div>

						<Button class="flex items-center mt-4 cursor-pointer" v-if="model.status === 'completed'">
							<span class="text-sm mr-2 font-bold">
								{{ $t('common.createAvatar') }}
							</span>
							<span class="material-icons-outlined text-base"> arrow_forward </span>
						</Button>
					</div>

					<div v-if="isFetchingModels" class="flex items-center justify-center pt-4">
						<Spinner />
					</div>
				</div>
				<button
					ref="newUploadContainer"
					@click="showUploadImage = true"
					class="px-6 py-4 border border-radial-1-card rounded-xl mt-6 mb-8 cursor-pointer text-center"
					v-if="displayNewUploadSection"
				>
					<p class="text-xl font-semibold text-gray-200">{{ $t('presetDetails.newUploadTitle') }}</p>
					<p class="my-4 text-gray-300 text-base">
						{{ $t('presetDetails.newUploadCostWarning') }}
						<strong>
							{{ `${presetData?.price + presetData?.modelPrice} ${$t('common.realityPoints')}` }}.</strong
						>
					</p>
					<Button>
						<div class="flex items-center">
							<span class="material-icons text-base mr-2">add_circle </span>
							<span class="text-sm">{{ $t('common.newUpload') }}</span>
						</div>
					</Button>
				</button>
			</div>
			<div v-if="showExamples && !loading.page" class="mt-4">
				<div
					v-if="loggedUser && loggedUser?.hasSubscription && !userHasEnoughPoints"
					class="my-6 page-container"
				>
					<p class="text-center text-xl text-white mb-4" v-html="$t('presetDetails.insufficientPoints')"></p>
					<PointsBalance v-if="!userHasEnoughPoints" />
				</div>

				<div class="my-6" v-if="loggedUser && !loggedUser?.hasSubscription">
					<div class="page-container text-center text-white">
						<p v-html="$t('presetDetails.subscriptionRequired')"></p>
						<strong>{{ getLowestPlanInfo }}</strong>
					</div>
				</div>
				<div class="page-container">
					<PresetImagesSwiper v-if="presetData && presetData.images.length" :images="presetData.images" />
				</div>
				<div v-if="loggedUser">
					<FloatingButton hasBackground>
						<Button @click="onCreateFromTheme" v-if="loggedUser?.hasSubscription && userHasEnoughPoints"
							>{{ $t('presetDetails.createFromTheme') }}
						</Button>
						<Button href="/checkout/plans" v-if="!loggedUser?.hasSubscription"
							>{{ $t('presetDetails.startingFrom') }} {{ getLowestPlanInfo }}
						</Button>
					</FloatingButton>
				</div>
			</div>
		</div>

		<div v-if="showGenderSelectionMenu" class="mt-4 text-center px-4">
			<p class="font-bold text-gray-500">{{ $t('presetDetails.genderSelectionPage.importantAnnouncement') }}</p>
			<p class="mt-2 text-gray-500">
				{{ $t('presetDetails.genderSelectionPage.modelCreationCostWarning') }}
				<strong> {{ `${presetData?.price + presetData?.modelPrice} ${$t('common.realityPoints')}` }}.</strong>
			</p>

			<p class="text-xl mt-6 font-bold">{{ $t('presetDetails.genderSelectionPage.genderIdentityPrompt') }}</p>
			<div class="mt-6">
				<button
					:class="[
						'border w-full p-6 text-left rounded-xl mb-4 font-bold flex items-center justify-between',
						selectedGenderIdentity?.id == gender.id ? 'border-white' : 'border-extended-blue-gray-900',
					]"
					v-for="gender in genderOptions"
					:key="`gender-option-${gender.id}`"
					@click="selectedGenderIdentity = gender"
				>
					<div class="flex items-center">
						<span class="material-symbols-rounded text-white text-5xl mr-2">
							{{ gender.value === 'male' ? 'male' : 'female' }}
						</span>
						<div>{{ $t(gender.text) }}</div>
					</div>
					<input
						type="radio"
						:id="`${gender.id}`"
						name="optionGender"
						class="hidden peer"
						:checked="selectedGenderIdentity?.id == gender.id"
						@change="selectedGenderIdentity = gender"
					/>
					<label
						name="optionGender"
						:for="`${gender.id}`"
						class="w-6 h-6 bg-black border-2 border-white rounded-full cursor-pointer peer-checked:bg-white"
					></label>
				</button>
			</div>

			<Button @click="createNewModel" class="mt-6" :disabled="!selectedGenderIdentity?.text?.length">
				<Spinner v-if="loading.creatingModel" />
				<span v-else>
					{{ $t('presetDetails.validPicturesButton') }}
				</span>
			</Button>

			<Button
				@click="onCancelGenderIdentitySelectionScreen"
				color="outline"
				class="mt-6"
				:disabled="loading.creatingModel"
			>
				{{ $t('presetDetails.validPicturesCancel') }}
			</Button>
		</div>

		<div v-show="loading.verifyingPictures" class="h-[calc(100vh-6rem)] md:h-[calc(100vh-12rem)]">
			<div class="relative overflow-hidden">
				<video
					class="aspect-square object-cover w-100"
					src="https://cdn.realitystudio.ai/assets/videos/processing-loading.mp4"
					loop
					autoplay
					muted
					:controls="false"
					playsinline
				></video>
				<div class="w-full absolute left-0 h-full bg-gradient-to-t from-app-blue to-transparent top-1"></div>
			</div>
			<div v-if="!isFinishedVerifying">
				<p class="text-xl text-center font-bold mt-4">
					{{ $t('presetDetails.verifyingPictures') }}
				</p>

				<p class="text-gray-300 text-center w-10/12 mx-auto mt-4">
					{{ $t('presetDetails.verifyingPicturesSubtitle') }}
				</p>

				<p class="text-4xl font-bold text-center mt-4">{{ verifiedPictures }}/10</p>

				<div class="flex flex-col" v-if="showRetryUploadButton">
					<p class="mt-6 text-lg font-bold text-center">
						{{ $t('presetDetails.photoVerificationTakingTooLong') }}
					</p>
					<button @click="tryUploadAgain(false)" class="mt-4 text-lg font-bold text-red-500">
						{{ $t('common.tryAgain') }}
					</button>
				</div>
			</div>

			<div
				v-if="isFinishedVerifying && !hasInvalidPictures"
				class="flex justify-center items-center flex-col w-full mt-4"
			>
				<p class="text-xl font-bold">{{ $t('common.toastTitle.success') }}</p>

				<div class="bg-lime-500 rounded-full w-16 h-16 flex animate-jump mt-4">
					<span class="material-symbols-outlined text-[64px]">check</span>
				</div>
			</div>

			<div
				v-if="isFinishedVerifying && hasInvalidPictures"
				class="flex justify-center items-center flex-col w-full mt-4"
			>
				<p class="text-xl font-bold">{{ $t('presetDetails.invalidPicturesTitle') }}</p>
				<div class="bg-red-500 rounded-full w-16 h-16 flex animate-jump mt-4">
					<span class="material-symbols-outlined text-[64px]">close</span>
				</div>
			</div>
		</div>

		<Modal
			:displayModal="modal.invalidPictures"
			:title="$t('presetDetails.invalidPicturesTitle')"
			@onClose="onCloseInvalidPicturesModal"
		>
			<div class="flex items-center">
				<div class="bg-red-500 w-8 h-8 rounded-full flex items-center justify-center">
					<span class="material-symbols-outlined text-black">close</span>
				</div>
				<p class="text-white ml-2 text-xl font-medium">
					{{ `${currentInvalidPictureIndex + 1}/${invalidPictures.length}` }}
					{{ invalidPictures.length === 1 ? $t('presetDetails.photo') : $t('presetDetails.photos') }}
				</p>
			</div>
			<p class="mt-2 text-base text-center text-gray-300">{{ $t('presetDetails.reason') }}</p>
			<p class="mt-2 text-xl font-bold text-center text-white">
				{{ getInvalidPictureReason(currentInvalidPicture.reason) }}
			</p>
			<div class="grid grid-cols-12 gap-2 my-6 items-center">
				<button class="col-span-1 col-start-3 text-end">
					<span class="material-symbols-outlined text-[32px]" @click="goToPreviousInvalidPicture"
						>chevron_left</span
					>
				</button>
				<div class="relative col-span-6" ref="invalidPictureImage" v-if="currentInvalidPicture">
					<div class="absolute bg-red-500 flex items-center justify-center top-2 left-2 z-[1] rounded-full">
						<span class="material-symbols-outlined text-[32px]">close</span>
					</div>
					<LazyImage
						:src="currentInvalidPicture.url"
						imgClass="h-[260px] object-cover w-full rounded-xl"
						:height="260"
						alt="Invalid image"
					/>
				</div>
				<button class="col-span-1" @click="goToNextInvalidPicture">
					<span class="material-symbols-outlined text-[32px]">chevron_right</span>
				</button>
			</div>

			<Button @click="tryUploadAgain">{{ $t('presetDetails.invalidPicturesTryAgain') }}</Button>
		</Modal>
		<Modal
			:displayModal="modal.createAvatar"
			:title="$t('presetDetails.createAvatarModal.title')"
			@onClose="onCloseCreateAvatarModal"
		>
			<p class="mt-6 text-base text-gray-300">
				{{ $t('presetDetails.createAvatarModal.description') }}
			</p>
			<p class="text-xl text-center my-6">
				<strong>{{ presetData?.price }}</strong>
				<br />
				{{ $t('common.realityPoints') }}
			</p>
			<Button @click="createPrediction">
				{{ loading.creatingPrediction ? $t('presetDetails.creatingAvatar') : $t('common.create') }}
			</Button>
			<Button
				@click="onCloseCreateAvatarModal"
				color="outline"
				class="mt-6"
				:disabled="loading.creatingPrediction"
			>
				{{ $t('common.cancel') }}
			</Button>
		</Modal>
		<Modal
			:displayModal="showInstructionsModal"
			@onClose="showInstructionsModal = false"
			:title="$t('presetDetails.instructionModal.title')"
			modalClass="max-h-[90vh] md:max-h-full"
		>
			<div>
				<div class="flex place-items-center text-xl font-medium">
					<span class="material-icons text-lime-500"> check_circle </span>
					<span class="ml-2">{{ $t('presetDetails.instructionModal.goodExampleTitle') }}</span>
				</div>

				<p class="text-gray-300 mt-4">
					{{ $t('presetDetails.instructionModal.goodExampleDescription') }}
				</p>

				<div class="grid grid-cols-3 gap-4 mt-4">
					<LazyImage
						v-for="(image, index) in goodImageExamples"
						:key="`good-image-example-${index}`"
						:src="image"
						alt="Good Examples"
						imgClass="rounded-xl h-full"
					/>
				</div>
			</div>

			<div class="mt-8">
				<div class="flex place-items-center text-xl font-medium">
					<span class="material-icons text-red-500"> cancel </span>
					<span class="ml-2">
						{{ $t('presetDetails.instructionModal.badExampleTitle') }}
					</span>
				</div>

				<p class="text-gray-300 mt-4">
					{{ $t('presetDetails.instructionModal.badExampleDescription') }}
				</p>

				<div class="grid grid-cols-3 gap-4 mt-4">
					<LazyImage
						v-for="(image, index) in badImageExamples"
						:key="`bad-image-example-${index}`"
						:src="image"
						alt="Bad Examples"
						imgClass="rounded-xl h-full"
					/>
				</div>
			</div>

			<div class="mt-4 bg-gray-800 rounded-xl p-4">
				<p class="font-medium text-center text-gray-200">
					{{ $t('presetDetails.instructionModal.warning') }}
				</p>
			</div>

			<div class="mt-6">
				<Button @click="handleCloseInstructions">
					{{ $t('presetDetails.instructionModal.okayBtn') }}
				</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
import {
	Input,
	Button,
	Spinner,
	FileInput,
	Modal,
	AnimatedSpinner,
	FloatingButton,
	LazyImage,
	PlanCard,
} from '@/components/default'
import { PointsBalance } from '@/components/subscription'
import { AuthenticationBox } from '@/components/auth'
import { PresetImagesSwiper, PlansSwiper } from '@/components/new-avatar'

import JSZip from 'jszip'
import ShowcaseSlides from '../../components/default/ShowcaseSlides.vue'

const IMAGE_SLIDE_DELAY_IN_MS = 3000 // 3 seconds

export default {
	name: 'ViewModel',
	components: {
		PlansSwiper,
		PresetImagesSwiper,
		Input,
		FileInput,
		Button,
		Modal,
		AnimatedSpinner,
		Spinner,
		FloatingButton,
		LazyImage,
		PlanCard,
		ShowcaseSlides,
		AuthenticationBox,
		PointsBalance,
	},
	async created() {
		this.$watch(
			() => this.$route.params,
			(toParams, previousParams) => {
				if (toParams.id !== previousParams.id) {
					this.init()
				}
			}
		)

		this.init()
	},
	mounted() {
		this.modelName = this.loggedUser?.userName || ''

		setTimeout(this.forwardPresetImage, IMAGE_SLIDE_DELAY_IN_MS)

		if (!this.loggedUser) {
			this.captureEvent('new_avatar_logged_out_user_view')
		}

		if (this.loggedUser) {
			this.permissions = this.$getPermissions()
		}
	},
	beforeUnmount() {
		if (this.elapsedTimer) {
			clearInterval(this.elapsedTimer)
		}

		this.removeModelsListener()
	},
	data() {
		return {
			modelCreatedAt: null,
			presetData: null,
			permissions: false,
			plans: null,
			currentPresetImageIndex: 0,
			hasCreatingModelError: false,
			mockupWindow: null,
			activeTipIndex: 0,
			activeTip: null,
			elapsedTimer: null,
			tipArr: [
				{
					images: ['/web/images/tips-images/tip-1.png'],
					title: this.$t('presetDetails.tips.title'),
					description: this.$t('presetDetails.tips.tipDescription1'),
				},
				{
					images: ['/web/images/tips-images/tip-2.png'],
					title: this.$t('presetDetails.tips.title'),
					description: this.$t('presetDetails.tips.tipDescription2'),
				},
				{
					images: ['/web/images/tips-images/tip-3.png'],
					title: this.$t('presetDetails.tips.title'),
					description: this.$t('presetDetails.tips.tipDescription3'),
				},
				{
					images: ['/web/images/tips-images/tip-4.png'],
					title: this.$t('presetDetails.tips.title'),
					description: this.$t('presetDetails.tips.tipDescription4'),
				},
			],
			goodImageExamples: [
				'/web/images/photo-examples/good-example-1.png',
				'/web/images/photo-examples/good-example-2.png',
				'/web/images/photo-examples/good-example-3.png',
			],
			badImageExamples: [
				'/web/images/photo-examples/bad-example-1.png',
				'/web/images/photo-examples/bad-example-2.png',
				'/web/images/photo-examples/bad-example-3.png',
			],
			showInstructionsModal: false,
			modelName: '',
			models: [],
			showUploadImage: false,
			picturesAreValid: false,
			currentInvalidPictureIndex: 0,
			hasInvalidPictures: false,
			verifiedPictures: 0,
			pictures: [],
			today: null,
			totalPicturesNeeded: 10,
			loading: {
				verifyingPictures: false,
				creatingModel: false,
				creatingPrediction: false,
				page: true,
			},
			modal: {
				invalidPictures: false,
				validPictures: false,
				createAvatar: false,
			},
			genderOptions: [
				{
					id: 1,
					value: 'male',
					text: 'presetDetails.genderSelectionPage.genders.male',
				},
				{
					id: 2,
					value: 'female',
					text: 'presetDetails.genderSelectionPage.genders.female',
				},
			],
			showGenderSelectionMenu: false,
			selectedGenderIdentity: null,
			selectedExistingModelId: null,
			modelCreatingIntervalInMS: 60000, // 1 minute
			modelsNext: '',
			modelsHasNext: false,
			isFetchingModels: false,
			showRetryUploadButton: false,
			retryPhotoUploadButtonDisplayTimeoutInMS: 20000, // 20 seconds
			retryPhotoUploadTimeoutId: null,
			verificationResultAnimationDurationInMS: 1500,
			showExamples: true,
		}
	},
	methods: {
		async init() {
			try {
				const [{ data: presetData }, { data: plansData }] = await Promise.all([
					this.axios.get(`/presets/${this.$route.params.id}`),
					this.axios.get('/plans?unit=monthly'),
				])

				this.plans = plansData

				if (!presetData) {
					this.$router.push('/')
					this.$toast({
						duration: 5000,
						title: this.$t('common.toastTitle.error'),
						message: this.$t('presetDetails.presetNotFoundToastDescription'),
						type: 'error',
					})
				}

				this.presetData = presetData

				if (!this.isMobile && typeof document !== 'undefined') {
					this.mockupWindow = document.getElementById('mockupWindow')
				}

				if (this.loggedUser) {
					const { data: modelsData } = await this.axios.get('/models')
					this.models = modelsData.results
					this.modelsNext = modelsData.next
					this.modelsHasNext = modelsData.hasNext

					if (!modelsData.results.length) {
						this.showUploadImage = true
					} else {
						this.addModelsListener()
					}
				}

				this.generateModelCreationInfoTip()
			} catch (error) {
				this.sentryCaptureException(error)
				this.$router.push('/')
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('presetDetails.presetNotFoundToastDescription'),
					type: 'error',
				})
			}

			this.loading.page = false
		},
		onCreateAvatarLoggedOutClick() {
			this.captureEvent('new_avatar_logged_out_create_avatar_click')

			this.$router.push({
				path: '/create-account',
				query: {
					redirectTo: `/new-avatar/${this.$route.params.id}`,
				},
			})
		},
		forwardPresetImage() {
			if (this.presetData?.images.length === 1) {
				return
			}

			const isLastImage = this.currentPresetImageIndex === this.presetData?.images.length - 1

			this.currentPresetImageIndex = isLastImage ? 0 : this.currentPresetImageIndex + 1

			setTimeout(this.forwardPresetImage, IMAGE_SLIDE_DELAY_IN_MS)
		},
		handleResetStates() {
			this.loading.creatingModel = false
			this.hasCreatingModelError = false
			this.picturesAreValid = false
			this.loading.verifyingPictures = false
			this.hasInvalidPictures = false
			this.modal.invalidPictures = false
			this.modal.validPictures = false
			this.verifiedPictures = 0
			this.currentInvalidPictureIndex = 0
			this.showUploadImage = false
			this.pictures = []
		},
		addModelsListener() {
			const targetContainer = this.isMobile ? document : this.mockupWindow

			if (targetContainer) {
				targetContainer.addEventListener('scroll', this.onModelsScroll)
			}
		},
		removeModelsListener() {
			const targetContainer = this.isMobile ? document : this.mockupWindow

			if (targetContainer) {
				targetContainer.removeEventListener('scroll', this.onModelsScroll)
			}
		},
		onModelsScroll() {
			let isScrolledToBottom = false

			const newUploadHeight = this.$refs.newUploadContainer?.offsetHeight || 0

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight - newUploadHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight - newUploadHeight
			}

			if (isScrolledToBottom) {
				this.fetchModels()
			}
		},
		async fetchModels() {
			if (this.isFetchingModels || !this.modelsHasNext) {
				return
			}

			this.isFetchingModels = true

			try {
				const { data } = await this.axios.get('/models', {
					params: {
						next: this.modelsNext,
					},
				})

				this.models = [...this.models, ...data.results]
				this.modelsNext = data.next
				this.modelsHasNext = data.hasNext
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetchingModels = false
		},
		async handleCloseInstructions() {
			this.showInstructionsModal = false

			if (this.loggedUser?.viewedInstructions) {
				return
			}

			try {
				await this.axios.post('/users/viewed-instructions')
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		handleSharePreset() {
			this.$share({
				title: 'RealityStudio',
				text: `I just created my avatar using RealityStudio!`,
				url: window.location.href,
			})
		},
		onCloseCreateAvatarModal() {
			this.modal.createAvatar = false
			this.captureEvent('new_avatar_create_avatar_modal_close')
		},
		onCancelGenderIdentitySelectionScreen() {
			this.loading.verifyingPictures = false
			this.selectedGenderIdentity = null
			this.showGenderSelectionMenu = false
			this.captureEvent('new_avatar_valid_pictures_modal_close')
		},
		onCloseInvalidPicturesModal() {
			this.pictures = this.pictures.filter(picture => picture.valid)
			this.modal.invalidPictures = false
			this.loading.verifyingPictures = false
			this.captureEvent('new_avatar_invalid_pictures_modal_close')
		},
		selectModel(modelId) {
			this.selectedExistingModelId = modelId
			this.modal.createAvatar = true
		},
		async createPrediction() {
			this.loading.creatingPrediction = true

			try {
				await this.axios.post(`/predictions`, {
					models: this.selectedExistingModelId,
					preset: this.presetData._id,
				})

				this.$router.push(`/my-account/library`)
			} catch (error) {
				const message = error.response.data.message

				if (message === 'You do not have enough points.') {
					this.$router.push('/checkout/plans')
				}

				this.sentryCaptureException(error)
			} finally {
				this.loading.creatingPrediction = false
			}
		},
		tryUploadAgain(clearUploads = true) {
			if (clearUploads) {
				this.pictures = this.pictures.filter(picture => picture.valid)
			}

			this.currentInvalidPictureIndex = 0
			this.loading.verifyingPictures = false
			this.hasInvalidPictures = false
			this.picturesAreValid = false
			this.modal.invalidPictures = false
		},
		goToPreviousInvalidPicture() {
			this.handleInvalidPicturesAnimation()
			if (this.currentInvalidPictureIndex <= 0) {
				this.currentInvalidPictureIndex = this.invalidPictures.length - 1
				return
			}
			this.currentInvalidPictureIndex--
		},
		goToNextInvalidPicture() {
			this.handleInvalidPicturesAnimation()
			if (this.currentInvalidPictureIndex >= this.invalidPictures.length - 1) {
				this.currentInvalidPictureIndex = 0
				return
			}

			this.currentInvalidPictureIndex++
		},
		handleInvalidPicturesAnimation() {
			const element = this.$refs.invalidPictureImage

			element.classList.remove('animate-jump')

			setTimeout(() => {
				element.classList.add('animate-jump')
			}, 100)
		},

		generateModelCreationInfoTip() {
			const modelCreationInfoTip = {
				images: this.presetData?.images,
				title: this.$t('presetDetails.tips.modelCreationInfoTipTitle'),
				description: this.$t('presetDetails.tips.modelCreationInfoTipDescription'),
			}

			this.tipArr.unshift(modelCreationInfoTip)
		},

		async createNewModel() {
			if (!this.picturesAreValid || this.loading.creatingModel) return
			this.loading.creatingModel = true

			try {
				const zip = new JSZip()

				this.pictures.forEach((picture, index) => {
					zip.file(`${index + 1}.${picture.data.name.split('.').pop()}`, picture.data)
				})

				const generatedZip = await zip.generateAsync({ type: 'blob' })

				const { url, key } = await this.axios
					.get(`/files/sign-url/${this.loggedUser?._id}.zip?generateModel=true`)
					.then(res => res.data)

				await this.axios.put(url, generatedZip)

				const { data } = await this.axios.post(`/models`, {
					name: this.modelName,
					modelType: 'face',
					imagesKeys: this.pictures.map(picture => picture.resourceKey),
					fileKey: key,
					presetId: this.presetData._id,
					gender: this.selectedGenderIdentity.value,
				})

				this.selectedExistingModelId = data.id
				const handleIsReady = async () => {
					try {
						const { data: modelData } = await this.axios.get(`/models/${data.id}`)

						if (modelData.status === 'completed') {
							clearInterval(this.modelCreatingInterval)
							this.$router.push(`/my-account/library`)
							return
						}

						if (modelData.status === 'failed') {
							clearInterval(this.modelCreatingInterval)
							this.hasCreatingModelError = true
							return
						}
					} catch (err) {
						this.sentryCaptureException(err)
					}
				}
				this.modelCreatingInterval = setInterval(handleIsReady, this.modelCreatingIntervalInMS)

				this.modelCreatedAt = new Date()
				this.today = new Date()
				this.elapsedTimer = setInterval(() => {
					this.today = new Date()
				}, 1000)

				const updateTip = async () => {
					const isLastTip = this.activeTipIndex === this.tipArr.length - 1

					this.activeTipIndex = isLastTip ? 0 : this.activeTipIndex + 1

					setTimeout(updateTip, 1000 * 10) // 10 seconds
				}

				setTimeout(updateTip, 1000 * 10) // 10 seconds

				this.showGenderSelectionMenu = false
				this.loading.verifyingPictures = false
			} catch (error) {
				if (error.response.data.message === 'You do not have enough points.') {
					this.$router.push('/checkout/plans')
				}
				this.sentryCaptureException(error)
			}
		},
		async verifyPictures() {
			if (this.loading.verifyingPictures) return

			this.verifiedPictures = 0
			this.loading.verifyingPictures = true
			this.hasInvalidPictures = false
			this.showRetryUploadButton = false

			try {
				for (let i = 0; i < this.pictures.length; i++) {
					if (!this.loading.verifyingPictures) return

					clearTimeout(this.retryPhotoUploadTimeoutId)

					this.retryPhotoUploadTimeoutId = setTimeout(() => {
						this.showRetryUploadButton = true
					}, this.retryPhotoUploadButtonDisplayTimeoutInMS)

					const pictureData = this.pictures[i].data

					const { url, key } = await this.axios
						.get(`/files/sign-url/${pictureData.name}`)
						.then(res => res.data)
					await this.axios.put(url, pictureData)

					const { data } = await this.axios.post('/files/verify', { resourceKey: key })

					if (!data.valid) {
						this.pictures[i].data = data.data
						this.pictures[i].reason = data.error
						this.pictures[i].invalid = true
						this.hasInvalidPictures = true
					} else {
						this.pictures[i].resourceKey = key
						this.pictures[i].valid = true
					}

					this.verifiedPictures++
				}
				if (!this.loading.verifyingPictures) return

				if (this.hasInvalidPictures) {
					setTimeout(() => {
						this.modal.invalidPictures = true
					}, this.verificationResultAnimationDurationInMS)
					return
				}

				this.picturesAreValid = true
				setTimeout(() => {
					this.loading.verifyingPictures = false
					this.showGenderSelectionMenu = true
				}, this.verificationResultAnimationDurationInMS)
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		async onFileSelect(fileList) {
			if (!this.permissions) {
				this.$toast({
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.permissions'),
					type: 'error',
				})

				this.permissions = this.$getPermissions()
				return
			}

			this.picturesAreValid = false
			if (fileList.length > this.totalPicturesNeeded) {
				fileList.splice(this.totalPicturesNeeded)
			}

			for (let i = 0; i < fileList.length; i++) {
				const file = fileList[i]
				if (!file) return
				const url = URL.createObjectURL(file)

				const blob = await fetch(url).then(r => r.blob())
				const fileFromBlob = new File([blob], `${file.name.split('.')[0]}.png`, { type: 'image/png' })

				this.pictures.push({
					url,
					invalid: false,
					valid: false,
					data: fileFromBlob,
				})
			}

			this.pictures.splice(this.totalPicturesNeeded)
		},
		getInvalidPictureReason(reason) {
			switch (reason) {
				case 'celebrity':
					const reasonData = this.currentInvalidPicture.data

					if (!reasonData) return ''

					const celebrityName = reasonData.celebrityName
					const celebrityConfidence = reasonData.celebrityConfidence

					return this.$t('presetDetails.imageErrorReasons.celebrity', {
						celebrityName,
						celebrityConfidence,
					})

				default:
					const reasonMessages = {
						'low-sharpness': this.$t('presetDetails.imageErrorReasons.lowSharpness'),
						'inappropriate-content': this.$t('presetDetails.imageErrorReasons.inappropriateContent'),
						'no-face': this.$t('presetDetails.imageErrorReasons.noFace'),
						'too-many-faces': this.$t('presetDetails.imageErrorReasons.tooManyFaces'),
					}

					return reasonMessages[reason] || this.$t('presetDetails.imageErrorReasons.unknown')
			}
		},
		onCreateFromTheme() {
			this.showExamples = false
			this.showInstructionsModal = this.loggedUser?.viewedInstructions
		},
	},
	computed: {
		canGoBack() {
			if (import.meta.env.SSR) {
				return false
			}

			/**
			 * Firefox + Safari doesn't support canGoBack, so we need to check the length
			 * @link https://developer.mozilla.org/en-US/docs/Web/API/Navigation_API
			 */
			return (
				(typeof navigation !== 'undefined' && navigation.canGoBack) ||
				(typeof window !== 'undefined' && window.history.length > 3)
			)
		},
		progressPercentage() {
			return `${(this.pictures.length / this.totalPicturesNeeded) * 100}%`
		},

		fullScreenHeight() {
			const defaultPaddingBottom = 24 // in pixels
			const footerHeight = 96 // in pixels

			const windowHeight = this.isMobile ? window.innerHeight : this.mockupWindow?.offsetHeight || 0

			return windowHeight - (footerHeight + defaultPaddingBottom)
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		tipWidthInPx() {
			if (!this.modelCreatedAt || !this.today) return 0
			const totalTips = this.tipArr.length
			const createdDate = this.$dayjs(this.modelCreatedAt)
			const today = this.$dayjs(this.today)
			const diffInSeconds = today.diff(createdDate, 'second')

			const percentageEachTenSeconds = ((diffInSeconds % 10) / totalTips) * 100

			return percentageEachTenSeconds
		},
		elapsedTimes() {
			if (!this.modelCreatedAt || !this.today) return '0m 0s'
			const createdDate = this.$dayjs(this.modelCreatedAt)
			const today = this.$dayjs(this.today)
			const diffInMinutes = today.diff(createdDate, 'minute')
			const diffInSeconds = today.diff(createdDate, 'second')
			const minutes = Math.floor(diffInMinutes)
			const seconds = diffInSeconds % 60

			return `${minutes}m ${seconds}s`
		},
		totalPicturesValidation() {
			return this.pictures.length === this.totalPicturesNeeded
		},
		modelNameValidation() {
			return this.modelName.length >= 4 && this.modelName.length <= 36
		},
		userHasEnoughPoints() {
			if (!this.loggedUser || !this.presetData) return false

			return (
				this.loggedUser?.points >= this.presetData.price + (this.models.length ? 0 : this.presetData.modelPrice)
			)
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
		currentInvalidPicture() {
			return this.invalidPictures[this.currentInvalidPictureIndex]
		},
		invalidPictures() {
			return this.pictures.filter(picture => picture.invalid)
		},
		isFinishedVerifying() {
			return this.loading.verifyingPictures && this.verifiedPictures === this.totalPicturesNeeded
		},
		displayNewUploadSection() {
			return (
				!this.showUploadImage &&
				this.loggedUser?.hasSubscription &&
				this.userHasEnoughPoints &&
				!this.showGenderSelectionMenu &&
				!this.loading.creatingModel &&
				!this.loading.verifyingPictures
			)
		},
		getLowestPlanInfo() {
			if (this.loggedUser && this.loggedUser.lang && this.plans) {
				const currency = this.loggedUser.lang === 'pt-BR' ? 'R$' : '$'
				const price = this.plans.reduce((prev, plan) => {
					if (plan.price < prev) {
						return plan.price
					}
					return prev
				}, this.plans[0].price)

				return `${currency} ${(Math.round(price * 100) / 100).toFixed(2)}/${this.$t(`common.monthly`)}`
			}
		},
	},
	watch: {
		showUploadImage(newValue) {
			if (!newValue) {
				this.addModelsListener()
				return
			}

			if (this.loggedUser?.viewedInstructions || !this.loggedUser?.hasSubscription || !this.userHasEnoughPoints) {
				return
			}
		},
	},
	head: {
		title: `RealityStudio - Create Avatar`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Create Avatar`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
