<template>
	<div>
		<div>
			<div
				:class="[
					'text-center relative rounded-xl bg-radial-2-card px-4 py-6',
					isSelected ? 'border-2 border-green-700 is-popular' : 'border border-radial-1-card',
				]"
			>
				<span :class="['absolute top-6 left-6 material-icons', isSelected ? 'text-green-700' : 'text-white']">
					{{ isSelected ? 'radio_button_checked' : 'radio_button_unchecked' }}
				</span>

				<img :src="plan.image" alt="Plan Image" class="absolute -top-28 left-1/2 !-translate-x-1/2" />
				<h1 class="font-bold text-2xl mb-4 mt-10">{{ plan.name }} ({{ billingCycle }})</h1>

				<h1 class="text-2xl font-bold mt-4 mb-2">
					{{ unitPrice }}/{{ $t('admin.index.modalGiveSubscription.billingCycleMonthly') }}
				</h1>

				<div class="mt-4 flex flex-col gap-2 text-gray-400">
					<span>{{ plan.points }} Points (+{{ plan.bonusPoints }} Bonus)</span>
					<span v-for="feature in plan.features" :key="feature">{{ feature }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Button, Spinner, Modal } from '@/components/default'

export default {
	components: {
		Button,
		Spinner,
		Modal,
	},
	props: {
		plan: {
			type: Object,
			required: true,
		},
		showCancelButton: {
			type: Boolean,
			default: false,
		},
		isSelected: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onSubscribeClick', 'onCancelClick'],
	methods: {},
	computed: {
		unitPrice() {
			if (!this.plan) return ''
			const { billingCycle } = this.plan

			switch (billingCycle.unit) {
				case 'yearly':
					return this.$formatCurrency(this.plan.price, this.loggedUser?.lang)
				default:
					return this.$formatCurrency(this.plan.price, this.loggedUser?.lang)
			}
		},
		billingCycle() {
			if (!this.plan) return ''

			const { billingCycle } = this.plan

			const billingCycles = {
				monthly: this.$t('admin.index.modalGiveSubscription.billingCycleMonthly'),
				yearly: this.$t('admin.index.modalGiveSubscription.billingCycleYearly'),
			}

			return billingCycles[billingCycle.unit.toLowerCase()]
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
}
</script>
