<template>
	<div>
		<Text2ImageLayout
			localePrefix="stickerTool"
			gtagPrefix="sticker_tool"
			toolType="sticker"
			apiSubmitEndpoint="/inferences/sticker"
			previewVideoSrc="https://cdn.realitystudio.ai/assets/videos/generate-stickers.mp4"
			pricingKey="sticker"
			:showTransparentImage="true"
		>
		</Text2ImageLayout>
	</div>
</template>

<script>
import { Text2ImageLayout } from '@/components/tools'

export default {
	components: {
		Text2ImageLayout,
	},
	head: {
		title: `RealityStudio - Sticker Tool`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Create a sticker easily with RealityStudio. Insert a text and get a sticker in seconds.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Sticker Tool `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Create a sticker easily with RealityStudio. Insert a text and get a sticker in seconds.',
			},
		],
	},
}
</script>
