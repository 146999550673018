<template>
	<div>
		<AdminHeader />
		<div class="page-container mt-6 pb-8">
			<div class="flex items-center">
				<button @click="$router.push('/admin')" class="material-icons text-[32px]">keyboard_backspace</button>
				<span class="text-3xl font-bold ml-2">{{ $t('admin.analytics.title') }} </span>
			</div>
			<div class="grid grid-cols-3 lg:grid-cols-12 gap-4 mt-8 border-b-2 border-radial-1-card" v-if="tabs.length">
				<button
					v-for="tab in tabs"
					@click="handleChangeTab(tab.id)"
					:key="tab.id"
					class="pb-2 relative"
					:class="{
						'before:contents-[\'\'] before:absolute before:left-0 before:-bottom-[2px] before:h-[2px] before:w-full before:bg-blue-600 border-blue-600 text-blue-600 font-bold':
							activeTab === tab.id,
					}"
				>
					{{ tab.label }}
				</button>
			</div>

			<Graph v-if="activeTab === 'graph'" />
			<Sales v-if="activeTab === 'sales'" />
			<Users v-if="activeTab === 'users'" />
		</div>
	</div>
</template>

<script>
import AdminHeader from '@/components/admin/AdminHeader.vue'
import Graph from '@/components/admin/analytics/Graph.vue'
import Sales from '@/components/admin/analytics/Sales.vue'
import Users from '@/components/admin/analytics/Users.vue'

export default {
	components: {
		Sales,
		Users,
		Graph,
		AdminHeader,
	},
	mounted() {
		if (this.$route.query.tab) {
			this.activeTab = this.$route.query.tab
		}
	},
	data() {
		return {
			activeGraphCycle: null,
			isFetching: false,
			activeTab: 'graph',
			tabs: [
				{
					id: 'graph',
					label: this.$t('admin.analytics.tabs.graphs'),
				},
				{
					id: 'sales',
					label: this.$t('admin.analytics.tabs.sales'),
				},
				{
					id: 'users',
					label: this.$t('admin.analytics.tabs.users'),
				},
			],
		}
	},
	methods: {
		updateUrl(tab) {
			const query = Object.assign({}, this.$route.query)
			query.tab = tab

			if (query.tab === 'graph') {
				delete query.tab
			}

			this.$router.push({ query })
		},
		handleChangeTab(tabId) {
			this.updateUrl(tabId)

			this.activeTab = tabId
		},
	},
}
</script>
