import { inject } from 'vue'
import getCurrentUser from '../middleware/getCurrentUser'
import store from '../store'
import middlewarePipeline from './middlewarePipeline'

export function appMiddleware({ router, i18n, req }) {
	return async (to, from) => {
		const axios = inject('axios')

		await getCurrentUser({ axios, store, i18n })

		if (!to.meta.middleware) {
			return true
		}

		const middleware = to.meta.middleware

		const context = {
			to,
			from,
			store,
			axios,
			router,
			req,
			next: middleware => middleware,
		}

		const nextMiddleware = await middlewarePipeline(context, middleware, 0)

		return nextMiddleware()
	}
}
