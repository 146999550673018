<template>
	<div>
		<div class="page-container py-6 px-4">
			<Header :title="$t('myAccountDelete.header')" :hideBackButton="true" />
			<div v-if="user && !tokenExpired">
				<div class="flex flex-col justify-center mt-8 mb-6">
					<img
						:src="user?.avatar || '/web/images/home-bg.jpg'"
						:alt="user?.userName"
						class="text-center mb-4 object-cover w-[120px] h-[120px] rounded-full border-white border-[2px] mx-auto"
					/>
					<strong class="text-lg text-white text-center">{{ user?.userName }}</strong>
				</div>
				<p
					class="mt-6 text-base leading-6 text-center text-gray-300"
					v-html="$t('myAccountDelete.description')"
				></p>
				<div class="mt-6 space-y-4">
					<button
						class="w-full text-left p-6 flex items-center justify-between border border-gray-900 rounded-xl"
						v-for="(reason, index) in commonReasons"
						@click="handleSelectReason(index)"
					>
						<p class="flex-1 w-full">
							{{ $t(reason) }}
						</p>
						<span class="material-icons text-2xl">
							{{ selectedReason === index ? 'check_box' : 'check_box_outline_blank' }}
						</span>
					</button>
				</div>
				<div class="mt-6">
					<label class="text-base leading-6">{{ $t('myAccountDelete.reason.label') }} </label>
					<textarea
						class="py-6 px-4 text-area-height border-gray-900 w-full rounded-md bg-app-blue border-[1px] mt-2 resize-none h-[200px]"
						v-model="personalReason"
						type="text"
						:placeholder="$t('myAccountDelete.reason.placeholder')"
					/>
				</div>
				<Button class="my-6" :isLoading="loading" type="button" @click="deleteAccount">
					<span class="leading-7 font-semibold text-lg">{{
						$t('myAccountDelete.buttons.deletePermanently')
					}}</span>
				</Button>
				<Button type="button" color="outline" @click="handleCancel">
					<span class="leading-7 font-semibold text-lg">{{ $t('myAccountDelete.buttons.cancel') }}</span>
				</Button>
			</div>
			<div v-if="!user && tokenExpired">
				<p class="my-8 text-lg text-white leading-6 text-center text-gray-300">
					{{ $t('myAccountDelete.tokenExpired') }}
				</p>
				<Button type="button" color="outline" @click="handleCancel">
					<span class="leading-7 font-semibold text-lg">{{ $t('myAccountDelete.email.button') }}</span>
				</Button>
			</div>
		</div>
	</div>
</template>
<script>
import { Header, Button } from '@/components/default'
import { useRoute, useRouter } from 'vue-router'
import { inject, ref } from 'vue'

export default {
	components: { Header, Button },
	head: {
		title: `RealityStudio - Delete account`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Delete Account `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
		],
	},
	async setup() {
		const axios = inject('axios')
		const route = useRoute()
		const router = useRouter()

		try {
			const user = await axios.get(`/auth/verify-user/${route.params.token}`).then(res => res.data)

			return { user: ref(user), deleteToken: route.params.token }
		} catch (error) {
			if (error.response && error.response.status === 401) {
				return {
					user: ref(null),
					deleteToken: route.params.token,
					tokenExpired: true,
				}
			}

			router.push('/')
		}
	},
	data() {
		return {
			loading: false,
			commonReasons: [
				'myAccountDelete.commonReasons.noFreeOption',
				'myAccountDelete.commonReasons.isPaid',
				'myAccountDelete.commonReasons.lowQuality',
				'myAccountDelete.commonReasons.isTooDifficult',
			],
			selectedReason: null,
			personalReason: '',
		}
	},
	methods: {
		handleSelectReason(reasonIndex) {
			if (this.selectedReason === reasonIndex) {
				this.selectedReason = null
				return
			}

			this.selectedReason = reasonIndex
		},
		handleCancel() {
			this.$router.push('/login')
			this.captureEvent('delete_account_cancel')
		},
		async deleteAccount() {
			if (this.loading) return
			this.loading = true
			try {
				const reason =
					this.personalReason || this.selectedReason
						? this.$t(this.commonReasons[this.selectedReason], 'en-US')
						: ''
				await this.axios.post(`/users/delete/${this.deleteToken}`, {
					reason,
				})
				await this.$store.dispatch('setUser', null)
				this.captureEvent('delete_account_success')
				window.location.href = '/login'
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('delete_account_error')
			}
			this.loading = false
		},
	},
}
</script>
