<template>
	<section class="mt-16 mb-28 lg:mb-14 md:ms-10">
		<div class="container mx-auto px-6">
			<img src="/web/images/landing-page/logo2.png" alt="Reality Studio Logo" />
			<div class="text-stone-600 font-semibold my-5 md:w-1/2">
				<p class="my-2">{{ $t('landingPage.footer.description2') }}</p>
				<p>{{ $t('landingPage.footer.description3') }}</p>
			</div>

			<div>
				<p class="font-bold">Social</p>
				<ul class="text-stone-600 flex flex-row gap-3 mt-2">
					<li>
						<a href="https://x.com/RealityStudioAI" target="_blank">
							<img src="/web/images/landing-page/twitter.svg" alt="twitter logo" />
						</a>
					</li>
					<li>
						<a href="https://www.instagram.com/realitystudio.ai/" target="_blank">
							<img src="/web/images/landing-page/instagram.svg" alt="instagram logo" />
						</a>
					</li>

					<li>
						<a href="https://www.tiktok.com/@realitystudio" target="_blank">
							<img src="/web/images/landing-page/tik-tok.svg" alt="tiktok logo" />
						</a>
					</li>
				</ul>
			</div>
			<div>
				<p class="mt-7 font-bold">Links</p>
				<ul class="mt-5 flex flex-col gap-y-3 text-stone-600">
					<li class="hover:text-stone-500 transition-all delay-100 ease-in-out">
						<a href="https://blog.realitystudio.ai/" target="_blank">Blog</a>
					</li>
					<li class="hover:text-stone-500 transition-all delay-100 ease-in-out">
						<a href="https://linktr.ee/realitystudio" target="_blank">{{
							$t('landingPage.footer.contact')
						}}</a>
					</li>
					<li class="hover:text-stone-500 transition-all delay-100 ease-in-out">
						<a href="https://realitystudio.ai/privacy-policy" target="_blank">{{
							$t('landingPage.footer.termsAndConditions')
						}}</a>
					</li>
					<li class="hover:text-stone-500 transition-all delay-100 ease-in-out">
						<a href="https://realitystudio.ai/privacy-policy" target="_blank">{{
							$t('landingPage.footer.privacyPolicy')
						}}</a>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'Footer',
}
</script>
