<template>
	<div class="relative rounded-xl overflow-hidden">
		<a :href="`/images/${image._id}`">
			<LazyImage :src="image.url" @click="onImageClick" :alt="image._id || 'image'" imgClass="object-cover" />
		</a>
		<div
			class="w-full pointer-events-none opacity-80 absolute top-0 left-0 h-full bg-gradient-to-t from-black to-transparent"
		></div>
		<Button
			v-if="isAuthor"
			class="top-0 start-0 absolute ml-2 mt-2 w-10 h-10 rounded p-2"
			color="delete"
			@click="handleDeleteImage(image._id)"
		>
			<i class="material-icons">delete</i></Button
		>
		<div class="top-0 end-0 mt-2 mr-2 absolute">
			<Button @click="handleFavoriteImage" class="w-10 h-10 rounded p-2 bg-black bg-opacity-40 text-white">
				<i :class="image.isFavorite ? 'material-icons' : 'material-symbols-outlined'">bookmark</i>
			</Button>

			<Button
				@click="downloadImage"
				:isLoading="isDownloading"
				class="w-10 h-10 mt-4 rounded p-2 bg-black bg-opacity-40 text-white"
			>
				<i class="material-icons">download</i>
			</Button>
		</div>
	</div>
</template>

<script>
import { LazyImage, Button } from '@/components/default'
export default {
	data() {
		return { isDownloading: false }
	},

	props: {
		image: {
			type: Object,
		},
	},
	components: { LazyImage, Button },

	methods: {
		async downloadImage() {
			this.isDownloading = true

			this.captureEvent(`library_image_download_intent`)

			try {
				await this.$downloadFile(this.image.url, `${this.image._id}.png`)

				this.captureEvent(`library_image_download_success`)
			} catch (error) {
				this.captureEvent(`library_image_download_error`)
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}

			this.isDownloading = false
		},

		async handleFavoriteImage() {
			try {
				this.captureEvent(`library_image_favorite_intent`)

				if (this.image.isFavorite) {
					await this.axios.delete(`/favorites/${this.image._id}`).then(res => res.data)
					this.image.isFavorite = false
					this.$emit('onFavoriteImage', { entityId: this.image._id, value: this.image.isFavorite })
					return
				}

				await this.axios.post('/favorites', {
					entity: this.image._id,
					entityType: 'image',
				})

				this.image.isFavorite = true
				this.$emit('onFavoriteImage', { entityId: this.image._id, value: this.image.isFavorite })
			} catch (error) {
				this.captureEvent(`library_image_favorite_error`)
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}
		},

		onImageClick() {
			this.$emit('onImageClick')
		},
		async handleDeleteImage(entityId) {
			try {
				await this.axios.delete(`/images/${entityId}`).then(res => res.data)
				this.$emit('onDeleteImage', entityId)
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}
		},
	},

	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},

		isAuthor() {
			return this.loggedUser?._id === this.image.user._id
		},
	},
}
</script>
