<template>
	<div class="page-container">
		<AuthLogo />

		<h1 class="text-xl font-semibold text-center">
			{{ $t('forgotPasswordPage.title') }}
		</h1>
		<p class="mt-4 text-gray-500 text-center">
			{{ $t('forgotPasswordPage.subtitle') }}
		</p>
		<form class="mt-6" @submit.prevent="onSubmit">
			<Input
				id="email"
				v-model="email"
				:label="$t('forgotPasswordPage.emailLabel')"
				:placeholder="$t('forgotPasswordPage.emailPlaceholder')"
				type="email"
				required
			/>
			<HCaptcha ref="hCaptcha" class="mt-6" @onVerify="token => (captchaToken = token)" />
			<Button type="submit" class="my-6" :isLoading="isSendingEmail" :disable="disableSubmit">
				{{ $t('forgotPasswordPage.sendButton') }}
			</Button>
		</form>
		<div class="text-lg leading-7 w-fit mx-auto">
			{{ $t('forgotPasswordPage.didRemember') }}
			<router-link @click="captureEvent('forgot_password_goto_login')" class="font-bold" to="login">
				{{ $t('forgotPasswordPage.gotoLogin') }}
			</router-link>
		</div>
	</div>
</template>

<script>
import { Header, Input, Button, HCaptcha } from '@/components/default'
import { Logo as AuthLogo } from '@/components/auth'

export default {
	components: {
		Header,
		Input,
		Button,
		HCaptcha,
		AuthLogo,
	},
	data() {
		return {
			email: '',
			isSendingEmail: false,
			captchaToken: '',
		}
	},
	methods: {
		async onSubmit() {
			this.isSendingEmail = true

			try {
				await this.axios.post('/auth/send-reset-password', {
					email: this.email,
					token: this.captchaToken,
					platform: this.platform,
				})

				this.$toast({
					type: 'success',
					title: this.$t('common.toastTitle.success'),
					message: this.$t('forgotPasswordPage.submitToastSuccessMessage'),
				})

				this.captureEvent('forgot_password_submit_success')

				this.captchaToken = ''
			} catch (error) {
				this.$refs.hCaptcha.reset()
				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('forgotPasswordPage.submitToastError'),
				})
				this.captureEvent('forgot_password_submit_error')
			}

			this.isSendingEmail = false
		},
	},
	computed: {
		disableSubmit() {
			return !this.email || (import.meta.env.DEV ? false : !this.captchaToken)
		},
		platform() {
			return this.$getPlatform()
		},
	},
	head: {
		title: `RealityStudio - Forget Password`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Forget Password`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
