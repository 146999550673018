<template>
	<button v-if="!href" :class="componentClass" @click="onClick" :type="type" :disabled="disabled" v-tw-merge>
		<Spinner v-if="isLoading" width="6" height="6" />
		<slot v-if="!isLoading"></slot>
	</button>
	<router-link v-if="href && href[0] === '/'" :to="href" :class="componentClass" @click="onClick" v-tw-merge>
		<slot></slot>
	</router-link>
	<a v-if="href && href[0] !== '/'" :href="href" :class="componentClass" @click="onClick" :target="target" v-tw-merge>
		<slot></slot>
	</a>
</template>

<script>
import Spinner from '@/components/default/Spinner.vue'

export default {
	components: { Spinner },
	name: 'Index',
	props: {
		class: {
			type: String,
		},
		href: {
			type: String,
		},
		type: {
			type: String,
			default: 'button',
		},
		color: {
			type: String,
			default: 'primary',
		},
		size: {
			type: String,
			default: 'full',
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		itemPosition: {
			type: String,
			default: 'center',
		},
		buttonClass: {
			type: String,
			default: 'rounded-xl text-lg leading-7 font-semibold  disabled:opacity-50 disabled:cursor-not-allowed',
		},
		target: {
			type: String,
			default: '_self',
		},
	},
	emits: ['click'],
	methods: {
		onClick() {
			this.$emit('click')
		},
	},
	computed: {
		componentColor() {
			switch (this.color) {
				case 'primary':
					return 'bg-white text-gray-900'
				case 'outline':
					return 'bg-transparent text-white border border-white'
				case 'transparent':
					return 'bg-transparent text-white'
				case 'green':
					return 'bg-green-700 text-white'
				case 'delete':
					return 'bg-red-700 text-white'
				case 'outline-gray':
					return 'bg-transparent text-white border border-gray-900'
				case 'outline-red':
					return 'bg-transparent text-red-500 border border-red-500'
				case 'lime':
					return 'bg-lime-300 text-gray-900'
				case 'gray':
					return 'bg-gray-600 text-white bg-opacity-20'
			}
		},
		componentSize() {
			switch (this.size) {
				case 'full':
					return 'w-full h-[76px] px-5'
				case 'fit':
					return 'w-fit'
				case 'compact':
					return 'w-full py-4 px-4'
			}
		},
		componentItemPosition() {
			switch (this.itemPosition) {
				case 'center':
					return 'flex items-center justify-center'
				case 'none':
					return ''
			}
		},
		componentClass() {
			return [this.componentColor, this.componentItemPosition, this.componentSize, this.buttonClass, this.class]
		},
	},
}
</script>
