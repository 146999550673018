<template>
	<div class="w-full">
		<div class="relative">
			<div class="w-full top-0 left-0 -z-10 md:top-6" v-if="currentlyDisplayedSlide?.images?.length">
				<div class="w-full aspect-square">
					<Transition name="fade">
						<img
							:src="currentlyDisplayedSlide?.images[0] || ''"
							:key="`showcase-slide-${currentlyDisplayedSlideIndex}`"
							class="w-full h-full object-cover"
							:alt="`showcase-slide-${currentlyDisplayedSlideIndex}`"
						/>
					</Transition>
					<div
						class="w-full opacity-80 absolute top-0 left-0 h-full bg-gradient-to-t from-black to-transparent"
					></div>
				</div>
			</div>
		</div>

		<div class="w-full py-1 bg-gray-700 relative" v-if="slides?.length > 1">
			<div :class="`py-1 absolute top-0 w-1/${slides.length} bg-blue-600`" :style="currentSlideIndicatorStyle" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		slides: { type: Array, required: true },
		slideChangeIntervalInMS: {
			type: Number,
			default: 3000,
		},
	},

	data() {
		return {
			currentlyDisplayedSlideIndex: 0,
			cycleSlidesIntervalId: null,
		}
	},

	methods: {
		scrollSlides() {
			this.currentlyDisplayedSlideIndex = (this.currentlyDisplayedSlideIndex + 1) % (this.slides?.length || 0)
			this.$emit('onSlideChange', this.currentlyDisplayedSlide)
		},

		initSlideCycle() {
			if (this.slides?.length > 1) {
				this.cycleSlidesIntervalId = setInterval(this.scrollSlides, this.slideChangeIntervalInMS)
			}
			this.$emit('onSlideChange', this.currentlyDisplayedSlide)
		},
	},

	mounted() {
		this.initSlideCycle()
	},

	emits: ['onSlideChange'],

	computed: {
		currentSlideIndicatorStyle() {
			return {
				left: this.slides.length ? `${(100 / this.slides.length) * this.currentlyDisplayedSlideIndex}%` : 0,
				transition: 'left 300ms ease-in-out',
				width: this.slides.length ? `${100 / this.slides.length}%` : 0,
			}
		},

		currentlyDisplayedSlide() {
			return this.slides[this.currentlyDisplayedSlideIndex]
		},
	},

	watch: {
		slides() {
			this.initSlideCycle()
		},
	},

	beforeUnmount() {
		clearInterval(this.cycleSlidesIntervalId)
	},
}
</script>
