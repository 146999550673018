<template>
	<input
		ref="fileInput"
		type="file"
		name="file"
		:accept="inputAccept ? inputAccept : 'image/*'"
		:id="inputId"
		@change="onFileSelected"
		style="display: none"
		multiple
	/>
</template>

<script>
export default {
	props: {
		inputAccept: String,
		label: {
			type: String,
		},
		inputId: {
			type: String,
			required: true,
		},
	},
	methods: {
		onFileSelected(e) {
			this.$emit('onFileSelect', e.target.files)
			this.$refs.fileInput.value = ''
		},
	},
}
</script>
