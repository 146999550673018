<template>
	<Image2TextLayout
		localePrefix="generateSocialMediaPostAnalysisTool"
		gtagPrefix="generate_social_media_post_analysis_tool"
		toolType="generate-social-media-post-analysis"
		apiSubmitEndpoint="/inferences/generate-social-media-post-analysis"
		previewVideoSrc="https://cdn.realitystudio.ai/assets/images/social-media-post-analysis.jpg"
		pricingKey="generateSocialMediaPostAnalysis"
	>
	</Image2TextLayout>
</template>

<script>
import { Image2TextLayout } from '@/components/tools'

export default {
	components: {
		Image2TextLayout,
	},
	head: {
		title: `RealityStudio - Generate Social Media Post Analysis`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Generate a detailed social media post analysis of your image easily with RealityStudio. Upload your image and get a analysis in seconds.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Generate Social Media Post Analysis`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Generate a detailed social media post analysis of your image easily with RealityStudio. Upload your image and get a analysis in seconds.',
			},
		],
	},
}
</script>
