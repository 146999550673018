import { h, render } from 'vue'
import Toast from '../components/default/Toast.vue'

export function removeElement(el) {
	const parentNode = el.parentNode

	if (parentNode && parentNode.remove) {
		el.parentNode?.remove()
	}
}

export function createComponent(component, props) {
	const vNode = h(component, props)
	const container = document.createElement('div')
	const parentContainer = document.querySelector('#toast-container')

	parentContainer.appendChild(container)
	render(vNode, container)

	return vNode.component
}

export default {
	install(app) {
		app.config.globalProperties.$toast = options => {
			if (import.meta.env.SSR) {
				return
			}

			const defaultProps = {
				duration: 2000,
			}

			const propsData = Object.assign({}, defaultProps, options)
			createComponent(Toast, propsData)
		}
	},
}
