<template>
	<div class="page-container py-6 px-4">
		<div v-if="processing">
			<h3 class="text-2xl text-center leading-8 font-bold mb-6 mt-8">
				{{ $t('checkoutPlans.processing.processing.title') }}
			</h3>
			<Spinner width="16" height="16" class="w-fit h-fit my-0 mx-auto" />
			<p class="my-6 text-xl leading-7 text-center">
				{{ $t('checkoutPlans.processing.processing.description') }}
			</p>
		</div>
		<div v-if="!processing">
			<h3 class="text-2xl text-center leading-8 font-bold mb-6 mt-8">
				{{ $t('checkoutPlans.processing.success.title') }}
			</h3>
			<p
				class="my-6 text-xl leading-7 text-center"
				v-html="$t('checkoutPlans.processing.success.description')"
			></p>
		</div>

		<PlanCard :plan="plan" class="swiper-slide plan-card h-auto" :showButton="false" />
		<div class="flex justify-center mt-8" v-if="!processing">
			<Button @click="goToNewAvatar">
				<p class="text-lg">
					{{ $t('presetDetails.createAvatarModal.title') }}
				</p>
			</Button>
		</div>
	</div>
</template>
<script>
import { useRouter, useRoute } from 'vue-router'
import { Spinner, PlanCard, Header, Button } from '@/components/default'
import { inject, ref } from 'vue'

export default {
	components: { Header, Button, Spinner, PlanCard },
	async setup() {
		const route = useRoute()
		const router = useRouter()
		const axios = inject('axios')

		try {
			let plan

			if (route.query && route.query.id) {
				plan = await axios.get(`/plans/${route.query.id}`).then(res => res.data)
			}

			const order = await axios.get('/orders/latest').then(res => res.data)

			if (order) {
				return {
					plan: order.plan,
					processing: ref(order.status !== 'paid'),
					order: order,
					redirect: route.query.redirect,
				}
			}

			return { plan: plan, processing: ref(true), order: null, redirect: route.query.redirect }
		} catch (error) {
			this.sentryCaptureException(error)
			if (error.response && error.response.status === 401) {
				return { plan: null, processing: ref(true), order: null, redirect: route.query.redirect }
			}

			router.push('/')
		}
	},
	data() {
		return {
			intervalId: null,
			intervalInMs: 5000,
		}
	},
	methods: {
		goToNewAvatar() {
			if (this.redirect) {
				this.$router.push(this.redirect)
				return
			}
			this.$router.push('/')
		},
		async pooling() {
			try {
				const order = await this.axios.get('/orders/latest').then(res => res.data)

				if (order && order.status === 'paid') {
					this.processing = false

					this.captureEvent('plans_subscribe_success')
					this.captureEvent(`plans_subscribe_success_${this.order?.plan?.englishName.toLowerCase()}`)
					this.sendTiktokCompletedPaymentEvent(this.loggedUser, this.order)

					const redirect = this.redirect ? `&redirect=${this.redirect}` : ''

					this.$router.push(`/checkout/processing?id=${this.planId}${redirect}`)

					clearInterval(this.intervalId)
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
	},
	mounted() {
		if (!this.processing) {
			this.captureEvent('plans_subscribe_success')
			this.captureEvent(`plans_subscribe_success_${this.order?.plan?.englishName.toLowerCase()}`)
			this.sendTiktokCompletedPaymentEvent(this.loggedUser, this.order)
		}
	},
	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
	mounted() {
		this.intervalId = setInterval(this.pooling, this.intervalInMs)
	},
	beforeUnmount() {
		clearInterval(this.intervalId)
	},
	head: {
		title: `RealityStudio - Payment`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Payment`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
