<template>
	<div>
		<Image2TextLayout
			localePrefix="generateCaptionTool"
			gtagPrefix="generate_caption_tool"
			toolType="generate-caption"
			apiSubmitEndpoint="/inferences/generate-caption"
			previewVideoSrc="https://cdn.realitystudio.ai/assets/videos/caption-generator.mp4"
			pricingKey="generateCaption"
		>
		</Image2TextLayout>
	</div>
</template>

<script>
import { Image2TextLayout } from '@/components/tools'

export default {
	components: {
		Image2TextLayout,
	},
	head: {
		title: `RealityStudio - Generate Caption to Image`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Generate Captions to your image easily with RealityStudio. Upload your image and get a caption in seconds.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Generate Caption to Image `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Generate Captions to your image easily with RealityStudio. Upload your image and get a caption in seconds.',
			},
		],
	},
}
</script>
