<template>
	<div>
		<div class="relative">
			<ShowcaseSlides :slides="showcasePresets" @onSlideChange="slide => (currentShowcaseSlide = slide)" />
			<div class="bottom-0 absolute mb-6 text-center w-full px-4">
				<p v-if="currentShowcaseSlide?.title" class="text-2xl mb-2 font-bold">
					{{ currentShowcaseSlide.title }}
				</p>
				<Button
					v-if="
						(currentShowcaseSlide?.btnAction || currentShowcaseSlide?.btnHref) &&
						currentShowcaseSlide?.btnText
					"
					:href="currentShowcaseSlide.btnHref"
					@click="currentShowcaseSlide.btnAction"
				>
					{{ currentShowcaseSlide.btnText }}
				</Button>
			</div>
			<!-- <div class="mt-4 flex items-center absolute top-0 ml-2">
					<button class="material-icons text-4xl mr-2" @click="$router.back()">chevron_left</button>
					<p class="text-xl font-bold">{{ $t('newAvatar.pageTitle') }}</p>
				</div> -->
		</div>
		<div class="mt-6">
			<div class="flex items-center justify-between page-container cursor-pointer" @click="goToTools(true)">
				<p class="text-lg font-bold">{{ $t('newAvatar.getToKnow') }}</p>
				<button @click="goToTools(true)" class="material-icons">chevron_right</button>
			</div>
			<div class="mt-4 ml-4 md:ml-0 pr-4 md:pr-0">
				<div class="swiper" ref="toolsSwiper">
					<div class="swiper-wrapper">
						<!-- <div
								class="border-radial-1-card border swiper-slide rounded-xl relative h-auto cursor-pointer"
								@click="goToTools"
							>
								<img
									class="h-full object-cover rounded-xl"
									src="/web/images/presets-and-tools/all-tools.png"
									alt="All tools"
								/>
								<div
									class="w-full opacity-80 absolute top-0 left-0 h-full bg-gradient-to-t from-black to-transparent"
								/>
								<div class="text-center px-4 w-full absolute bottom-0 mb-6">
									<p class="text-lg font-bold">{{ $t('newAvatar.allToolsCard.title') }}</p>
									<p class="mt-4">{{ $t('newAvatar.allToolsCard.description') }}</p>
									<Button @click="goToTools" class="mt-4">{{
										$t('newAvatar.allToolsCard.btnText')
									}}</Button>
								</div>
							</div> -->
						<ToolsCard
							v-for="(tool, index) in computedTools"
							:key="`tool-${index}`"
							:tool="tool"
							class="h-auto swiper-slide"
						/>
					</div>
				</div>
			</div>
		</div>
		<router-link
			v-if="beSurprisedImages.length"
			to="/new-avatar/be-surprised"
			class="relative h-[500px] mt-6 block"
		>
			<Transition name="fade">
				<div :key="beSurprisedImages[beSurprisedImageIndex]" class="page-container">
					<img
						class="object-cover h-[400px] w-full rounded-xl"
						:src="beSurprisedImages[beSurprisedImageIndex]"
						alt="image"
					/>
				</div>
			</Transition>
			<div class="bg-gradient-to-t from-black to-transparent w-full h-1/2 absolute bottom-[100px]" />
			<div
				class="rounded-xl border-app-blue border-8 text-center bg-radial-2-card p-4 absolute bottom-0 w-3/4 left-1/2 transform -translate-x-1/2"
			>
				<span class="font-bold"
					>{{ $t('newAvatar.beSurprisedText') }}
					<span class="font-medium text-gray-300">{{ $t('newAvatar.beSurprisedSubText') }} </span>
				</span>
				<Button class="text-xl mt-6 font-semibold" @click="$router.push('/new-avatar/be-surprised')">{{
					$t('beSurprised.refresh')
				}}</Button>
			</div>
		</router-link>
		<div class="page-container mt-6 pb-6">
			<h1 class="text-xl font-bold text-left mt-4">
				{{ $t('newAvatar.selectPresetText') }}
			</h1>
			<Input
				v-if="presets.length"
				id="presetTextSearch"
				inputClass="w-full h-full disabled:cursor-not-allowed mt-4"
				:placeholder="$t('newAvatar.textSearchInputPlaceholder')"
				@update:modelValue="value => searchPresetsByText(value)"
			/>
			<!-- TODO: implement the code below with the preset category feature in the future -->
			<!-- <div class="mt-4 swiper swiper-container w-full overflow-visible" ref="categorySwiper">
				<div class="swiper-wrapper">
					<button
						v-for="category in presetCategories"
						:class="[
							'bg-gray-600 mr-2 bg-opacity-20 font-bold text-sm p-4 rounded-xl whitespace-nowrap swiper-slide w-max',
						]"
						@click="selectedCategoryTag = category"
						:key="category"
					>
						<p>{{ category }}</p>
					</button>
				</div>
			</div> -->
			<div v-if="!isFetchingPresets && !filteredPresets.length">
				<h1 class="text-xl font-semibold text-center mb-4 mt-4">
					{{ $t('newAvatar.presetSectionEmptyText') }}
				</h1>
			</div>
			<div v-if="filteredPresets.length" class="grid grid-cols-2 gap-x-4 gap-y-8 mt-4">
				<LibraryCard
					v-for="preset in filteredPresets"
					:key="preset._id"
					:data="preset"
					@click="handleSelectPreset"
				/>
			</div>
			<div class="flex items-center justify-center mt-6" v-if="isFetchingPresets">
				<AnimatedSpinner />
			</div>
		</div>
	</div>
</template>

<script>
import { Input, Select, AnimatedSpinner, Modal, Button, ShowcaseSlides } from '@/components/default'
import { LibraryCard, ToolsCard } from '@/components/new-avatar'
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'

export default {
	components: {
		Input,
		Select,
		AnimatedSpinner,
		Modal,
		Button,
		LibraryCard,
		ShowcaseSlides,
		ToolsCard,
	},
	data() {
		return {
			next: null,
			hasNext: false,
			presets: [],
			paginationLimit: 6,
			isFetchingPresets: false,
			mockupWindow: null,
			selectedPresetId: null,
			swiperInstances: {},
			textSearch: {
				text: '',
				lastSearchedText: '',
				debounceTimeoutId: null,
				results: [],
				next: null,
				hasNext: null,
			},
			showcasePresets: [],
			currentShowcaseSlide: null,

			// TODO: Implement with preset categories:

			// presetCategories: ['All', 'Test 1', 'Another test', '727', 'Pingas', 'Pingas', 'Pingas', 'Pingas'],
			// selectedPreset: 'All',

			tools: [
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/free-avatar.mp4',
					title: 'freeAvatarTool.pageTitle',
					description: 'freeAvatarTool.description',
					btnText: 'common.viewTool',
					btnAction: this.goToFreeAvatar,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/baby.mp4',
					title: 'tools.baby.title',
					description: 'tools.baby.description',
					btnText: 'common.viewTool',
					btnAction: this.goToBabyTransformation,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/restore_image.mp4',
					title: 'restoreImageTool.pageTitle',
					description: 'restoreImageTool.description',
					btnText: 'common.viewTool',
					btnAction: this.goToRestoreImage,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/generate-stickers.mp4',
					title: 'stickerTool.pageTitle',
					description: 'stickerTool.description',
					btnText: 'common.viewTool',
					btnAction: this.goToSticker,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/colorize.mp4',
					title: 'colorizeImageTool.pageTitle',
					description: 'colorizeImageTool.description',
					btnText: 'common.viewTool',
					btnAction: this.goToColorizeImage,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/scribble.mp4',
					title: 'drawingToArtTool.pageTitle',
					description: 'drawingToArtTool.description',
					btnText: 'common.viewTool',
					btnAction: this.goToDrawingToArt,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/remove-background.mp4',
					title: 'removeBackgroundTool.pageTitle',
					description: 'removeBackgroundTool.description',
					btnText: 'common.viewTool',
					btnAction: this.goToRemoveBackground,
					isNew: true,
				},

				{
					image: 'https://cdn.realitystudio.ai/assets/videos/caption-generator.mp4',
					title: 'generateCaptionTool.pageTitle',
					description: 'generateCaptionTool.description',
					btnText: 'common.viewTool',
					btnAction: this.goToGenerateCaption,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/images/logo-analysis.jpg',
					title: 'generateLogoAnalysisTool.pageTitle',
					description: 'generateLogoAnalysisTool.description',
					btnText: 'common.viewTool',
					btnAction: this.goToLogoAnalysis,
					backdropClass: '!opacity-20',
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/images/social-media-post-analysis.jpg',
					title: 'generateSocialMediaPostAnalysisTool.pageTitle',
					description: 'generateSocialMediaPostAnalysisTool.description',
					btnText: 'common.viewTool',
					btnAction: this.goToSocialMediaPostAnalysis,
					backdropClass: '!opacity-20',
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/generate-prompt-from-image.mp4',
					title: 'generatePromptFromImageTool.pageTitle',
					description: 'generatePromptFromImageTool.description',
					btnText: 'common.viewTool',
					btnAction: this.goToPromptFromCamera,
					isNew: true,
				},
				{
					image: '/web/images/presets-and-tools/random-generation-box.png',
					title: 'tools.randomAvatar.title',
					description: 'tools.randomAvatar.description',
					btnText: 'tools.randomAvatar.btnText',
					btnAction: this.goToRandomPresetCreation,
					isNew: true,
				},
			],
			beSurprisedImageIndex: 0,
			beSurprisedImages: [],
			beSurprisedInterval: null,
		}
	},
	async created() {},
	async mounted() {
		await this.initBeSurprised()
		await this.fetchTopThree()
		await this.fetchPresets(true)
		await this.fetchShowcasePresets()

		this.mockupWindow = document.querySelector('#mockupWindow')
		const eventTarget = this.isMobile ? document : this.mockupWindow

		eventTarget.addEventListener('scroll', this.onScroll)

		this.initSwiper('toolsSwiper', {
			direction: 'horizontal',
			slidesPerView: 1.35,
			loop: false,
			modules: [Autoplay],
			autoplay: {
				delay: 5 * 1000, // 5 seconds
				disableOnInteraction: true,
			},
			grabCursor: true,
			spaceBetween: 16,
		})

		// this.initSwiper('categorySwiper', {
		// 	direction: 'horizontal',
		// 	freeMode: true,
		// 	loop: false,
		// 	grabCursor: true,
		// 	spaceBetween: 16,
		// 	slidesPerView: 'auto',
		// })

		if (!this.loggedUser) {
			this.captureEvent('new_avatar_logged_out_user_view')
		}
	},
	beforeUnmount() {
		if (this.beSurprisedInterval) {
			clearInterval(this.beSurprisedInterval)
		}

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScroll)

		Object.values(this.swiperInstances).forEach(swiper => {
			swiper.destroy(true, true)
		})
	},
	methods: {
		goToSocialMediaPostAnalysis() {
			this.captureEvent('new_avatar_tools_social_media_post_analysis_click')
			this.$router.push('/tools/generate-social-media-post-analysis')
		},
		goToLogoAnalysis() {
			this.captureEvent('new_avatar_tools_logo_analysis_click')
			this.$router.push('/tools/generate-logo-analysis')
		},
		goToFreeAvatar() {
			this.captureEvent('new_avatar_tools_free_avatar_click')
			this.$router.push('/tools/free-avatar')
		},
		async initBeSurprised() {
			try {
				const data = await this.axios.get('/presets/sample/5').then(res => res.data)

				this.beSurprisedImages = data
					.map(preset => preset.images.slice(0, 2))
					.flat()
					.sort(() => Math.random() - 0.5)

				this.beSurprisedInterval = setInterval(() => {
					if (this.beSurprisedImageIndex === this.beSurprisedImages.length - 1) {
						this.beSurprisedImageIndex = 0
						return
					}

					this.beSurprisedImageIndex++
				}, 5000) // 5 seconds
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		goToPromptFromCamera() {
			this.captureEvent('new_avatar_tools_prompt_from_camera_click')
			this.$router.push('/tools/generate-prompt-from-image')
		},
		goToDrawingToArt() {
			this.captureEvent('new_avatar_tools_drawing_to_art_click')
			this.$router.push('/tools/drawing-to-art')
		},
		goToSticker() {
			this.captureEvent('new_avatar_tools_sticker_click')
			this.$router.push('/tools/sticker')
		},
		goToGenerateCaption() {
			this.captureEvent('new_avatar_tools_generate_caption_click')

			this.$router.push('/tools/generate-caption')
		},
		goToRestoreImage() {
			this.captureEvent('new_avatar_tools_restore_image_click')

			this.$router.push('/tools/restore-image')
		},
		goToColorizeImage() {
			this.captureEvent('new_avatar_colorize_image_click')
			this.$router.push('/tools/colorize-image')
		},
		goToTools(isTitleSection = false) {
			this.captureEvent(isTitleSection ? 'new_avatar_tools_header_click' : 'new_avatar_tools_click')
			this.$router.push('/tools')
		},
		goToRemoveBackground() {
			this.captureEvent('new_avatar_remove_background_click')
			this.$router.push('/tools/remove-background')
		},
		goToBabyTransformation() {
			this.captureEvent('new_avatar_tools_baby_click')
			this.$router.push('/tools/generate-baby')
		},
		async fetchTopThree() {
			try {
				console.log(this.browserLang)
				const { data } = await this.axios.get('/presets/most-used', {
					params: { lang: this.browserLang },
				})

				if (this.presets.length) {
					this.presets = [...data, ...this.presets]
				}

				this.presets = data
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		handleSelectPreset(presetId) {
			this.captureEvent('new_avatar_goto_preset')
			this.selectedPresetId = presetId
			this.$router.push(`/new-avatar/${this.selectedPresetId}`)
		},
		onScroll() {
			const isScrolledToBottom = this.isMobile
				? window.innerHeight + window.scrollY >= document.body.offsetHeight
				: this.mockupWindow.scrollTop + this.mockupWindow.offsetHeight >= this.mockupWindow.scrollHeight

			if (isScrolledToBottom) {
				this.textSearch.text ? this.fetchMorePresetsByText() : this.fetchPresets()
			}
		},
		async fetchPresets(isFirstFetch) {
			if (this.isFetchingPresets || (!this.hasNext && !isFirstFetch)) return
			this.isFetchingPresets = true

			const paginationLimit = isFirstFetch ? this.paginationLimit - this.presets.length : this.paginationLimit

			const params = {
				limit: paginationLimit,
				next: this.hasNext ? this.next : undefined,
				ignorePopular: true,
				lang: this.browserLang,
			}

			try {
				const { data } = await this.axios.get('/presets', { params })

				this.presets = [...this.presets, ...data.results]
				this.hasNext = data.hasNext
				this.next = data.next
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetchingPresets = false
		},

		initSwiper(refName, params) {
			if (this.swiperInstances[refName]) {
				this.swiperInstances[refName].destroy()
			}

			this.swiperInstances = { ...this.swiperInstances, [refName]: new Swiper(this.$refs[refName], params) }
		},

		resetTextSearchData() {
			this.textSearch = {
				text: '',
				lastSearchedText: '',
				debounceTimeoutId: null,
				results: [],
				next: null,
				hasNext: null,
			}
		},

		async searchPresetsByText(text) {
			this.isFetchingPresets = true

			clearTimeout(this.textSearch.debounceTimeoutId)
			if (this.lastSearchedText === text) return

			if (!text?.length) {
				this.resetTextSearchData()
				this.isFetchingPresets = false
				return
			}

			this.textSearch.results = []
			this.textSearch.text = text

			const searchTimerInMs = 600 // 600ms - any shorter triggers another request if the user holds backspace to erase

			this.textSearch.debounceTimeoutId = setTimeout(this.executeTextSearch, searchTimerInMs)
		},

		async fetchMorePresetsByText() {
			if (this.isFetchingPresets) return

			if (!this.textSearch.hasNext) {
				this.isFetchingPresets = false
				return
			}
			this.isFetchingPresets = true

			this.executeTextSearch()
		},

		async executeTextSearch() {
			const params = {
				limit: this.paginationLimit,
				next: this.textSearch.hasNext ? this.textSearch.next : undefined,
				searchText: this.textSearch.text,
				lang: this.browserLang,
			}

			try {
				const response = await this.axios.get(`/presets`, { params }).then(res => res.data)

				this.textSearch.results = [...this.textSearch.results, ...response.results]
				this.textSearch.next = response.next
				this.textSearch.hasNext = response.hasNext
				this.textSearch.lastSearchedText = this.textSearch.text
			} catch (error) {
				this.sentryCaptureException(error)
			} finally {
				this.isFetchingPresets = false
			}
		},

		async goToRandomPresetCreation() {
			this.captureEvent('new_avatar_random_preset_click')

			try {
				const randomPreset = await this.axios
					.get('/presets/sample/1', { params: { lang: this.browserLang } })
					.then(res => res.data)

				if (!randomPreset.length) return

				this.$router.push(`/new-avatar/${randomPreset[0]._id}`)
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},

		async fetchShowcasePresets() {
			try {
				const { data } = await this.axios.get('/presets/featured', {
					params: { lang: this.browserLang },
				})

				const showcasePresets = data.map(preset => {
					return {
						images: preset.images,
						title: preset.name,
						btnText: this.$t('myAccountHome.createNow'),
						btnHref: `/new-avatar/${preset._id}`,
					}
				})

				showcasePresets.push({
					images: ['/web/images/presets-and-tools/baby-showcase.png'],
					title: this.$t('tools.baby.title'),
					btnText: this.$t('tools.baby.description'),
					btnAction: () => this.$router.push('/tools/generate-baby'),
				})

				showcasePresets.unshift({
					images: ['/web/images/presets-and-tools/subscribe-offer-modal.jpg'],
					title: this.$t('freeAvatarTool.pageTitle'),
					btnText: this.$t('freeAvatarTool.submitButton'),
					btnAction: () => this.$router.push('/tools/free-avatar'),
				})

				this.showcasePresets = showcasePresets
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
	},

	computed: {
		browserLang() {
			return this.$store.getters.getBrowserLang
		},
		computedTools() {
			return this.tools.map(tool => {
				return {
					...tool,
					title: this.$t(tool.title),
					description: this.$t(tool.description),
					btnText: this.$t(tool.btnText),
				}
			})
		},

		isMobile() {
			return this.$store.getters.getIsMobile
		},

		filteredPresets() {
			if (this.textSearch.text) {
				return this.textSearch.results
			}
			return this.presets
		},

		loggedUser() {
			return this.$store.getters.getUser
		},
	},
	head: {
		title: `RealityStudio - Select preset`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Explore a variety of presets and begin crafting your unique avatar with RealityStudio. Choose from a range of customization options to personalize your digital self.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Select preset`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Explore a variety of presets and begin crafting your unique avatar with RealityStudio. Choose from a range of customization options to personalize your digital self.',
			},
		],
	},
}
</script>
