<template>
	<div class="page-container pt-6 px-4 relative">
		<div v-show="!isImagesModalOpened">
			<Header class="mb-6" :title="$t(`${localePrefix}.pageTitle`)" />
			<div v-if="!previewImageBlob">
				<video
					v-if="previewIsVideo"
					class="mb-4 mx-auto w-[230px] h-[300px] object-cover rounded-xl"
					autoplay
					muted
					loop
					playsinline
					:controls="false"
				>
					<source :src="previewVideoSrc" type="video/mp4" />
				</video>
				<img v-else class="mb-4 mx-auto w-[230px] h-[300px] object-cover rounded-xl" :src="previewVideoSrc" />
			</div>
			<img
				v-if="previewImageBlob"
				class="mb-4 mx-auto w-[230px] h-[300px] object-cover rounded-xl"
				:src="previewImageBlob"
			/>
			<div class="text-center mb-4">
				<p
					class="text-base leading-6 mb-4"
					v-html="
						$tc(`${localePrefix}.priceDescription`, pointsPrice, {
							pointsAmount: pointsPrice,
						})
					"
				></p>
				<p class="text-lg leading-7" v-html="$t(`${localePrefix}.description`)"></p>
			</div>
			<Button v-if="userHasEnoughPoints" @click="handleImageUpload">
				{{ $t(!previewImageBlob ? `${localePrefix}.uploadButton` : `${localePrefix}.changeImageButton`) }}
				<input
					ref="fileInput"
					class="hidden"
					type="file"
					name="file"
					accept="image/*"
					@change="event => onFileSelect(event)"
				/>
			</Button>
			<Button v-if="!!imageData" class="mt-4" @click="handleSubmit" :isLoading="isUploading">{{
				$t(`${localePrefix}.submitButton`)
			}}</Button>
			<AuthenticationBox v-if="!loggedUser" :redirectTo="currentPathname" :pageName="gtagPrefix" class="mt-4" />
			<PlansSwiper v-if="loggedUser && !userHasEnoughPoints" :plans="plans" class="mt-4" />
			<div class="my-4" v-if="!!inferences.length">
				<p class="text-lg leading-7 font-bold mb-4">
					{{ $t(`${localePrefix}.createdTitle`) }}
				</p>
				<div class="grid grid-cols-2 gap-4">
					<div class="relative" v-for="inference in inferences" :key="inference._id">
						<div class="relative h-[214px] w-full" v-if="inference.outputs.length">
							<LazyImage
								:src="inference.input"
								:height="214"
								alt="Image without background"
								@click="handleOpenImage(inference)"
								imgClass="absolute top-0 left-0 rounded-xl h-full w-full object-cover cursor-pointer z-1"
							/>
						</div>
						<div
							class="rounded-xl h-[214px] w-full bg-radial-2-card flex items-center justify-center"
							v-if="inference.status === 'processing'"
						>
							<Spinner
								iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
								height="12"
								width="12"
							/>
						</div>
						<div
							class="relative rounded-xl h-[214px] w-full bg-radial-2-card flex items-center justify-center"
							v-if="!inference.outputs.length && inference.status === 'failed'"
						>
							<img
								@click="() => handleOpenImage(inference)"
								src="/web/images/presets-and-tools/tool-failed.png"
								alt="tool-failed"
								class="absolute rounded-xl max-w-[60px] h-full object-contain cursor-pointer z-1"
							/>
						</div>

						<div class="absolute bottom-0 z-2 p-2 w-full">
							<Button
								v-if="inference.status === 'completed'"
								class="text-center"
								:color="showCopyText(inference) ? 'green' : 'primary'"
								@click="copyText(inference)"
								:disabled="!inference || inference.status !== 'completed'"
							>
								{{
									showCopyText(inference)
										? $t(`${localePrefix}.textCopied`)
										: $t(`${localePrefix}.copyText`)
								}}
							</Button>
						</div>
						<button
							class="bg-red-700 flex items-center justify-center rounded-full h-8 w-8 absolute top-2 right-2 z-2"
							@click="handleDeleteImage(inference)"
							type="button"
						>
							<span v-if="deletingId !== inference._id" class="material-icons text-base">delete</span>
							<Spinner
								iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
								height="4"
								width="4"
								v-else
							/>
						</button>
					</div>
				</div>

				<Spinner v-if="isFetchingInferences" iconClass="mx-auto mt-4" height="12" width="12" />
			</div>
		</div>
		<div v-show="isImagesModalOpened" class="h-full w-full rounded-t-xl md:px-0 top-0 left-0 flex flex-col gap-4">
			<div class="flex justify-between">
				<Button color="transparent" size="fit" @click="() => (isImagesModalOpened = false)">
					<span class="material-icons text-[32px]">close</span>
				</Button>
				<Button
					v-if="currentInference"
					@click="handleDeleteImage(currentInference)"
					color="transparent"
					size="fit"
					:isLoading="deletingId === currentInference._id"
					:disabled="currentInference.status === 'processing'"
				>
					<span class="material-icons text-[32px]">delete</span>
				</Button>
			</div>
			<div class="flex flex-1">
				<div
					class="bg-radial-2-card flex items-center justify-center w-full rounded-xl h-[460px]"
					v-if="(!currentInference || !currentInference.outputs.length) && !previewImageBlob"
				>
					<Spinner
						v-if="currentInference?.status === 'processing'"
						iconClass="text-gray-200 animate-spin dark:text-gray-600 w-[32px] h-[32px] fill-white"
						height="32"
						width="32"
					/>
					<div
						class="relative h-[460px] w-full text-center flex flex-col items-center justify-center px-8"
						v-if="currentInference?.status === 'failed'"
					>
						<h2 class="text-3xl color-white font-bold mb-8">
							{{ $t(`${localePrefix}.toolFailed.title`) }}
						</h2>
						<img src="/web/images/presets-and-tools/tool-failed.png" alt="tool-failed" />
						<p
							class="text-md leading-7 color-white my-6"
							v-html="$t(`${localePrefix}.toolFailed.description`)"
						></p>
						<Button
							:class="{
								'opacity-50': isUploading,
							}"
							@click="handleUploadMore"
							:isLoading="isUploading"
							>{{ $t(`${localePrefix}.toolFailed.button`) }}</Button
						>
					</div>
				</div>
				<div class="relative h-[460px] w-full" v-else>
					<img
						:src="previewImageBlob || currentInference.input"
						class="absolute object-cover h-full w-full rounded-xl z-10"
						alt="Image without background"
					/>
				</div>
			</div>
			<div>
				<div class="swiper inference-inputs-swiper">
					<div class="swiper-wrapper">
						<Button
							color="transparent"
							size="fit"
							@click="handleUploadMore"
							:isLoading="isUploading"
							:class="{
								'opacity-50': isUploading,
							}"
							class="swiper-slide relative !h-[60px] !w-[60px]"
						>
							<div class="rounded-xl h-[60px] w-[60px] flex items-center justify-center bg-radial-2-card">
								<span class="material-icons text-2xl">add_photo_alternate</span>
							</div>
						</Button>
						<div
							v-for="inference of inferences"
							:key="inference._id"
							class="swiper-slide relative !h-[60px] !w-[60px]"
						>
							<div
								class="flex items-center justify-center bg-radial-2-card rounded-xl absolute -z-10 h-[60px] w-[60px]"
								v-if="inference.status === 'processing'"
							>
								<Spinner
									iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
									height="6"
									width="6"
								/>
							</div>
							<LazyImage
								v-if="inference.input && inference.outputs.length && inference.status === 'completed'"
								:src="inference.input"
								realityStudioIconClass="w-50 h-50 m-auto"
								lazyWrapperClass="absolute text-center top-0 left-0 z-10 flex w-full"
								:imgClass="`rounded-xl h-full w-full object-cover cursor-pointer absolute top-0 left-0 z-10 ${
									inference._id === currentInference?._id
										? 'border-2 border-blue-600'
										: 'border-2 border-transparent'
								}`"
								@click="() => handleOpenImage(inference)"
								alt="Image without background"
								:height="60"
							/>
							<div
								v-if="inference.status === 'failed'"
								:class="`rounded-xl h-[60px] w-[60px] object-cover cursor-pointer absolute top-0 left-0 z-10 ${
									inference._id === currentInference?._id
										? 'border-2 border-blue-600'
										: 'border-2 border-transparent'
								}`"
							>
								<img
									@click="() => handleOpenImage(inference)"
									src="/web/images/presets-and-tools/tool-failed.png"
									alt="tool-failed"
									class="p-1 h-full w-full"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="currentInference && currentInference.outputs.length" class="mb-32 lg:mb-0">
				<div class="bg-radial-2-card rounded-xl mt-4 p-4 pb-10 relative">
					<Button
						@click="copyText(currentInference)"
						class="absolute right-4 bottom-4"
						color="transparent"
						size="fit"
					>
						<div class="material-icons text-2xl">
							{{ showCopyText(currentInference) ? 'done' : 'content_copy' }}
						</div>
					</Button>
					<div class="space-y-4 text-gray-300" :class="outputWrapperClass" v-html="computedOutput"></div>
					<div
						class="z-10 absolute top-0 left-0 w-full h-full bg-black/70 rounded-xl flex items-center justify-center"
						v-if="isRemixing"
					>
						<Spinner />
					</div>
				</div>
			</div>
			<Button v-if="!!previewImageBlob" @click="handleSubmit" :isLoading="isUploading" :disabled="isUploading">
				{{ $t(`${localePrefix}.submitButton`) }}
			</Button>
			<div class="fixed bottom-4 w-[calc(100%-32px)] lg:w-full lg:static z-10" v-else>
				<Button
					@click="() => handleRemixInference(currentInference)"
					:disabled="isRemixing || currentInference?.status === 'processing'"
				>
					<span class="material-icons text-2xl mr-2">{{ isRemixing ? 'done' : 'refresh' }}</span>
					{{ isRemixing ? $t(`${localePrefix}.regeneratingButton`) : $t(`${localePrefix}.regenerateButton`) }}
				</Button>
				<div class="flex items-center justify-center gap-4 mt-6">
					<Button
						color="transparent"
						class="w-auto h-auto"
						@click="backwardOutput"
						:disabled="currentOutputIndex === 0"
					>
						<span class="material-icons text-2xl">chevron_left</span></Button
					>

					<span>{{ currentOutputIndex + 1 }}/{{ currentInference?.outputs?.length || 1 }}</span>

					<Button
						color="transparent"
						class="w-auto h-auto"
						@click="forwardOutput"
						:disabled="currentOutputIndex === (currentInference?.outputs?.length || 1) - 1"
					>
						<span class="material-icons text-2xl">chevron_right</span></Button
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Header, Button, LazyImage, Spinner, FloatingButton } from '@/components/default'
import { AuthenticationBox } from '@/components/auth'
import { PlansSwiper } from '@/components/new-avatar'
import Swiper from 'swiper'
import { ref, inject } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'ToolLayout',
	components: {
		Header,
		Button,
		PlansSwiper,
		LazyImage,
		Spinner,
		AuthenticationBox,
		FloatingButton,
	},
	async setup(props) {
		const axios = inject('axios')
		const store = useStore()
		const loggedUser = store.getters.getUser

		const [pricing, plans, inferences] = await Promise.all([
			axios.get('/inferences/pricing').then(res => res.data),
			axios.get('/plans?unit=monthly').then(res => res.data),
			loggedUser && axios.get(`/inferences/library?type=${props.toolType}`).then(res => res.data),
		])

		return {
			pointsPrice: pricing[props.pricingKey] || 0,
			plans,
			inferences: ref((inferences && inferences.results) || []),
			hasNext: ref(inferences?.hasNext),
			next: ref(inferences?.next),
		}
	},
	data() {
		return {
			isRemixing: false,
			currentOutputIndex: 0,
			mockupWindow: null,
			imageData: null,
			previewImageBlob: null,
			isUploading: false,
			deletingId: null,
			getInferencesIntervals: [],
			isImagesModalOpened: false,
			currentInference: null,
			fetchInferenceIntervalInMs: 5000,
			inferenceOutputsSwiperInstance: null,
			isFetchingInferences: false,
			textCopied: false,
			textCopiedInferenceId: null,
			textCopiedDurationInMs: 5000,
			gettingImage: false,
			isRemixing: false,
			currentOutputIndex: 0,
		}
	},
	props: {
		outputWrapperClass: {
			type: String,
		},
		pricingKey: {
			type: String,
		},
		previewVideoSrc: {
			type: String,
		},
		toolType: {
			type: String,
			required: true,
		},
		localePrefix: {
			type: String,
			required: true,
		},
		gtagPrefix: {
			type: String,
			required: true,
		},
		apiSubmitEndpoint: {
			type: String,
			required: true,
		},
	},
	methods: {
		forwardOutput() {
			if (this.currentInference?.outputs.length === this.currentOutputIndex + 1) {
				return
			}

			this.currentOutputIndex++
		},
		backwardOutput() {
			if (this.currentOutputIndex === 0) {
				return
			}

			this.currentOutputIndex--
		},
		async handleRemixInference(currentInference) {
			this.isRemixing = true
			this.captureEvent(`${this.gtagPrefix}_remix_inference_intent`)

			try {
				await this.axios
					.post(this.apiSubmitEndpoint, {
						image: currentInference.input,
					})
					.then(res => res.data)

				const poolingInterval = setInterval(
					() => this.fetchInferenceGroup(currentInference._id),
					this.fetchInferenceIntervalInMs
				)

				this.getInferencesIntervals.push({
					interval: poolingInterval,
					inferenceId: currentInference._id,
				})

				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.success'),
					message: this.$t(`${this.localePrefix}.successMessage`),
					type: 'success',
				})

				this.captureEvent(`${this.gtagPrefix}_remix_inference_success`)
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent(`${this.gtagPrefix}_remix_inference_error`)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t(`${this.localePrefix}.errorMessage`),
					type: 'error',
				})
			}
		},
		async handleSubmit() {
			const file = this.imageData

			this.isUploading = true

			this.captureEvent(`${this.gtagPrefix}_submit_tool_intent`)
			try {
				const signedUrlResponse = await this.axios.get(`/files/sign-url/${file.name}`).then(res => res.data)

				await this.axios.put(signedUrlResponse.url, file)

				const inference = await this.axios
					.post(this.apiSubmitEndpoint, {
						image: signedUrlResponse.key,
					})
					.then(res => res.data)

				this.inferences = [inference, ...this.inferences]

				this.$nextTick(() => {
					this.inferenceOutputsSwiperInstance?.update()
				})
				this.handleOpenImage(inference)

				const poolingInterval = setInterval(
					() => this.fetchInferenceGroup(inference._id),
					this.fetchInferenceIntervalInMs
				)

				this.getInferencesIntervals.push({
					interval: poolingInterval,
					inferenceId: inference._id,
				})

				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.success'),
					message: this.$t(`${this.localePrefix}.successMessage`),
					type: 'success',
				})

				this.captureEvent(`${this.gtagPrefix}_submit_tool_success`)
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent(`${this.gtagPrefix}_submit_tool_error`)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t(`${this.localePrefix}.errorMessage`),
					type: 'error',
				})
			}

			this.isUploading = false
		},
		async handleImageUpload() {
			if (this.gettingImage) return
			this.gettingImage = true
			const picture = await this.$takePicture()

			if (picture) {
				let blob = await fetch(picture).then(r => r.blob())
				this.previewImageBlob = picture
				this.imageData = blob
				this.gettingImage = false
				return
			}

			if (this.$refs.fileInput) {
				this.$refs.fileInput.click()
			}
			this.gettingImage = false
		},
		async onFileSelect(event) {
			const file = event.target.files[0]

			if (!file) return

			this.$refs.fileInput.value = ''

			this.captureEvent(`${this.gtagPrefix}_image_upload_click`)
			this.previewImageBlob = URL.createObjectURL(file)
			this.imageData = file
		},
		handleOpenImage(inference) {
			if (!!this.previewImageBlob) {
				this.previewImageBlob = null
				this.imageData = null
			}

			this.captureEvent(`${this.gtagPrefix}_open_image_click`)
			this.currentInference = inference
			this.currentOutputIndex = inference.outputs.length > 0 ? inference.outputs.length - 1 : 0
			this.isImagesModalOpened = true
			window.scrollTo({ top: 0, behavior: 'smooth' })
		},
		initInferencesSwiper() {
			this.inferenceOutputsSwiperInstance = new Swiper('.inference-inputs-swiper', {
				slidesPerView: 'auto',
				freeMode: true,
				spaceBetween: 16,
				grabCursor: true,
				mousewheel: true,
				on: {
					activeIndexChange: () => {
						if (!this.inferenceOutputsSwiperInstance || this.isFetchingInferences) return

						/**
						 * Load more items when the user it's close to the end of the list
						 */
						if (
							this.inferenceOutputsSwiperInstance.activeIndex * 2 > this.inferences.length &&
							this.hasNext
						) {
							this.fetchInferences()
						}
					},
				},
			})
		},
		handleUploadMore() {
			this.captureEvent(`${this.gtagPrefix}_upload_more_click`)
			this.currentInference = null
			this.handleImageUpload()
		},
		onScrollPage() {
			let isScrolledToBottom = false

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight
			}

			if (isScrolledToBottom) {
				this.fetchInferences()
			}
		},
		async handleDeleteImage(inference) {
			this.deletingId = inference._id

			try {
				await this.axios.delete(`/inferences/library/${inference._id}`)

				this.inferences = this.inferences.filter(_inference => _inference._id !== inference._id)

				if (this.currentInference?._id === inference._id) {
					const anotherInference = this.inferences?.[0]

					this.currentInference = anotherInference
				}

				if (this.isImagesModalOpened && this.inferences.length === 0) {
					this.isImagesModalOpened = false
				}

				this.$nextTick(() => {
					this.inferenceOutputsSwiperInstance?.update()
				})
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}

			this.deletingId = null
		},
		async fetchInferences() {
			if (this.isFetchingInferences || !this.hasNext) {
				return
			}

			this.isFetchingInferences = true

			try {
				const { results, hasNext, next } = await this.axios
					.get(`/inferences?${this.hasNext ? `next=${this.next}` : ''}&type=${this.toolType}`)
					.then(res => res.data)

				const inferenceLength = this.inferences.length

				this.inferences = [...this.inferences, ...results]
				this.hasNext = hasNext
				this.next = next

				if (inferenceLength !== this.inferences.length) {
					this.$nextTick(() => {
						this.inferenceOutputsSwiperInstance?.update()
					})
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetchingInferences = false
		},
		async fetchInference(inferenceId) {
			try {
				const inference = await this.axios.get(`/inferences/${inferenceId}`).then(res => res.data)

				this.inferences = this.inferences.map(_inferenceGroup => {
					if (_inferenceGroup.inferences.includes(inferenceId)) {
						return inference
					}

					return _inferenceGroup
				})

				if (inference.status !== 'processing') {
					clearInterval(
						this.getInferencesIntervals.find(interval => interval.inferenceId === inferenceId)?.interval
					)
					const displayOfferModal = this.checkShowSubscribeOfferModal()

					if (displayOfferModal) {
						this.$mitt.emit('openSubscribeOfferModal')
					}
					this.$mitt.emit('openFeedbackModal', inference)
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		async fetchInferenceGroup(inferenceGroupId) {
			try {
				const inference = await this.axios.get(`/inferences/library/${inferenceGroupId}`).then(res => res.data)

				this.inferences = this.inferences.map(_inferenceGroup => {
					if (_inferenceGroup._id === inferenceGroupId) {
						return inference
					}

					return _inferenceGroup
				})

				if (this.currentInference?._id === inferenceGroupId) {
					this.currentInference = inference
					this.currentOutputIndex = inference.outputs.length > 0 ? inference.outputs.length - 1 : 0
				}

				if (inference.status !== 'processing') {
					if (this.isRemixing) {
						this.isRemixing = false
					}

					const interval = this.getInferencesIntervals.find(
						interval => interval.inferenceId === inferenceGroupId
					)?.interval

					if (interval) {
						clearInterval(interval)

						this.getInferencesIntervals = this.getInferencesIntervals.filter(
							interval => interval.inferenceId !== inferenceGroupId
						)
					}

					const displayOfferModal = this.checkShowSubscribeOfferModal()

					if (displayOfferModal) {
						this.$mitt.emit('openSubscribeOfferModal')
					}
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		async copyText(inference) {
			this.captureEvent(`${this.gtagPrefix}_copy_text_intent`)
			let formattedText = ''

			try {
				const { output } = JSON.parse(inference.outputs[this.currentOutputIndex])

				for (const row of output) {
					switch (row.type) {
						case 'h3':
							if (formattedText) formattedText += '\n'
							formattedText += `# ${row.content}\n`
							break
						case 'p':
							if (formattedText) formattedText += '\n'
							formattedText += `${row.content}\n`
							break
						case 'li':
							if (formattedText) formattedText += '\n'
							formattedText += `- ${row.content}\n`
							break
					}
				}
			} catch (error) {
				formattedText = inference.outputs[this.currentOutputIndex]
			}

			try {
				await this.copyToClipboard(formattedText)
				this.textCopiedInferenceId = inference?._id
				this.textCopied = true
				this.captureEvent(`${this.gtagPrefix}_copy_text_success`)
			} catch (error) {
				this.captureEvent(`${this.gtagPrefix}_copy_text_error`)
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}
		},
		showCopyText(inference) {
			if (this.textCopied && inference && this.textCopiedInferenceId === inference._id) {
				return true
			}

			return false
		},
		checkShowSubscribeOfferModal() {
			if (this.loggedUser && this.loggedUser.plan && this.loggedUser.plan.free) {
				return this.loggedUser.modals.subscriptionOfferModalDisplayedAt
					? this.$dayjs().isAfter(
							this.$dayjs(this.loggedUser.modals.subscriptionOfferModalDisplayedAt).add(30, 'minute')
					  )
					: true
			}

			return !this.loggedUser.hasSubscription
		},
		formatTextOutput(json) {
			let html = ''
			let isListOpen = false

			json.output.forEach(item => {
				switch (item.type) {
					case 'h3':
						if (isListOpen) {
							html += '</ul>'
							isListOpen = false
						}
						html += `<h3 class='text-2xl leading-8 font-bold text-white'>${item.content}</h3>`
						break
					case 'p':
						if (isListOpen) {
							html += '</ul>'
							isListOpen = false
						}
						html += `<p>${item.content}</p>`
						break
					case 'ul':
						if (isListOpen) {
							html += '</ul>'
						}
						html += '<ul class="list-disc space-y-4 pl-6">'
						isListOpen = true
						break
					case 'li':
						if (item.content.includes(':')) {
							const [boldText, ...rest] = item.content.split(':')
							html += `<li><b>${boldText}:</b>${rest.join(':')}</li>`
						} else {
							html += `<li>${item.content}</li>`
						}
						break
				}
			})

			if (isListOpen) {
				html += '</ul>' // Close the last <ul> tag if it's still open
			}

			return html
		},
	},
	computed: {
		previewIsVideo() {
			return this.previewVideoSrc?.endsWith('.mp4')
		},
		computedOutput() {
			if (!this.currentInference?.outputs?.length) {
				return ''
			}

			try {
				const jsonOutput = JSON.parse(this.currentInference.outputs[this.currentOutputIndex])

				if (jsonOutput.output) {
					return this.formatTextOutput(jsonOutput)
				}
			} catch (error) {}

			return this.currentInference.outputs[this.currentOutputIndex]
		},
		currentPathname() {
			return this.$route.path
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
		userHasEnoughPoints() {
			if (!this.loggedUser) {
				return false
			}

			return this.loggedUser?.points >= this.pointsPrice
		},
	},
	watch: {
		isImagesModalOpened(newValue) {
			if (newValue) {
				this.currentOutputIndex = 0
			}
		},
		textCopied(newValue, oldValue) {
			if (newValue) {
				setTimeout(() => {
					this.textCopied = false
				}, this.textCopiedDurationInMs)
			}
		},
	},
	mounted() {
		this.mockupWindow = this.getWindow()

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScrollPage)

		this.initInferencesSwiper()
	},
	beforeUnmount() {
		this.getInferencesIntervals.forEach(interval => clearInterval(interval.interval))

		if (this.inferenceOutputsSwiperInstance) {
			this.inferenceOutputsSwiperInstance.destroy()
		}

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScrollPage)
	},
}
</script>
