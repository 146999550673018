<template>
	<form v-if="user" @submit.prevent="updateUsername" class="py-6 flex flex-col gap-4">
		<p class="text-xl">Update your name</p>
		<div class="flex flex-col gap-2">
			<Label>Your name:</Label>
			<input
				type="text"
				v-model="userName"
				class="border leading-10 px-4 rounded"
				:class="error && 'border-red-200'"
			/>
		</div>
		<div class="flex justify-between">
			<span @click="cancel" class="border px-4 cursor-pointer leading-10 rounded hover:bg-gray-200">
				Cancel
			</span>
			<button
				class="border px-4 cursor-pointer border-green-400 rounded text-green-400 hover:bg-green-400 hover:text-white leading-10"
				type="submit"
			>
				Save
			</button>
		</div>
	</form>
</template>

<script>
export default {
	computed: {
		user() {
			return this.$store.state.user || null
		},
		userName: {
			get() {
				return this.user?.userName
			},
			set(value) {
				return value
			},
		},
	},
	data() {
		return {
			error: false,
			message: '',
		}
	},
	methods: {
		cancel() {
			this.userName = this.user.userName
		},
		async updateUsername() {
			this.error = false
			this.message = ''

			try {
				await this.axios
					.post('/username', {
						userName: this.userName,
					})
					.then(res => res.data)

				this.$store.commit('setUser', {
					...this.user,
					userName: this.userName,
				})
			} catch (error) {
				this.sentryCaptureException(error)

				this.error = true
				this.message = 'Something went wrong, please try again later.'
			}
		},
	},
}
</script>
