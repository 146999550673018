<template>
	<div class="border border-radial-1-card rounded-xl p-2 grid grid-cols-2 h-12 mt-6 relative">
		<div
			class="bg-white h-8 rounded-xl w-full absolute -z-10 mt-2 mx-2 transition-transform duration-500"
			ref="whiteBackgroundTab"
			:class="{
				'translate-x-0': activeTab === 'yearly',
				'translate-x-full': activeTab === 'monthly',
			}"
		></div>
		<button
			ref="yearlyTab"
			class="rounded-xl text-sm font-medium h-8"
			:class="{
				'bg-transparent text-black': activeTab === 'yearly',
			}"
			@click="handleChangeTab('yearly')"
		>
			{{ $t('checkoutPlanTabs.yearlyPlan') }}
		</button>
		<button
			@click="handleChangeTab('monthly')"
			class="rounded-xl text-sm font-medium h-8"
			:class="{
				'bg-transparent text-black': activeTab === 'monthly',
			}"
		>
			{{ $t('checkoutPlanTabs.monthlyPlan') }}
		</button>
	</div>
</template>

<script>
export default {
	emits: ['onChangeTab'],
	props: {
		activeTab: {
			type: String,
			default: 'yearly',
		},
	},
	methods: {
		handleChangeTab(tab) {
			this.$emit('onChangeTab', tab)
		},
		onResize() {
			this.resizeBackground()
		},
		resizeBackground() {
			if (!this.$refs.yearlyTab || !this.$refs.whiteBackgroundTab) return

			this.$refs.whiteBackgroundTab.style.width = this.$refs.yearlyTab.offsetWidth + 'px'
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.resizeBackground()
		})
		window.addEventListener('resize', this.onResize)
	},
	unmounted() {
		window.removeEventListener('resize', this.onResize)
	},
}
</script>
