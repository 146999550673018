<template>
	<div>
		<Image2TextLayout
			localePrefix="generatePromptFromImageTool"
			gtagPrefix="generate_prompt_from_image_tool"
			toolType="generate-prompt-from-image"
			apiSubmitEndpoint="/inferences/generate-prompt-from-image"
			previewVideoSrc="https://cdn.realitystudio.ai/assets/videos/generate-prompt-from-image.mp4"
			pricingKey="generatePromptFromImage"
		>
		</Image2TextLayout>
	</div>
</template>

<script>
import { Image2TextLayout } from '@/components/tools'

export default {
	components: {
		Image2TextLayout,
	},
	head: {
		title: `RealityStudio - Generate prompt from image`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Generate a prompt from your image easily with RealityStudio. Upload your photo and get your prompt in seconds.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Generate prompt from image `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Generate a prompt from your image easily with RealityStudio. Upload your photo and get your prompt in seconds.',
			},
		],
	},
}
</script>
