<template>
	<div class="h-full flex items-center justify-center">
		<div class="mt-6 text-center flex items-center justify-center flex-col">
			<img class="w-[100px] h-[100px]" src="/web/icons/any-avatar-library.svg" alt="Icon" />
			<p v-html="$t('emptyState.suggestionMessage')"></p>
			<Button class="mt-6" @click="goToRandomPresetCreation()">
				{{ ctaText }}
			</Button>
		</div>
	</div>
</template>

<script>
import Button from '@/components/default/Button.vue'

export default {
	name: 'AnyAvatarPlaceholder',
	components: {
		Button,
	},
	data() {
		return {
			randomMethod: null,
		}
	},
	methods: {
		async goToRandomPresetCreation() {
			if (this.loggedUser?.hasSubscription) {
				this.captureEvent('new_avatar_random_preset_click')

				try {
					const randomPreset = await this.axios.get('/presets/sample/1').then(res => res.data)

					if (!randomPreset.length) {
						return
					}

					this.$router.push(`/new-avatar/${randomPreset[0]._id}`)
				} catch (error) {
					this.sentryCaptureException(error)
				}
			} else {
				this.$router.push('/checkout/plans')
			}
		},
	},
	computed: {
		ctaText() {
			if (this.loggedUser?.hasSubscription) {
				return this.$t('emptyState.createAvatarNow')
			}

			return this.$t('emptyState.subscribeNow')
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
}
</script>
