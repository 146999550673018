<template>
	<div class="page-container">
		<div class="flex items-center py-6">
			<Button size="fit" color="transparent" @click="$router.push('/research')">
				<span class="material-icons">arrow_back_ios</span>
			</Button>
			<h1 class="font-bold text-xl">
				{{ collection.name }}
			</h1>
		</div>

		<template v-if="collection.content.length">
			<MasonryGrid :items="collection.content" v-slot="slotProps">
				<ResearchCard
					:item="slotProps.item"
					@onClickImage="openDetails"
					@onDelete="deleteItemFromCollection"
					:allowBookmark="false"
					:allowDelete="true"
				></ResearchCard>
			</MasonryGrid>
		</template>
	</div>

	<div
		v-if="isOpenDetails"
		class="fixed top-0 left-0 z-30 w-screen h-screen lg:w-2/4 lg:left-1/2 lg:-translate-x-1/2"
		id="modal"
	>
		<div class="mx-auto">
			<Button
				@click="closeDetails"
				size="fit"
				color="transparent"
				buttonClass="border-2 border-white/5 bg-[#2C3843]/80 rounded-full p-3 absolute top-4 right-4"
			>
				<span class="material-symbols-outlined">close</span>
			</Button>

			<LazyImage
				:src="detailsData.image"
				:alt="detailsData.title"
				:allowZoom="true"
				imgClass="w-full object-cover cursor-pointer h-screen -z-10"
			/>

			<Button target="_blank" :href="detailsData.link" class="absolute bottom-4 w-[calc(100%-32px)] ml-4">
				{{ $t('researchResult.visitPage') }}</Button
			>
		</div>
	</div>
</template>

<script>
import { inject, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Button, LazyImage, MasonryGrid } from '@/components/default'
import ResearchCard from '../../components/research/ResearchCard.vue'

export default {
	components: {
		ResearchCard,
		MasonryGrid,
		Button,
		LazyImage,
	},
	data() {
		return {
			isOpenDetails: false,
			detailsData: null,
		}
	},
	watch: {
		isOpenDetails(newValue) {
			this.$store.commit('setIsDisplayingFooter', !newValue)
		},
	},
	methods: {
		async deleteItemFromCollection(item) {
			try {
				await this.axios.post(`/research-collections/${this.collection._id}/remove`, {
					image: item.image,
				})

				this.collection.content = this.collection.content.filter(content => content.image !== item.image)
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		closeDetails() {
			this.isOpenDetails = false
		},
		openDetails(item) {
			this.isOpenDetails = true
			this.detailsData = item
		},
	},
	head: {
		title: `RealityStudio - Image`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Image`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
		],
	},
	async setup() {
		const axios = inject('axios')
		const route = useRoute()
		const router = useRouter()

		const collection = await axios.get(`/research-collections/${route.params.id}`).then(res => res.data)

		if (!collection) {
			return router.push('/404')
		}

		return {
			collection: ref(collection),
		}
	},
}
</script>
