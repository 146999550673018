<template>
	<div>
		<div class="flex border-b border-gray-900 mb-6 mt-4 px-4" v-if="showHeader">
			<button class="relative">
				<div class="flex-col flex pb-4 px-4 border-b-[2px] border-blue-900 text-blue-900">
					<span class="material-icons">auto_awesome</span>
					{{ $t('imageGeneration.tabs.create') }}
				</div>
			</button>
			<button @click="() => $router.push('/images/library')" class="mx-6 relative">
				<div class="flex-col flex pb-4 px-4">
					<span class="material-icons">photo_library</span>
					{{ $t('imageGeneration.tabs.library') }}
				</div>
			</button>
		</div>
		<div v-if="!displayModelSelection && !displayLoraSelection" class="page-container pb-20">
			<TabSelection @onChangeTab="value => (tabShown = value.value)" :tabs="tabs" />
			<div v-if="tabShown === 'Prompt'">
				<div class="py-6 border-b border-radial-1-card">
					<RangeSlider :min="1" :max="4" :step="1" v-model="inference.input.totalImages">
						<template v-slot:label>
							<div class="flex items-center">
								<span
									class="material-icons text-xl px-1 border border-radial-1-card bg-radial-2-card rounded-md mr-2"
									>photo</span
								>
								<p class="font-bold">{{ $t('imageGeneration.create.numberOfImages') }}</p>
								<span class="material-icons-outlined text-xl ml-auto">info</span>
							</div>
						</template>
						<template v-slot:footer>
							<div class="mt-4 flex">
								(
								<p>{{ $t('imageGeneration.create.minimum') }}:</p>
								<strong>{{ totalImagesMinimum }}</strong
								>,
								<p>{{ $t('imageGeneration.create.maximum') }}:</p>
								<strong>{{ totalImagesMaximum }}</strong
								>)
							</div>
						</template>
					</RangeSlider>
				</div>
				<div class="py-6">
					<TextArea
						:labelText="$t('imageGeneration.create.prompt')"
						v-model="inference.input.prompt"
						:placeholder="$t('imageGeneration.create.promptPlaceHolder')"
						name="prompt"
						id="prompt"
						hidable
						class="mb-4"
					/>
					<TextArea
						:labelText="$t('imageGeneration.create.negativePrompt')"
						v-model="inference.input.negativePrompt"
						:placeholder="$t('imageGeneration.create.negativePromptPlaceHolder')"
						name="negative-prompt"
						id="negative-prompt"
						hidable
						:initTextAreaHidden="true"
					/>
				</div>
				<div class="pb-4">
					<div class="mb-6" ref="model">
						<div class="flex items-center mb-2">
							<p class="font-bold">{{ $t('imageGeneration.create.model') }}</p>
							<span class="material-icons-outlined text-xl ml-auto">info</span>
						</div>
						<Button
							class="border border-radial-1-card rounded-xl p-4"
							color="none"
							@click="() => (displayModelSelection = true)"
						>
							<span
								v-if="!inference.diffusionModels.length"
								class="material-icons px-1 border border-radial-1-card bg-radial-2-card rounded-md mr-2 text-base text-gray-500 w-[32px] h-[32px] items-center flex justify-center"
								>block</span
							>
							<div
								v-else
								class="border border-radial-1-card bg-radial-2-card rounded-md mr-2 items-center flex justify-center"
							>
								<img
									:src="inference.diffusionModels[0].image"
									alt="model-image"
									class="object-cover p-1 max-w-[32px] max-h-[32px] aspect-square rounded-md"
								/>
							</div>
							<p class="text-base font-normal">
								{{ inference.diffusionModels[0] ? inference.diffusionModels[0].name : 'None' }}
							</p>
							<span class="material-icons-outlined text-xl ml-auto">expand_more</span>
						</Button>
					</div>
					<div v-if="inference.diffusionModels[0].config.enableLora" ref="loras">
						<div class="flex items-center mb-2">
							<p class="font-bold">{{ $t('imageGeneration.create.lora') }}</p>
							<span class="material-icons-outlined text-xl ml-auto">info</span>
						</div>
						<Button
							class="border border-radial-1-card rounded-xl p-4"
							color="none"
							@click="() => (displayLoraSelection = true)"
						>
							<div
								v-if="inference.input.loras && inference.input.loras.length > 0"
								class="flex items-center"
							>
								<div
									class="border border-radial-1-card bg-radial-2-card rounded-md mr-2 items-center flex justify-center"
								>
									<img
										src="/web/icons/image-creation/lora.svg"
										alt="model-image"
										class="object-cover p-1 max-w-[32px] max-h-[32px] aspect-square rounded-md"
									/>
								</div>
								<p class="text-base font-normal">{{ $t('imageGeneration.create.addLora') }}</p>
							</div>
							<div v-else class="flex items-center">
								<div
									class="border border-radial-1-card bg-radial-2-card rounded-md mr-2 items-center flex justify-center material-icons text-gray-500 text-base w-[32px] h-[32px]"
								>
									block
								</div>
								<p class="text-base font-normal text-gray-700">
									{{ $t('imageGeneration.create.none') }}
								</p>
							</div>
							<span class="material-icons-outlined text-xl ml-auto">expand_more</span>
						</Button>
						<div
							v-for="(lora, index) in inference.input.loras"
							:key="index"
							class="border-b border-radial-1-card pt-4 pb-8"
						>
							<RangeSlider :min="loraMin" :max="loraMax" :disabled="!lora.enabled" v-model="lora.value">
								<template v-slot:label>
									<div class="flex items-center">
										<img
											:src="lora.image"
											:alt="`lora-image-${index + 1}`"
											class="mr-2 h-[32px] w-[32px] object-cover rounded-md"
										/>
										<p>{{ lora.name }}</p>
									</div>
								</template>
								<template v-slot:footer>
									<div class="mt-4 flex items-center">
										<Button
											class="h-[32px] w-[100px] px-2 flex items-center justify-center rounded-md mr-4"
											size="fit"
											color="none"
											:class="
												lora.enabled
													? ' text-white border border-white'
													: 'bg-white text-black '
											"
											@click="lora.enabled = !lora.enabled"
										>
											<span class="material-icons mr-2 text-base"
												>{{ lora.enabled ? 'toggle_on' : 'toggle_off' }}
											</span>
											<p class="text-xs leading-6 font-bold">
												{{
													lora.enabled
														? $t('imageGeneration.create.disable')
														: $t('imageGeneration.create.enable')
												}}
											</p>
										</Button>
										<Button
											class="h-[32px] w-[100px] px-2 flex items-center justify-center rounded-md mr-4 text-white bg-red-700"
											size="fit"
											color="none"
											@click="removeLora(index)"
										>
											<span class="material-icons mr-2 text-base">delete</span>
											<p class="text-xs leading-6 font-bold">
												{{ $t('imageGeneration.create.remove') }}
											</p>
										</Button>
									</div>
								</template>
							</RangeSlider>
						</div>
					</div>
				</div>
				<div
					class="py-6 border-b border-radial-1-card"
					v-if="inference.diffusionModels[0].config.enableOutputQuality"
				>
					<RangeSlider
						:min="outputQualityMin"
						:max="outputQualityMax"
						v-model="inference.input.outputQuality"
						:step="1"
					>
						<template v-slot:label
							><div class="flex items-center">
								<span
									class="material-icons text-xl px-1 border border-radial-1-card bg-radial-2-card rounded-md mr-2"
									>flash_on</span
								>
								<p class="font-bold">{{ $t('imageGeneration.create.outputQuality') }}</p>
								<span class="material-icons-outlined text-xl ml-auto">info</span>
							</div></template
						>
						<template v-slot:footer>
							<div class="mt-4 flex">
								(
								<p>{{ $t('imageGeneration.create.minimum') }}:</p>
								<strong>{{ outputQualityMin }}</strong
								>,
								<p>{{ $t('imageGeneration.create.maximum') }}:</p>
								<strong>{{ outputQualityMax }}</strong
								>)
							</div>
							<div class="mt-6">
								<Button
									class="h-[32px] w-[100px] px-2 flex items-center justify-center rounded-md mr-4 text-white border border-white"
									size="fit"
									color="none"
									@click="inference.input.outputQuality = outputQualityMax / 2"
								>
									<span class="material-icons mr-2 text-base">refresh</span>
									<p class="text-xs leading-6 font-bold">
										{{ $t('imageGeneration.create.default') }}
									</p>
								</Button>
							</div>
						</template>
					</RangeSlider>
				</div>
				<div
					class="py-6 border-b border-radial-1-card"
					v-if="inference.diffusionModels[0].config.aspectRatios?.length > 0"
				>
					<div class="flex items-center mb-4">
						<p class="font-bold">{{ $t('imageGeneration.create.aspectRatio') }}</p>
						<span class="material-icons-outlined text-xl ml-auto">info</span>
					</div>
					<div class="grid grid-cols-2 gap-4">
						<Button
							v-for="(aspectRatio, index) in inference.diffusionModels[0].config.aspectRatios"
							:key="index"
							color="transparent"
							class="text-center py-6 border cursor font-normal relative"
							:class="
								inference.input.aspectRatio === aspectRatio ? 'border-blue-600' : 'border-radial-1-card'
							"
							@click="inference.input.aspectRatio = aspectRatio"
						>
							<p>{{ aspectRatio }}</p>
							<span
								class="material-icons text-blue-600 text-base absolute bottom-2 right-2"
								v-if="inference.input.aspectRatio === aspectRatio"
							>
								check_circle
							</span>
						</Button>
					</div>
				</div>
				<div class="mt-6" v-if="inference.diffusionModels[0].config.enableImage">
					<div class="flex items-center mb-6">
						<p class="font-bold">{{ $t('imageGeneration.create.imageToImage') }}</p>
						<span class="material-icons-outlined text-xl ml-auto">info</span>
					</div>
					<label
						class="cursor-pointer flex items-center border rounded-xl border-radial-1-card w-full px-4 py-5 mx-auto mb-6"
						for="userPicturesInput"
						@click="handleImageUpload"
					>
						<div class="flex items-center">
							<span class="material-symbols-outlined text-3xl mr-2">add_circle</span>
							<p class="font-bold">
								{{ $t('imageGeneration.create.addPhoto') }}
							</p>
						</div>
					</label>

					<input
						inputId="userPicturesInput"
						ref="fileInput"
						class="hidden"
						type="file"
						name="file"
						accept="image/*"
						@change="event => onFileSelect(event)"
					/>
					<div class="h-[300px] mb-6" v-if="previewImageBlob || inference.input.image">
						<div class="relative w-fit mx-auto">
							<img
								class="mb-4 mx-auto h-[300px] object-cover rounded-xl cursor-pointer"
								:src="inference.input.image || previewImageBlob"
							/>
							<Button
								class="bg-red-700 rounded-md h-8 w-8 absolute top-2 right-2 z-10"
								color="none"
								size="fit"
								@click="handleDeleteImage"
							>
								<span class="material-icons text-base">delete</span>
							</Button>
						</div>
					</div>
					<RangeSlider
						v-if="
							(inference.diffusionModels[0].config.enableImage && previewImageBlob && imageData) ||
							inference.input.image
						"
						:min="imageStrengthMin"
						:max="imageStrengthMax"
						v-model="inference.input.imageStrength"
						name="imageStrength"
						class="mb-6"
					>
						><template v-slot:label>
							<strong class="font-bold">{{ $t('imageGeneration.create.imageStrength') }}</strong>
						</template>
					</RangeSlider>
				</div>
			</div>
			<div v-if="tabShown === 'Advanced' && advancedTabEnabled">
				<div
					class="py-6 border-b border-radial-1-card"
					v-if="inference.diffusionModels[0].config.allowedResolutions.length > 0"
				>
					<div class="flex items-center mb-4">
						<p class="font-bold">{{ $t('imageGeneration.create.imageDimensions') }}</p>
						<span class="material-icons-outlined text-xl ml-auto">info</span>
					</div>
					<div class="grid grid-cols-2 gap-4">
						<Button
							v-for="(dimension, index) in inference.diffusionModels[0].config.allowedResolutions"
							:key="index"
							color="transparent"
							class="text-center py-6 border cursor font-normal relative"
							:class="
								inference.input.dimension === dimension ? 'border-blue-600' : 'border-radial-1-card'
							"
							@click="inference.input.dimension = dimension"
						>
							<p>{{ dimension }}</p>
							<span
								class="material-icons text-blue-600 text-base absolute bottom-2 right-2"
								v-if="inference.input.dimension === dimension"
							>
								check_circle
							</span>
						</Button>
					</div>
				</div>
				<div class="py-6 border-b border-radial-1-card" v-if="inference.diffusionModels[0].config.enableMask">
					<Button
						class="border rounded-xl p-4"
						color="none"
						@click="inference.input.magicPrompt = !inference.input.magicPrompt"
						:class="inference.input.magicPrompt ? 'border-blue-600' : 'border-radial-1-card'"
					>
						<div
							class="border border-radial-1-card bg-radial-2-card rounded-md mr-2 items-center flex justify-center"
						>
							<img
								src="/web/icons/image-creation/magic-prompt.svg"
								alt="model-image"
								class="object-cover p-1 max-w-[32px] max-h-[32px] aspect-square rounded-md"
							/>
						</div>
						<p class="text-base font-normal">{{ $t('imageGeneration.create.magicPrompt') }}</p>
						<span class="material-icons-outlined text-xl ml-auto mr-4 z-20">info</span>
						<span class="material-icons text-2xl">{{
							inference.input.magicPrompt ? 'check_box' : 'check_box_outline_blank'
						}}</span>
					</Button>
				</div>
				<div
					class="py-6 border-b border-radial-1-card"
					v-if="inference.diffusionModels[0].config.schedulers.length > 1"
				>
					<div class="flex items-center mb-2">
						<p class="font-bold">{{ $t('imageGeneration.create.scheduler') }}</p>
						<span class="material-icons-outlined text-xl ml-auto">info</span>
					</div>
					<Select
						:options="inference.diffusionModels[0].config.schedulers"
						:value="inference.input.scheduler"
						label=""
						:menuTitle="$t('imageGeneration.create.scheduler')"
						@onChange="value => (inference.input.scheduler = value)"
					/>
				</div>
				<div
					class="py-6 border-b border-radial-1-card"
					v-if="inference.diffusionModels[0].config.enablePromptStrength"
				>
					<RangeSlider
						:min="promptStrengthMin"
						:max="promptStrengthMax"
						v-model="inference.input.promptStrength"
					>
						<template v-slot:label
							><div class="flex items-center">
								<span
									class="material-icons text-xl px-1 border border-radial-1-card bg-radial-2-card rounded-md mr-2"
									>flash_on</span
								>
								<p class="font-bold">{{ $t('imageGeneration.create.promptStrength') }}</p>
								<span class="material-icons-outlined text-xl ml-auto">info</span>
							</div></template
						>
						<template v-slot:footer>
							<div class="mt-4 flex">
								(
								<p>{{ $t('imageGeneration.create.minimum') }}:</p>
								<strong>{{ promptStrengthMin }}</strong
								>,
								<p>{{ $t('imageGeneration.create.maximum') }}:</p>
								<strong>{{ promptStrengthMax }}</strong
								>)
							</div>
							<div class="mt-6">
								<Button
									class="h-[32px] w-[100px] px-2 flex items-center justify-center rounded-md mr-4 text-white border border-white"
									size="fit"
									color="none"
									@click="inference.input.promptStrength = promptStrengthMax / 2"
								>
									<span class="material-icons mr-2 text-base">refresh</span>
									<p class="text-xs leading-6 font-bold">
										{{ $t('imageGeneration.create.default') }}
									</p>
								</Button>
							</div>
						</template>
					</RangeSlider>
				</div>
				<div class="py-6 border-b border-radial-1-card">
					<RangeSlider
						:min="guidanceScaleMin"
						:max="guidanceScaleMax"
						v-model="inference.input.guidanceScale"
					>
						<template v-slot:label
							><div class="flex items-center">
								<span
									class="material-icons text-xl px-1 border border-radial-1-card bg-radial-2-card rounded-md mr-2"
									>flash_on</span
								>
								<p class="font-bold">{{ $t('imageGeneration.create.guidanceScale') }}</p>
							</div></template
						>
						<template v-slot:footer>
							<div class="mt-4 flex">
								(
								<p>{{ $t('imageGeneration.create.minimum') }}:</p>
								<strong>{{ guidanceScaleMin }}</strong
								>,
								<p>{{ $t('imageGeneration.create.maximum') }}:</p>
								<strong>{{ guidanceScaleMax }}</strong
								>)
							</div>
							<div class="mt-6">
								<Button
									class="h-[32px] w-[100px] px-2 flex items-center justify-center rounded-md mr-4 text-white border border-white"
									size="fit"
									color="none"
									@click="inference.input.guidanceScale = guidanceScaleDefault"
								>
									<span class="material-icons mr-2 text-base">refresh</span>
									<p class="text-xs leading-6 font-bold">
										{{ $t('imageGeneration.create.default') }}
									</p>
								</Button>
							</div>
						</template>
					</RangeSlider>
				</div>
				<div class="py-6 border-b border-radial-1-card">
					<RangeSlider
						:min="numInferenceStepsMin"
						:max="numInferenceStepsMax"
						v-model="inference.input.numInferenceSteps"
						:step="1"
					>
						<template v-slot:label
							><div class="flex items-center">
								<span
									class="material-icons text-xl px-1 border border-radial-1-card bg-radial-2-card rounded-md mr-2"
									>flash_on</span
								>
								<p class="font-bold">{{ $t('imageGeneration.create.numInferenceSteps') }}</p>
							</div></template
						>
						<template v-slot:footer>
							<div class="mt-4 flex">
								(
								<p>{{ $t('imageGeneration.create.minimum') }}:</p>
								<strong>{{ numInferenceStepsMin }}</strong
								>,
								<p>{{ $t('imageGeneration.create.maximum') }}:</p>
								<strong>{{ numInferenceStepsMax }}</strong
								>)
							</div>
							<div class="mt-6">
								<Button
									class="h-[32px] w-[100px] px-2 flex items-center justify-center rounded-md mr-4 text-white border border-white"
									size="fit"
									color="none"
									@click="inference.input.numInferenceSteps = numInferenceDefault"
								>
									<span class="material-icons mr-2 text-base">refresh</span>
									<p class="text-xs leading-6 font-bold">
										{{ $t('imageGeneration.create.default') }}
									</p>
								</Button>
							</div>
						</template>
					</RangeSlider>
				</div>
				<div class="py-6">
					<div class="flex items-center">
						<p class="font-bold">{{ $t('imageGeneration.create.seed') }}</p>
						<span class="material-icons-outlined text-xl ml-auto">info</span>
					</div>
					<div>
						<div class="my-6 grid grid-cols-2 gap-4" v-if="!inference.input.randomSeed">
							<Input
								:label="$t('imageGeneration.create.minimum')"
								v-model="inference.input.seedMin"
								labelClass="font-bold"
								id="seedMin"
							/>
							<Input
								:label="$t('imageGeneration.create.maximum')"
								v-model="inference.input.seedMax"
								labelClass="font-bold"
								id="seedMax"
							/>
						</div>
						<p class="font-bold leading-6 my-4 text-center" v-if="inference.input.randomSeed">
							{{ $t('imageGeneration.create.randomSeedWarning') }}
						</p>
						<Button
							class="border border-radial-1-card rounded-xl p-4"
							color="none"
							@click="inference.input.randomSeed = !inference.input.randomSeed"
							:class="inference.input.randomSeed ? 'border-blue-600' : 'border-radial-1-card'"
						>
							<div
								class="border border-radial-1-card bg-radial-2-card rounded-md mr-2 items-center flex justify-center"
							>
								<img
									src="/web/icons/image-creation/random-seed.svg"
									alt="model-image"
									class="object-cover p-1 max-w-[32px] max-h-[32px] aspect-square rounded-md"
								/>
							</div>
							<p class="text-base font-normal">{{ $t('imageGeneration.create.randomSeed') }}</p>
							<span class="material-icons-outlined text-xl ml-auto mr-4 z-20">info</span>
							<span class="material-icons text-2xl">{{
								inference.input.randomSeed ? 'check_box' : 'check_box_outline_blank'
							}}</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
		<FloatingButton
			hasBackground
			backgroundColor="bg-app-blue"
			v-if="!displayLoraSelection && !displayModelSelection"
		>
			<Button @click="generateImage" :isLoading="loading" :disabled="disableGenerateButton" v-if="loggedUser">
				<div class="flex items-center" v-if="hasEnoughPoints">
					{{ $t('imageGeneration.create.generateFor') }}
					<img
						src="/web/icons/image-creation/points-icon.svg"
						alt="points-icon"
						class="w-[24px] h-[24px] object-cover mx-1"
					/>{{ price }}
				</div>
				<p v-else v-html="$t('imageGeneration.create.noPointsGoToPlans')"></p>
			</Button>
			<Button v-else @click="() => $router.push('/login')">
				<p v-html="$t('imageGeneration.create.unLoggedUser')" />
			</Button>
		</FloatingButton>
		<LoraSelection
			@onConfirmSelection="value => handleLoraSelection(value)"
			v-if="displayLoraSelection"
			:selectedLoras="inference.models"
			@onClose="handleCloseLoraSelection"
		/>

		<ModelSelection
			@onConfirmSelection="value => handleModelSelection(value)"
			v-if="displayModelSelection"
			:selectedModels="inference.diffusionModels"
			@onClose="handleCloseModelSelection"
		/>
	</div>
</template>
<script>
import {
	Header,
	TabSelection,
	Button,
	LazyImage,
	RangeSlider,
	TextArea,
	Select,
	Input,
	FloatingButton,
} from '@/components/default'
import { LoraSelection, ModelSelection } from '@/components/image-creation'
import { useRoute, useRouter } from 'vue-router'
import { inject, ref } from 'vue'

export default {
	data() {
		return {
			totalImagesMinimum: 1,
			totalImagesMaximum: 4,
			imageStrengthMin: 0.01,
			imageStrengthMax: 0.99,
			promptStrengthMin: 0.01,
			promptStrengthMax: 0.99,
			guidanceScaleMin: 1.1,
			guidanceScaleMax: 9.99,
			guidanceScaleDefault: 3.5,
			numInferenceStepsMin: 20,
			numInferenceStepsMax: 60,
			numInferenceDefault: 30,
			loraMin: 0.01,
			loraMax: 0.99,
			imageData: null,
			previewImageBlob: null,
			tabShown: 'Prompt',
			displayLoraSelection: false,
			displayModelSelection: false,
			getTimeoutInMs: 700,
			randomSeed: true,
			loading: false,
			redirectTimeoutInMs: 3000,
			showHeader: true,
			outputQuality: 80,
			outputQualityMin: 1,
			outputQualityMax: 100,
			tabs: [
				{
					name: this.$t('imageGeneration.tabs.prompt'),
					icon: 'brush',
					value: 'Prompt',
				},
				{
					name: this.$t('imageGeneration.tabs.advanced'),
					icon: 'settings',
					value: 'Advanced',
					disabled: false,
				},
			],
		}
	},

	async setup() {
		const axios = inject('axios')
		const route = useRoute()
		const router = useRouter()

		const [diffusionModels, pricing] = await Promise.all([
			axios.get('/diffusion-models?public=true').then(res => res.data),
			axios.get('/inferences/pricing').then(res => res.data),
		])

		diffusionModels.results = diffusionModels.results.map(model => {
			if (model.config && model.config.schedulers && model.config.schedulers.length > 0) {
				model.config.schedulers = model.config.schedulers.map(scheduler => ({
					value: scheduler,
					label: scheduler,
				}))
			}

			return model
		})

		if (route.query.id) {
			const inference = await axios.get(`/inferences/${route.query.id}`).then(res => res.data)

			if (!inference) {
				router.push('/images/library')
				return
			}

			return {
				inference: ref(inference),
				defaultPrice: pricing.imageCreation,
			}
		}

		const selectedDiffusionModel = diffusionModels.results[0]
		const inference = {
			input: {
				prompt: '',
				negativePrompt: '',
				model: null,
				image: null,
				imageStrength: 0.5,
				totalImages: 4,
				promptStrength: 0.5,
				guidanceScale: 3.5,
				numInferenceSteps: 50,
				randomSeed: true,
				outputQuality: 80,
				aspectRatio: selectedDiffusionModel.config.aspectRatios[0] || '',
				dimension: selectedDiffusionModel.config.allowedResolutions[0] || '',
				scheduler: selectedDiffusionModel.config.schedulers[0]?.value,
			},
			models: [],
			diffusionModels: [selectedDiffusionModel],
		}

		return {
			inference: ref(inference),
			defaultPrice: pricing.imageCreation,
		}
	},
	components: {
		Header,
		TabSelection,
		Button,
		LazyImage,
		RangeSlider,
		TextArea,
		Select,
		Input,
		LoraSelection,
		ModelSelection,
		FloatingButton,
	},

	methods: {
		async generateImage() {
			if (this.loading) return
			this.loading = true

			if (!this.hasEnoughPoints) {
				this.$router.push('/checkout/plans?redirect=/image/create')
				return
			}

			try {
				if (this.imageData) {
					const file = this.imageData
					const signedUrlResponse = await this.axios.get(`/files/sign-url/${file.name}`).then(res => res.data)
					await this.axios.put(signedUrlResponse.url, file)
					this.inference.input.image = signedUrlResponse.key
				}

				await this.axios.post('/inferences/image-creation', { inference: this.inference }).then(res => res.data)

				this.$toast({
					type: 'success',
					title: this.$t('common.toastTitle.success'),
				})
				setTimeout(() => {
					this.$router.push('/images/library')
				}, this.redirectTimeoutInMs)
			} catch (error) {
				this.sentryCaptureException(error)

				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
				})
				this.loading = false
			}
		},

		handleImageUpload() {
			if (this.$refs.fileInput) {
				this.$refs.fileInput.click()
			}
		},

		handleDeleteImage() {
			this.previewImageBlob = null
			this.imageData = null
			this.inference.image = null
		},

		async onFileSelect(event) {
			const file = event.target.files[0]

			if (!file) return
			this.$refs.fileInput.value = ''

			this.captureEvent(`free_avatar_tool_image_upload_click`)
			this.previewImageBlob = URL.createObjectURL(file)
			this.imageData = file
		},

		removeLora(index) {
			this.inference.models.splice(index, 1)
			this.inference.input.loras.splice(index, 1)
		},

		handleLoraSelection(loras) {
			const formattedLoras = loras.map(lora => ({
				_id: lora._id,
				name: lora.name,
				value: 0.5,
				enabled: true,
				image: lora.image || lora.images[0],
				file: lora.file,
			}))
			this.inference.models = loras
			this.inference.input.loras = formattedLoras
			this.displayLoraSelection = false
			this.$nextTick(() => {
				const lorasRef = this.$refs.loras
				if (lorasRef) {
					lorasRef.scrollIntoView({ behavior: 'smooth' })
				}
			})
		},

		handleModelSelection(model) {
			this.inference.diffusionModels[0] = model
			this.displayModelSelection = false
		},

		handleMoralBehavior(value) {
			this.showHeader = value
			this.$store.commit('setIsDisplayingFooter', value)
		},

		handleCloseLoraSelection() {
			this.displayLoraSelection = false
			this.$nextTick(() => {
				const lorasRef = this.$refs.loras
				if (lorasRef) {
					lorasRef.scrollIntoView({ behavior: 'smooth' })
				}
			})
		},

		handleCloseModelSelection() {
			this.displayModelSelection = false
			this.$nextTick(() => {
				const modelRef = this.$refs.model
				if (modelRef) {
					modelRef.scrollIntoView({ behavior: 'smooth' })
				}
			})
		},
	},
	computed: {
		isValidForm() {
			return this.inference.input.prompt.length > 0
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
		hasEnoughPoints() {
			if (!this.loggedUser) return false
			return this.$store.getters.getUser.points >= this.price
		},
		disableGenerateButton() {
			if (!this.hasEnoughPoints) {
				return false
			}

			return !this.isValidForm || this.loading
		},
		advancedTabEnabled() {
			const hasConfigs = Object.keys(this.inference.diffusionModels[0].config).length > 0
			return (
				this.inference.diffusionModels[0] &&
				this.inference.diffusionModels[0].config &&
				hasConfigs &&
				(this.inference.diffusionModels[0].baseModel === 'stable-diffusion-15' ||
					this.inference.diffusionModels[0].baseModel === 'stable-diffusion-xl')
			)
		},
		price() {
			return this.inference?.diffusionModels?.[0]?.price || this.defaultPrice
		},
	},
	watch: {
		displayModelSelection(value) {
			this.handleMoralBehavior(!value)
		},
		displayLoraSelection(value) {
			this.handleMoralBehavior(!value)
		},
		advancedTabEnabled(value) {
			this.tabs[1].disabled = !value
		},
	},
	head: {
		title: `RealityStudio - Image Creation Library`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Image Creation Library`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
		],
	},
}
</script>
