<template>
	<div class="pt-7 page-container relative -mb-24" ref="modelModal">
		<div class="flex items-center">
			<button @click="closeModelSelection"><i class="material-icons text-3xl mr-2">chevron_left</i></button>
			<p class="text-xl font-bold">{{ $t('imageGeneration.create.model') }}</p>
		</div>
		<TabSelection @onChangeTab="value => handleChangeTab(value)" :tabs="tabs" class="mt-4" />
		<!-- <div class="swiper models-swiper">
			<div class="swiper-wrapper">
				<Button
					@click="item => (selectedFilter = filter.value)"
					:color="filter.value === selectedFilter ? 'primary' : 'gray'"
					class="text-sm w-max swiper-slide mt-4"
					size="compact"
					v-for="filter in filters"
					:key="filter.name"
					>{{ filter.title }}
				</Button>
			</div>
		</div> -->
		<Input
			class="mt-4"
			v-model="textSearch.text"
			:placeholder="$t('imageGeneration.create.searchModelPlaceHolder')"
			id="search-model"
			@update:modelValue="value => searchModelsByText(value)"
		/>
		<Spinner class="flex justify-center my-4" v-if="loading" />
		<div class="grid grid-cols-2 gap-4 my-4" v-if="tab === 'Library'">
			<div class="col-span-1" v-for="model in filteredModels" :key="model._id">
				<AdditionalParameterCard
					class="cursor-pointer"
					:item="model"
					itemType="model"
					:isSelected="model._id === selectedModel?._id"
					@onSelectItem="value => handleModelSelection(value)"
					@onBookmarkItem="value => handleFavorite(value)"
				/>
			</div>
		</div>
		<div class="grid grid-cols-2 gap-4 my-4" v-if="tab === 'Favorite'">
			<div class="col-span-1" v-for="favorite in filteredFavorites" :key="favorite._id">
				<AdditionalParameterCard
					class="cursor-pointer"
					:item="favorite.entity"
					itemType="model"
					:isSelected="favorite.entity._id === selectedModel?._id"
					@onSelectItem="value => handleModelSelection(value)"
					@onBookmarkItem="value => handleFavorite(value)"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import { Button, TabSelection, Input, Spinner } from '@/components/default'
import { Swiper } from 'swiper'
import { FreeMode } from 'swiper/modules'
import { AdditionalParameterCard } from '@/components/image-creation'
import { inject, ref } from 'vue'

export default {
	components: {
		AdditionalParameterCard,
		Button,
		TabSelection,
		Input,
		Spinner,
	},
	async setup() {
		const axios = inject('axios')

		const [diffusionModels, favoritesDiffusionModels] = await Promise.all([
			axios.get('/diffusion-models?public=true').then(res => res.data),
			axios.get(`/favorites/diffusion-model`).then(res => res.data),
		])

		diffusionModels.results = diffusionModels.results.map(model => {
			if (model.config && model.config.schedulers.length > 0) {
				model.config.schedulers = model.config.schedulers.map(scheduler => ({
					value: scheduler,
					label: scheduler,
				}))
			}

			return model
		})

		return {
			models: ref(diffusionModels.results),
			next: ref(diffusionModels.next),
			hasNext: ref(diffusionModels.hasNext),
			favorites: ref(favoritesDiffusionModels.results),
			favoritesNext: ref(favoritesDiffusionModels.next),
			favoritesHasNext: ref(favoritesDiffusionModels.hasNext),
		}
	},
	mounted() {
		this.initSwiper()
		this.$refs.modelModal.scrollIntoView({ behavior: 'smooth', block: 'start' })

		this.mockupWindow = this.getWindow()
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScrollPage)

		this.selectedModel = this.selectedModels[0]
	},

	beforeUnmount() {
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScrollPage)
	},

	data() {
		return {
			selectedModel: null,
			selectedFilter: 'realityStudio',
			filters: [
				{ title: 'RealityStudio', value: 'realityStudio' },
				{
					title: 'Public',
					value: 'public',
				},
			],
			tabs: [
				{
					name: this.$t('imageGeneration.tabs.model'),
					iconSrc: '/web/icons/image-creation/model-icon.svg',
					value: 'Library',
				},
				{ name: this.$t('imageGeneration.tabs.favorites'), icon: 'bookmark', value: 'Favorite' },
			],
			tab: 'Library',
			textSearch: {
				text: '',
				lastSearchedText: '',
				debounceTimeoutId: null,
				results: [],
				next: null,
				hasNext: null,
			},
			textSearchFavorites: {
				text: '',
				lastSearchedText: '',
				debounceTimeoutId: null,
				results: [],
				next: null,
				hasNext: null,
			},
			loading: false,
		}
	},

	props: {
		selectedModels: {
			type: Array,
			required: true,
		},
	},

	methods: {
		handleModelSelection(model) {
			this.selectedModel = model
			this.$emit('onConfirmSelection', this.selectedModel)
		},

		async handleFavorite(entity) {
			try {
				let bookmarked = false
				if (!entity.isFavorite) {
					await this.axios.post(`/favorites`, {
						entity: entity._id,
						entityType: 'diffusion-model',
						searchQuery: entity.name,
					})
					bookmarked = true
				} else {
					await this.axios.delete(`/favorites/${entity._id}`)
				}

				this.models.find(diffusionModel => diffusionModel._id === entity._id).isFavorite = bookmarked

				if (this.textSearch.results.length) {
					this.textSearch.results.find(diffusionModel => diffusionModel._id === entity._id).isFavorite =
						bookmarked
				}

				if (this.tab === 'Favorite') {
					this.favorites.find(favorite => favorite.entity._id === entity._id).entity.isFavorite = bookmarked
					this.favorites.splice(
						this.favorites.findIndex(favorite => favorite.entity._id === entity._id),
						1
					)

					if (this.textSearchFavorites.results.length) {
						this.textSearchFavorites.results.find(
							favorite => favorite.entity._id === entity._id
						).entity.isFavorite = bookmarked
						this.textSearchFavorites.results.splice(
							this.textSearchFavorites.results.findIndex(favorite => favorite.entity._id === entity._id),
							1
						)
					}
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},

		async handleChangeTab(tab) {
			this.tab = tab.value

			if (this.tab === 'Favorite') {
				this.favorites = []
				this.favoritesNext = null
				this.favoritesHasNext = null

				await this.fetchFavorites(true)
			}

			if (this.tab === 'Library') {
				this.models = []
				this.next = null
				this.hasNext = null

				await this.fetchModels(true)
			}
		},
		initSwiper() {
			this.swiperInstance = new Swiper('.models-swiper', {
				modules: [FreeMode],
				direction: 'horizontal',
				spaceBetween: 8, // in pixels,
				slidesPerView: 'auto',
				grabCursor: true,
				freeMode: true,
			})
		},

		closeModelSelection() {
			this.$emit('onClose')
		},
		onScrollPage() {
			let isScrolledToBottom = false

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight
			}

			if (isScrolledToBottom) {
				if (this.tab === 'Library') {
					this.textSearch.text ? this.fetchMoreModelsByText() : this.fetchModels()
				} else {
					this.textSearchFavorites.text ? this.fetchMoreFavoritesByText() : this.fetchFavorites()
				}
			}
		},
		resetTextSearchData() {
			this.textSearch = {
				text: '',
				lastSearchedText: '',
				debounceTimeoutId: null,
				results: [],
				next: null,
				hasNext: null,
			}

			this.textSearchFavorites = {
				text: '',
				lastSearchedText: '',
				debounceTimeoutId: null,
				results: [],
				next: null,
				hasNext: null,
			}
		},

		async searchModelsByText(text) {
			this.loading = true

			clearTimeout(this.textSearch.debounceTimeoutId)
			if (this.textSearch.lastSearchedText === text) return

			if (!text?.length) {
				this.resetTextSearchData()
				this.loading = false
				return
			}

			this.textSearch.results = []
			this.textSearch.text = text

			const searchTimerInMs = 800

			this.textSearch.debounceTimeoutId = setTimeout(this.executeTextSearch, searchTimerInMs)
		},

		async fetchMoreModelsByText() {
			if (this.loading) return

			if (!this.textSearch.hasNext) {
				this.loading = false
				return
			}
			this.loading = true

			this.executeTextSearch()
		},

		async executeTextSearch() {
			const params = {
				next: this.textSearch.hasNext ? this.textSearch.next : undefined,
				searchText: this.textSearch.text,
				public: true,
			}

			try {
				const response = await this.axios.get(`/diffusion-models`, { params }).then(res => res.data)
				this.textSearch.results = [...this.textSearch.results, ...response.results]
				this.textSearch.next = response.next
				this.textSearch.hasNext = response.hasNext
				this.textSearch.lastSearchedText = this.textSearch.text
			} catch (error) {
				this.sentryCaptureException(error)
			} finally {
				this.loading = false
			}
		},

		async fetchModels(isFirstFetch) {
			if (this.loading || (!this.hasNext && !isFirstFetch)) return
			this.loading = true

			const params = {
				next: this.hasNext ? this.next : undefined,
				public: true,
			}

			try {
				const { data } = await this.axios.get('/diffusion-models', { params })

				this.models = [...this.models, ...data.results]
				this.hasNext = data.hasNext
				this.next = data.next
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading = false
		},

		async searchFavoritesByText(text) {
			this.loading = true

			clearTimeout(this.textSearchFavorites.debounceTimeoutId)
			if (this.textSearchFavorites.lastSearchedText === text) return

			if (!text?.length) {
				this.resetTextSearchData()
				this.loading = false
				return
			}

			this.textSearchFavorites.results = []
			this.textSearchFavorites.text = text

			const searchTimerInMs = 800

			this.textSearchFavorites.debounceTimeoutId = setTimeout(this.executeTextSearchFavorites, searchTimerInMs)
		},

		async fetchMoreFavoritesByText() {
			if (this.loading) return

			if (!this.textSearchFavorites.hasNext) {
				this.loading = false
				return
			}
			this.loading = true

			this.executeTextSearchFavorites()
		},

		async executeTextSearchFavorites() {
			const params = {
				next: this.textSearchFavorites.hasNext ? this.textSearchFavorites.next : undefined,
				searchQuery: this.textSearchFavorites.text,
				public: true,
			}

			try {
				const response = await this.axios.get(`/favorites/diffusion-model`, { params }).then(res => res.data)
				this.textSearchFavorites.results = [...this.textSearchFavorites.results, ...response.results]
				this.textSearchFavorites.next = response.next
				this.textSearchFavorites.hasNext = response.hasNext
				this.textSearchFavorites.lastSearchedText = this.textSearchFavorites.text
			} catch (error) {
				this.sentryCaptureException(error)
			} finally {
				this.loading = false
			}
		},

		async fetchFavorites(isFirstFetch) {
			if (this.loading || (!this.favoritesHasNext && !isFirstFetch)) return
			this.loading = true

			const params = {
				next: this.favoritesHasNext ? this.favoritesNext : undefined,
			}

			try {
				const { data } = await this.axios.get('/favorites/diffusion-model', { params })

				this.favorites = [...this.favorites, ...data.results]
				this.favoritesHasNext = data.favoritesHasNext
				this.favoritesNext = data.favoritesNext
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading = false
		},
	},
	computed: {
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		filteredModels() {
			if (this.textSearch.text) {
				return this.textSearch.results
			}
			return this.models
		},
		filteredFavorites() {
			if (this.textSearchFavorites.text) {
				return this.textSearchFavorites.results
			}
			return this.favorites
		},
	},
}
</script>
