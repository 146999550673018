<template>
	<div class="border border-radial-1-card rounded-xl">
		<div
			class="py-5 px-4 flex items-center"
			:class="{ 'border-b border-radial-1-card': !textAreaHidden }"
			@click="hideTextArea"
		>
			<label :for="id" :class="['leading-6 font-bold mr-2', labelClass]">
				{{ labelText }}
			</label>
			<p v-if="subLabel" :class="subLabelClass">{{ subLabel }}</p>
			<button class="material-icons ml-auto" v-if="hidable">
				{{ textAreaHidden ? 'expand_more' : 'expand_less' }}
			</button>
		</div>
		<transition name="">
			<div v-if="(hidable && !textAreaHidden) || !hidable" class="p-4 flex">
				<textarea
					:class="['rounded-xl bg-transparent resize-none w-full p-2', textAreaClass]"
					type="text"
					:value="modelValue"
					:placeholder="placeholder"
					@input="onChange"
					:rows="rows"
					:cols:="cols"
					:name="name"
					:id="id"
				/>
				<button
					v-if="deleteButton"
					class="material-icons ml-4 mb-auto text-base"
					type="button"
					@click="onDelete"
				>
					cancel
				</button>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		labelText: {
			type: String,
			default: '',
		},
		modelValue: {
			type: String,
		},
		placeholder: {
			type: String,
		},
		rows: {
			type: Number,
			default: 4,
		},
		cols: {
			type: Number,
			default: 50,
		},
		labelClass: {
			type: String,
		},
		subLabel: {
			type: String,
		},
		subLabelClass: {
			type: String,
		},
		textAreaClass: {
			type: String,
		},
		deleteButton: {
			type: Boolean,
			default: true,
		},
		name: {
			type: String,
		},
		id: {
			type: String,
		},
		hidable: {
			type: Boolean,
			default: false,
		},
		initTextAreaHidden: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			textAreaHidden: this.hidable ? this.initTextAreaHidden : false,
		}
	},
	emits: ['update:modelValue'],
	methods: {
		onChange(event) {
			this.$emit('update:modelValue', event.target.value)
		},
		onDelete() {
			this.$emit('update:modelValue', '')
		},
		hideTextArea() {
			this.textAreaHidden = !this.textAreaHidden
		},
	},
}
</script>
