<template>
	<div class="page-container pt-6 px-4 relative">
		<div v-show="!isImagesModalOpened">
			<Header class="mb-6" :title="$t(`${localePrefix}.pageTitle`)" />
			<div v-if="!userHasEnoughPoints">
				<video
					class="mb-4 mx-auto w-[230px] h-[300px] object-cover rounded-xl"
					autoplay
					muted
					loop
					playsinline
					:controls="false"
				>
					<source :src="previewVideoSrc" type="video/mp4" />
				</video>
				<div class="text-center mb-4">
					<p
						class="text-base leading-6 mb-4"
						v-html="
							$tc(`${localePrefix}.priceDescription`, pointsPrice, {
								pointsAmount: pointsPrice,
							})
						"
					></p>
					<p class="text-lg leading-7" v-html="$t(`${localePrefix}.description`)"></p>
				</div>
			</div>
			<div v-if="userHasEnoughPoints">
				<p class="text-lg leading-7 color-white text-center my-4">{{ $t(`${localePrefix}.uploadImage`) }}</p>
				<div class="relative mx-auto w-[230px] h-[300px]" @click="handleImageUpload" v-if="previewImageBlob">
					<img class="mb-4 w-full h-full object-cover rounded-xl cursor-pointer" :src="previewImageBlob" />
					<button
						class="bg-red-700 rounded-full h-8 w-8 absolute top-2 right-2 z-10"
						@click="handleDeleteImage(inference)"
						type="button"
					>
						<span class="material-icons text-base">delete</span>
					</button>
				</div>
				<label
					v-if="userHasEnoughPoints && !previewImageBlob"
					class="cursor-pointer flex items-center justify-center border border-dashed rounded-xl border-gray-800 h-[260px] w-1/2 mx-auto"
					for="userPicturesInput"
					@click="handleImageUpload"
				>
					<div class="flex flex-col items-center">
						<span class="material-symbols-outlined text-3xl mb-2">add_circle</span>
						<p class="font-bold">
							{{ $t('common.addPhotos') }}
						</p>
					</div>
				</label>

				<input
					inputId="userPicturesInput"
					ref="fileInput"
					class="hidden"
					type="file"
					name="file"
					accept="image/*"
					@change="event => onFileSelect(event)"
				/>
				<div class="text-center my-4">
					<p
						class="text-base leading-6 mb-4"
						v-html="
							$tc(`${localePrefix}.priceDescription`, pointsPrice, {
								pointsAmount: pointsPrice,
							})
						"
					></p>
				</div>
				<Input
					id="text"
					:label="$t(`${localePrefix}.inputTextLabel`)"
					required
					v-model="text"
					class="my-6"
					labelClass="text-center text-lg leading-7 font-bold"
					:placeholder="$t(`${localePrefix}.inputTextPlaceHolder`)"
				/>
				<Button v-if="!!imageData && text" class="mt-4" @click="handleSubmit" :isLoading="isUploading">{{
					$t(`${localePrefix}.submitButton`)
				}}</Button>
			</div>
			<AuthenticationBox v-if="!loggedUser" :redirectTo="currentPathname" :pageName="gtagPrefix" class="mt-4" />
			<PlansSwiper v-if="loggedUser && !userHasEnoughPoints" :plans="plans" class="mt-4" />
			<div class="my-4" v-if="!!inferences.length">
				<p class="text-lg leading-7 font-bold mb-4">
					{{ $t(`${localePrefix}.createdTitle`) }}
				</p>
				<div class="grid grid-cols-2 gap-4">
					<div class="relative" v-for="inference in inferences" :key="inference._id">
						<div class="relative h-[214px] w-full" v-if="inference.outputs">
							<LazyImage
								:src="inference.outputs"
								:height="214"
								alt="Image without background"
								@click="handleOpenImage(inference)"
								imgClass="absolute top-0 left-0 rounded-xl h-full w-full object-cover cursor-pointer z-10"
							/>
							<img
								v-if="showTransparentImage"
								class="absolute top-0 left-0 h-full w-full object-cover rounded-xl"
								src="/web/images/presets-and-tools/transparent.jpg"
								alt="Transparent image"
							/>
						</div>
						<div
							class="rounded-xl h-[214px] w-full bg-radial-2-card flex items-center justify-center"
							v-if="!inference.outputs && inference.status === 'processing'"
						>
							<Spinner
								iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
								height="12"
								width="12"
							/>
						</div>
						<div
							class="relative rounded-xl h-[214px] w-full bg-radial-2-card flex items-center justify-center"
							v-if="!inference.outputs && inference.status === 'failed'"
						>
							<img
								@click="() => handleOpenImage(inference)"
								src="/web/images/presets-and-tools/tool-failed.png"
								alt="tool-failed"
								class="absolute rounded-xl max-w-[60px] h-full object-contain cursor-pointer z-1"
							/>
						</div>
						<button
							class="bg-red-700 flex items-center justify-center rounded-full h-8 w-8 absolute top-2 right-2 z-10"
							@click="handleDeleteImage(inference)"
							type="button"
						>
							<span v-if="deletingId !== inference._id" class="material-icons text-base">delete</span>
							<Spinner
								iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
								height="4"
								width="4"
								v-else
							/>
						</button>
					</div>
				</div>

				<Spinner v-if="isFetchingInferences" iconClass="mx-auto mt-4" height="12" width="12" />
			</div>
		</div>
		<div v-show="isImagesModalOpened" class="h-full w-full rounded-t-xl md:px-0 top-0 left-0 flex flex-col gap-4">
			<div class="flex justify-between">
				<Button color="transparent" size="fit" @click="() => (isImagesModalOpened = false)">
					<span class="material-icons text-[32px]">close</span>
				</Button>
				<Button
					v-if="currentInference"
					@click="handleDeleteImage(currentInference)"
					color="transparent"
					size="fit"
					:isLoading="deletingId === currentInference._id"
					:disabled="currentInference.status === 'processing'"
				>
					<span class="material-icons text-[32px]">delete</span>
				</Button>
			</div>
			<div class="flex flex-1">
				<div
					class="bg-radial-2-card flex items-center justify-center w-full rounded-xl h-[460px]"
					v-if="(!currentInference || !currentInference.outputs) && !previewImageBlob"
				>
					<Spinner
						v-if="currentInference?.status === 'processing'"
						iconClass="text-gray-200 animate-spin dark:text-gray-600 w-[32px] h-[32px] fill-white"
						height="32"
						width="32"
					/>
					<div
						class="relative h-[460px] w-full text-center flex flex-col items-center justify-center px-8"
						v-if="currentInference?.status === 'failed'"
					>
						<h2 class="text-3xl color-white font-bold mb-8">
							{{ $t(`${localePrefix}.toolFailed.title`) }}
						</h2>
						<img src="/web/images/presets-and-tools/tool-failed.png" alt="tool-failed" />
						<p
							class="text-md leading-7 color-white my-6"
							v-html="$t(`${localePrefix}.toolFailed.description`)"
						></p>
						<Button
							:class="{
								'opacity-50': isUploading,
							}"
							@click="handleUploadMore"
							:isLoading="isUploading"
							>{{ $t(`${localePrefix}.toolFailed.button`) }}</Button
						>
					</div>
				</div>
				<div class="relative h-[460px] w-full" v-else>
					<img
						v-if="!currentInference && previewImageBlob"
						:src="previewImageBlob"
						class="absolute object-cover h-full w-full rounded-xl z-10"
						alt="Image without background"
					/>
					<BeforeAfterSource
						v-if="currentInference"
						:firstSource="currentInference.input"
						:secondSource="currentInference.outputs"
						sourceClass="rounded-xl h-[460px] w-full object-cover z-10"
						indicatorClass="z-10"
					/>
					<img
						v-if="showTransparentImage"
						class="absolute h-full w-full object-cover rounded-xl"
						src="/web/images/presets-and-tools/transparent.jpg"
						alt="Transparent image"
					/>
				</div>
			</div>
			<div>
				<div class="swiper inference-outputs-swiper">
					<div class="swiper-wrapper">
						<Button
							color="transparent"
							size="fit"
							@click="handleUploadMore"
							:isLoading="isUploading"
							:class="{
								'opacity-50': isUploading,
							}"
							class="swiper-slide relative !h-[60px] !w-[60px]"
						>
							<div class="rounded-xl h-[60px] w-[60px] flex items-center justify-center bg-radial-2-card">
								<span class="material-icons text-2xl">add_photo_alternate</span>
							</div>
						</Button>
						<div
							v-for="inference of inferences"
							:key="inference._id"
							class="swiper-slide relative !h-[60px] !w-[60px]"
						>
							<div
								class="flex items-center justify-center h-[60px] w-[60px] bg-radial-2-card rounded-xl absolute -z-10"
								v-if="!inference.outputs && inference.status === 'processing'"
							>
								<Spinner
									iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
									height="6"
									width="6"
								/>
							</div>
							<LazyImage
								v-if="inference.outputs && inference.status === 'completed'"
								:src="inference.outputs"
								lazyWrapperClass="absolute text-center top-0 left-0 z-10"
								:imgClass="`rounded-xl h-full w-full object-cover cursor-pointer absolute top-0 left-0 z-10 ${
									inference._id === currentInference?._id
										? 'border-2 border-blue-600'
										: 'border-2 border-transparent'
								}`"
								@click="() => handleOpenImage(inference)"
								alt="Image without background"
								:height="60"
							/>
							<img
								v-if="inference.outputs && showTransparentImage"
								class="absolute top-0 left-0 h-[60px] w-[60px] object-cover rounded-xl"
								src="/web/images/presets-and-tools/transparent.jpg"
								alt="Transparent image"
							/>
							<div
								v-if="inference.status === 'failed'"
								:class="`rounded-xl h-[60px] w-[60px] object-cover cursor-pointer absolute top-0 left-0 z-10 ${
									inference._id === currentInference?._id
										? 'border-2 border-blue-600'
										: 'border-2 border-transparent'
								}`"
							>
								<img
									@click="() => handleOpenImage(inference)"
									src="/web/images/presets-and-tools/tool-failed.png"
									alt="tool-failed"
									class="p-1 h-full w-full"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Button v-if="!!previewImageBlob" @click="handleSubmit" :isLoading="isUploading" :disabled="isUploading">
				{{ $t(`${localePrefix}.submitButton`) }}
			</Button>
			<Button
				v-else
				color="outline"
				@click="downloadImage"
				:isLoading="isDownloading"
				:disabled="!currentInference || currentInference.status !== 'completed'"
			>
				<span class="material-icons text-2xl mr-2">download</span>
				{{ $t(`${localePrefix}.downloadButton`) }}
			</Button>
		</div>
	</div>
</template>

<script>
import { Header, Button, LazyImage, Spinner, Input, BeforeAfterSource } from '@/components/default'
import { AuthenticationBox } from '@/components/auth'
import { PlansSwiper } from '@/components/new-avatar'
import Swiper from 'swiper'
import { inject, ref } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'ToolLayout',
	components: {
		BeforeAfterSource,
		Header,
		Button,
		PlansSwiper,
		LazyImage,
		Spinner,
		AuthenticationBox,
		Input,
	},
	async setup(props) {
		const axios = inject('axios')
		const store = useStore()
		const loggedUser = store.getters.getUser

		const [pricing, plans, inferences] = await Promise.all([
			axios.get('/inferences/pricing').then(res => res.data),
			axios.get('/plans?unit=monthly').then(res => res.data),
			loggedUser && axios.get(`/inferences?type=${props.toolType}`).then(res => res.data),
		])

		return {
			pointsPrice: pricing[props.pricingKey] || 0,
			plans,
			inferences: ref((inferences && inferences.results) || []),
			hasNext: ref(inferences?.hasNext),
			next: ref(inferences?.next),
		}
	},
	data() {
		return {
			mockupWindow: null,
			imageData: null,
			previewImageBlob: null,
			isUploading: false,
			deletingId: null,
			getInferencesIntervals: [],
			isImagesModalOpened: false,
			currentInference: null,
			isDownloading: false,
			fetchInferenceIntervalInMs: 5000,
			inferenceOutputsSwiperInstance: null,
			isFetchingInferences: false,
			text: '',
		}
	},
	props: {
		showTransparentImage: {
			type: Boolean,
			default: false,
		},
		pricingKey: {
			type: String,
		},
		previewVideoSrc: {
			type: String,
		},
		toolType: {
			type: String,
			required: true,
		},
		localePrefix: {
			type: String,
			required: true,
		},
		gtagPrefix: {
			type: String,
			required: true,
		},
		apiSubmitEndpoint: {
			type: String,
			required: true,
		},
	},
	methods: {
		async handleSubmit() {
			const file = this.imageData

			this.isUploading = true

			this.captureEvent(`${this.gtagPrefix}_submit_tool_intent`)
			try {
				const signedUrlResponse = await this.axios.get(`/files/sign-url/${file.name}`).then(res => res.data)

				await this.axios.put(signedUrlResponse.url, file)

				const inference = await this.axios
					.post(this.apiSubmitEndpoint, {
						image: signedUrlResponse.key,
						prompt: this.text,
					})
					.then(res => res.data)

				this.inferences = [inference, ...this.inferences]

				this.$nextTick(() => {
					this.inferenceOutputsSwiperInstance?.update()
				})

				const poolingInterval = setInterval(
					() => this.fetchInference(inference._id),
					this.fetchInferenceIntervalInMs
				)

				this.getInferencesIntervals.push({
					interval: poolingInterval,
					inferenceId: inference._id,
				})

				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.success'),
					message: this.$t(`${this.localePrefix}.successMessage`),
					type: 'success',
				})

				this.captureEvent(`${this.gtagPrefix}_submit_tool_success`)
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent(`${this.gtagPrefix}_submit_tool_error`)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t(`${this.localePrefix}.errorMessage`),
					type: 'error',
				})
			}

			this.isUploading = false
		},
		handleImageUpload() {
			if (this.$refs.fileInput) {
				this.$refs.fileInput.click()
			}
		},
		async onFileSelect(event) {
			const file = event.target.files[0]

			if (!file) return

			this.$refs.fileInput.value = ''

			this.captureEvent(`${this.gtagPrefix}_image_upload_click`)
			this.previewImageBlob = URL.createObjectURL(file)
			this.imageData = file
		},
		handleOpenImage(inference) {
			if (!!this.previewImageBlob) {
				this.previewImageBlob = null
				this.imageData = null
			}

			this.captureEvent(`${this.gtagPrefix}_open_image_click`)
			this.currentInference = inference
			this.isImagesModalOpened = true
			window.scrollTo({ top: 0, behavior: 'smooth' })
		},
		initInferencesSwiper() {
			this.inferenceOutputsSwiperInstance = new Swiper('.inference-outputs-swiper', {
				slidesPerView: 'auto',
				freeMode: true,
				spaceBetween: 16,
				grabCursor: true,
				mousewheel: true,
				on: {
					activeIndexChange: () => {
						if (!this.inferenceOutputsSwiperInstance || this.isFetchingInferences) return

						/**
						 * Load more items when the user it's close to the end of the list
						 */
						if (
							this.inferenceOutputsSwiperInstance.activeIndex * 2 > this.inferences.length &&
							this.hasNext
						) {
							this.fetchInferences()
						}
					},
				},
			})
		},
		handleUploadMore() {
			this.captureEvent(`${this.gtagPrefix}_upload_more_click`)

			this.isImagesModalOpened = false
		},
		onScrollPage() {
			let isScrolledToBottom = false

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight
			}

			if (isScrolledToBottom) {
				this.fetchInferences()
			}
		},
		async handleDeleteImage(inference) {
			this.deletingId = inference._id

			try {
				await this.axios.delete(`/inferences/${inference._id}`)

				this.inferences = this.inferences.filter(_inference => _inference._id !== inference._id)

				if (this.currentInference?._id === inference._id) {
					const anotherInference = this.inferences?.[0]

					this.currentInference = anotherInference
				}

				if (this.isImagesModalOpened && this.inferences.length === 0) {
					this.isImagesModalOpened = false
				}

				this.$nextTick(() => {
					this.inferenceOutputsSwiperInstance?.update()
				})
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}

			this.deletingId = null
		},
		async fetchInferences() {
			if (this.isFetchingInferences || !this.hasNext) {
				return
			}

			this.isFetchingInferences = true

			try {
				const { results, hasNext, next } = await this.axios
					.get(`/inferences?${this.hasNext ? `next=${this.next}` : ''}&type=${this.toolType}`)
					.then(res => res.data)

				const inferenceLength = this.inferences.length

				this.inferences = [...this.inferences, ...results]
				this.hasNext = hasNext
				this.next = next

				if (inferenceLength !== this.inferences.length) {
					this.$nextTick(() => {
						this.inferenceOutputsSwiperInstance?.update()
					})
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetchingInferences = false
		},
		async fetchInference(inferenceId) {
			try {
				const inference = await this.axios.get(`/inferences/${inferenceId}`).then(res => res.data)

				this.inferences = this.inferences.map(_inference => {
					if (_inference._id === inferenceId) {
						return inference
					}

					return _inference
				})

				if (this.currentInference?._id === inferenceId) {
					this.currentInference = inference
				}

				if (inference.status !== 'processing') {
					clearInterval(
						this.getInferencesIntervals.find(interval => interval.inferenceId === inferenceId)?.interval
					)
					const displayOfferModal = this.checkShowSubscribeOfferModal()

					if (displayOfferModal) {
						this.$mitt.emit('openSubscribeOfferModal')
					}
					this.$mitt.emit('openFeedbackModal', inference)
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		async downloadImage() {
			this.isDownloading = true

			this.captureEvent(`${this.gtagPrefix}_image_download_intent`)

			try {
				await this.$downloadFile(this.currentInference.outputs[0], `${this.currentInference._id}.png`)

				this.captureEvent(`${this.gtagPrefix}_image_download_success`)
			} catch (error) {
				this.captureEvent(`${this.gtagPrefix}_image_download_error`)
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}

			this.isDownloading = false
		},
		checkShowSubscribeOfferModal() {
			if (this.loggedUser && this.loggedUser.plan && this.loggedUser.plan.free) {
				return this.loggedUser.modals.subscriptionOfferModalDisplayedAt
					? this.$dayjs().isAfter(
							this.$dayjs(this.loggedUser.modals.subscriptionOfferModalDisplayedAt).add(30, 'minute')
					  )
					: true
			}

			return !this.loggedUser.hasSubscription
		},
	},
	computed: {
		currentPathname() {
			return this.$route.path
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
		userHasEnoughPoints() {
			if (!this.loggedUser) {
				return false
			}

			return this.loggedUser?.points >= this.pointsPrice
		},
	},
	mounted() {
		this.mockupWindow = this.getWindow()

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScrollPage)

		this.initInferencesSwiper()
	},
	beforeUnmount() {
		this.getInferencesIntervals.forEach(interval => clearInterval(interval.interval))

		if (this.inferenceOutputsSwiperInstance) {
			this.inferenceOutputsSwiperInstance.destroy()
		}

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScrollPage)
	},
}
</script>
