<template>
	<div class="w-full">
		<div class="grid grid-cols-3 gap-8">
			<div v-for="tab in tabsItems" :key="tab.name" class="relative">
				<Button color="transparent" class="p-0 h-fit" @click="changeTab(tab.name)">
					<p
						class="font-bold leading-6"
						:class="{
							'text-white': tab.name !== activeTab,
							'text-lime-400': tab.name === activeTab,
						}"
					>
						{{ tab.label }}
					</p>
				</Button>

				<div v-if="tab.name === activeTab" class="bg-lime-400 h-1 -bottom-7 absolute w-full" />
			</div>
		</div>

		<div class="bg-gray-800 w-full h-1 mt-6"></div>
	</div>
</template>

<script>
import { Button } from '@/components/default'

export default {
	name: 'DataCreationTabs',
	data() {
		return {
			tabsItems: [
				{
					name: 'model',
					label: this.$t('admin.presetsPage.dataCreationTabs.model'),
				},
				{
					name: 'preview',
					label: this.$t('admin.presetsPage.dataCreationTabs.preview'),
				},
				{
					name: 'presets',
					label: 'Presets',
				},
			],
		}
	},
	props: {
		activeTab: {
			type: String,
		},
	},
	emits: ['onChangeTab'],
	methods: {
		changeTab(tab) {
			if (tab === 'presets') {
				this.$router.push('/admin/presets')
				return
			}

			this.$emit('onChangeTab', tab)
		},
	},
	components: {
		Button,
	},
}
</script>
