<template>
	<div class="w-full py-6 border-b border-gray-900 inset">
		<div class="page-container flex justify-between">
			<router-link to="/admin">
				<img class="h-16" src="/web/images/admin/header-logo.svg" alt="RealityStudio Admin" />
			</router-link>
			<router-link to="/my-account/menu">
				<img
					v-if="loggedUser?.avatar"
					class="h-16 rounded-full"
					:src="loggedUser?.avatar"
					:alt="loggedUser?.userName"
				/>
				<div
					v-if="!loggedUser?.avatar"
					class="h-16 bg-gray-900 rounded-full flex justify-center items-center w-16"
				>
					<span class="text-white text-lg font-bold uppercase">{{ loggedUser?.userName.slice(0, 2) }}</span>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
}
</script>
