<template>
	<div class="page-container pt-9">
		<Header :title="$t('myAccountSubscription.title')" />
		<PlanCard class="mt-6" v-if="currentPlan" :plan="currentPlan" :showButton="false">
			<template #footer>
				<Button class="mt-11" @click="$router.push('/checkout/plans')">
					{{ $t('checkoutPlans.planCard.changeSubscription') }}
				</Button>
				<button
					@click="showCancelSubscriptionModal = true"
					class="text-center text-lg text-white font-bold mt-4"
				>
					{{ $t('checkoutPlans.planCard.cancel') }}
				</button>
			</template>
		</PlanCard>

		<div class="mt-4 border border-radial-1-card px-4 py-6 rounded-xl" v-if="isActive && isPaid">
			<Translation keypath="myAccountSubscription.nextBillingDate" tag="p">
				<b>{{ renewAt }} </b>
				<b>{{ $formatCurrency(planPrice) }}</b>
			</Translation>
		</div>
	</div>

	<Modal
		@onClose="showCancelSubscriptionModal = false"
		:displayModal="showCancelSubscriptionModal"
		:title="$t('myAccountSubscription.cancelConfirmationTitle')"
	>
		<p class="text-center text-xl font-medium">{{ $t('myAccountSubscription.cancelConfirmationMessage') }}</p>

		<PlanCard class="my-8" :plan="currentPlan" :showButton="false" :showGradient="false" />

		<div class="gap-y-6 flex flex-col">
			<Button @click="handleCancelSubscription">
				<span v-if="!isCancellingSubscription">{{ $t('myAccountSubscription.cancelButton') }}</span>
				<Spinner v-else />
			</Button>
			<Button color="outline" @click="showCancelSubscriptionModal = false">{{
				$t('myAccountSubscription.keepSubscriptionButton')
			}}</Button>
		</div>
	</Modal>
</template>

<script>
import { Header, PlanCard } from '@/components/default'
import { AnimatedSpinner, Button, Modal, Spinner } from '../../components/default'
import { Translation } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { getLocaleByLang } from '@/plugins/date'
import { inject } from 'vue'

export default {
	name: 'MyAccountSubscription',
	components: { Translation, Header, PlanCard, Modal, Button, AnimatedSpinner, Spinner },
	data() {
		return {
			currentPlan: null,
			showCancelSubscriptionModal: false,
			cancellingPlanId: null,
			isCancellingSubscription: false,
		}
	},
	async setup() {
		const axios = inject('axios')
		const router = useRouter()

		const currentPlan = await axios.get('/plans/active').then(res => res.data)

		if (!currentPlan) {
			router.push('/checkout/plans')
			return
		}

		return { currentPlan }
	},
	methods: {
		async handleCancelSubscription() {
			this.captureEvent('subscription_cancel_subscription_click')

			this.isCancellingSubscription = true

			try {
				await this.axios.delete(`/subscriptions/${this.currentPlan._id}`)

				this.$router.push('/checkout/plans')

				this.captureEvent('subscription_cancel_subscription_success')
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('subscription_cancel_subscription_error')
			}

			this.isCancellingSubscription = false
		},
	},
	computed: {
		renewAt() {
			if (!this.isActive) {
				return
			}

			const lastUpdate = this.$dayjs(this.currentPlan?.subscription.updatedAt)
			const dateFormat = this.isAmerican ? 'MMMM DD YYYY' : 'DD MMMM YYYY'
			const locale = getLocaleByLang(this.$store.getters.getUser?.lang)

			if (this.currentPlan?.isMonthly) {
				return lastUpdate.add(1, 'month').locale(locale).format(dateFormat)
			}

			if (this.currentPlan?.isYearly) {
				return lastUpdate.add(1, 'year').locale(locale).format(dateFormat)
			}
		},
		planPrice() {
			return this.currentPlan?.isYearly ? this.currentPlan?.yearlyPrice : this.currentPlan?.monthlyPrice
		},
		isActive() {
			return this.currentPlan?.subscription.active
		},
		isPaid() {
			return this.currentPlan?.price > 0
		},
		isAmerican() {
			const user = this.$store.getters.getUser

			if (!user) {
				return false
			}

			return user.lang === 'en-US'
		},
	},
	head: {
		title: `RealityStudio - My subscription`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - My subscription`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>

<style scoped>
.card {
	@apply border-radial-1-card border rounded-xl p-6 gap-y-4 flex flex-col items-center mt-4 text-center;
}
</style>
