<template>
	<div>
		<div class="relative">
			<div ref="imagesSwiper">
				<ShowcaseSwiper
					@onChangeImage="value => (selectedImageIndex = value)"
					:images="inference?.outputEntities"
					:initialIndex="parseInt(selectedImageIndex)"
				/>
			</div>
			<div class="absolute top-4 left-4 z-10">
				<Button
					size="fit"
					color="transparent"
					class="rounded-md bg-black/60 px-2 py-1 items-center flex justify-center"
					@click="$router.back()"
					v-if="canGoBack"
				>
					<span class="material-symbols-outlined text-3xl">chevron_left</span>
				</Button>
			</div>
			<div class="absolute top-4 right-4 space-y-4 z-10">
				<Button
					v-if="loggedUser?._id === inference?.user._id"
					size="fit"
					color="transparent"
					class="rounded-md bg-black/60 px-2 py-1 items-center flex justify-center"
					@click="handleFavorite(inference)"
					:isLoading="loading.favorite"
					:disabled="loading.favorite"
				>
					<span class="material-icons text-3xl">{{
						inference?.isFavorite ? 'bookmark' : 'bookmark_outline'
					}}</span>
				</Button>
				<Button
					@click="downloadImage(this.selectedImage)"
					:isLoading="loading.download"
					size="fit"
					color="transparent"
					class="bg-black/60 rounded px-2 py-1 text-white"
				>
					<i class="material-icons text-3xl">download</i>
				</Button>
			</div>
		</div>
		<div class="page-container flex items-center justify-center">
			<Button
				@click="publishImage"
				v-if="isImageCreator"
				:isLoading="loading.publish"
				:color="selectedImage?.public ? 'outline-red' : 'outline'"
				class="mt-4"
				><div class="flex items-center">
					<i class="material-icons-outlined mr-2">{{
						selectedImage?.public ? 'do_not_disturb_on' : 'add_box'
					}}</i>
					<p>
						{{
							selectedImage?.public
								? $t('myAccountLibrary.image.removeFromFeed')
								: $t('myAccountLibrary.image.publishToFeed')
						}}
					</p>
				</div>
			</Button>
			<Button
				v-if="canShare"
				@click="shareImage"
				:isLoading="loading.share"
				class="mt-4"
				:class="{ 'p-6 ml-4': isImageCreator }"
				:size="isImageCreator ? 'fit' : 'full'"
			>
				<p v-if="!isImageCreator" class="mr-4">{{ $t('predictionIdPage.shareBtn') }}</p>
				<span class="material-icons">share</span>
			</Button>
		</div>
		<div v-if="!loggedUser" class="my-4">
			<div class="page-container">
				<div class="mb-4 text-center font-satoshi">
					<h2 class="font-bold text-xl leading-8">{{ $t('imageGeneration.library.unLoggedUser.title') }}</h2>
					<p class="leading-6">{{ $t('imageGeneration.library.unLoggedUser.description') }}</p>
				</div>
				<AuthenticationBox
					v-if="!loggedUser"
					:redirectTo="`/images/${$route.params.imageId}`"
					pageName="images_image_id"
				/>
			</div>
		</div>
		<div class="page-container" v-if="loggedUser">
			<div class="flex items-center mt-4">
				<div class="w-12 h-12 border-2 border-white rounded-full mr-2 overflow-hidden">
					<LazyImage imgClass="w-full object-cover" :src="inference?.user.avatar" alt="Creator image" />
				</div>
				<p class="text-gray-300">
					{{ $t('imageGeneration.library.image.createdBy') }}
					<a class="text-white font-bold" href="#">{{ inference?.user.userName }}</a>
				</p>
			</div>
			<Accordion class="mt-4" initiallyExpanded :isExpandable="false"
				><template #title>
					<div>
						<p class="text-gray-300">
							{{
								$formatDate(
									inference?.createdAt,
									$i18n.locale === 'en-US' ? 'MM/DD/YYYY [at] HH:mm' : 'DD/MM/YYYY [as] HH:mm'
								)
							}}
						</p>
						<p class="text-lg font-bold">{{ $t('imageGeneration.library.image.details') }}</p>
					</div>
				</template>

				<template #content>
					<div class="divide-y-2 divide-radial-1-card -mx-6">
						<div class="flex items-center p-4" v-if="loggedUser">
							<Button class="rounded-sm py-2 w-full mr-2 text-gray-900" size="fit" @click="handleRemix"
								><i class="material-icons mr-2 text-2xl">replay</i>
								<p class="text-xs !font-bold leading-4">
									{{ $t('imageGeneration.library.image.remix') }}
								</p></Button
							>
							<Button
								v-if="loggedUser?._id === inference?.user._id"
								size="fit"
								class="py-2 px-3 !rounded border-radial-1-card border"
								color="delete"
								@click="handleDeleteInference"
								:isLoading="loading.delete"
								:disabled="loading.delete"
								><i class="material-icons text-2xl">delete</i></Button
							>
						</div>
						<div class="p-6" v-if="isToolInference">
							<p class="font-bold">{{ $t('myAccountLibrary.image.tool') }}</p>
							<div class="flex items-center mt-2">
								<div class="w-12 h-12 border-2 border-white rounded-full mr-2 overflow-hidden">
									<LazyImage
										imgClass="w-full object-cover bg-white"
										:src="getToolImage()"
										alt="tool-image"
									/>
								</div>
								<p class="text-gray-300">{{ getToolName() }}</p>
							</div>
						</div>
						<div class="p-6" v-if="inference?.preset">
							<p class="font-bold">{{ $t('imageGeneration.library.image.presetUsed') }}</p>
							<div class="flex items-center mt-2">
								<div class="w-12 h-12 border-2 border-white rounded-full mr-2 overflow-hidden">
									<LazyImage
										imgClass="w-full object-cover"
										:src="inference?.preset.images[0]"
										:alt="inference?.preset.name"
									/>
								</div>
								<p class="text-gray-300">{{ inference?.preset.name }}</p>
							</div>
						</div>

						<div class="p-6" v-if="inference?.diffusionModels?.length > 0">
							<p class="font-bold">{{ $t('imageGeneration.library.image.modelUsed') }}</p>
							<div class="flex items-center mt-2">
								<div class="w-12 h-12 border-2 border-white rounded-full mr-2 overflow-hidden">
									<LazyImage
										imgClass="w-full object-cover"
										:src="inference?.diffusionModels[0]?.image"
										:alt="inference?.diffusionModels[0]?.name"
									/>
								</div>
								<p class="text-gray-300">{{ inference?.diffusionModels[0]?.name }}</p>
							</div>
						</div>

						<div class="px-6 py-6 space-y-6" v-if="!isToolInference">
							<div v-if="inference?.models?.length">
								<p class="font-bold">{{ $t('imageGeneration.library.image.lorasUsed') }}</p>
								<div class="grid grid-cols-2 mt-2 gap-y-4">
									<div
										v-for="lora in inference?.models"
										class="flex items-center mt-2"
										:key="lora._id"
									>
										<div
											class="min-w-8 w-8 h-8 border-2 border-white rounded-full mr-2 overflow-hidden"
										>
											<LazyImage
												imgClass="w-full object-cover"
												:src="lora.image ?? '/web/images/logo.svg'"
												:alt="lora.name"
											/>
										</div>
										<p class="text-gray-300 overflow-ellipsis line-clamp-1">{{ lora.name }}</p>
									</div>
								</div>
							</div>

							<div class="bg-radial-2-card p-4 rounded-xl" v-if="inference?.input?.prompt">
								<div
									class="-mx-4 px-4 pb-4 border-b-2 border-radial-1-card flex justify-between items-center"
								>
									<p class="font-bold text-lg">{{ $t('imageGeneration.library.image.prompt') }}</p>
									<Button
										size="fit"
										color="none"
										class="font-normal text-2xl material-icons"
										@click="copyContent(inference?.input.prompt)"
										>content_copy</Button
									>
								</div>
								<p class="mt-4 text-gray-300">
									{{ inference?.input.prompt }}
								</p>
							</div>

							<div class="bg-radial-2-card p-4 rounded-xl" v-if="inference?.input?.negativePrompt">
								<div
									class="-mx-4 px-4 pb-4 border-b-2 border-radial-1-card flex justify-between items-center"
								>
									<p class="font-bold text-lg">
										{{ $t('imageGeneration.library.image.negativePrompt') }}
									</p>
									<Button
										size="fit"
										color="none"
										class="font-normal material-icons text-2xl"
										@click="copyContent(inference?.input.negativePrompt)"
										>content_copy</Button
									>
								</div>
								<p class="mt-4 text-gray-300">
									{{ inference?.input.negativePrompt }}
								</p>
							</div>

							<div class="flex justify-between items-center" v-if="inference?.input?.dimension">
								<p class="font-bold">{{ $t('imageGeneration.library.image.resolution') }}</p>
								<p class="text-gray-300">{{ inference?.input.dimension }}</p>
							</div>
						</div>

						<div class="flex pt-4 px-6 items-center" v-if="inference?.input?.seed">
							<p class="font-bold">{{ $t('imageGeneration.library.image.seed') }}</p>
							<p class="text-gray-300 ml-auto mr-2">{{ inference?.input.seed }}</p>
							<Button
								size="fit"
								color="none"
								class="font-normal material-icons text-2xl"
								@click="copyContent(inference?.input.seed)"
								>content_copy</Button
							>
						</div>
					</div>
				</template>
			</Accordion>
			<div v-if="othersArr?.length">
				<p class="mt-6 text-xl leading-7 font-bold">{{ $t('myAccountLibrary.image.others') }}</p>
				<div class="grid grid-cols-2 gap-4 mt-4">
					<LibraryImage v-for="image in othersArr" :key="image._id" class="cursor-pointer" :image="image" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { LazyImage, ShowcaseSlides, Accordion, Button, ShowcaseSwiper } from '@/components/default'
import { LibraryImage } from '@/components/account/library'
import { useRoute, useRouter } from 'vue-router'
import { inject, ref } from 'vue'
import { AuthenticationBox } from '@/components/auth'

export default {
	async setup() {
		const axios = inject('axios')
		const route = useRoute()
		const router = useRouter()

		const inference = await axios.get(`/images/${route.params.imageId}`).then(res => res.data)
		const othersData = await axios
			.get(`/images/public?limit=4`)
			.then(res => res.data)
			.catch(console.error)

		if (!inference || !inference?.outputEntities.length) {
			return router.push('/404')
		}

		return {
			inference: ref(inference),
			selectedImageIndex: ref(inference?.outputEntities.findIndex(entity => entity._id === route.params.imageId)),
			othersArr: othersData?.results || [],
		}
	},

	head: {
		title: `RealityStudio - Image`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Image`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
		],
	},
	watch: {
		'$route.params': async function () {
			this.inference = await this.axios.get(`/images/${this.$route.params.imageId}`).then(res => res.data)
		},
	},

	computed: {
		selectedImage() {
			return this.inference?.outputEntities[this.selectedImageIndex]
		},
		canGoBack() {
			if (import.meta.env.SSR) {
				return false
			}

			/**
			 * Firefox + Safari doesn't support canGoBack, so we need to check the length
			 * @link https://developer.mozilla.org/en-US/docs/Web/API/Navigation_API
			 */
			return (
				(typeof navigation !== 'undefined' && navigation.canGoBack) ||
				(typeof window !== 'undefined' && window.history.length > 3)
			)
		},

		loggedUser() {
			return this.$store.getters.getUser
		},

		isImageCreator() {
			return this.loggedUser?._id == this.inference?.user._id
		},

		isToolInference() {
			return this.inference?.entityType !== 'create-avatar' && this.inference?.entityType !== 'image-creation'
		},

		canShare() {
			return this.inference?.outputEntities.some(entity => entity.public)
		},
	},

	data() {
		return {
			loading: {
				download: false,
				delete: false,
				favorite: false,
				publish: false,
				share: false,
			},
		}
	},

	methods: {
		async publishImage() {
			try {
				const isPublic = !!!this.selectedImage.public
				this.captureEvent(`explore_image_${isPublic ? 'unpublish' : 'publish'}_intent`)

				this.loading.publish = true
				await this.axios.post(`/images/publish/${this.selectedImage._id}/${isPublic}`)

				this.captureEvent(`explore_image_${isPublic ? 'unpublish' : 'publish'}_success`)

				if (isPublic) {
					this.$toast({
						duration: 5000,
						title: this.$t('myAccountLibrary.image.publishToFeed'),
						message: this.$t('myAccountLibrary.image.publishSuccessToastMessage'),
						type: 'success',
					})
				} else {
					this.$toast({
						duration: 5000,
						title: this.$t('myAccountLibrary.image.removeFromFeed'),
						message: this.$t('myAccountLibrary.image.removeFromFeedSuccessToastMessage'),
						type: 'warning',
					})
				}

				this.selectedImage.public = !this.selectedImage.public
			} catch (error) {
				this.captureEvent(`explore_image_publish_error`)
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			} finally {
				this.loading.publish = false
			}
		},
		async downloadImage(image) {
			this.loading.download = true

			this.captureEvent(`explore_image_download_intent`)

			try {
				await this.$downloadFile(image.url, `${image._id}.png`)

				this.captureEvent(`explore_image_download_success`)
			} catch (error) {
				this.captureEvent(`explore_image_download_error`)
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}

			this.loading.download = false
		},
		handleRemix() {
			if (!!this.inference?.preset) {
				this.$router.push(`/new-avatar/${this.inference?.preset._id}`)
				return
			}

			if (this.inference?.entityType === 'image-creation') {
				this.$router.push(`/images/create?id=${this.inference?._id}`)
				return
			}

			this.$router.push(`/tools/${this.inference?.entityType}`)
		},
		copyContent(content) {
			this.copyToClipboard(content)
			this.$toast({
				duration: 5000,
				message: this.$t('imageGeneration.library.image.textCopied'),
				type: 'success',
			})
		},
		async handleDeleteInference() {
			if (this.loading.delete) return
			this.loading.delete = true
			try {
				await this.axios.delete(`/inferences/${this.inference?._id}`)

				this.$nextTick(() => {
					this.$router.back()
				})
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}
			this.loading.delete = false
		},
		async handleFavorite(entity) {
			if (this.loading.favorite) return
			this.loading.favorite = true
			try {
				if (!entity.isFavorite) {
					await this.axios.post(`/favorites`, {
						entity: entity._id,
						entityType: 'inference',
						searchQuery: null,
					})
					this.inference.isFavorite = true
				} else {
					await this.axios.delete(`/favorites/${entity._id}`)
					this.inference.isFavorite = false
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.loading.favorite = false
		},

		getToolImage() {
			return '/web/images/presets-and-tools/pen-tool.svg'
		},

		getToolName() {
			if (!this.isToolInference) return

			return `${this.$t(
				`${this.inference?.entityType?.replace(/-(\w)/g, (_, letter) => letter.toUpperCase())}Tool.pageTitle`
			)}`
		},

		shareImage() {
			if (this.loading.share) return
			this.loading.share = true

			try {
				this.$share({
					title: 'Reality Studio',
					text: this.$t('imageGeneration.library.image.shareText'),
					url: window.location.href,
				})
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.loading.share = false
		},
	},

	mounted() {
		setTimeout(() => {
			this.$refs.imagesSwiper.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			})
		}, 50)
	},

	components: {
		LazyImage,
		ShowcaseSlides,
		Accordion,
		Button,
		ShowcaseSwiper,
		LibraryImage,
		AuthenticationBox,
	},
}
</script>
