<template>
	<div class="flex items-center justify-between">
		<div class="flex items-center">
			<Button @click="onBack" color="transparent" size="fit" v-if="!hideBackButton">
				<span class="material-symbols-outlined"> arrow_back </span>
			</Button>
			<h1 class="text-xl font-semibold ml-2">{{ title }}</h1>
		</div>
		<slot></slot>
	</div>
</template>

<script>
import Button from './Button.vue'

export default {
	components: {
		Button,
	},
	props: {
		hideBackButton: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
		},
	},
	methods: {
		onBack() {
			this.$router.back()
		},
	},
	computed: {
		currentUser() {
			return this.$store.getters.getUser ?? 0
		},
	},
}
</script>
