<template>
	<div class="flex items-center justify-center" v-if="!isDevelopment">
		<VueHcaptcha ref="hCaptcha" :sitekey="hCaptchaSiteKey" @verify="onVerifyCaptcha" theme="dark" />
	</div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'

export default {
	emits: ['onVerify'],
	methods: {
		onVerifyCaptcha(token) {
			this.$emit('onVerify', token)
		},
		reset() {
			this.$refs?.hCaptcha?.reset()
		},
	},
	computed: {
		hCaptchaSiteKey() {
			return typeof APP_ENV === 'undefined'
				? import.meta.env.VITE_HCAPTCHA_SITE_KEY
				: APP_ENV?.VITE_HCAPTCHA_SITE_KEY
		},
		isDevelopment() {
			return import.meta.env.DEV
		},
	},
	components: {
		VueHcaptcha,
	},
}
</script>
