import useStore from '../store'
import { Capacitor } from '@capacitor/core'

export default {
	install(app) {
		const currencyByLang = {
			'en-US': 'USD',
			'pt-BR': 'BRL',
		}

		app.config.globalProperties.$getPlatform = () => {
			return Capacitor.getPlatform()
		}

		app.config.globalProperties.$formatCurrency = value => {
			const user = useStore.getters.getUser
			const lang = user?.lang || 'en-US'

			return new Intl.NumberFormat(lang, {
				style: 'currency',
				currency: currencyByLang[lang] || 'USD',
			}).format(value)
		}

		app.config.globalProperties.getWindow = () => {
			const isMobile = window.innerWidth < 768
			let targetElement

			if (isMobile) {
				targetElement = document
			} else {
				targetElement = document.querySelector('#mockupWindow')
			}

			return targetElement
		}

		app.config.globalProperties.copyToClipboard = text => {
			if (!document) {
				return
			}

			const element = document.createElement('textarea')
			element.value = text
			element.setAttribute('readonly', '')
			element.style.position = 'absolute'
			element.style.left = '-9999px'
			document.body.appendChild(element)
			element.select()
			document.execCommand('copy')
			document.body.removeChild(element)
		}

		app.config.globalProperties.truncateText = (string, length = 0) => {
			if (string.length < length) {
				return string
			}

			return string.substring(0, length) + '...'
		}

		app.config.globalProperties.$share = async content => {
			const { Capacitor } = await import('@capacitor/core')

			if (Capacitor.getPlatform() === 'web') {
				if (navigator && navigator.share) {
					navigator.share(content)
				}
			} else {
				const { Share } = await import('@capacitor/share')
				const canShare = await Share.canShare()
				if (!canShare) return
				await Share.share(content)
			}
		}
	},
}
