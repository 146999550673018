import { Capacitor } from '@capacitor/core'

export default async function checkHomepage({ store, req }) {
	const isAuthenticated = !!store.getters.getUser
	const isWeb = import.meta.env.SSR ? !req.useragent.isMobile : Capacitor.getPlatform() === 'web'

	if (!isAuthenticated && isWeb) {
		return '/lp'
	}

	if (!isAuthenticated && !isWeb) {
		return '/welcome'
	}

	return true
}
