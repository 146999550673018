<template>
	<div>
		<div class="page-container py-6 px-4">
			<Header :title="$t('myAccountDelete.header')" />
			<div v-if="!emailSent">
				<p
					class="mt-6 text-base leading-6 text-center text-gray-300"
					v-html="$t('myAccountDelete.description')"
				></p>

				<div class="my-6 p-6 border-[1px] border-gray-900 text-center text-gray-300 rounded-md">
					<p class="font-bold text-lg mb-4">{{ $t('myAccountDelete.downloadArea.title') }}</p>
					<p v-html="$t('myAccountDelete.downloadArea.description', { imagesLength: images.length })"></p>
					<Button
						:isLoading="loading.downloadImages"
						class="mt-4"
						type="button"
						@click="handleDownload"
						:disabled="images.length === 0"
					>
						<span class="material-icons text-[16px] text-black mr-2"> download </span>
						<span class="leading-5 font-bold text-sm"
							>{{ $t('myAccountDelete.downloadArea.downloadButton') }}
						</span>
					</Button>
				</div>

				<Button :isLoading="loading.sendEmail" class="my-6" type="button" @click="sendEmail">
					<span class="leading-5 font-bold text-sm">{{ $t('myAccountDelete.buttons.continue') }} </span>
				</Button>
				<Button type="button" color="outline" @click="handleCancel">
					<span class="leading-5 font-bold text-sm">{{ $t('myAccountDelete.buttons.cancel') }}</span>
				</Button>
			</div>
			<div v-if="emailSent">
				<div class="flex flex-col justify-center">
					<div class="text-center">
						<img src="/web/images/delete-account.png" alt="delete-account" class="mx-auto mt-6 mb-4" />
						<p class="text-lg font-bold text-white leading-7 mb-4">
							{{ $t('myAccountDelete.email.title') }}
						</p>
						<p class="text-base leading-6 font-normal text-gray-300 mb-6">
							{{ $t('myAccountDelete.email.description') }}
						</p>
						<Button type="button" color="outline" @click="handleHome">
							<span class="leading-5 font-bold text-lg">{{ $t('myAccountDelete.email.button') }}</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Header, Button } from '@/components/default'
import { inject, ref } from 'vue'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'

export default {
	components: { Header, Button },
	head: {
		title: `RealityStudio - Delete account`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Delete Account `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
		],
	},
	async setup() {
		const axios = inject('axios')
		const images = await axios.get('/images/').then(res => res.data)
		return { images: ref(images) }
	},
	data() {
		return {
			emailSent: false,
			loading: {
				sendEmail: false,
				downloadImages: false,
			},
			permissions: false,
		}
	},
	mounted() {
		this.permissions = this.$getPermissions()
	},
	methods: {
		async handleDownload() {
			if (!this.permissions) {
				this.$toast({
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.permissions'),
					type: 'error',
				})

				this.permissions = this.$getPermissions()
				return
			}

			if (this.loading.downloadImages) return

			this.loading.downloadImages = true
			try {
				const zip = new JSZip()

				this.images.forEach((image, index) => {
					if (image.url) {
						zip.file(`${index + 1}.png`, this.urlToPromise(image.url), { binary: true })
					}
				})

				const generatedZip = await zip.generateAsync({ type: 'blob' })
				await this.$downloadFile(generatedZip, 'realityavatar.zip')
				if (this.isMobile) {
					this.$toast({
						type: 'success',
						title: this.$t('predictionIdPage.toastDownloadSuccess.title'),
						message: this.$t('predictionIdPage.toastDownloadSuccess.message'),
					})
				}

				this.loading.downloadImages = false

				this.captureEvent('delete_account_download_all_images_success')
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('delete_account_download_all_images_error')
				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
				})
			}
		},
		async urlToPromise(url) {
			try {
				const data = await new Promise((resolve, reject) => {
					JSZipUtils.getBinaryContent(url, (err, data) => {
						if (err) {
							reject(err)
						} else {
							resolve(data)
						}
					})
				})

				return data
			} catch (error) {
				throw error
			}
		},
		handleCancel() {
			this.captureEvent('delete_account_cancel')
			this.$router.push('/my-account/settings')
		},
		handleHome() {
			this.$router.push('/')
		},
		async sendEmail() {
			if (this.loading.sendEmail) return
			this.loading.sendEmail = true
			try {
				await this.axios.post('/users/send-delete-email')

				this.emailSent = true
				this.captureEvent('delete_account_email_sent_success')
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('delete_account_email_sent_error')
			}
			this.loading.sendEmail = false
		},
	},
}
</script>
<style scoped>
.text-area-height {
	min-height: 200px;
}
</style>
