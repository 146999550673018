<template>
	<div class="col-span-4 rounded-xl pb-8 pt-20 border-2 border-radial-1-card relative grid grid-rows-2 gap-6 lg:pt-8">
		<div class="flex items-center justify-center">
			<div class="absolute">
				<div class="pie"></div>
			</div>

			<div class="absolute">
				<div
					ref="biggerPie"
					:style="{
						'--p': biggerData.percentage,
					}"
					class="pie pie-bigger"
				></div>
			</div>

			<span class="text-lg font-bold w-full text-center">
				{{ centerText }}
			</span>
		</div>
		<div class="grid-cols-12 grid place-items-center mt-8 lg:mt-0">
			<div class="col-span-6 flex">
				<div class="h-[108px] w-[30px] bg-emerald-400 rounded-3xl" />
				<div class="ml-2 relative">
					<p class="font-bold" v-html="biggerData.label"></p>
					<span class="mt-2 text-xs text-gray-400 font-semibold">
						{{ biggerData.value }}
					</span>
					<p class="font-bold absolute bottom-0">{{ biggerData.percentage ?? '-' }}%</p>
				</div>
			</div>
			<div class="col-span-6 flex">
				<div class="h-[108px] w-[30px] bg-purple-400 rounded-3xl" />
				<div class="ml-2 relative">
					<p class="font-bold" v-html="smallerData.label"></p>
					<span class="mt-2 text-xs text-gray-400 font-semibold">
						{{ smallerData.value }}
					</span>
					<p class="font-bold absolute bottom-0">{{ smallerData.percentage ?? '-' }}%</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['centerText', 'biggerData', 'smallerData'],
}
</script>

<style scoped>
.pie-bigger {
	--c: #34d399 !important;
	--b: 60px !important;
	--w: 210px !important;
}

.pie {
	--p: 100;
	--c: #a78bfa;
	--b: 40px;
	--w: 180px;

	width: var(--w);
	aspect-ratio: 1;
	position: relative;
	display: inline-grid;
	margin: 5px;
	place-content: center;
	font-size: 25px;
	font-weight: bold;
	font-family: sans-serif;
}
.pie:before,
.pie:after {
	content: '';
	position: absolute;
	border-radius: 50%;
}
.pie:before {
	inset: 0;
	background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
		conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
	-webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
	mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
	background-size: 0 0, auto;
}
</style>
