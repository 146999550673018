<template>
	<div class="flex flex-col gap-2">
		<label class="text-base leading-6 cursor-pointer" :class="labelClass" v-if="label" :for="id">{{ label }}</label>
		<input
			class="rounded-xl text-base leading-6 font-normal text-white placeholder:text-gray-700 bg-transparent border border-extended-blue-gray-900 py-6 px-4"
			:id="id"
			:class="inputClass"
			:value="modelValue"
			:placeholder="placeholder"
			:type="type"
			:required="required"
			@input="onChange"
			:disabled="disabled"
			:minlength="minlength"
			:maxlength="maxlength"
			:readonly="readonly"
			:inputmode="inputmode"
		/>
	</div>
</template>
<script>
export default {
	props: {
		inputmode: {
			type: String,
		},
		inputClass: {
			type: String,
		},
		minlength: {
			type: String,
		},
		maxlength: {
			type: String,
		},
		id: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'text',
		},
		placeholder: {
			type: String,
		},
		labelClass: {
			type: String,
		},
		label: {
			type: String,
		},
		required: {
			type: Boolean,
		},
		modelValue: {
			type: [String, Number],
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue'],
	methods: {
		onChange(event) {
			this.$emit('update:modelValue', event.target.value)
		},
	},
}
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
</style>