<template>
	<div class="grid grid-cols-1 lg:gap-8 lg:grid-cols-3">
		<form @submit.prevent="handleCreatePreview" class="space-y-6">
			<Select
				:label="$t('admin.presetsPage.presetsCreationForm.modelVersion')"
				class="mt-6"
				dropdownClass="max-h-[550px] pb-0"
				:options="diffusionModelVersions"
				:value="presetData.modelVersion"
				:menuTitle="$t('admin.presetsPage.presetsCreationForm.modelVersion')"
				@onChange="presetData.modelVersion = $event"
				@onReachEnd="fetchDiffusionModels"
			/>
			<Button color="outline" class="mt-6" @click="$router.push('/admin/create/diffusion-model')">{{
				$t('admin.presetsPage.presetsCreationForm.createModelVersion')
			}}</Button>

			<Select
				class="mt-6"
				:label="$t('admin.presetsPage.presetsCreationForm.lorasModels')"
				dropdownClass="max-h-[550px] pb-0"
				:options="loraOptions"
				:value="presetData.modelId"
				:menuTitle="$t('admin.presetsPage.presetsCreationForm.lorasModels')"
				@onChange="presetData.modelId = $event"
				@onReachEnd="fetchModels"
			/>

			<Select
				class="mt-6"
				:label="$t('admin.presetsPage.presetsCreationForm.styleType')"
				dropdownClass="max-h-[550px] pb-0"
				:options="presetsLorasOptions"
				:value="presetData.loraUrls"
				:menuTitle="$t('admin.presetsPage.presetsCreationForm.style')"
				@onChange="presetData.loraUrls = $event"
				@onReachEnd="fetchModels"
			/>

			<TextArea
				name="presetPrompt"
				id="presetPrompt"
				placeholder="Enter the prompt for the preset"
				rows="5"
				v-model="presetData.prompt"
				labelText="Prompt"
				:deleteButton="false"
			/>

			<TextArea
				name="presetNegativePrompt"
				id="presetNegativePrompt"
				placeholder="Enter the negative prompt for the preset"
				rows="5"
				v-model="presetData.negativePrompt"
				labelText="Prompt"
				:deleteButton="false"
			/>

			<Input
				id="minSeed"
				type="text"
				:label="$t('admin.presetsPage.presetsCreationForm.minSeed')"
				:placeholder="$t('admin.presetsPage.presetsCreationForm.minSeed')"
				v-model="presetData.minSeed"
			/>

			<Input
				id="loraScales"
				type="text"
				:label="$t('admin.presetsPage.presetsCreationForm.loraScales')"
				:placeholder="$t('admin.presetsPage.presetsCreationForm.loraScales')"
				v-model="presetData.loraScales"
			/>

			<Input
				id="maxSeed"
				type="text"
				:label="$t('admin.presetsPage.presetsCreationForm.maxSeed')"
				:placeholder="$t('admin.presetsPage.presetsCreationForm.maxSeed')"
				v-model="presetData.maxSeed"
			/>

			<Input
				id="cfgScale"
				type="text"
				:label="$t('admin.presetsPage.presetsCreationForm.cfgScale')"
				:placeholder="$t('admin.presetsPage.presetsCreationForm.cfgScale')"
				v-model="presetData.cfgScale"
			/>

			<Input
				id="scheduler"
				type="text"
				label="Scheduler"
				placeholder="Scheduler"
				v-model="presetData.scheduler"
			/>

			<Input id="steps" type="text" label="Steps" placeholder="Steps" v-model="presetData.steps" />

			<Button
				class="mt-6"
				type="submit"
				:isLoading="loading.preview"
				:disabled="loading.preview || !isValidPreviewForm"
				>{{ $t('admin.presetsPage.presetsCreationForm.makePreview') }}</Button
			>

			<Button
				class="mt-6"
				@click="handleCreatePreset"
				:isLoading="loading.creating"
				:disabled="loading.creating || !isValidPresetForm"
				>{{ $t('admin.presetsPage.presetsCreationForm.createPreset') }}</Button
			>
		</form>
		<div class="col-span-2 space-y-4 lg:mt-6 mt-8">
			<div class="border border-gray-800 rounded-xl p-6" v-for="preview in previews" :key="preview._id">
				<div class="flex items-center justify-between mb-6">
					<h2 class="font-2xl font-bold">{{ preview.preset.name }}</h2>
					<span class="text-gray-500">{{ $formatDate(preview.createdAt) }}</span>
				</div>

				<div class="grid grid-cols-1 lg:grid-cols-4 gap-4">
					<div :key="i" v-for="i in 4" v-if="!preview.images.length">
						<div class="border border-dashed border-gray-800 rounded-xl h-[300px] grid place-items-center">
							<Spinner />
						</div>
						<p class="mt-2 font-bold text-xl text-center">Seed</p>
						<div class="w-full rounded-full text-center py-2 border border-gray-800 mt-2">--------</div>
					</div>
					<div :key="image._id" v-for="image in preview.images" v-else>
						<div>
							<LazyImage
								imgClass="rounded-xl w-full h-[300px] object-cover"
								:src="image.url"
								:height="300"
								alt="previe image"
							/>
						</div>
						<p class="mt-2 font-bold text-xl text-center">Seed</p>
						<div class="w-full rounded-full text-center py-2 border border-gray-800 mt-2">
							{{ preview.seed }}
						</div>
					</div>
				</div>
				<Button color="outline" class="mt-6" @click="openPreviewDetails(preview)">
					<span class="material-icons text-xl">
						{{ preview.isOpened ? 'visibility_off' : 'visibility' }}
					</span>
					<span class="ml-2">
						{{ preview.isOpened ? 'Close' : 'Open' }}
					</span>
				</Button>

				<div v-if="preview.isOpened">
					<div
						class="border border-gray-800 rounded-xl grid grid-cols-3 place-items-center my-4 w-max mx-auto p-4"
					>
						<p class="flex-1"><strong>CFG:</strong> {{ preview.preset.input.cfgScale }}</p>
						<p class="flex-1"><strong>Scheduler:</strong> {{ preview.preset.input.scheduler }}</p>
						<p class="flex-1"><strong>Steps:</strong> {{ preview.preset.input.steps }}</p>
					</div>
					<div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
						<div class="border border-gray-800 rounded-xl">
							<h1 class="text-xl font-bold p-6">Prompt</h1>
							<div class="w-full bg-gray-800 h-[2px]" />
							<p
								class="text-gray-500 p-6"
								contenteditable
								@input="e => (preview.preset.input.prompt = e.target.innerText)"
							>
								{{ preview.preset.input.prompt }}
							</p>
						</div>
						<div class="border border-gray-800 rounded-xl">
							<h1 class="text-xl font-bold p-6">Negative prompt</h1>
							<div class="w-full bg-gray-800 h-[2px]" />
							<p
								class="text-gray-500 p-6"
								contenteditable
								@input="e => (preview.preset.input.negativePrompt = e.target.innerText)"
							>
								{{ preview.preset.input.negativePrompt }}
							</p>
						</div>
					</div>
					<div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
						<div>
							<Button color="outline" class="mt-6" @click="copyToClipboard(preview.preset.input.prompt)">
								<span class="material-icons text-[32px]">content_copy</span>
								<span class="ml-2">Copy</span>
							</Button>
						</div>

						<div>
							<Button
								color="outline"
								class="mt-6"
								@click="copyToClipboard(preview.preset.input.negativePrompt)"
							>
								<span class="material-icons text-[32px]">content_copy</span>
								<span class="ml-2">Copy</span>
							</Button>
						</div>

						<Button
							color="outline"
							class="col-span-2"
							@click="remixPreview(preview)"
							:isLoading="loading.remixPreview"
						>
							<span class="material-icons text-[32px]">replay</span>
							<span class="ml-2">Remix</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Button, Select, Input, TextArea, LazyImage, Spinner } from '@/components/default'

export default {
	name: 'PresetsCreationForm',
	components: {
		Spinner,
		LazyImage,
		Button,
		Select,
		Input,
		TextArea,
	},
	data() {
		return {
			styleOptions: [
				{
					label: 'style',
					value: 'style',
				},
				{
					label: 'face',
					value: 'face',
				},
				{
					label: 'pet',
					value: 'pet',
				},
				{
					label: 'multiple',
					value: 'multiple',
				},
			],
			diffusionModels: {
				results: [],
				hasNext: false,
				next: null,
				limit: 15,
			},
			presetData: {
				price: 0,
				prompt: '<1> as fireman, portrait, (close-up head:1.3), (facing camera:1.3), (intricate details:1.2), style of <2>, hdr, (symmetrical eyes:1.3), digital painting, cinematic, elegant, harvest fall aura, 8k, cinematic illumination, focus on face, highly detailed',
				negativePrompt:
					'asymetrical eyes, uneven eyes, helmet, golden armor, (nfsw:1.3), cropped, out of frame, worst quality, (3d), (nude:1.3), jpeg artifacts, ugly, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, dehydrated, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers',
				modelVersion: '',
				modelId: '',
				minSeed: '1',
				modelType: 'style',
				maxSeed: '7000000',
				loraUrls: '',
				cfgScale: '3.5',
				loraScales: '0.6 | 0.1',
				steps: '50',
				scheduler: 'K_EULER_ANCESTRAL',
				published: false,
			},
			isFetchingDiffusionModels: false,
			loading: {
				styleLoras: false,
				preview: false,
				remixPreview: false,
				creating: false,
				presetsLoras: false,
			},
			previews: [],
			models: [],
			presetsLoras: [],
		}
	},
	created() {
		this.fetchModels(true)
		this.fetchDiffusionModels(true)
		this.fetchPresetsLoras(true)
	},
	methods: {
		async fetchPresetsLoras(forceFetch) {
			if (this.loading.presetsLoras || (!forceFetch && !this.presetsLoras.hasNext)) {
				return
			}

			this.loading.presetsLoras = true

			try {
				const { data } = await this.axios.get(`/admin/my-models/all?modelType=style`)

				this.presetsLoras = data
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.presetsLoras = false
		},
		async fetchModels() {
			if (this.loading.models) {
				return
			}

			this.loading.models = true

			try {
				const data = await this.axios.get(`/admin/my-models/all?modelType=face`).then(res => res.data)

				this.models = data
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.models = false
		},

		async remixPreview(preview) {
			if (this.loading.remixPreview) {
				return
			}

			this.loading.remixPreview = true

			try {
				const { data } = await this.axios.post('/admin/create-preview', {
					...preview.preset.input,
				})
				const previewId = data._id
				this.previews = [await this.getPreview(data._id), ...this.previews]

				const checkLogs = async () => {
					const { data } = await this.axios.get(`/admin/predictions/${previewId}`)

					if (data.status === 'completed') {
						this.previews = this.previews.map(preview => {
							if (preview._id === previewId) {
								return data
							}

							return preview
						})
						return
					}

					setTimeout(checkLogs, 5000) // 5 seconds
				}

				setTimeout(checkLogs, 5000) // 5 seconds
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.remixPreview = false
		},
		openPreviewDetails(preview) {
			const previewId = preview._id
			preview.isOpened = !preview.isOpened

			this.previews = this.previews.map(preview => {
				if (preview._id === previewId) {
					return preview
				}

				return preview
			})
		},
		async handleCreatePreview() {
			if (this.loading.preview) {
				return
			}
			this.loading.preview = true

			try {
				const { data } = await this.axios.post('/admin/create-preview', this.presetData)
				const previewId = data._id
				const preview = await this.getPreview(previewId)
				this.previews = [preview, ...this.previews]

				const checkLogs = async () => {
					const { data } = await this.axios.get(`/admin/predictions/${previewId}`)

					if (data.status === 'completed') {
						this.previews = this.previews.map(preview => {
							if (preview._id === previewId) {
								return data
							}

							return preview
						})
						return
					}

					if (data.status === 'failed') {
						this.previews = this.previews.filter(preview => preview._id !== previewId)
						return
					}

					setTimeout(checkLogs, 5000) // 5 seconds
				}

				setTimeout(checkLogs, 5000) // 5 seconds
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading.preview = false
		},
		async getPreview(id) {
			try {
				const { data } = await this.axios.get(`/admin/predictions/${id}`)

				return { ...data, isOpened: false }
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		async handleCreatePreset() {
			if (this.loading.creating) {
				return
			}

			this.loading.creating = true

			try {
				const { data } = await this.axios.post('/admin/create-preset', this.presetData)

				this.$toast({
					title: 'Preset created',
					message: 'The preset has been created successfully',
					type: 'success',
				})
			} catch (error) {
				this.sentryCaptureException(error)
			}

			// this.$router.push(`/admin/presets/${data._id}`)

			this.loading.creating = false
		},
		async fetchDiffusionModels(forceFetch) {
			if (this.isFetchingDiffusionModels || (!forceFetch && !this.diffusionModels.hasNext)) {
				return
			}

			this.isFetchingDiffusionModels = true

			try {
				const data = await this.axios
					.get(
						`/diffusion-models?next=${this.diffusionModels.next || ''}&limit=${this.diffusionModels.limit}`
					)
					.then(res => res.data)

				this.diffusionModels.results = [...this.diffusionModels.results, ...data.results]
				this.diffusionModels.hasNext = data.hasNext
				this.diffusionModels.next = data.next
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetchingDiffusionModels = false
		},
	},
	computed: {
		presetsLorasOptions() {
			if (this.presetsLoras.length === 0) {
				return []
			}

			const none = {
				label: 'None',
				value: '',
			}

			const loraOptions = this.presetsLoras.map(model => ({
				label: model.name,
				value: model.file,
			}))

			return [...loraOptions, none]
		},
		loraOptions() {
			if (this.models.length === 0) {
				return []
			}

			const none = {
				label: 'None',
				value: '',
			}

			const loraOptions = this.models.map(model => ({
				label: model.name,
				value: model._id,
			}))

			return [...loraOptions, none]
		},
		diffusionModelVersions() {
			if (this.diffusionModels.results.length === 0) {
				return []
			}

			return this.diffusionModels.results.map(model => ({
				label: model.name,
				value: model.gatewayId,
			}))
		},
		isValidPreviewForm() {
			const loraScalesLength = this.presetData.loraScales.split('|').length
			let neededLoraScales = 0

			if (this.presetData.modelId) neededLoraScales++
			if (this.presetData.loraUrls) neededLoraScales++

			return (
				this.presetData.modelVersion &&
				this.presetData.modelType &&
				this.presetData.minSeed &&
				this.presetData.maxSeed &&
				this.presetData.cfgScale &&
				this.presetData.scheduler &&
				this.presetData.steps &&
				this.presetData.prompt &&
				this.presetData.negativePrompt &&
				neededLoraScales === loraScalesLength
			)
		},
		isValidPresetForm() {
			return (
				this.presetData.modelVersion &&
				this.presetData.modelId &&
				this.presetData.modelType &&
				this.presetData.minSeed &&
				this.presetData.maxSeed &&
				this.presetData.cfgScale &&
				this.presetData.scheduler &&
				this.presetData.steps &&
				this.presetData.prompt &&
				this.presetData.negativePrompt
			)
		},
	},
}
</script>
