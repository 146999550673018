<template>
	<button @click="onClick" class="cursor-pointer">
		<div :class="['relative h-[300px]', { 'border border-radial-1-card rounded-xl': !isImageLoaded }]">
			<div
				v-if="isImageLoaded"
				class="w-full z-10 absolute bottom-0 left-0 h-1/2 bg-gradient-to-t from-black to-transparent rounded-xl"
			></div>
			<div
				class="h-8 w-8 z-10 bg-gray-900 absolute top-2 left-2 flex rounded-full items-center justify-center"
				v-if="data.isMostUsed"
			>
				<span class="material-icons text-white text-base"> local_fire_department </span>
			</div>

			<LazyImage
				v-for="(image, index) in data.images"
				:key="image"
				class="invisible absolute top-0 left-0 w-full"
				:class="{
					'!visible': index === currentImageIndex,
				}"
				:imgClass="[
					'rounded-xl object-cover w-full h-full transition-all duration-700 ease-in-out',
					{ 'opacity-0': index !== currentImageIndex },
				]"
				:src="image"
				alt="Preset Image"
				@onImageLoaded="isImageLoaded = true"
			/>

			<p class="mb-4 text-center w-full text-gray-200 absolute bottom-0 font-bold z-10">{{ data.name }}</p>
		</div>
	</button>
</template>

<script>
import { LazyImage } from '@/components/default'

export default {
	name: 'NewAvatarLibraryCard',
	components: { LazyImage },

	data() {
		return {
			isImageLoaded: false,
			currentImageIndex: 0,
			changeImageIntervalInMs: 3000,
			intervalInstance: null,
		}
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	emits: ['click'],
	mounted() {
		if (this.data.images.length > 1) {
			this.intervalInstance = setInterval(this.nextImage, this.changeImageIntervalInMs)
		}
	},
	beforeUnmount() {
		clearInterval(this.intervalInstance)
	},
	methods: {
		nextImage() {
			if (this.currentImageIndex === this.data.images.length - 1) {
				this.currentImageIndex = 0
				return
			}

			this.currentImageIndex++
		},
		onClick() {
			this.$emit('click', this.data._id)
		},
	},
}
</script>
