<template>
	<div ref="loraModal">
		<div class="page-container relative pt-7" :class="selectedLoras.length >= selectionLimit ? 'pb-24' : 'pb-0'">
			<div class="flex items-center">
				<button @click="closeLoraSelection"><i class="material-icons text-3xl mr-2">chevron_left</i></button>
				<p class="text-xl font-bold">{{ $t('imageGeneration.create.lora') }}</p>
			</div>
			<TabSelection @onChangeTab="value => handleChangeTab(value)" :tabs="tabs" class="mt-4" />
			<!-- <div class="swiper lora-swiper">
			<div class="swiper-wrapper">
				<Button
					@click="item => (selectedFilter = filter.value)"
					:color="filter.value === selectedFilter ? 'primary' : 'gray'"
					class="text-sm w-max swiper-slide mt-4"
					size="compact"
					v-for="filter in filters"
					:key="filter.name"
					>{{ filter.title }}
				</Button>
			</div>
		</div> -->
			<Input
				class="mt-4"
				v-model="textSearch.text"
				:placeholder="$t('imageGeneration.create.searchLoraPlaceHolder')"
				id="search-lora"
				@update:modelValue="value => searchLorasByText(value)"
			/>
			<Spinner class="flex justify-center my-4" v-if="loading" />
			<div class="grid grid-cols-2 gap-4 my-4" v-if="tab === 'Library'">
				<div class="col-span-1" v-for="lora in filteredLoras" :key="lora._id">
					<AdditionalParameterCard
						class="cursor-pointer"
						:item="lora"
						itemType="lora"
						:isSelected="currentlySelectedLoras.some(_lora => _lora._id === lora._id)"
						@onSelectItem="value => handleLoraSelection(value)"
						@onBookmarkItem="value => handleFavorite(value)"
					/>
				</div>
			</div>
			<div class="grid grid-cols-2 gap-4 my-4" v-if="tab === 'Favorite'">
				<div class="col-span-1" v-for="favorite in filteredFavorites" :key="favorite._id">
					<AdditionalParameterCard
						class="cursor-pointer"
						:item="favorite.entity"
						itemType="lora"
						:isSelected="currentlySelectedLoras.some(favorite => favorite.entity._id === lora._id)"
						@onSelectItem="value => handleLoraSelection(value)"
						@onBookmarkItem="value => handleFavorite(value)"
					/>
				</div>
			</div>
		</div>
		<div
			class="w-full md:left-1/2 md:-translate-x-1/2w-full md:!w-[418px] fixed mb-5 bottom-0 md:mb-7 floating-width z-20 md:-translate-x-1/2 md:-ml-px"
		>
			<div class="px-4 py-2">
				<div
					v-if="currentlySelectedLoras.length >= selectionLimit"
					class="mb-6 bg-blue-800 border-blue-800 border rounded-xl bg-opacity-40 backdrop-filter backdrop-blur-sm p-6 text-center px-4"
					v-html="$t('imageGeneration.create.loraLimit', { selectionLimit })"
				></div>

				<Button @click="confirmSelection" class="font-bold px-4 w-full py-6" size="fit">{{
					$t('imageGeneration.create.confirm')
				}}</Button>
			</div>
		</div>
	</div>
</template>
<script>
import { Button, TabSelection, Input, Spinner } from '@/components/default'
import { Swiper } from 'swiper'
import { FreeMode } from 'swiper/modules'
import { AdditionalParameterCard } from '@/components/image-creation'
import { inject, ref } from 'vue'

export default {
	components: {
		AdditionalParameterCard,
		Button,
		TabSelection,
		Input,
		Spinner,
	},

	async setup() {
		const axios = inject('axios')

		const [models, favoritesLoras] = await Promise.all([
			axios.get('/models?imageCreation=true').then(res => res.data),
			axios.get(`/favorites/model`).then(res => res.data),
		])

		return {
			loras: ref(models.results),
			next: ref(models.next),
			hasNext: ref(models.hasNext),
			favorites: ref(favoritesLoras.results),
			favoritesNext: ref(favoritesLoras.next),
			favoritesHasNext: ref(favoritesLoras.hasNext),
		}
	},

	mounted() {
		this.initSwiper()
		this.$refs.loraModal.scrollIntoView({ behavior: 'smooth', block: 'start' })

		this.mockupWindow = this.getWindow()
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScrollPage)

		this.currentlySelectedLoras = this.selectedLoras
	},
	beforeUnmount() {
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScrollPage)
	},

	data() {
		return {
			selectedFilter: 'realityStudio',
			selectedTab: {
				name: 'Lora',
				iconSrc: '/web/icons/image-creation/model-icon.svg',
			},
			currentlySelectedLoras: [],
			filters: [
				{ title: 'RealityStudio', value: 'realityStudio' },
				{
					title: 'Public',
					value: 'public',
				},
				{
					title: 'My Lora',
					value: 'myLora',
				},
			],

			tabs: [
				{
					name: this.$t('imageGeneration.create.lora'),
					iconSrc: '/web/icons/image-creation/lora.svg',
					value: 'Library',
				},
				{ name: this.$t('imageGeneration.tabs.favorites'), icon: 'bookmark', value: 'Favorite' },
			],
			tab: 'Library',
			textSearch: {
				text: '',
				lastSearchedText: '',
				debounceTimeoutId: null,
				results: [],
				next: null,
				hasNext: null,
			},
			textSearchFavorites: {
				text: '',
				lastSearchedText: '',
				debounceTimeoutId: null,
				results: [],
				next: null,
				hasNext: null,
			},
			loading: false,
		}
	},

	props: {
		selectedLoras: {
			type: Array,
			default: () => [],
		},
		selectionLimit: {
			type: Number,
			default: 3,
		},
	},

	methods: {
		handleLoraSelection(lora) {
			if (this.currentlySelectedLoras.some(_lora => _lora._id === lora._id)) {
				return (this.currentlySelectedLoras = this.currentlySelectedLoras.filter(
					_lora => _lora._id !== lora._id
				))
			}

			if (this.currentlySelectedLoras.length >= this.selectionLimit) return

			this.currentlySelectedLoras.push(lora)
		},

		confirmSelection() {
			this.$emit('onConfirmSelection', this.currentlySelectedLoras)
		},

		async handleFavorite(entity) {
			try {
				let bookmarked = false
				if (!entity.isFavorite) {
					await this.axios.post(`/favorites`, {
						entity: entity._id,
						entityType: 'model',
						searchQuery: entity.name,
					})
					bookmarked = true
				} else {
					await this.axios.delete(`/favorites/${entity._id}`)
				}

				this.loras.find(lora => lora._id === entity._id).isFavorite = bookmarked

				if (this.textSearch.results.length) {
					this.textSearch.results.find(lora => lora._id === entity._id).isFavorite = bookmarked
				}

				if (this.tab === 'Favorite') {
					this.favorites.find(favorite => favorite.entity._id === entity._id).entity.isFavorite = bookmarked
					this.favorites.splice(
						this.favorites.findIndex(favorite => favorite.entity._id === entity._id),
						1
					)

					if (this.textSearchFavorites.results.length) {
						this.textSearchFavorites.results.find(
							favorite => favorite.entity._id === entity._id
						).entity.isFavorite = bookmarked
						this.textSearchFavorites.results.splice(
							this.textSearchFavorites.results.findIndex(favorite => favorite.entity._id === entity._id),
							1
						)
					}
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},

		async handleChangeTab(tab) {
			this.tab = tab.value

			if (this.tab === 'Favorite') {
				this.favorites = []
				this.favoritesNext = null
				this.favoritesHasNext = null

				await this.fetchFavorites(true)
			}

			if (this.tab === 'Library') {
				this.loras = []
				this.next = null
				this.hasNext = null

				await this.fetchLoras(true)
			}
		},

		initSwiper() {
			this.swiperInstance = new Swiper('.lora-swiper', {
				modules: [FreeMode],
				direction: 'horizontal',
				spaceBetween: 8, // in pixels,
				slidesPerView: 'auto',
				grabCursor: true,
				freeMode: true,
			})
		},

		closeLoraSelection() {
			this.$emit('onClose')
		},
		onScrollPage() {
			let isScrolledToBottom = false

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight
			}

			if (isScrolledToBottom) {
				if (this.tab === 'Library') {
					this.textSearch.text ? this.fetchMoreLorasByText() : this.fetchLoras()
				} else {
					this.textSearchFavorites.text ? this.fetchMoreFavoritesByText() : this.fetchFavorites()
				}
			}
		},
		resetTextSearchData() {
			this.textSearch = {
				text: '',
				lastSearchedText: '',
				debounceTimeoutId: null,
				results: [],
				next: null,
				hasNext: null,
			}

			this.textSearchFavorites = {
				text: '',
				lastSearchedText: '',
				debounceTimeoutId: null,
				results: [],
				next: null,
				hasNext: null,
			}
		},

		async searchLorasByText(text) {
			this.loading = true

			clearTimeout(this.textSearch.debounceTimeoutId)
			if (this.textSearch.lastSearchedText === text) return

			if (!text?.length) {
				this.resetTextSearchData()
				this.loading = false
				return
			}

			this.textSearch.results = []
			this.textSearch.text = text

			const searchTimerInMs = 800

			this.textSearch.debounceTimeoutId = setTimeout(this.executeTextSearch, searchTimerInMs)
		},

		async fetchMoreLorasByText() {
			if (this.loading) return

			if (!this.textSearch.hasNext) {
				this.loading = false
				return
			}
			this.loading = true

			this.executeTextSearch()
		},

		async executeTextSearch() {
			const params = {
				next: this.textSearch.hasNext ? this.textSearch.next : undefined,
				searchText: this.textSearch.text,
				imageCreation: true,
			}

			try {
				const response = await this.axios.get(`/models`, { params }).then(res => res.data)
				this.textSearch.results = [...this.textSearch.results, ...response.results]
				this.textSearch.next = response.next
				this.textSearch.hasNext = response.hasNext
				this.textSearch.lastSearchedText = this.textSearch.text
			} catch (error) {
				this.sentryCaptureException(error)
			} finally {
				this.loading = false
			}
		},

		async fetchLoras(isFirstFetch) {
			if (this.loading || (!this.hasNext && !isFirstFetch)) return
			this.loading = true

			const params = {
				next: this.hasNext ? this.next : undefined,
				imageCreation: true,
			}

			try {
				const { data } = await this.axios.get('/models', { params })

				this.loras = [...this.loras, ...data.results]
				this.hasNext = data.hasNext
				this.next = data.next
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading = false
		},

		async searchFavoritesByText(text) {
			this.loading = true

			clearTimeout(this.textSearchFavorites.debounceTimeoutId)
			if (this.textSearchFavorites.lastSearchedText === text) return

			if (!text?.length) {
				this.resetTextSearchData()
				this.loading = false
				return
			}

			this.textSearchFavorites.results = []
			this.textSearchFavorites.text = text

			const searchTimerInMs = 800

			this.textSearchFavorites.debounceTimeoutId = setTimeout(this.executeTextSearchFavorites, searchTimerInMs)
		},

		async fetchMoreFavoritesByText() {
			if (this.loading) return

			if (!this.textSearchFavorites.hasNext) {
				this.loading = false
				return
			}
			this.loading = true

			this.executeTextSearchFavorites()
		},

		async executeTextSearchFavorites() {
			const params = {
				next: this.textSearchFavorites.hasNext ? this.textSearchFavorites.next : undefined,
				searchQuery: this.textSearchFavorites.text,
				public: true,
			}

			try {
				const response = await this.axios.get(`/favorites/model`, { params }).then(res => res.data)
				this.textSearchFavorites.results = [...this.textSearchFavorites.results, ...response.results]
				this.textSearchFavorites.next = response.next
				this.textSearchFavorites.hasNext = response.hasNext
				this.textSearchFavorites.lastSearchedText = this.textSearchFavorites.text
			} catch (error) {
				this.sentryCaptureException(error)
			} finally {
				this.loading = false
			}
		},

		async fetchFavorites(isFirstFetch) {
			if (this.loading || (!this.favoritesHasNext && !isFirstFetch)) return
			this.loading = true

			const params = {
				next: this.favoritesHasNext ? this.favoritesNext : undefined,
			}

			try {
				const { data } = await this.axios.get('/favorites/model', { params })

				this.favorites = [...this.favorites, ...data.results]
				this.favoritesHasNext = data.favoritesHasNext
				this.favoritesNext = data.favoritesNext
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.loading = false
		},
	},
	computed: {
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		filteredLoras() {
			if (this.textSearch.text) {
				return this.textSearch.results
			}
			return this.loras
		},
		filteredFavorites() {
			if (this.textSearchFavorites.text) {
				return this.textSearchFavorites.results
			}
			return this.favorites
		},
	},
}
</script>
