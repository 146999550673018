<template>
	<div>
		<div class="page-container pt-9 pb-6">
			<Header :title="$t('myAccountLanguage.changeLanguage')" />

			<div>
				<Select
					:options="languageOptions"
					:value="selectedLang || loggedUser?.lang"
					:menuTitle="$t('myAccountLanguage.title')"
					@onChange="onChangeLanguage"
					class="mt-4"
				/>

				<Button @click="handleUpdateLanguage" :isLoading="isSavingSettings" class="mt-8">
					<span> {{ $t('myAccountSettings.saveButton') }}</span>
				</Button>
			</div>
		</div>
	</div>
</template>

<script>
import { Select, Button, Header } from '@/components/default'

export default {
	components: { Select, Button, Header },

	data() {
		return {
			isSavingSettings: false,
			selectedLang: '',
			languageOptions: [
				{
					label: 'English',
					value: 'en-US',
				},
				{
					label: 'Portuguese (Brazil)',
					value: 'pt-BR',
				},
				{
					label: 'Spanish',
					value: 'es-ES',
				},
			],
		}
	},

	methods: {
		onChangeLanguage(langValue) {
			this.selectedLang = langValue
		},

		async handleUpdateLanguage() {
			if (this.isSavingSettings) return
			this.isSavingSettings = true

			try {
				await this.updateLanguage()

				this.$toast({
					title: this.$t('common.toastTitle.success'),
					message: this.$t('myAccountSettings.toastMessage.success'),
					type: 'success',
				})

				this.captureEvent('settings_updated_success')
			} catch (error) {
				this.sentryCaptureException(error)

				this.$toast({
					title: this.$t('common.toastTitle.error'),
					message: this.$t('myAccountSettings.toastMessage.error'),
					type: 'error',
				})
				this.captureEvent('settings_updated_error')
			}

			this.isSavingSettings = false
		},

		async updateLanguage() {
			if (!this.selectedLang || this.selectedLang === this.loggedUser?.lang) {
				return
			}

			try {
				await this.axios.put('/users/language', {
					lang: this.selectedLang,
				})

				this.$i18n.locale = this.selectedLang
				this.$store.commit('setLanguage', this.selectedLang)
				this.captureEvent('settings_change_language_success')
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('settings_change_language_error')
			}
		},
	},

	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
	},

	head: {
		title: `RealityStudio - Language`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Language`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
