import { Capacitor } from '@capacitor/core'
export default {
	install(app) {
		app.config.globalProperties.$takePicture = async () => {
			if (Capacitor.getPlatform() !== 'web') {
				console.log('Taking picture with Capacitor')
				try {
					const { Camera, CameraResultType } = await import('@capacitor/camera')
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: false,
						resultType: CameraResultType.DataUrl,
					})

					return image.dataUrl
				} catch (error) {
					console.error('Error taking picture with Capacitor', error)
				}
				return null
			}
		}
	},
}
