<template>
	<div>
		<AdminHeader />
		<div class="page-container my-6">
			<div class="flex item-center mb-8">
				<button @click="$router.push('/admin/plans')" class="material-icons text-[32px]">
					keyboard_backspace
				</button>
				<span class="text-3xl font-bold ml-1"
					>{{ $t(`admin.managePlan.${newPlan ? 'createPlan' : 'editPlan'}`) }}
				</span>
			</div>
			<div class="grid grid-cols-1 md:grid-cols-2">
				<div class="px-4">
					<label class="cursor-pointer border flex rounded-lg border-gray-800 py-6 px-4" for="planImage">
						<Spinner v-if="loading.image" class="mx-auto" />
						<div v-else class="flex items-center">
							<span class="material-icons text-2xl mr-2">add_circle</span>
							<p class="font-bold">{{ $t('admin.managePlan.addIcon') }}</p>
						</div>
					</label>
					<FileInput
						inputAccept="image/svg,image/jpeg,image/png"
						@onFileSelect="file => onFileSelect(file[0])"
						inputId="planImage"
						:style="{
							display: 'none',
						}"
					/>
					<Select
						:options="languageOptions"
						:value="selectedLang"
						:menuTitle="$t('admin.managePlan.planLanguage')"
						@onChange="value => (selectedLang = value)"
						class="my-8"
					/>
					<h2 class="text-lg font-bold leading-7 text-white mb-8">
						{{ $t('admin.managePlan.language') }} : {{ selectedLang }}
					</h2>
					<Input
						id="planName"
						:label="$t('admin.managePlan.planName')"
						class="my-8"
						v-model="plan.name[selectedLang]"
						labelClass="font-bold"
					/>
					<h2 class="mb-6 text-lg leading-7 font-bold">{{ $t('admin.managePlan.addFeatures') }}</h2>
					<div class="mb-8">
						<div v-for="(feature, index) in plan.features" :key="index" class="flex flex-row items-end">
							<Input
								id="feature"
								:label="`${$t('admin.managePlan.feature')} (${index + 1})`"
								class="mt-8 w-full"
								v-model="plan.features[index][selectedLang]"
								labelClass="font-bold"
							/>
							<Button
								color="outline-gray"
								type="button"
								@click="removeFeatureRow(index)"
								size="fit"
								class="p-6 ml-8"
							>
								<span class="material-icons"> delete</span></Button
							>
						</div>
						<Button color="outline-gray" type="button" @click="addFeatureRow" class="mt-6">
							<span class="material-icons text-2xl"> add_circle</span></Button
						>
					</div>
					<h2 class="mb-4 font-bold">{{ $t('admin.managePlan.financial.title') }}</h2>
					<div class="p-6 border border-gray-900 mb-6">
						<Select
							:options="billingCycleOptions"
							:value="plan.billingCycle.unit"
							:menuTitle="$t('admin.managePlan.financial.planCycle')"
							@onChange="value => (plan.billingCycle.unit = value)"
						/>
						<div class="flex flex-row my-6">
							<Input
								id="price"
								labelClass="font-bold"
								:label="`${currencyPerLanguage[selectedLang]}`"
								v-model="plan.priceByCurrency[currencyPerLanguage[selectedLang].toLowerCase()]"
								class="mr-4 w-full"
							/>
							<Input
								id="frequency"
								labelClass="font-bold"
								:label="$t('admin.managePlan.financial.frequency')"
								v-model="plan.billingCycle.frequency"
								class="w-full"
							/>
						</div>
						<div class="flex flex-row">
							<Input
								id="points"
								labelClass="font-bold"
								:label="$t('admin.managePlan.financial.points')"
								v-model="plan.points"
								class="w-full mr-4"
							/>
							<Input
								id="bonusPoints"
								labelClass="font-bold"
								:label="$t('admin.managePlan.financial.bonusPoints')"
								v-model="plan.bonusPoints"
								class="w-full"
							/>
						</div>
					</div>
					<h2 class="mb-4 font-bold">{{ $t('admin.managePlan.planDescription') }}</h2>
					<textarea
						class="py-6 px-4 mb-8 text-area-height border-gray-900 w-full rounded-md bg-app-blue border mt-2 resize-none h-[100px]"
						v-model="plan.description[selectedLang]"
						type="text"
						:placeholder="$t('admin.managePlan.planDescriptionPlaceholder')"
					/>
					<Button type="button" @click="savePlan" :isLoading="loading.save">
						{{ $t('admin.managePlan.save') }}</Button
					>
				</div>
				<div class="px-4">
					<div class="fixed w-2/5 pr-6">
						<div class="border border-gray-900 py-8 rounded-xl">
							<div class="border border-gray-900 py-6 px-4 w-[400px] mx-auto rounded-xl">
								<div>
									<span class="text-sm leading-5 mb-1">{{
										$t('admin.managePlan.planCard.includedWith')
									}}</span>
									<div class="flex items-center mb-6">
										<img :src="plan.image" class="w-[24px] h-[24px]" alt="Icon" v-if="plan.image" />
										<span class="material-icons" v-if="!plan.image">add_circle</span>
										<p class="text-2xl ml-2 leading-8">
											{{
												plan.name[selectedLang]
													? plan.name[selectedLang]
													: $t('admin.managePlan.planCard.name')
											}}
										</p>
									</div>
									<div class="mb-4">
										<div
											class="flex items-center mb-4"
											v-for="(feature, index) in plan.features"
											:key="index"
										>
											<span class="material-icons text-blue-600 w-[24px] h-[24px] mr-4"
												>done</span
											>
											<p>
												{{ feature[selectedLang] ? feature[selectedLang] : '-' }}
											</p>
										</div>
									</div>
									<div class="mb-8">
										<p class="text-sm leading-5 text-gray-300 text-balance break-words">
											{{
												plan.description && plan.description[selectedLang]
													? plan.description[selectedLang]
													: $t('admin.managePlan.planCard.description')
											}}
										</p>
									</div>
									<Button>
										{{ $t('admin.managePlan.planCard.only') }}
										{{ plan.priceByCurrency[currencyPerLanguage[selectedLang].toLowerCase()] }}
										{{ currencyPerLanguage[selectedLang] }} /
										{{ getPlanCycleTranslation(plan.billingCycle.unit) }}</Button
									>
								</div>
							</div>
							<div class="mt-6 font-bold w-[400px] px-4 mx-auto">
								<p class="">USD: $ {{ plan.priceByCurrency.usd }}</p>
								<p class="mt-1">BRL: R$ {{ plan.priceByCurrency.brl }}</p>
								<p class="mt-1">{{ $t('admin.managePlan.financial.points') }} : {{ plan.points }}</p>
								<p class="mt-1">
									{{ $t('admin.managePlan.financial.bonusPoints') }} : {{ plan.bonusPoints }}
								</p>
								<p class="mt-1">
									{{ $t('admin.managePlan.totalPoints') }} :
									{{ parseInt(plan.points) + parseInt(plan.bonusPoints) }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { AdminHeader } from '@/components/admin'
import { Input, Button, Select, FileInput, Spinner } from '@/components/default'
import { inject, ref } from 'vue'
import { useRoute } from 'vue-router'

export default {
	components: {
		Select,
		Input,
		Button,
		AdminHeader,
		FileInput,
		Spinner,
	},
	async setup() {
		const axios = inject('axios')
		const route = useRoute()
		if (route.query && route.query.id) {
			const plan = await axios.get(`/plans/${route.query.id}?simple=true`).then(res => res.data)
			return { plan: ref(plan), newPlan: false }
		}

		return {
			plan: ref({
				name: {
					'en-US': '',
					'pt-BR': '',
					'es-ES': '',
				},
				priceByCurrency: {
					brl: '',
					usd: '',
				},
				points: '',
				bonusPoints: '',
				mostPopular: false,
				features: [
					{
						'en-US': '',
						'pt-BR': '',
						'es-ES': '',
					},
				],
				billingCycle: { unit: 'monthly', frequency: 1 },
				disabled: true,
				image: '',
				description: {
					'en-US': '',
					'pt-BR': '',
					'es-ES': '',
				},
			}),
			newPlan: true,
		}
	},
	data() {
		return {
			selectedLang: 'en-US',
			languageOptions: [
				{
					label: this.$t('admin.managePlan.languages.english'),
					value: 'en-US',
				},
				{
					label: this.$t('admin.managePlan.languages.portuguese'),
					value: 'pt-BR',
				},
				{
					label: this.$t('admin.managePlan.languages.spanish'),
					value: 'es-ES',
				},
			],
			currencyPerLanguage: {
				'en-US': 'USD',
				'pt-BR': 'BRL',
				'es-ES': 'USD',
			},
			billingCycleOptions: [
				{
					label: this.$t('admin.managePlan.financial.monthly'),
					value: 'monthly',
				},
				{
					label: this.$t('admin.managePlan.financial.yearly'),
					value: 'yearly',
				},
			],
			loading: {
				image: false,
				save: false,
			},
		}
	},
	methods: {
		async savePlan() {
			if (this.loading.save) return
			this.loading.save = true
			try {
				if (this.newPlan) {
					await this.createPlan(this.plan)
				} else {
					await this.editPlan(this.plan)
				}
			} catch (error) {
				console.error(error)
			}
			this.loading.save = false
		},
		async createPlan(plan) {
			try {
				const newPlan = await this.axios.post('/plans', plan).then(res => res.data)
				this.plan = newPlan
			} catch (error) {
				console.error(error)
			}
		},
		async editPlan(plan) {
			try {
				await this.axios.put(`/plans/${plan._id}`, plan).then(res => res.data)
			} catch (error) {
				console.error(error)
			}
		},
		async onFileSelect(file) {
			if (this.loading.image) return
			this.loading.image = true

			try {
				if (file) {
					const { url, key } = await this.axios.get(`/files/sign-url/${file.name}`).then(res => res.data)
					await this.axios.put(url, file)
					this.plan.image = await this.axios.post(`/files/cloudfront-url`, { key }).then(res => res.data)
				}
			} catch (error) {
				this.error = error
			}

			this.loading.image = false
		},
		addFeatureRow() {
			this.plan.features.push({
				'en-US': '',
				'pt-BR': '',
				'es-ES': '',
			})
		},
		removeFeatureRow(index) {
			this.plan.features.splice(index, 1)
		},
		getPlanCycleTranslation(unit) {
			return this.$t(`admin.managePlan.financial.${unit}`)
		},
	},
	head: {
		title: `RealityStudio - Manage Plan`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Manage Plan`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>