<template>
	<div class="page-container mt-8">
		<h1 class="font-bold text-2xl mb-6 text-center">Page Not Found</h1>

		<p class="text-center font-medium font-xl">
			Sorry, we couldn't find what you were looking for.
			<br />
			If you think this is an error, please
			<a class="underline" href="https://linktr.ee/realityavatar" target="_blank">contact support</a>
		</p>

		<Button @click="captureEvent('not_found_goto_home')" href="/" class="mt-6">Back to home</Button>
	</div>
</template>

<script>
import { Button } from '@/components/default'

export default {
	components: {
		Button,
	},
	head: {
		title: `RealityStudio - Not Found`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Not Found`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
