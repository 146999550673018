<template>
	<div class="flex flex-col gap-2 py-4">
		<h1>Privacy Policy for RealityStudio</h1>

		<p>Last Updated: Jan of 2024</p>

		<h2>1. Introduction</h2>

		<p>
			Welcome to RealityStudio! This Privacy Policy outlines how we collect, use, disclose, and protect the
			information obtained from users of our mobile application. By using RealityStudio, you agree to the terms
			outlined in this policy.
		</p>

		<h2>2. Information We Collect</h2>

		<h3>2.1 Personal Information:</h3>

		<p>
			We may collect personal information, such as your name, email address, and profile picture when you create
			an account or customize your experience.
		</p>

		<h3>2.2 User-Generated Content:</h3>

		<p>
			RealityStudio allows users to generate pictures using AI. Any pictures or content created by users are
			considered user-generated content and may be stored on our servers.
		</p>

		<h3>2.3 Device Information:</h3>

		<p>
			We may collect information about your device, including the device type, operating system, and unique device
			identifiers.
		</p>

		<h3>2.4 Usage Information:</h3>

		<p>
			We collect information about how you use RealityStudio, including the features you interact with, the
			duration of use, and any error messages.
		</p>

		<h2>3. How We Use Your Information</h2>

		<h3>3.1 Personalization:</h3>

		<p>
			We use your information to personalize your experience within the app, providing you with customized
			AI-generated pictures.
		</p>

		<h3>3.2 Communication:</h3>

		<p>
			We may use your email address to send you important updates, announcements, and marketing communications.
			You can opt-out of marketing communications at any time.
		</p>

		<h3>3.3 Improvement of Services:</h3>

		<p>
			We analyze user behavior and feedback to enhance and improve the functionality and features of RealityStudio.
		</p>

		<h2>4. Data Sharing and Disclosure</h2>

		<h3>4.1 Third-Party Service Providers:</h3>

		<p>
			We may share your information with third-party service providers who assist us in providing and improving
			our services. These providers are obligated to protect your information.
		</p>

		<h3>4.2 Legal Requirements:</h3>

		<p>We may disclose your information if required to do so by law or in response to a valid legal request.</p>

		<h2>5. Security</h2>

		<p>
			We employ industry-standard security measures to protect your information from unauthorized access,
			disclosure, alteration, and destruction.
		</p>

		<h2>6. Your Choices</h2>

		<p>
			You can manage your communication preferences and opt-out of certain data processing activities. However,
			some features of the app may require certain information to function properly.
		</p>

		<h2>7. Children's Privacy</h2>

		<p>
			RealityStudio is not intended for children under the age of 13. We do not knowingly collect or solicit
			personal information from children.
		</p>

		<h2>8. Changes to this Privacy Policy</h2>

		<p>
			We may update this Privacy Policy to reflect changes in our practices. We will notify users of any material
			changes through the app or other means.
		</p>

		<h2>9. Contact Us</h2>

		<p>
			If you have any questions or concerns about this Privacy Policy, please contact us at contact@realitystudio.ai.
		</p>
	</div>
</template>
<script>
export default {
	head: {
		title: `RealityStudio - Privacy Policy`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Privacy `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
