<template>
	<div
		class="px-4 pt-4 z-[200] h-screen overflow-y-auto fixed top-0 left-1/2 bg-app-blue md:max-w-[592px] -translate-x-1/2 w-full"
		v-if="displayModal"
	>
		<div class="relative">
			<Button
				color="transparent"
				size="fit"
				class="mb-6 w-full justify-end flex"
				itemPosition="none"
				@click="closeModal"
			>
				<span class="material-symbols-outlined text-[32px]">close</span>
			</Button>
			<div class="mb-4">
				<h2 class="text-2xl leading-8 font-bold mb-4 text-center">{{ $t('subscribeOfferModal.title') }}</h2>
				<img
					src="/web/images/presets-and-tools/subscribe-offer-modal.jpg"
					alt="subscribe-banner"
					class="w-full object-cover mb-4 rounded-xl"
				/>
				<p class="text-lg leading-7 text-center">
					{{ $t('subscribeOfferModal.description') }}
				</p>
			</div>
			<div class="mb-6">
				<div
					v-for="(benefit, index) in benefits"
					:key="index"
					class="rounded-xl mb-4 border border-radial-1-card"
				>
					<div
						:class="{
							'rounded-xl bg-gradient-to-r from-lime-500 via-purple-500 to-cyan-500 border-padding':
								benefit.highlight,
						}"
					>
						<div class="flex h-full w-full items-center bg-app-blue back px-6 py-6 rounded-xl">
							<img :src="benefit.image" :alt="`benefit-${index + 1}`" class="mr-4" />
							<strong class="text-white leading-6">{{ benefit.text }}</strong>
						</div>
					</div>
				</div>
			</div>
			<h2 class="text-lg leading-7 text-white mb-4 text-center">
				{{ $t('subscribeOfferModal.benefitsFooter') }} <strong>{{ getLowestPlanInfo }}</strong>
			</h2>
			<div class="pb-4">
				<PlanCard :plan="plan" :showButton="false" />
			</div>
			<div
				class="floating-width w-auto md:px-4 py-2 sticky bottom-0 md:mb-6 z-20 md:-ml-px bg-app-blue border-t border-radial-1-card md:border-0"
			>
				<Button @click="handleSubscribe"
					>{{ $t('subscribeOfferModal.subscribeButton') }} {{ getLowestPlanInfo }}
				</Button>
				<Button @click="onViewPlans" color="transparent">{{ $t('subscribeOfferModal.viewAllPlans') }}</Button>
			</div>
		</div>
	</div>
</template>

<script>
import { Button, PlanCard, FloatingButton } from '@/components/default'
import { inject } from 'vue'

export default {
	components: {
		Button,
		PlanCard,
		FloatingButton,
	},
	data() {
		return {
			isCreatingSession: false,
			benefits: [
				{
					image: '/web/icons/subscribe-offer-modal/benefit-1.svg',
					text: this.$t('subscribeOfferModal.benefit1'),
					highlight: false,
				},
				{
					image: '/web/icons/subscribe-offer-modal/benefit-2.svg',
					text: this.$t('subscribeOfferModal.benefit2'),
					highlight: false,
				},
				{
					image: '/web/icons/subscribe-offer-modal/benefit-3.svg',
					text: this.$t('subscribeOfferModal.benefit3'),
					highlight: true,
				},
			],
			displayModal: false,
			defaultDisplayTimerInSeconds: 5,
		}
	},
	mounted() {
		this.$mitt.on(
			'openSubscribeOfferModal',
			async (displayTimerInMs = this.defaultDisplayTimerInSeconds * 1000) => {
				setTimeout(() => {
					this.displayModal = true
					this.updateTimeOfferModal()
				}, displayTimerInMs)
			}
		)
	},
	async setup() {
		const axios = inject('axios')
		const plans = await axios.get('/plans/').then(res => res.data)

		const plan = plans.reduce((prev, current) => (prev.price < current.price ? prev : current))
		return { plan }
	},
	methods: {
		closeModal() {
			this.displayModal = false
		},
		async onViewPlans() {
			await this.$router.push('/checkout/plans')
			this.displayModal = false
		},
		async handleSubscribe() {
			const plan = this.plan
			this.isCreatingSession = true

			this.captureEvent(`plans_subscribe_click_${plan.englishName.toLowerCase()}`)

			try {
				this.$router.push(`/checkout/payment-select?id=${plan._id}`)
				this.captureEvent('plans_subscribe_intent')
				this.sendTiktokInitCheckoutEvent(this.loggedUser)
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('plans_subscribe_error')

				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('checkoutPlans.errorToastMessage'),
				})
			}

			this.isCreatingSession = false
		},
		async updateTimeOfferModal() {
			if (!this.loggedUser) return

			try {
				await this.axios.put('/users/update-time-offer-modal')

				this.$store.commit('setUser', {
					...this.loggedUser,
					modals: {
						...this.loggedUser.modals,
						subscriptionOfferModalDisplayedAt: this.$dayjs(),
					},
				})
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
	},
	computed: {
		getLowestPlanInfo() {
			return `${this.$formatCurrency(this.plan.price)}/${this.$t(`common.monthly`)}`
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
}
</script>

<style scoped>
.border-padding {
	padding: 2px;
}
</style>
