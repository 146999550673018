<template>
	<div
		class="bg-app-blue z-50 py-6 border-t md:rounded-b-xl flex items-center justify-around border-radial-1-card w-full h-24 footerNavigation"
		v-if="displayFooter"
	>
		<router-link
			@click.native="captureEvent(`footer_tabs_goto_${tab.id}`)"
			v-for="tab in tabs"
			:key="tab.id"
			:to="tab.href"
			class="flex flex-col items-center justify-center relative btnFooter"
			:class="{
				'text-footer-active-link': tab.active,
			}"
		>
			<span class="material-icons"> {{ tab.icon }} </span>
			<span class="text-sm font-medium">{{ $t(tab.labelI18Key) }}</span>
			<!-- <div
				class="h-2 w-2 bg-red-500 rounded-full absolute top-0 right-1"
				v-if="tab.label === 'Menu' && hasUnreadNotifications"
			/> -->
			<div
				class="h-2 w-2 bg-red-500 rounded-full animate-pulse absolute top-0 right-1"
				v-if="
					tab.labelI18Key === 'footerTabs.menu' &&
					hasUnreadNotifications &&
					(hasProcessingActions.model || hasProcessingActions.prediction)
				"
			/>
			<div
				class="h-2 w-2 bg-gray-400 animate-pulse rounded-full absolute top-0 right-1"
				v-if="
					tab.labelI18Key === 'footerTabs.menu' &&
					!hasUnreadNotifications &&
					(hasProcessingActions.model || hasProcessingActions.prediction)
				"
			/>
		</router-link>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			tabs: [
				{
					id: 'my_account',
					labelI18Key: 'footerTabs.home',
					href: '/',
					icon: 'home',
					active: false,
				},
				{ id: 'explore', labelI18Key: 'footerTabs.explore', href: '/explore', icon: 'explore', active: false },
				{
					id: 'new_avatar',
					labelI18Key: 'footerTabs.create',
					href: '/images/create',
					icon: 'auto_awesome',
					active: false,
				},
				{
					id: 'menu',
					labelI18Key: 'footerTabs.menu',
					href: '/my-account/menu',
					activeHrefAlias: [
						'/my-account/notifications',
						'/my-account/library',
						'/my-account/settings',
						'/my-account/subscription',
					],
					icon: 'menu',
					active: false,
				},
			],
			disabledPaths: ['/checkout/plans'],
		}
	},
	created() {
		this.checkTabs()
	},
	methods: {
		checkTabs() {
			const currentPath = this.$route.path

			const isToolPage = currentPath.includes('/tools/')
			const isDisabled = this.disabledPaths.includes(currentPath) || isToolPage
			this.$store.commit('setIsDisplayingFooter', !isDisabled)

			this.tabs.forEach(tab => {
				const isActive = tab.href === currentPath
				const isAliasActive = tab.activeHrefAlias && tab.activeHrefAlias.includes(currentPath)

				tab.active = isActive || isAliasActive
			})
		},
	},
	computed: {
		displayFooter() {
			return this.$store.getters.getIsDisplayingFooter
		},
		hasProcessingActions() {
			return this.$store.getters.getHasProcessingActions
		},

		hasUnreadNotifications() {
			return this.$store.getters.getHasUnreadNotifications
		},
	},
	watch: {
		$route() {
			this.checkTabs()
		},
	},
}
</script>
<style>
/* .footerNavigation {
	.btnFooter {
		padding: 16px;
		width: 70px;
		border-radius: 20px;
		border: 1px solid #02070d;
		#02070D
		&:focus {
			border-radius: 26px;
			background-color: rgba(128, 128, 128, 0.021);
			border: 1px solid #2563eb;
			transform: scale(1);
			transition: all 0.5s;
		}
	}
} */
</style>
