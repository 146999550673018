<template>
	<div class="w-full animate-fade-up animate-duration-300" ref="root">
		<div class="rounded-xl" :class="toastBackgroundColor">
			<div class="flex items-center p-4">
				<div class="rounded-full p-[18px] flex items-center justify-center mr-2" :class="getIconBackground">
					<span class="material-icons text-[32px]"> {{ icon }} </span>
				</div>
				<div>
					<p class="text-sm font-bold">{{ title }}</p>
					<p class="text-sm font-normal">{{ message }}</p>
				</div>
			</div>

			<div
				class="h-4 rounded-bl-xl w-1/2"
				:class="getTimerBackground"
				:style="{
					width: timerWidth,
				}"
			></div>
		</div>
	</div>
</template>

<script>
import { removeElement } from '../../plugins/toast'
import { render } from 'vue'

export default {
	name: 'Toast',
	props: {
		title: {
			type: String,
			required: true,
		},
		message: {
			type: String,
			required: true,
		},
		duration: {
			type: Number,
			required: true,
		},
		type: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			passedTime: 0,
			passedTimeInterval: null,
			closeTimeout: null,
		}
	},
	mounted() {
		this.closeTimeout = setTimeout(this.close, this.duration)

		this.passedTimeInterval = setInterval(() => {
			this.passedTime += 10
		}, 10)
	},
	unmounted() {
		clearInterval(this.passedTimeInterval)
	},
	methods: {
		close() {
			clearTimeout(this.closeTimeout)

			// unmounted the component
			const wrapper = this.$el
			render(null, wrapper)
			removeElement(wrapper)
		},
	},
	computed: {
		icon() {
			switch (this.type) {
				case 'success':
					return 'check_circle'
				case 'warning':
					return 'warning'
				case 'error':
					return 'cancel'
			}
		},
		toastBackgroundColor() {
			const colorsByToastType = {
				success: 'bg-green-800',
				warning: 'bg-yellow-800',
				error: 'bg-red-800',
			}

			return colorsByToastType[this.type]
		},
		getIconBackground() {
			const colorsByToastType = {
				success: 'bg-green-700',
				warning: 'bg-yellow-700',
				error: 'bg-red-900',
			}

			return colorsByToastType[this.type]
		},
		getTimerBackground() {
			const colorsByToastType = {
				success: 'bg-green-700',
				warning: 'bg-yellow-700',
				error: 'bg-red-900',
			}

			return colorsByToastType[this.type]
		},
		timerWidth() {
			const percent = (100 / this.duration) * this.passedTime
			return `${percent}%`
		},
	},
}
</script>
