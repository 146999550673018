import Register from '@/views/Register.vue'
import ForgetPassword from '@/views/ForgetPassword.vue'
import DeleteUser from '@/views/DeleteUser.vue'
import Login from '@/views/Login.vue'
import NotFound from '@/views/NotFound.vue'
import RedefinePassword from '@/views/RedefinePassword.vue'
import CheckoutProcessing from '@/views/checkout/Processing.vue'
import MyAccountLibrary from '@/views/myAccount/MyAccountLibrary.vue'
import MyAccountSettings from '@/views/myAccount/MyAccountSettings.vue'
import MyAccountLanguage from '@/views/myAccount/MyAccountLanguage.vue'
import NewAvatarPresetId from '@/views/newAvatar/NewAvatarPresetId.vue'
import NewAvatarTools from '@/views/newAvatar/NewAvatarTools.vue'
import NewAvatarBeSurprised from '@/views/newAvatar/NewAvatarBeSurprised.vue'
import authenticateUser from '../middleware/authenticateUser'
import authenticateGuest from '../middleware/authenticateGuest'
import authenticateAdmin from '../middleware/authenticateAdmin'
import checkHomepage from '../middleware/checkHomepage'
import ExpressCheckout from '@/views/checkout/ExpressCheckout.vue'
import CheckoutPlans from '@/views/checkout/Plans.vue'
import CheckoutPaymentSelect from '@/views/checkout/PaymentSelect.vue'
import MyAccountNotifications from '@/views/myAccount/MyAccountNotifications.vue'
import MyAccountHome from '@/views/myAccount/MyAccountHome.vue'
import MyAccountMenu from '@/views/myAccount/MyAccountMenu.vue'
import MyAccountSubscription from '@/views/myAccount/MyAccountSubscription.vue'
import AdminIndex from '@/views/admin/Index.vue'
import AdminCreate from '@/views/admin/Create.vue'
import AdminCreateDiffusionModel from '@/views/admin/CreateDiffusionModel.vue'
import AdminPresets from '@/views/admin/Presets.vue'
import AdminPresetsId from '@/views/admin/PresetsId.vue'
import AdminAnalytics from '@/views/admin/Analytics.vue'
import AdminModels from '@/views/admin/Models.vue'
import AdminNewsletter from '@/views/admin/Newsletter.vue'
import MyAccountDelete from '@/views/myAccount/MyAccountDelete.vue'
import MyAccountBalance from '@/views/myAccount/MyAccountBalance.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import RemoveBackground from '@/views/tools/RemoveBackground.vue'
import ManagePlan from '@/views/admin/plans/ManagePlan.vue'
import Plans from '@/views/admin/plans/Index.vue'
import ColorizeImage from '@/views/tools/ColorizeImage.vue'
import GenerateCaption from '@/views/tools/GenerateCaption.vue'
import RestoreImage from '@/views/tools/RestoreImage.vue'
import BabyTransform from '@/views/tools/BabyTransform.vue'
import DrawingToArt from '@/views/tools/DrawingToArt.vue'
import StickerTool from '@/views/tools/StickerTool.vue'
import GeneratePromptFromImage from '@/views/tools/GeneratePromptFromImage.vue'
import ImageCreationImageId from '@/views/imageCreation/ImageId.vue'
import ImageCreationCreation from '@/views/imageCreation/Creation.vue'
import GenerateLogoAnalysis from '@/views/tools/GenerateLogoAnalysis.vue'
import GenerateSocialMediaPostAnalysis from '@/views/tools/GenerateSocialMediaPostAnalysis.vue'
import FreeAvatar from '@/views/tools/FreeAvatar.vue'
import CancelNewsletter from '@/views/CancelNewsletter.vue'
import ExploreFeed from '@/views/explore/ExploreFeed.vue'
import ImageCreationGenerationLibrary from '@/views/imageCreation/GenerationLibrary.vue'
import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'
import NewAvatarLibrary from '@/views/newAvatar/NewAvatarLibrary.vue'
import Welcome from '@/views/Welcome.vue'
import Layout from '@/layout/default.vue'
import AdminLayout from '@/layout/admin.vue'
import ResearchIndex from '@/views/research/Index.vue'
import ResearchResult from '@/views/research/Result.vue'
import ResearchGallery from '@/views/research/Gallery.vue'
import LandingPage from '@/views/LandingPage.vue'
import Home from '@/views/Home.vue'
import ResearchGalleryId from '@/views/research/GalleryId.vue'

export default function createMyRouter(isSSR) {
	return createRouter({
		history: isSSR ? createMemoryHistory() : createWebHistory(),
		routes: [
			{
				path: '/admin',
				name: 'Admin',
				meta: {
					middleware: [authenticateAdmin],
				},
				component: AdminLayout,
				children: [
					{
						path: '',
						name: 'AdminIndex',
						component: AdminIndex,
					},
					{
						path: 'create',
						name: 'AdminCreate',
						component: AdminCreate,
					},
					{
						path: 'presets',
						name: 'AdminPresets',
						component: AdminPresets,
					},
					{
						path: 'presets/:id',
						name: 'AdminPresetId',
						component: AdminPresetsId,
					},
					{
						path: 'analytics',
						name: 'AdminAnalytics',
						component: AdminAnalytics,
					},
					{
						path: 'models',
						name: 'AdminModels',
						component: AdminModels,
					},
					{
						path: 'feedbacks',
						name: 'AdminFeedbacks',
						component: () => import('@/views/admin/Feedbacks.vue'),
					},
					{
						path: 'plans',
						name: 'Plans',
						component: Plans,
					},
					{
						path: 'plans/manage-plan',
						name: 'ManagePlan',
						component: ManagePlan,
					},
					{
						path: '/admin/create/diffusion-model',
						name: 'AdminCreateDiffusionModel',
						component: AdminCreateDiffusionModel,
					},
					{
						path: 'newsletter',
						name: 'AdminNewsletter',
						component: AdminNewsletter,
					},
				],
			},
			{
				path: '/lp',
				name: 'LandingPage',
				component: LandingPage,
			},
			{
				path: '/',
				name: 'Index',
				component: Layout,
				children: [
					{
						path: '/',
						name: 'Home',
						component: Home,
						meta: {
							middleware: [checkHomepage],
						},
					},
					{
						path: '/privacy-policy',
						name: 'PrivacyPolicy',
						component: PrivacyPolicy,
					},
					{
						path: '/new-avatar/library',
						name: 'NewAvatarLibrary',
						component: NewAvatarLibrary,
					},
					{
						path: '/new-avatar/:id',
						name: 'NewAvatarPresetId',
						component: NewAvatarPresetId,
					},
					{
						path: '/new-avatar/be-surprised',
						name: 'NewAvatarBeSurprised',
						component: NewAvatarBeSurprised,
					},
					{
						path: '/cancel-newsletter',
						name: 'CancelNewsletter',
						component: CancelNewsletter,
					},
					{
						path: '/confirm-delete-user/:token',
						name: 'confirmDeleteUser',
						component: DeleteUser,
					},
					{
						path: '/research',
						name: 'Research',
						meta: {
							middleware: [authenticateUser],
						},
						children: [
							{
								path: '',
								name: 'ResearchIndex',
								component: ResearchIndex,
							},
							{
								path: '/research/:search',
								name: 'ResearchResult',
								component: ResearchResult,
							},
							{
								path: '/research/gallery',
								name: 'ResearchGallery',
								component: ResearchGallery,
							},
							{
								path: '/research/gallery/:id',
								name: 'ResearchGalleryId',
								component: ResearchGalleryId,
							},
						],
					},
					{
						path: '/my-account',
						name: 'myAccount',
						meta: {
							middleware: [authenticateUser],
						},
						children: [
							{
								path: '',
								name: 'myAccountHome',
								component: MyAccountHome,
							},
							{
								path: 'menu',
								name: 'myAccountMenu',
								component: MyAccountMenu,
							},
							{
								path: 'notifications',
								name: 'myAccountNotifications',
								component: MyAccountNotifications,
							},
							{
								path: 'subscription',
								name: 'myAccountSubscription',
								component: MyAccountSubscription,
							},
							{
								path: 'settings',
								name: 'myAccountSettings',
								component: MyAccountSettings,
							},
							{ path: 'language', name: 'myAccountLanguage', component: MyAccountLanguage },
							{
								path: 'library',
								name: 'myAccountLibrary',
								component: MyAccountLibrary,
							},
							{
								path: 'settings/delete',
								name: 'myAccountDelete',
								component: MyAccountDelete,
							},
							{
								path: 'balance',
								name: 'myAccountBalance',
								component: MyAccountBalance,
							},
							{
								path: 'models',
								name: 'myAccountModels',
								component: () => import('@/views/myAccount/MyAccountModels.vue'),
							},
							{
								path: 'models/:id',
								name: 'myAccountModelId',
								component: () => import('@/views/myAccount/MyAccountModelId.vue'),
							},
						],
					},
					{
						path: '/login',
						name: 'login',
						component: Login,
						meta: {
							middleware: [authenticateGuest],
						},
					},
					{
						path: '/welcome',
						name: 'welcome',
						component: Welcome,
					},
					{
						path: '/create-account',
						name: 'createAccount',
						component: Register,
						meta: {
							middleware: [authenticateGuest],
						},
					},
					{
						path: '/forget-password',
						name: 'forgetPassword',
						component: ForgetPassword,
						meta: {
							middleware: [authenticateGuest],
						},
					},
					{
						path: '/redefine-password',
						name: 'redefinePassword',
						component: RedefinePassword,
						meta: {
							middleware: [authenticateGuest],
						},
					},
					{
						path: '/checkout',
						name: 'Checkout',
						meta: {
							middleware: [authenticateUser],
						},
						children: [
							{
								path: '',
								name: 'Checkout',
								component: ExpressCheckout,
							},
							{
								path: 'plans',
								name: 'checkoutPlans',
								component: CheckoutPlans,
							},
							{
								path: 'processing',
								name: 'checkoutProcessing',
								component: CheckoutProcessing,
							},
							{
								path: 'payment-select',
								name: 'checkoutPaymentSelect',
								component: CheckoutPaymentSelect,
							},
						],
					},
					{
						path: '/tools',
						name: 'Tools',
						children: [
							{
								path: '',
								name: 'Tools',
								component: NewAvatarTools,
							},
							{
								path: 'remove-background',
								name: 'RemoveBackground',
								component: RemoveBackground,
							},
							{
								path: 'colorize-image',
								name: 'ColorizeImage',
								component: ColorizeImage,
							},
							{
								path: 'restore-image',
								name: 'RestoreImage',
								component: RestoreImage,
							},
							{
								path: 'drawing-to-art',
								name: 'DrawingToArt',
								component: DrawingToArt,
							},
							{
								path: 'generate-baby',
								name: 'BabyTransform',
								component: BabyTransform,
							},
							{
								path: 'sticker',
								name: 'StickerTool',
								component: StickerTool,
							},
							{
								path: 'generate-caption',
								name: 'GenerateCaption',
								component: GenerateCaption,
							},
							{
								path: 'generate-prompt-from-image',
								name: 'GeneratePromptFromImage',
								component: GeneratePromptFromImage,
							},
							{
								path: 'free-avatar',
								name: 'FreeAvatar',
								component: FreeAvatar,
							},
							{
								path: 'generate-logo-analysis',
								name: 'GenerateLogoAnalysis',
								component: GenerateLogoAnalysis,
							},
							{
								path: 'generate-social-media-post-analysis',
								name: 'GenerateSocialMediaPostAnalysis',
								component: GenerateSocialMediaPostAnalysis,
							},
						],
					},
					{
						path: '/images',
						name: 'ImageCreation',
						children: [
							{
								path: ':imageId',
								name: 'ImageCreationImageId',
								component: ImageCreationImageId,
							},
							{
								path: 'create',
								name: 'ImageCreationCreation',
								component: ImageCreationCreation,
							},
							{
								path: 'library',
								name: 'ImageCreationGenerationLibrary',
								component: ImageCreationGenerationLibrary,
							},
						],
					},
					{
						path: '/:catchAll(.*)',
						name: 'notFound',
						component: NotFound,
					},
					{
						path: '/explore',
						name: 'ExploreFeed',

						children: [{ path: '', name: 'ExploreFeed', component: ExploreFeed }],
					},
				],
			},

			{
				path: '/:catchAll(.*)',
				name: 'notFound',
				component: NotFound,
			},
		],
	})
}
