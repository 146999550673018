import Hotjar from '@hotjar/browser'

export default {
	install(app) {
		if (import.meta.env.DEV) {
			return
		}

		const siteId = 3842387
		const hotjarVersion = 6

		Hotjar.init(siteId, hotjarVersion)
	},
}
