<template>
	<div
		v-if="loggedUser?.hasSubscription"
		class="bg-gradient-to-t to-transparent bg-origin-border rounded-xl border-radial-1-card border-[1px] box-content py-4 px-6"
		:class="loggedUser.points === 0 ? 'from-red-600' : 'from-blue-600'"
	>
		<div class="text-center">
			<h1 class="font-lg">
				<span class="font-bold uppercase">{{ userSubscription?.name }}</span>
				{{ $t('myAccountSubscription.title') }}
			</h1>
			<h1 class="text-3xl font-bold mt-4">{{ loggedUser.points }}</h1>
			<p class="mt-2">{{ $t('common.realityPoints') }}</p>

			<Button href="/checkout/plans" class="mt-4 flex">
				<span class="font-bold text-sm">{{ $t('myAccountHome.getPoints') }}</span>
			</Button>
		</div>
	</div>
</template>

<script>
import { Button } from '@/components/default'

export default {
	data() {
		return {
			userSubscription: null,
		}
	},

	methods: {
		async getUserSubscription() {
			if (!this.loggedUser) return

			const subscription = await this.axios.get('/plans/active').then(res => res.data)

			this.userSubscription = subscription
		},
	},

	created() {
		this.getUserSubscription()
	},

	components: {
		Button,
	},

	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
}
</script>
