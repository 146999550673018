export default async function authenticateUser({ store }) {
	const isAuthenticated = !!store.getters.getUser

	
	if (!isAuthenticated) {
		return '/login'
	}

	return true
}
