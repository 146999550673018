<template>
	<div>
		<AdminHeader />
		<div class="page-container mt-6 pb-8">
			<div class="flex items-center">
				<button @click="$router.push('/admin')" class="material-icons text-[32px]">keyboard_backspace</button>
				<span class="text-3xl font-bold ml-2">
					{{ $t('admin.presetsPage.title') }}
				</span>
			</div>
			<div class="grid-cols-12 grid gap-y-8 md:gap-x-8 lg:gap-8 mt-8">
				<Input
					id="search"
					class="col-span-12"
					:class="{
						'lg:col-span-6': !isDevOpened,
						'lg:col-span-10': isDevOpened,
					}"
					inputClass="h-full disabled:cursor-not-allowed"
					:placeholder="$t('admin.presetsPage.searchPlaceholder')"
					:disabled="isDevOpened"
					v-model="searchValue"
				/>

				<Button :isLoading="isImporting" class="lg:col-span-2 md:col-span-6 col-span-12" v-if="!isDevOpened">
					<label for="importFile" class="flex items-center cursor-pointer">
						<span class="material-icons text-gray-900 text-2xl">upload_file</span>
						<p class="text-gray-900 font-bold ml-2">
							{{ $t('admin.presetsPage.importBtn') }}
						</p>
					</label>
				</Button>
				<input
					id="importFile"
					type="file"
					name="file"
					accept=".csv"
					@change="e => importPreset(e.target.files[0])"
					style="display: none"
				/>

				<Button class="lg:col-span-2 md:col-span-6 col-span-12" color="outline" v-if="!isDevOpened">
					<span class="material-icons text-white text-2xl">download</span>
					<p class="text-white font-bold ml-2">
						{{ $t('admin.presetsPage.exportAllBtn') }}
					</p>
				</Button>

				<Button @click="exportSample" class="lg:col-span-2 col-span-12" v-if="!isDevOpened">
					<span class="material-icons text-2xl">download</span>
					<p class="font-bold ml-2">
						{{ $t('admin.presetsPage.exportSampleBtn') }}
					</p>
				</Button>

				<Button
					color="delete"
					class="lg:col-span-2 col-span-12"
					:disabled="!presets?.results?.length"
					@click="deleteDevPresets"
					v-if="isDevOpened"
				>
					<span class="material-icons text-2xl">delete</span>
					<p class="font-bold ml-2">
						{{ $t('admin.presetsPage.deleteAllBtn') }}
					</p>
				</Button>
			</div>
			<div class="grid grid-cols-2 lg:grid-cols-6 gap-6 my-6">
				<button
					v-for="filter in filterTypes"
					:disabled="isFetching"
					:key="filter"
					class="rounded-xl py-4 px-6 flex items-center justify-center disabled:cursor-not-allowed disabled:opacity-50"
					:class="{
						'bg-blue-600 font-bold': filterType === filter,
						'border border-gray-500': filterType !== filter,
					}"
					@click="filterType = filterType === filter ? null : filter"
				>
					<p class="text-white">
						{{ getFilterName(filter) }}
					</p>
				</button>
			</div>
			<div class="gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4" v-if="presets?.results?.length">
				<PresetCard v-for="preset in presets.results" :key="preset._id" :data="preset" />
			</div>
			<div v-if="!presets?.results?.length && !isFetching" class="flex items-center justify-center mt-8">
				<p class="text-2xl font-bold">{{ $t('admin.presetsPage.noPresetsFound') }}</p>
			</div>
			<div v-if="isFetching" class="w-full flex mt-8 items-center justify-center">
				<AnimatedSpinner />
			</div>
		</div>
	</div>
</template>

<script>
import AdminHeader from '@/components/admin/AdminHeader.vue'
import Button from '@/components/default/Button.vue'
import PresetCard from '@/components/admin/PresetCard.vue'
import Input from '@/components/default/Input.vue'
import { AnimatedSpinner, Spinner } from '../../components/default'
import Papa from 'papaparse'

const DESKTOP_PAGINATION_LIMIT = 8
const MOBILE_PAGINATION_LIMIT = 4

export default {
	components: { AdminHeader, Button, PresetCard, Input, Spinner, AnimatedSpinner },
	data() {
		return {
			presets: null,
			isFetching: false,
			loading: {
				deletingAll: false,
			},
			searchValue: '',
			filterType: null,
			isImporting: false,
			samplePresetData: {
				id: '6588c9ead888d3f149bbdd25',
				name_en_us: 'Wonderland',
				name_pt_br: 'Pais das Maravilhas',
				name_es_es: 'Pais das Maravilhas',
				images: 'https://cdn.realitystudio.ai/assets/images/avatars/wonderland.png',
				file: 'https://cdn.realitystudio.ai/safetensors/8b4f320e-ce91-46cf-9884-3db03811d90d.safetensors',
				prompt: '"<1> like a The Mad Hatter From Alice in Wonderland, portrait, (close-up head:1.3), (facing camera:1.3), (intricate details:1.2), style of <2>, hdr, (symmetrical eyes:1.3), digital painting, cinematic, elegant, harvest fall aura, 8k, cinematic illumination, focus on face, highly detailed"',
				negativePrompt:
					'"asymetrical eyes, uneven eyes, helmet, golden armor, (nfsw:1.3), cropped, out of frame, worst quality, (3d), (nude:1.3), jpeg artifacts, ugly, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, dehydrated, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers"',
				minSeed: '500000',
				maxSeed: '7000000',
				cfgScale: '3.5',
				loraScales: '0.6 | 0.1',
				steps: '50',
				scheduler: 'K_EULER_ANCESTRAL',
				modelVersion: '3132ea21666a311210b55615118763c46080d7fafdd649fb26b8856e43b0162c',
				price: '25',
			},
			filterTypes: ['published', 'draft', 'dev'],
		}
	},
	async created() {
		const params = {
			limit: this.paginationLimit,
		}

		const { data } = await this.axios.get('/admin/presets', { params })

		this.presets = data
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll)
	},
	computed: {
		isDevOpened() {
			return this.filterType === 'dev'
		},
		paginationLimit() {
			const isMobile = this.$store.getters.isMobile

			return isMobile ? MOBILE_PAGINATION_LIMIT : DESKTOP_PAGINATION_LIMIT
		},
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	},
	methods: {
		async deleteDevPresets() {
			if (this.loading.deletingAll || this.presets?.results?.length === 0) {
				return
			}

			this.loading.deletingAll = true

			try {
				await this.axios.delete('/admin/purge-development-presets')

				this.$toast({
					type: 'success',
					title: this.$t('common.toastTitle.success'),
					message: this.$t('admin.presetsPage.toastMessage.purgeDevelopment'),
				})

				this.presets = []
			} catch (error) {
				this.sentryCaptureException(error)

				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
				})
			}

			this.loading.deletingAll = false
		},
		getFilterName(filterType) {
			const translationKey = `admin.presetsPage.filters.${filterType.toLowerCase()}`

			if (!this.$te(translationKey)) {
				this.sentryCaptureException(new Error(`Translation key not found: ${translationKey}`))
				return filterType.charAt(0).toUpperCase() + filterType.slice(1)
			}

			return this.$t(translationKey)
		},
		async exportSample() {
			try {
				const titleKeys = Object.keys(this.samplePresetData)
				const csvData = Object.values(this.samplePresetData)
				const csvContent = [titleKeys, csvData].map(row => row.join(',')).join('\n')

				const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
				const objUrl = URL.createObjectURL(blob)

				const blobContent = await fetch(objUrl).then(async response => await response.blob())

				await this.$downloadFile(blobContent, 'presets-sample.csv')

				this.$toast({
					type: 'success',
					title: this.$t('common.toastTitle.success'),
					message: this.$t('admin.presetsPage.toastMessage.exportSampleSuccess'),
				})
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('admin.presetsPage.toastMessage.exportSampleError'),
				})
			}
		},
		async importSinglePreset(presetData) {
			if (!presetData) {
				return false
			}

			const {
				name_en_us,
				name_pt_br,
				name_es_es,
				images,
				file,
				prompt,
				negativePrompt,
				minSeed,
				maxSeed,
				cfgScale,
				loraScales,
				steps,
				price,
				scheduler,
				modelVersion,
			} = presetData

			if (
				!name_en_us ||
				!name_pt_br ||
				!name_es_es ||
				!images ||
				!prompt ||
				!negativePrompt ||
				!minSeed ||
				!maxSeed ||
				!cfgScale ||
				!loraScales ||
				!steps ||
				!scheduler ||
				!modelVersion ||
				!price
			) {
				return false
			}

			try {
				await this.axios.post('/presets', {
					_id: presetData.id,
					name: {
						'en-US': name_en_us,
						'pt-BR': name_pt_br,
						'es-ES': name_es_es,
					},
					images: images.split(';'),
					modelType: 'face',
					prompt,
					negativePrompt,
					loraScales: String(loraScales),
					cfgScale: String(cfgScale),
					minSeed: isNaN(minSeed) ? 1 : minSeed,
					maxSeed: isNaN(maxSeed) ? 99999999999999 : maxSeed,
					filesKeys: file,
					modelVersion,
					scheduler,
					price,
					steps,
					published: true,
				})

				return true
			} catch (error) {
				this.sentryCaptureException(error)
			}

			return false
		},
		async importPreset(file) {
			if (!file) {
				return
			}

			const reader = new FileReader()

			reader.onload = async event => {
				this.isImporting = true

				const csv = event.target.result
				const data = Papa.parse(csv, {
					header: true,
					dynamicTyping: true,
					skipEmptyLines: true,
				}).data
				const success = []
				const errors = []

				for (let i = 0; i < data.length; i++) {
					const imported = await this.importSinglePreset(data[i])

					if (imported) {
						success.push(data[i])
					} else {
						errors.push(data[i])
					}
				}

				if (errors.length) {
					this.$toast({
						type: 'error',
						title: this.$t('common.toastTitle.error'),
						message: this.$t('admin.presetsPage.toastMessage.importError', {
							presetsCount: errors.length,
						}),
					})
				}

				if (success.length) {
					this.$toast({
						type: 'success',
						title: this.$t('common.toastTitle.success'),
						message: this.$t('admin.presetsPage.toastMessage.importSuccess', {
							presetsCount: success.length,
						}),
					})

					this.getPresets(true)
				}

				this.isImporting = false
			}

			reader.readAsText(file)
		},
		handleScroll() {
			if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
				this.getPresets()
			}
		},
		async getPresets(forceFetch) {
			if (this.isFetching || (!forceFetch && !this.presets?.hasNext)) return
			this.isFetching = true

			try {
				const { data } = await this.axios.get('/admin/presets', {
					params: {
						limit: this.paginationLimit,
						next: this.presets?.next,
						search: this.searchValue ? this.searchValue : undefined,
						type: this.filterType ? this.filterType : undefined,
					},
				})

				this.presets.results = [...this.presets?.results, ...data.results]
				this.presets.next = data.next
				this.presets.hasNext = data.hasNext
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetching = false
		},
	},
	watch: {
		filterType(newValue, oldValue) {
			if (newValue === 'dev') {
				this.searchValue = ''
			}

			if (newValue !== oldValue) {
				this.presets.results = []
				this.presets.next = null
				this.getPresets(true)
			}
		},
		async searchValue(newValue, oldValue) {
			if (!newValue) {
				this.presets.results = []
				this.presets.next = null

				await this.getPresets(true)
			}

			if (newValue !== oldValue && newValue) {
				this.presets.results = []
				this.presets.next = null

				await this.getPresets(true)
			}
		},
	},
}
</script>
