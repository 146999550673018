<template>
	<div class="flex flex-col items-center justify-center">
		<h1 class="font-xl font-bold mb-4">{{ $t('followUs.title') }}</h1>
		<div class="flex items-center justify-center gap-6">
			<a v-for="item in socialMedias" target="_blank" :href="item.link" :key="item.label" class="cursor-pointer">
				<img :src="item.iconPath" :alt="item.label" class="w-8 h-8" />
			</a>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			socialMedias: [
				{
					label: 'Discord',
					iconPath: '/web/icons/discord.svg',
					link: 'https://discord.gg/realitykingdom',
				},
				{
					label: 'X',
					iconPath: '/web/icons/x.svg',
					link: 'https://x.com/realityavatar_',
				},
				{
					label: 'Instagram',
					iconPath: '/web/icons/instagram.svg',
					link: 'https://instagram.com/realityavatar_',
				},
			],
		}
	},
}
</script>
