<template>
	<div v-if="!!text">
		<div class="flex items-center">
			<span class="material-icons text-blue-600 w-[24px] h-[24px] mr-4">done</span>
			<p v-if="!isHtml">
				{{ computedText }}
			</p>
			<p v-else v-html="computedText"></p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['text', 'isHtml'],
	computed: {
		computedText() {
			return this.text[0].toUpperCase() + this.text.slice(1)
		},
	},
}
</script>
