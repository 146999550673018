<template>
	<div class="relative">
		<Input
			:label="label"
			:id="id"
			:type="hidePassword ? 'password' : 'text'"
			class="mt-2"
			v-model="passwordValue"
			@update:modelValue="onChange"
			:placeholder="placeholder"
			:required="required"
		/>

		<button class="w-fit absolute top-1/2 right-7 translate-y-1" @click.prevent="showPassword">
			<span class="material-icons">{{ hidePassword ? 'visibility_off' : 'visibility' }}</span>
		</button>
	</div>
</template>

<script>
import { Input } from '@/components/default'

export default {
	components: { Input },
	data() {
		return {
			hidePassword: true,
			passwordValue: '',
		}
	},
	props: {
		label: {
			type: String,
		},
		id: {
			type: String,
			required: true,
		},
		placeholder: {
			placeholder: String,
		},
		required: {
			type: Boolean,
		},
	},
	emits: ['update:passwordValue'],
	methods: {
		showPassword() {
			this.hidePassword = !this.hidePassword
		},
		onChange(value) {
			this.$emit('update:passwordValue', value)
		},
	},
}
</script>
