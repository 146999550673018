import { twMerge } from 'tailwind-merge'

const computeClasses = (el, binding, vNode) => {
	const existingClasses = el.classList.value
	const inheritedClasses = vNode?.ctx?.attrs

	// No need to run twMerge if there are no classes
	if (!existingClasses || !inheritedClasses) return

	// This works because all fallthrough classes are added at the end of the string
	el.classList.value = twMerge(existingClasses, inheritedClasses)
}

export const directive = {
	beforeMount: computeClasses,
	updated: computeClasses,
}

// the actual plugin
export default {
	install: app => {
		app.directive('twMerge', directive)
	},
}
