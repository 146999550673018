<template>
	<div>
		<AdminHeader />
		<div class="page-container mt-6 mb-10" v-if="analyticsDataItems.length">
			<div class="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
				<AnalyticsData
					v-for="item in analyticsDataItems"
					:key="item.description"
					class="col-span-1"
					:data="item"
				/>
			</div>

			<div class="mt-8 border-t-[1px] py-6 border-gray-900">
				<h2 class="text-lg font-bold">{{ $t('admin.index.quickMenu.title') }}</h2>
			</div>

			<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12">
				<MenuItem v-for="item in menuItems" :data="item" :key="item" />
			</div>
		</div>
		<Modal
			:displayModal="giveSubscriptionModal.isOpen"
			:title="$t('admin.index.modalGiveSubscription.title')"
			@onClose="giveSubscriptionModal.isOpen = false"
		>
			<div class="flex flex-col items-center">
				<img src="/web/images/admin/gift.png" alt="Gift" />
				<p class="text-center mt-4" v-html="$t('admin.index.modalGiveSubscription.description')"></p>

				<div class="mt-4 w-full">
					<Spinner v-if="!giveSubscriptionModal.plans.length" />
					<div v-else>
						<PlanCard
							class="mt-28 cursor-pointer first:mt-20"
							v-for="plan in giveSubscriptionModal.plans"
							:plan="plan"
							:key="plan._id"
							:isSelected="giveSubscriptionModal.selectedPlan == plan._id"
							@click="selectPlan(plan)"
						/>
					</div>
				</div>

				<Input
					class="w-full mt-6"
					id="email"
					v-model="giveSubscriptionModal.email"
					label="Email"
					:placeholder="$t('admin.index.modalGiveSubscription.emailPlaceholder')"
					type="email"
					required
					inputClass="border-gray-700 w-full"
				/>

				<Button
					class="disabled:opacity-60 disabled:cursor-not-allowed mt-4"
					:disabled="!giveSubscriptionModal.email || !giveSubscriptionModal.selectedPlan"
					@click="handleGiveSubscription"
					:isLoading="giveSubscriptionModal.loading"
				>
					{{ $t('admin.index.modalGiveSubscription.button') }}
				</Button>
			</div>
		</Modal>

		<Modal
			:displayModal="giveTestModelsModal.isOpen"
			:title="$t('admin.index.modalGiveTestModels.title')"
			@onClose="giveTestModelsModal.isOpen = false"
		>
			<div class="flex flex-col items-center">
				<img src="/web/images/admin/pictures.png" alt="Gift" />
				<p class="text-center">
					{{ $t('admin.index.modalGiveTestModels.description') }}
				</p>

				<Input
					class="w-full mt-6"
					id="email"
					v-model="giveTestModelsModal.email"
					label="Email"
					:placeholder="$t('admin.index.modalGiveTestModels.emailPlaceHolder')"
					type="email"
					required
					inputClass="border-gray-700 w-full"
				/>

				<Button
					@click="handleTestModelsCreation"
					class="disabled:opacity-60 disabled:cursor-not-allowed mt-4"
					:disabled="!giveTestModelsModal.email"
					:isLoading="giveTestModelsModal.loading"
				>
					{{ $t('admin.index.modalGiveTestModels.button') }}
				</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
import { Button, Select, Input, Spinner, Modal } from '@/components/default'
import { AdminHeader, AnalyticsData, MenuItem, PlanCard } from '@/components/admin'

/**
 * @todo: get the exchange rate from somewhere else so this can be as accurate as possible
 */
const EXCHANGE_RATE = 4.9

export default {
	components: {
		Button,
		Input,
		Spinner,
		AdminHeader,
		Select,
		AnalyticsData,
		MenuItem,
		Modal,
		PlanCard,
	},
	data() {
		return {
			analyticsDataItems: [],
			menuItems: [
				{
					title: this.$t('admin.index.quickMenu.generalAnalytics.title'),
					description: this.$t('admin.index.quickMenu.generalAnalytics.description'),
					image: '/web/images/admin/analytics-card.png',
					btnText: this.$t('admin.index.quickMenu.generalAnalytics.button'),
					btnAction: () => this.$router.push('/admin/analytics'),
				},

				{
					title: this.$t('admin.index.quickMenu.presetCreation.title'),
					description: this.$t('admin.index.quickMenu.presetCreation.description'),
					image: '/web/images/admin/create-theme-robot.png',
					btnText: this.$t('admin.index.quickMenu.presetCreation.button'),
					btnAction: () => this.$router.push('/admin/create'),
				},

				{
					title: this.$t('admin.index.quickMenu.giveTestModel.title'),
					description: this.$t('admin.index.quickMenu.giveTestModel.description'),
					image: '/web/images/admin/pictures.png',
					btnText: this.$t('admin.index.quickMenu.giveTestModel.button'),
					btnAction: () => (this.giveTestModelsModal.isOpen = true),
				},

				{
					title: this.$t('admin.index.quickMenu.giveSubscription.title'),
					description: this.$t('admin.index.quickMenu.giveSubscription.description'),
					image: '/web/images/admin/gift.png',
					btnText: this.$t('admin.index.quickMenu.giveSubscription.button'),
					btnAction: () => this.openGiveSubscriptionModal(),
				},

				{
					title: this.$t('admin.index.quickMenu.presetManagement.title'),
					description: this.$t('admin.index.quickMenu.presetManagement.description'),
					image: '/web/images/admin/pictures.png',
					btnText: this.$t('admin.index.quickMenu.presetManagement.button'),
					btnAction: () => this.$router.push('/admin/presets'),
				},

				{
					title: this.$t('admin.index.quickMenu.avatarFeedbacks.title'),
					description: this.$t('admin.index.quickMenu.avatarFeedbacks.description'),
					image: '/web/images/admin/pictures.png',
					btnText: this.$t('admin.index.quickMenu.avatarFeedbacks.button'),
					btnAction: () => this.$router.push('/admin/feedbacks'),
				},

				{
					title: this.$t('admin.index.quickMenu.viewModels.title'),
					description: this.$t('admin.index.quickMenu.viewModels.description'),
					image: '/web/images/admin/pictures.png',
					btnText: this.$t('admin.index.quickMenu.viewModels.button'),
					btnAction: () => this.$router.push('/admin/models'),
				},

				{
					title: this.$t('admin.index.quickMenu.plans.title'),
					description: this.$t('admin.index.quickMenu.plans.description'),
					image: '/web/images/admin/plans-card.png',
					btnText: this.$t('admin.index.quickMenu.plans.button'),
					btnAction: () => this.$router.push('/admin/plans'),
					imageClass: 'max-h-[200px] max-w-[150px] ml-8',
				},
			],

			giveSubscriptionModal: {
				isOpen: false,
				email: '',
				loading: false,
				plans: [],
				selectedPlan: null,
			},

			giveTestModelsModal: {
				isOpen: false,
				email: '',
				loading: false,
			},
			poolingInterval: 1000 * 10, //10 seconds
			poolingIntervalId: null,
		}
	},

	async created() {
		this.giveSubscriptionModal.email = this.loggedUser?.email
		this.giveTestModelsModal.email = this.loggedUser?.email

		this.analyticsDataItems = await this.fetchData()
	},

	methods: {
		async fetchData() {
			{
				const data = await this.axios.get('/admin/main-panel-analytics').then(res => res.data)
				let monthlyRevenue = {}

				if (data.monthlyRevenue.length) {
					monthlyRevenue.usd = data.monthlyRevenue.find(revenue => revenue.currency === 'usd')?.value || 0
					monthlyRevenue.brl = data.monthlyRevenue.find(revenue => revenue.currency === 'brl')?.value || 0
				}

				const monthlyRevenueValue = data.monthlyRevenue.length
					? (monthlyRevenue.brl + monthlyRevenue.usd * EXCHANGE_RATE).toFixed(2)
					: '0.00'

				return [
					{
						icon: 'attach_money',
						value: `R$ ${monthlyRevenueValue}`,
						description: this.$t('admin.index.analyticsDataItems.monthlyRevenue'),
					},

					{
						icon: 'bolt',
						value: data.newSubscriptions,
						description: this.$t('admin.index.analyticsDataItems.newSubscriptions7d'),
					},

					{
						icon: 'shopping_cart',
						value: data.activeSubscriptions,
						description: this.$t('admin.index.analyticsDataItems.activeSubscriptions'),
					},

					{
						icon: 'brush',
						value: data.imageFeedbackRate + '%',
						description: this.$t('admin.index.analyticsDataItems.positiveFeedbacks'),
					},
				]
			}
		},
		async pooling() {
			this.analyticsDataItems = await this.fetchData()
		},
		async openGiveSubscriptionModal() {
			this.giveSubscriptionModal.isOpen = true

			if (!this.giveSubscriptionModal.plans.length) {
				const { data } = await this.axios.get('/admin/plans')

				this.giveSubscriptionModal.plans = data
			}
		},

		selectPlan(plan) {
			this.giveSubscriptionModal.selectedPlan = plan._id
		},

		async handleTestModelsCreation() {
			this.giveTestModelsModal.loading = true
			try {
				await this.axios.post('/admin/give-models', {
					email: this.giveTestModelsModal.email,
				})

				this.$toast({
					type: 'success',
					title: this.$t('common.toastTitle.success'),
					message: this.$t('admin.presetsPage.toastMessage.giveTestModels'),
				})
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
				})
			}
			this.giveTestModelsModal.isOpen = false
			this.giveTestModelsModal.loading = false
		},
		async handleGiveSubscription() {
			this.giveSubscriptionModal.loading = true
			try {
				await this.axios.post('/admin/give-subscription', {
					email: this.giveSubscriptionModal.email,
					planId: this.giveSubscriptionModal.selectedPlan,
				})

				this.$toast({
					type: 'success',
					title: this.$t('common.toastTitle.success'),
					message: this.$t('admin.presetsPage.toastMessage.giveSubscriptionSuccess'),
				})
			} catch (error) {
				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
				})
			}
			this.giveSubscriptionModal.isOpen = false
			this.giveSubscriptionModal.loading = false
		},
	},

	mounted() {
		this.poolingIntervalId = setInterval(() => {
			this.pooling()
		}, this.poolingInterval)
	},
	beforeUnmount() {
		clearInterval(this.poolingIntervalId)
	},

	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
}
</script>
