<template>
	<div>
		<AppLoading v-show="isSSR" />
		<div v-show="!isSSR" class="md:mb-0 pb-6 md:pb-0" :class="{ 'mb-24': isAuthenticated }">
			<div v-if="isMobile" v-show="!isLoading" class="relative">
				<Suspense>
					<router-view />
				</Suspense>
				<Suspense>
					<slot></slot>
				</Suspense>
			</div>
			<div
				v-if="!isMobile"
				v-show="!isLoading"
				class="max-w-[592px] mx-auto border-2 border-gray-900 rounded-xl mt-6 relative desktop-container"
			>
				<div
					id="mockupWindow"
					class="mobile-mockup-scroll overflow-y-auto"
					:class="{
						authenticated: isAuthenticated,
					}"
				>
					<div
						class="max-w-[414px] mx-auto pb-6"
						:class="{
							'mb-24': isAuthenticated,
						}"
					>
						<Suspense>
							<router-view />
						</Suspense>
						<Suspense>
							<slot></slot>
						</Suspense>
					</div>
				</div>
				<FooterTabs class="justify-evenly absolute bottom-0" v-if="isAuthenticated"></FooterTabs>
			</div>
			<AppLoading v-if="isLoading" />
			<FooterTabs class="fixed bottom-0 md:hidden" v-if="isAuthenticated"></FooterTabs>
			<div v-if="!isSSR" :class="{ 'page-container': isMobile }">
				<FloatingButton class="z-50">
					<div class="flex flex-col gap-4" id="toast-container"></div>
				</FloatingButton>
			</div>
		</div>
		<Modal
			v-if="needAppUpdate"
			:displayModal="needAppUpdate"
			:title="$t('index.modalUpdateApp.title')"
			hideClose
			:canClose="false"
		>
			<h2 class="text-white text-xlg mb-6 text-center">{{ $t('index.modalUpdateApp.description') }}</h2>

			<Button @click="goToPlayStore">{{ $t('index.modalUpdateApp.updateButton') }} </Button>
		</Modal>
		<NewFeatureModal />
		<Suspense>
			<SubscribeOfferModal />
		</Suspense>
		<Suspense>
			<FeedbackModal />
		</Suspense>
	</div>
</template>

<script>
import { FloatingButton, FooterTabs, Modal, Button } from '../components/default'
import { useStore } from 'vuex'
import useDeviceDimensions from '../hooks/useDeviceDimensions'
import { watch } from 'vue'
import AppLoading from '../components/default/AppLoading.vue'
import NewFeatureModal from '../components/tools/NewFeatureModal.vue'
import SubscribeOfferModal from '../components/tools/SubscribeOfferModal.vue'
import FeedbackModal from '../components/account/settings/FeedbackModal.vue'
export default {
	setup() {
		const store = useStore()
		const { isMobile } = useDeviceDimensions()

		watch(isMobile, newVal => {
			store.commit('setIsMobile', newVal)
		})
	},
	data() {
		return {
			needAppUpdate: false,
		}
	},
	components: {
		FooterTabs,
		FloatingButton,
		Modal,
		Button,
		AppLoading,
		NewFeatureModal,
		SubscribeOfferModal,
		FeedbackModal,
	},
	async mounted() {
		if (!this.loggedUser) {
			const locale = await this.$getLocale()
			this.location = locale?.split('-')[1]
			this.$store.commit('setBrowserLang', locale)
			this.$i18n.locale = locale
		} else {
			this.location = this.loggedUser.location
			this.$i18n.locale = this.loggedUser.lang
			this.identifyUser(this.loggedUser)
		}

		await this.checkAppVersion()

		this.$addPushNotificationsListener()
	},
	methods: {
		async updateUserLocation() {
			try {
				if (this.loggedUser) {
					await this.axios.put('/users/location', {
						location: this.location,
					})
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		async checkAppVersion() {
			const { Capacitor } = await import('@capacitor/core')
			if (Capacitor.getPlatform() === 'web') return

			const { App } = await import('@capacitor/app')
			const { version: appVersion } = await App.getInfo()

			const response = await fetch('/android-app-version.json')
			const { version: serverVersion } = await response.json()

			const intAppVersion = parseInt(appVersion.replace(/\./g, ''))
			const intServerVersion = parseInt(serverVersion.replace(/\./g, ''))
			if (intServerVersion > intAppVersion) {
				this.needAppUpdate = true
			}
		},
		goToPlayStore() {
			window.open('https://play.google.com/store/apps/details?id=net.realityavatar.app&pcampaignid=web_share')
		},
	},
	watch: {
		isAuthenticated(newValue) {
			if (newValue) {
				this.$i18n.locale = this.loggedUser.lang
				this.identifyUser(this.loggedUser)
			}

			if (this.loggedUser && this.loggedUser.location !== this.location) {
				this.updateUserLocation()
				this.loggedUser.location = this.location
			}
		},
		'loggedUser.lang'(newValue) {
			this.$i18n.locale = newValue
		},
	},
	computed: {
		isSSR() {
			return import.meta.env.SSR
		},
		isAuthenticated() {
			return !!this.$store.getters.getUser
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		isLoading() {
			return this.$store.getters.getIsLoading
		},
	},
}
</script>

<style scoped>
.desktop-container {
	height: calc(100vh - 48px);
	max-height: 950px;
}

.mobile-mockup-scroll.authenticated {
	height: calc(100% - 96px);
}

.mobile-mockup-scroll {
	height: calc(100%) !important;
}
</style>
