<template>
	<div class="page-container">
		<AuthLogo />
		<!-- <Header :title="$t('signUpPage.title')"></Header> -->
		<div class="flex flex-col gap-4">
			<GoogleLogin :redirectTo="$route.query?.redirectTo" />
			<div class="flex items-center gap-2">
				<div class="flex-1 flex bg-gray-900 h-px rounded-xl"></div>
				<span class="text-sm leading-5 font-normal w-fit">
					{{ $t('signUpPage.or') }}
				</span>
				<div class="flex-1 flex bg-gray-900 h-px rounded-xl"></div>
			</div>
		</div>
		<form class="flex flex-col gap-6 mt-6" @submit.prevent="onSubmit">
			<Input
				id="email"
				v-model="email"
				:label="$t('signUpPage.emailLabel')"
				:placeholder="$t('signUpPage.emailPlaceholder')"
				type="email"
				required
			/>
			<PasswordInput
				:label="$t('signUpPage.passwordLabel')"
				:id="'password'"
				:placeholder="$t('signUpPage.passwordPlaceholder')"
				@update:passwordValue="value => (password = value)"
				required
				class="h-[120px]"
			/>
			<SubscribeToNewsletter class="mt-4" @onChange="value => (newsletterSubscription = value)" />
			<Checkbox id="terms" v-model="agreedWithTerms" required>
				<a href="https://realitystudio.ai/privacy-policy" target="_blank" v-html="$t('signUpPage.terms')" />
			</Checkbox>
			<div class="flex flex-col gap-4">
				<HCaptcha ref="hCaptcha" @onVerify="token => (captchaToken = token)" v-if="platform === 'web'" />
				<Button type="submit" :isLoading="loading">
					{{ $t('signUpPage.signUpButton') }}
				</Button>
				<div class="text-lg leading-7 w-fit mx-auto">
					{{ $t('signUpPage.alreadyHaveAccount') }}
					<router-link @click.native="captureEvent('register_goto_login')" class="font-bold" to="login">
						{{ $t('signUpPage.signIn') }}</router-link
					>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { SubscribeToNewsletter } from '@/components/account/settings'
import { HCaptcha, Checkbox, Input, Header, Button, PasswordInput } from '@/components/default'
import { Logo as AuthLogo } from '@/components/auth'
import GoogleLogin from '@/components/auth/GoogleLogin.vue'

export default {
	components: {
		Header,
		Input,
		Button,
		HCaptcha,
		Checkbox,
		AuthLogo,
		GoogleLogin,
		PasswordInput,
		SubscribeToNewsletter,
	},
	name: 'CreateAccount',
	data() {
		return {
			email: '',
			password: '',
			captchaToken: '',
			loading: false,
			agreedWithTerms: false,
			hidePassword: true,
		}
	},
	computed: {
		platform() {
			return this.$getPlatform()
		},
	},
	methods: {
		async onSubmit() {
			this.loading = true

			try {
				const locale = await this.$getLocale()

				const { user } = await this.axios
					.post('/auth/register', {
						email: this.email,
						password: this.password,
						token: this.captchaToken,
						locale,
						platform: this.platform,
						newsletterSubscription: this.newsletterSubscription,
					})
					.then(res => res.data)

				this.$store.commit('setUser', user)
				this.captureEvent('register_submit_success')
				this.sendTiktokCompleteRegistrationEvent(user)
				this.$requestPushNotifications()

				const redirectTo = this.$route.query?.redirectTo

				if (redirectTo) {
					this.$router.push(redirectTo)
					return
				}

				this.$router.push('/')
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('register_submit_error')

				if (this.platform === 'web') {
					this.$refs.hCaptcha.reset()
				}

				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('signUpPage.submitToastError'),
				})
			}

			this.loading = false
		},

		showPassword() {
			this.hidePassword = !this.hidePassword
		},
	},
	head: {
		title: `RealityStudio - Create account`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Create account`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
