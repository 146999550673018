<template>
	<div class="swiper preset-images-swiper">
		<div class="swiper-wrapper">
			<div v-for="(image, index) in images" :key="index" class="swiper-slide w-full !h-[400px]">
				<img
					:src="image.url || image"
					:alt="`preset-image-${index + 1}`"
					class="rounded-xl !h-[400px] object-cover"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { Swiper } from 'swiper'
import { Autoplay } from 'swiper/modules'
const SLIDE_DELAY_IN_MS = 3000 // 3 seconds
export default {
	props: ['images'],
	data() {
		return {
			swiperInstance: null,
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.initSwiper()
		})
	},
	beforeUnmount() {
		if (this.swiperInstance) {
			if (Array.isArray(this.swiperInstance)) {
				this.swiperInstance.forEach(swiper => {
					swiper.destroy()
				})
				return
			}

			this.swiperInstance.destroy()
		}
	},
	methods: {
		initSwiper() {
			this.swiperInstance = new Swiper('.preset-images-swiper', {
				direction: 'horizontal',
				spaceBetween: 16, // in pixels
				modules: [Autoplay],
				autoplay: {
					delay: SLIDE_DELAY_IN_MS,
					disableOnInteraction: true,
				},
				slidesPerView: 1.4,
				grabCursor: true,
			})
		},
	},
}
</script>
<style scoped>
</style>

