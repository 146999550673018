<template>
	<div class="h-screen md:px-0 p-4 md:px-0 absolute top-0 left-0 w-full z-20 bg-app-blue">
		<div class="md:max-w-[592px] mx-auto flex flex-col h-full gap-4">
			<div class="rounded-3xl h-12 w-full loading-effect"></div>
			<div class="grid grid-cols-2 gap-4 flex-1">
				<div v-for="index in 4" :key="index" class="rounded-3xl loading-effect"></div>
			</div>
		</div>
	</div>
</template>
