import useStore from '../store'
import { sentryCaptureException } from './sentry-capture-exception'
export default {
	install(app) {
		const axios = app.config.globalProperties.axios

		app.config.globalProperties.$requestPushNotifications = async () => {
			const user = useStore.getters.getUser
			if (!user) {
				return
			}
			const { Capacitor } = await import('@capacitor/core')
			if (Capacitor.getPlatform() === 'web') return

			const { PushNotifications } = await import('@capacitor/push-notifications')
			PushNotifications.addListener('registrationError', err => {
				sentryCaptureException(err.error)
			})

			PushNotifications.addListener('registration', async token => {
				try {
					await axios.post('/users/push-token', {
						token: token.value,
					})
				} catch (error) {
					sentryCaptureException(error)
				}
			})

			let permission = await PushNotifications.checkPermissions()

			if (permission.receive !== 'granted' || !user.firebaseToken) {
				permission = await PushNotifications.requestPermissions()

				if (permission.receive === 'granted') {
					PushNotifications.register()
				}
			}
		}

		app.config.globalProperties.$addPushNotificationsListener = async () => {
			const user = useStore.getters.getUser

			if (!user) {
				return
			}

			const { Capacitor } = await import('@capacitor/core')
			if (Capacitor.getPlatform() === 'web') return

			const { PushNotifications } = await import('@capacitor/push-notifications')
			PushNotifications.addListener('pushNotificationActionPerformed', actionPerformed => {
				if (actionPerformed.actionId === 'tap' && actionPerformed.notification?.data?.url) {
					app.config.globalProperties.$router.push(actionPerformed.notification?.data?.url)
				}
			})
		}
	},
}
