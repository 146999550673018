<template>
	<div>
		<AdminHeader />
		<div class="page-container mt-6 pb-8">
			<div class="flex items-center">
				<button @click="$router.push('/admin')" class="material-icons text-[32px]">keyboard_backspace</button>
				<span class="text-3xl font-bold ml-2">
					{{ $t('admin.presetsPage.title') }}
				</span>
			</div>
			<div class="w-fit">
				<DataCreationTabs class="mt-8" :activeTab="activeTab" @onChangeTab="handleChangeTab" />
			</div>

			<ModelCreationForm
				:class="{
					hidden: !isModel,
				}"
			/>
			<PresetsCreationForm
				:class="{
					hidden: isModel,
				}"
			/>
		</div>
	</div>
</template>

<script>
import { DataCreationTabs, AdminHeader, ModelCreationForm, PresetsCreationForm } from '@/components/admin'

export default {
	name: 'Create',
	data() {
		return {
			activeTab: 'model',
		}
	},
	created() {
		if (this.$route.query.activeTab) {
			this.activeTab = this.$route.query.activeTab
		}
	},
	methods: {
		handleChangeTab(tab) {
			this.activeTab = tab
			this.$router.replace(`/admin/create?activeTab=${tab}`)

			if (typeof window !== 'undefined') {
				const titleByTab = {
					model: 'Create model | RealityStudio',
					preview: 'Create presets | RealityStudio',
				}

				window.document.title = titleByTab[tab]
			}
		},
	},
	computed: {
		isModel() {
			return this.activeTab === 'model'
		},
	},
	components: {
		DataCreationTabs,
		AdminHeader,
		ModelCreationForm,
		PresetsCreationForm,
	},
	head: {
		title: 'Create model | RealityStudio',
	},
}
</script>
