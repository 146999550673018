import App from './App.vue'
import store from './store'
import DatePlugin from './plugins/date.js'
import ToastPlugin from './plugins/toast'
import HelpersPlugin from './plugins/helpers'
import PushNotificationsPlugin from './plugins/push-notifications.js'
import sentryCaptureExceptionPlugin from './plugins/sentry-capture-exception.js'
import { createHead, VueHeadMixin } from '@unhead/vue'
import { appMiddleware } from './router/appMiddleware'
import VueGtagPlugin from 'vue-gtag'
import { createI18n } from 'vue-i18n'
import ptBR from './locales/pt-BR.json'
import enUS from './locales/en-US.json'
import esES from './locales/es-ES.json'
import * as Sentry from '@sentry/vue'
import { createSSRApp } from 'vue'
import createMyRouter from './router'
import twMerge from './directives/twMerge.js'
import { MotionPlugin } from '@vueuse/motion'
import createAxios from './plugins/axios.js'

import './index.css'

const SENTRY_DSN = typeof APP_ENV !== 'undefined' ? APP_ENV.VITE_SENTRY_DSN : import.meta.env.VITE_SENTRY_DSN
const GOOGLE_ANALYTICS_ID =
	typeof APP_ENV !== 'undefined' ? APP_ENV.VITE_GOOGLE_ANALYTICS_ID : import.meta.env.VITE_GOOGLE_ANALYTICS_ID
const STRIPE_PUBLIC_KEY =
	typeof APP_ENV !== 'undefined' ? APP_ENV.VITE_STRIPE_PUBLIC_KEY : import.meta.env.VITE_STRIPE_PUBLIC_KEY
const LOADING_STATE_TIMEOUT_IN_MS = 250

export const createApp = ({ req } = {}) => {
	const app = createSSRApp(App)
	const head = createHead()
	const i18n = createI18n({
		locale: 'en-US',
		fallbackLocale: 'en-US',
		messages: {
			'en-US': enUS,
			'pt-BR': ptBR,
			'es-ES': esES,
		},
		warnHtmlInMessage: 'off',
	})
	const router = createMyRouter(import.meta.env.SSR)
	const axios = createAxios({
		headers: req?.headers,
	})

	app.use(axios)
	app.use(i18n)

	Sentry.init({
		app,
		dsn: SENTRY_DSN,
	})

	app.use(head)
	app.use(router)
	app.use(store)
	app.use(DatePlugin)
	app.use(ToastPlugin)
	app.use(sentryCaptureExceptionPlugin)
	app.use(VueGtagPlugin, {
		config: {
			id: GOOGLE_ANALYTICS_ID,
		},
	})
	app.use(HelpersPlugin)
	app.use(twMerge)
	app.use(MotionPlugin)
	app.mixin(VueHeadMixin)

	router.beforeEach(
		appMiddleware({
			router,
			i18n,
			req,
		})
	)

	/**
	 * Loading state
	 */
	router.beforeEach((to, from, next) => {
		store.commit('setIsLoading', true)
		next()
	})

	router.beforeResolve(() => {
		setTimeout(() => {
			store.commit('setIsLoading', false)
		}, 500)
	})

	return { app, head, i18n: i18n.global, axios, router }
}
