<template>
	<div class="flex items-center h-[calc(100vh-6rem)] relative" v-if="currentStep === 1">
		<div class="flex items-center absolute top-6 mx-4 self-start">
			<Button size="fit" color="transparent" @click="$router.push('/')">
				<span class="material-icons text-2xl">arrow_back_ios</span>
			</Button>
			<h1 class="font-bold">
				{{ $t('researchIndex.pageTitle') }}
			</h1>
		</div>

		<div class="page-container">
			<Button
				@click="() => currentStep++"
				color="transparent"
				class="flex items-center justify-between border-[#1F262F] border-2 rounded-xl px-6 w-full"
			>
				<div class="flex items-center">
					<span class="material-icons text-2xl mr-4">lightbulb</span>
					<h1 class="font-bold">
						{{ $t('researchIndex.exploreText') }}
					</h1>
				</div>
				<span class="material-icons text-2xl">chevron_right</span>
			</Button>

			<Button
				href="/research/gallery"
				color="transparent"
				class="mt-6 flex items-center justify-between border-[#1F262F] border-2 rounded-xl px-6 w-full"
			>
				<div class="flex items-center">
					<span class="material-icons text-2xl mr-4">image</span>
					<h1 class="font-bold">
						{{ $t('researchIndex.galleryText') }}
					</h1>
				</div>
				<span class="material-icons text-2xl">chevron_right</span>
			</Button>
		</div>
	</div>

	<div class="flex flex-col h-[calc(100vh-6rem)]" v-if="currentStep === 2">
		<form @submit.prevent="handleSearch" class="w-full">
			<div class="flex items-center mt-6 page-container">
				<Button size="fit" color="transparent" @click="() => (currentStep = 1)">
					<span class="material-icons text-2xl">arrow_back_ios</span>
				</Button>
				<div class="bg-radial-2-card h-2 rounded-xl w-full">
					<div class="w-1/2 bg-blue-600 h-2 rounded-xl"></div>
				</div>
			</div>

			<h1 class="font-bold text-center text-xl mt-12">
				{{ $t('researchIndex.searchingFor') }}
			</h1>

			<Input
				id="search"
				v-model="searchParam"
				class="mt-6 page-container"
				:placeholder="$t('researchIndex.searchPlaceholder')"
			/>
		</form>

		<div class="page-container absolute bottom-4 lg:bottom-0 lg:relative lg:mt-8">
			<Button @click="handleSearch" :disabled="!!!searchParam" class="bottom-4">
				<span>
					{{ $t('researchIndex.continueBtn') }}
				</span>
				<span class="material-icons">chevron_right</span>
			</Button>
		</div>
	</div>
</template>

<script>
import { Button, Input } from '@/components/default'

export default {
	name: 'ResearchIndex',
	components: {
		Button,
		Input,
	},
	data() {
		return { currentStep: 1, searchParam: '' }
	},
	methods: {
		handleSearch() {
			this.$router.push(`/research/${this.searchParam}`)
		},
	},
	head: {
		title: `Reality Avatar - Research`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with Reality Avatar!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `Reality Avatar - Research`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with Reality Avatar!',
			},
		],
	},
}
</script>
