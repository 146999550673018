<template>
	<div ref="wrapper">
		<div class="relative">
			<video
				autoplay
				loop
				muted
				playsinline
				:controls="false"
				:src="firstSource"
				alt="image"
				class="absolute top-0 left-0"
				:class="sourceClass"
				ref="firstSource"
				v-if="!isFirstSrcImage"
			/>
			<img
				:src="firstSource"
				alt="image"
				class="absolute top-0 left-0"
				:class="sourceClass"
				ref="firstSource"
				v-if="isFirstSrcImage"
			/>
			<video
				autoplay
				loop
				muted
				playsinline
				:controls="false"
				:src="secondSource"
				alt="image"
				class="absolute top-0 left-0"
				:class="sourceClass"
				ref="secondSource"
				v-if="!isSecondSrcImage"
			/>
			<img
				:src="secondSource"
				alt="image"
				class="absolute top-0 left-0"
				:class="sourceClass"
				ref="secondSource"
				v-if="isSecondSrcImage"
			/>
		</div>

		<div class="flex items-center justify-center">
			<div class="absolute h-full top-0 flex items-center justify-center" ref="indicator" :class="indicatorClass">
				<div class="h-full bg-[#FFFFFF80] w-[2px]"></div>
				<div
					class="rounded-full h-[32px] w-[32px] border-[#FFFFFF80] border-2 flex items-center justify-center backdrop-blur-xl absolute"
				>
					<span class="material-icons text-2xl">chevron_right</span>
				</div>
			</div>
		</div>
		<div class="absolute bottom-0 w-full h-1/2 bg-gradient-to-t from-black z-10" v-if="blackBottomGradient" />
	</div>
</template>

<script>
export default {
	name: 'BeforeAfterSource',
	props: {
		sourceClass: {
			type: String,
		},
		indicatorClass: {
			type: String,
		},
		firstSource: {
			type: String,
			required: true,
		},
		secondSource: {
			type: String,
			required: true,
		},
		blackBottomGradient: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.resetToCenter()
		})

		this.$refs.wrapper.addEventListener('mousemove', this.handleMouseMove)
		this.$refs.wrapper.addEventListener('touchmove', this.handleMouseMove)

		/**
		 * @todo Testing this ux change
		 */
		// this.$refs.wrapper.addEventListener('touchend', this.resetToCenterAnimated)
		// this.$refs.wrapper.addEventListener('mouseleave', this.resetToCenterAnimated)
	},
	methods: {
		handleMouseMove(e) {
			const wrapperRect = this.$refs.wrapper.getBoundingClientRect()
			const indicatorRect = this.$refs.indicator.getBoundingClientRect()
			const x = e.touches ? e.touches[0].clientX - wrapperRect.left : e.clientX - wrapperRect.left
			const indicatorWidth = indicatorRect.width
			const wrapperWidth = wrapperRect.width
			const indicatorLeft = x - indicatorWidth / 2
			const indicatorRight = x + indicatorWidth / 2

			if (indicatorLeft < 0) {
				this.$refs.indicator.style.left = '0px'
			} else if (indicatorRight > wrapperWidth) {
				this.$refs.indicator.style.left = `${wrapperWidth - indicatorWidth}px`
			} else {
				this.$refs.indicator.style.left = `${x - indicatorWidth / 2}px`
			}

			const { secondSource, firstSource } = this.$refs

			secondSource.style.clipPath = `inset(0 0 0 ${x}px)`
			firstSource.style.clipPath = `inset(0 ${wrapperWidth - x}px 0 0)`
		},
		resetToCenter() {
			this.$refs.indicator.style.left = '50%'

			const { secondSource, firstSource } = this.$refs

			secondSource.style.clipPath = 'inset(0 0 0 50%)'
			firstSource.style.clipPath = 'inset(0 50% 0 0)'
		},
		resetToCenterAnimated() {
			this.$refs.indicator.style.transition = 'left 0.5s'
			this.$refs.secondSource.style.transition = 'clip-path 0.5s'
			this.$refs.firstSource.style.transition = 'clip-path 0.5s'
			this.resetToCenter()

			setTimeout(() => {
				this.$refs.indicator.style.transition = 'left 0s'
				this.$refs.secondSource.style.transition = 'clip-path 0s'
				this.$refs.firstSource.style.transition = 'clip-path 0s'
			}, 500)
		},
	},
	computed: {
		isFirstSrcImage() {
			if (!this.firstSource) {
				return false
			}

			return this.firstSource.includes('mp4') ? false : true
		},
		isSecondSrcImage() {
			if (!this.secondSource) {
				return false
			}

			return this.secondSource.includes('mp4') ? false : true
		},
	},
}
</script>
