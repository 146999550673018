<template>
	<div v-if="displayModal">
		<div class="bg-black/80 fixed w-screen h-screen top-0 left-0 z-10"></div>

		<transition
			enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 translate-y-48"
			enter-to-class="transform opacity-100"
		>
			<div
				class="fixed md:rounded-t-xl max-w-[592px] md:left-1/2 md:-translate-x-1/2 h-max w-full bottom-0 left-0 bg-black border border-gray-900 z-[60]"
			>
				<div class="py-8 flex flex-col items-center justify-center px-6">
					<img
						src="/web/images/icon-logo-white.png"
						alt="logo"
						class="h-full max-w-[80px] max-h-[80px] object-cover mb-4"
					/>
					<div class="font-satoshi text-center px-4">
						<h2 class="text-xl mb-4">{{ feedbackText.title }}</h2>
						<p class="text-gray-600" v-html="feedbackText.description"></p>
					</div>
				</div>
				<div v-if="feedbackText.isReason" class="px-4 py-6 flex justify-center">
					<TextArea
						name="reason"
						id="reason"
						:placeholder="$t('feedbackModal.reasonPlaceholder')"
						rows="4"
						class="w-full"
						v-model="reason"
						:labelText="$t('feedbackModal.reason')"
						:deleteButton="false"
					/>
				</div>
				<div class="flex flex-col">
					<Button
						class="border-t border-gray-900 py-6 px-4"
						color="none"
						buttonClass="font-normal"
						v-for="(button, index) in feedbackText.buttons"
						:key="index"
						@click="button.action"
					>
						<p>{{ button.label }}</p>
					</Button>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { Button, TextArea } from '@/components/default'
export default {
	components: {
		Button,
		TextArea,
	},
	data() {
		return {
			feedbackTexts: {
				firstStep: {
					isFeedbackOptions: true,
					isReason: false,
					title: this.$t('feedbackModal.firstStep.title'),
					description: this.$t('feedbackModal.firstStep.description'),
					buttons: [
						{
							action: () => this.handleFeedbackClick('positive'),
							label: this.$t('feedbackModal.firstStep.positive'),
						},
						{
							action: () => this.handleFeedbackClick('negative'),
							label: this.$t('feedbackModal.firstStep.negative'),
						},
						{
							action: () => this.handleFeedbackClick('ignored'),
							label: this.$t('feedbackModal.cancel'),
						},
					],
				},
				secondStep: {
					isFeedbackOptions: false,
					isReason: false,
					title: this.$t('feedbackModal.secondStep.title'),
					description: this.$t('feedbackModal.secondStep.description'),
					buttons: [
						{
							action: () => this.goToPlayStoreReview(),
							label: this.$t('feedbackModal.secondStep.feedback'),
						},
						{
							action: () => this.close(),
							label: this.$t('feedbackModal.cancel'),
						},
					],
				},
				negativeStep: {
					isFeedbackOptions: false,
					isReason: true,
					title: this.$t('feedbackModal.secondStep.title'),
					description: this.$t('feedbackModal.secondStep.description'),
					buttons: [
						{
							action: () => this.handleSendReason(),
							label: this.$t('feedbackModal.reasonSubmit'),
						},
						{
							action: () => this.handleSendReason(),
							label: this.$t('feedbackModal.cancel'),
						},
					],
				},
			},
			showText: 'firstStep',
			entity: {},
			displayModal: false,
			defaultDisplayTimerInSeconds: 5,
			reason: '',
			feedback: '',
		}
	},
	mounted() {
		this.$mitt.on('openFeedbackModal', async entity => {
			this.entity = entity
			this.checkShowFeedbackModal()
		})
	},
	methods: {
		close() {
			this.displayModal = false
		},
		async checkShowFeedbackModal() {
			if (!this.loggedUser) return
			try {
				const result = await this.axios.get('/feedback/check').then(res => res.data)
				setTimeout(() => {
					this.displayModal = result.displayModal
				}, this.defaultDisplayTimerInSeconds * 1000)
			} catch (error) {
				this.sentryCaptureException(error)
				this.displayModal = false
			}
		},
		handleFeedbackClick(feedback) {
			this.feedback = feedback

			if (this.feedback === 'negative') {
				this.showText = 'negativeStep'
				return
			}

			this.sendFeedback(feedback)
		},
		handleSendReason() {
			this.sendFeedback(this.feedback, this.reason)
		},
		async sendFeedback(feedback, reason = null) {
			try {
				await this.axios.post('/feedback', {
					type: feedback,
					entityMetadata: JSON.stringify(this.entity),
					reason,
				})
			} catch (error) {
				this.sentryCaptureException(error)
			}

			if (feedback === 'ignored' || feedback === 'negative') {
				this.close()
				return
			}

			this.showText = 'secondStep'
		},
		goToPlayStoreReview() {
			window.open(
				'https://play.google.com/store/apps/details?id=net.realityavatar.app&pcampaignid=web_share',
				'_blank'
			)
		},
	},
	computed: {
		feedbackText() {
			return this.feedbackTexts[this.showText]
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
}
</script>