import { sha256 } from 'js-sha256'

const identifyUser = user => {
	ttq.identify({
		email: user.email,
		external_id: sha256(user._id),
	})
}

export default {
	install(app) {
		app.config.globalProperties.sendTiktokCompletedPaymentEvent = (user, order) => {
			if (typeof ttq === 'undefined') {
				return
			}

			identifyUser(user)

			ttq.track('CompletePayment', {
				contents: [
					{
						content_name: order.plan.name,
					},
				],
				value: order.plan.price,
				currency: order.currency.toUpperCase(),
			})
		}

		app.config.globalProperties.sendTiktokInitCheckoutEvent = user => {
			if (typeof ttq === 'undefined') {
				return
			}

			identifyUser(user)

			ttq.track('InitiateCheckout', {})
		}

		app.config.globalProperties.sendTiktokCompleteRegistrationEvent = user => {
			if (typeof ttq === 'undefined') {
				return
			}

			identifyUser(user)

			ttq.track('CompleteRegistration', {})
		}
	},
}
