import { Capacitor } from '@capacitor/core'
import { v4 } from 'uuid'
export default {
	install(app) {
		app.config.globalProperties.$downloadFile = async (fileUrl, fileName, directory = 'Documents') => {
			const blob = typeof fileUrl === 'string' ? await fetch(fileUrl).then(r => r.blob()) : fileUrl

			if (Capacitor.getPlatform() === 'web') {
				const link = document.createElement('a')

				link.href = window.URL.createObjectURL(blob)
				link.download = fileName
				link.click()
				link.remove()
			} else {
				const fileSystem = await import('@capacitor/filesystem')
				const reader = new FileReader()

				reader.readAsDataURL(blob)

				await new Promise((resolve, reject) => {
					reader.onloadend = () => {
						console.log('Downloading...', reader.result)

						fileSystem.Filesystem.writeFile({
							path: `reality-studio-${v4()}-${fileName}`,
							data: reader.result,
							directory: fileSystem.Directory[directory],
							recursive: true,
						})
							.then(resolve)
							.catch(reject)
					}
				})

				console.log('Download complete.')
			}
		}

		app.config.globalProperties.$getPermissions = async () => {
			if (Capacitor.getPlatform() !== 'web') {
				const { Filesystem } = await import('@capacitor/filesystem')
				const permissions = await Filesystem.checkPermissions()

				if (permissions.publicStorage === 'granted') {
					return true
				}

				const request = await Filesystem.requestPermissions()
				return request
			}

			return true
		}
	},
}
