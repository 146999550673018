import dayjs from 'dayjs'

import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import 'dayjs/locale/es'

export const getLocaleByLang = lang => {
	switch (lang) {
		case 'pt-BR':
			return 'pt-br'
		case 'en-US':
			return 'en'
		case 'es-ES':
			return 'es'
		default:
			return 'en'
	}
}

export default {
	install(app) {
		app.config.globalProperties.$dayjs = dayjs
		app.config.globalProperties.$formatDate = (date, format = 'DD/MM/YYYY [at] HH:mm') => {
			return dayjs(date).format(format)
		}
	},
}
