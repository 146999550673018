<template>
	<div class="page-container pt-12 px-4">
		<template v-if="!hasCanceled && !hasReactivated">
			<div class="flex justify-center flex-col items-center text-center">
				<i class="material-symbols-outlined text-6xl text-red-500">chat_error</i>
				<p class="font-bold text-xl mt-8 mb-4">{{ $t('newsletter.cancelNewsletter') }}</p>
				<p>{{ $t('newsletter.giveUsAReason') }}</p>
			</div>

			<div class="mt-6 space-y-4">
				<button
					class="w-full text-left p-6 flex items-center justify-between border border-gray-900 rounded-xl gap-2"
					v-for="(reason, index) in commonReasons"
					@click="handleSelectReason(index)"
					:key="`reason-${index}`"
				>
					<p class="flex-1 w-full">
						{{ $t(reason) }}
					</p>
					<span class="material-icons text-2xl">
						{{ selectedReason === index ? 'check_box' : 'check_box_outline_blank' }}
					</span>
				</button>
			</div>
			<div class="mt-6">
				<label class="text-base leading-6">{{ $t('newsletter.cancelingReasons.other.label') }} </label>
				<textarea
					class="py-6 px-4 text-area-height border-gray-900 w-full rounded-md bg-app-blue border-[1px] mt-4 resize-none h-[200px]"
					v-model="personalReason"
					type="text"
					:placeholder="$t('newsletter.cancelingReasons.other.placeholder')"
				/>
			</div>
			<Button
				@click="cancelNewsletter"
				:disabled="!personalReason && typeof selectedReason !== 'number'"
				class="mt-4"
				>{{ $t('newsletter.cancelNewsletter') }}</Button
			>
		</template>

		<template v-if="hasCanceled && !hasReactivated">
			<div class="flex justify-center flex-col items-center text-center">
				<i class="material-symbols-outlined text-6xl text-red-500">chat_error</i>
				<p class="font-bold text-xl mt-8 mb-4">{{ $t('newsletter.newsletterCanceled') }}</p>
				<p v-if="!isRedirecting">{{ $t('newsletter.cancelationDescription') }}</p>
			</div>
			<template v-if="!isRedirecting">
				<Button @click="reactivateNewsletter" class="mt-6">{{ $t('newsletter.reactivate') }}</Button>
				<Button @click="redirectToHome" class="mt-4" color="outline">{{ $t('newsletter.noThanks') }}</Button>
			</template>
		</template>

		<template v-if="hasReactivated">
			<div class="flex justify-center flex-col items-center text-center">
				<i class="material-symbols-outlined text-6xl text-green-400">check_circle</i>
				<p class="font-bold text-xl mt-8 mb-4">{{ $t('newsletter.newsletterReactivated') }}</p>
			</div>
		</template>
		<p class="text-center" v-if="isRedirecting">{{ $t('newsletter.redirecting') }}</p>
	</div>
</template>

<script>
import { Header, Button } from '@/components/default'
export default {
	head: {
		title: `RealityStudio - Cancel Newsletter`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Cancel Newsletter `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
		],
	},

	data() {
		return {
			commonReasons: [
				'newsletter.cancelingReasons.frequency',
				'newsletter.cancelingReasons.relevance',
				'newsletter.cancelingReasons.contentQuality',
				'newsletter.cancelingReasons.preferences',
				'newsletter.cancelingReasons.neverSubscribed',
				'newsletter.cancelingReasons.noLongerInterested',
			],
			selectedReason: null,
			personalReason: '',
			hasCanceled: false,
			hasReactivated: false,
			isRedirecting: false,
		}
	},

	components: { Header, Button },

	methods: {
		async cancelNewsletter() {
			await this.axios.put('/users/newsletter-subscription', {
				newsletterSubscription: false,
				email: this.email || '',
				reason: this.personalReason || this.$t(this.commonReasons[this.selectedReason], 'en-US'),
			})

			this.hasCanceled = true
		},

		async reactivateNewsletter() {
			await this.axios.put('/users/newsletter-subscription', {
				newsletterSubscription: true,
				email: this.email || '',
			})

			this.hasReactivated = true
			this.redirectToHome()
		},

		redirectToHome() {
			this.isRedirecting = true
			setTimeout(() => this.$router.push('/'), 3000)
		},

		handleSelectReason(reasonIndex) {
			if (this.selectedReason === reasonIndex) {
				this.selectedReason = null
				return
			}

			this.selectedReason = reasonIndex
		},
	},

	mounted() {
		if (!this.email) {
			return this.$router.push('/')
		}
	},

	computed: {
		email() {
			return this.$route.query?.email || null
		},
	},
}
</script>
