<template>
	<div>
		<AdminHeader />
		<div class="page-container mt-6 pb-8">
			<div class="flex items-center">
				<button @click="$router.push('/admin')" class="material-icons text-[32px]">keyboard_backspace</button>
				<span class="text-3xl font-bold ml-2"> Diffusion models </span>
			</div>

			<div class="grid gap-6 grid-cols-1 lg:grid-cols-3">
				<div>
					<DataCreationTabs class="my-8" @onChangeTab="handleChangeTab" />
					<Input
						id="diffusionName"
						type="text"
						label="Diffusion name"
						placeholder="Input model version name"
						v-model="diffusionData.name"
					/>
					<Input
						class="mt-4"
						id="diffusionVersion"
						type="text"
						label="Diffusion gateway name"
						placeholder="Input model gateway name"
						v-model="diffusionData.gatewayName"
					/>
					<Input
						class="mt-4"
						id="diffusionVersion"
						type="text"
						label="Diffusion version"
						placeholder="Input model version"
						v-model="diffusionData.gatewayId"
					/>

					<Input
						class="mt-4"
						id="price"
						type="number"
						label="Price"
						placeholder="model price"
						v-model="diffusionData.price"
					/>

					<Select
						label="Base Model"
						:value="diffusionData.baseModel"
						menuTitle="Base Model"
						:options="baseModelOptions"
						@onChange="value => (diffusionData.baseModel = value)"
						class="mt-4"
					/>
					<div class="grid grid-cols-3 mt-4">
						<Checkbox
							class="mt-4"
							:checked="diffusionData.public"
							id="diffusionModelPublic"
							v-model="diffusionData.public"
						>
							<p>Public model</p>
						</Checkbox>
						<Checkbox
							class="mt-4"
							:checked="diffusionData.featured"
							id="diffusionModelFeatured"
							v-model="diffusionData.featured"
						>
							<p>Featured</p>
						</Checkbox>
						<Checkbox
							class="mt-4"
							:checked="diffusionData.enableLora"
							id="diffusionModelEnableLora"
							v-model="diffusionData.enableLora"
						>
							<p>Enable Lora</p>
						</Checkbox>
						<Checkbox
							class="mt-4"
							:checked="diffusionData.enableImage"
							id="diffusionModelEnableImage"
							v-model="diffusionData.enableImage"
						>
							<p>Enable Image</p>
						</Checkbox>
						<Checkbox
							class="mt-4"
							:checked="diffusionData.enableMask"
							id="diffusionModelEnableMask"
							v-model="diffusionData.enableMask"
						>
							<p>Enable Mask</p>
						</Checkbox>
						<Checkbox
							class="mt-4"
							:checked="diffusionData.enableQuality"
							id="diffusionModelEnableQuality"
							v-model="diffusionData.enableQuality"
						>
							<p>Enable Quality</p>
						</Checkbox>
						<Checkbox
							class="mt-4"
							:checked="diffusionData.enableCustomResolution"
							id="diffusionModelEnableCustomResolution"
							v-model="diffusionData.enableCustomResolution"
						>
							<p>Enable Custom Resolution</p>
						</Checkbox>
						<Checkbox
							class="mt-4"
							:checked="diffusionData.enablePromptStrength"
							id="diffusionModelEnablePromptStrength"
							v-model="diffusionData.enablePromptStrength"
						>
							<p>Enable Prompt Strengh</p>
						</Checkbox>
						<Checkbox
							class="mt-4"
							:checked="diffusionData.enablePromptImprovement"
							id="diffusionModelEnablePromptImprovement`"
							v-model="diffusionData.enablePromptImprovement"
						>
							<p>Enable Prompt Improvement</p>
						</Checkbox>
						<Checkbox
							class="mt-4"
							:checked="diffusionData.enableMagicPrompt"
							id="diffusionModelEnableMagicPrompt"
							v-model="diffusionData.enableMagicPrompt"
						>
							<p>Enable Magic Prompt</p>
						</Checkbox>
						<Checkbox
							class="mt-4"
							:checked="diffusionData.enableOutputQuality"
							id="diffusionModelEnableOutputQuality"
							v-model="diffusionData.enableOutputQuality"
						>
							<p>Enable Output Quality</p>
						</Checkbox>
					</div>
					<div class="mt-4">
						<Input
							class="mt-4"
							id="diffusionAllowedResolution"
							type="text"
							label="Allowed resolution"
							placeholder="allowed resolution"
							v-model="allowedResolution"
						/>
						<Button @click="handleAddAllowedResolution" class="mt-4"> Add Resolution</Button>
						<p class="mt-4" v-if="diffusionData?.allowedResolutions?.length">Click to remove</p>
						<div class="grid grid-cols-3 gap-4 mt-4">
							<Button
								v-for="(resolution, index) in diffusionData.allowedResolutions"
								:key="index"
								color="transparent"
								size="fit"
								class="text-center py-6 px-4 border cursor font-normal relative border-radial-1-card"
								@click="handleRemoveResolution(resolution)"
							>
								<p>{{ resolution }}</p>
								<span class="material-icons text-red-600 text-base absolute top-1 right-1">
									delete
								</span>
							</Button>
						</div>
					</div>
					<div class="mt-4">
						<Input
							class="mt-4"
							id="diffusionAspectRatio"
							type="text"
							label="Aspect Ratio (flux only)"
							placeholder="Aspect Ratio (flux only)"
							v-model="aspectRatio"
						/>
						<Button @click="handleAddAspectRatio" class="mt-4"> Add Aspect Ratio</Button>
						<p class="mt-4" v-if="diffusionData.aspectRatios.length">Click to remove</p>
						<div class="grid grid-cols-3 gap-4 mt-4">
							<Button
								v-for="(aspectRatio, index) in diffusionData.aspectRatios"
								:key="index"
								color="transparent"
								size="fit"
								class="text-center py-6 px-6 border cursor font-normal relative border-radial-1-card"
								@click="handleRemoveAspectRatio(aspectRatio)"
							>
								<p>{{ aspectRatio }}</p>
								<span class="material-icons text-red-600 text-base absolute top-1 right-1">
									delete
								</span>
							</Button>
						</div>
					</div>
					<div class="mt-4">
						<Input
							class="mt-4"
							id="diffusionScheduler"
							type="text"
							label="Diffusion Scheduler"
							placeholder="Scheduler"
							v-model="scheduler"
						/>
						<Button @click="handleAddScheduler" class="mt-4"> Add Scheduler</Button>
						<div class="grid grid-cols-4 gap-4 mt-4">
							<div v-for="(resolution, index) in diffusionData.schedulers" :key="index">
								<p>{{ resolution }}</p>
							</div>
						</div>
					</div>
					<div class="mt-4">
						<p class="font-bold mb-6">Image</p>
						<label
							class="cursor-pointer flex items-center border rounded-xl border-radial-1-card w-full px-4 py-5 mx-auto mb-6"
							for="userPicturesInput"
							@click="handleImageUpload"
						>
							<div class="flex items-center">
								<span class="material-symbols-outlined text-3xl mr-2">add_circle</span>
								<p class="font-bold">
									{{ $t('imageGeneration.create.addPhoto') }}
								</p>
							</div>
						</label>

						<input
							inputId="userPicturesInput"
							ref="fileInput"
							class="hidden"
							type="file"
							name="file"
							accept="image/*"
							@change="event => onFileSelect(event)"
						/>
						<div class="h-[300px] mb-6" v-if="previewImageBlob || diffusionData.image">
							<div class="relative w-fit mx-auto">
								<img
									class="mb-4 mx-auto h-[300px] object-cover rounded-xl cursor-pointer"
									:src="previewImageBlob || diffusionData.image"
								/>
								<Button
									class="bg-red-700 rounded-md h-8 w-8 absolute top-2 right-2 z-10"
									color="none"
									size="fit"
									@click="handleDeleteImage"
								>
									<span class="material-icons text-base">delete</span>
								</Button>
							</div>
						</div>
					</div>
					<Button
						:isLoading="isCreating"
						type="button"
						:disabled="isCreating"
						class="mt-4"
						v-if="!diffusionData._id"
						@click="handleCreateDiffusionModel"
					>
						Create
					</Button>
					<Button
						:isLoading="isCreating"
						type="button"
						:disabled="isCreating"
						class="mt-4"
						v-if="diffusionData._id"
						@click="handleEditDiffusion"
					>
						Edit
					</Button>
				</div>

				<div class="mt-6 space-y-2 col-span-2">
					<div
						v-for="model in diffusionModels.results"
						:key="model.version"
						class="flex items-center justify-between py-6 px-4 rounded-xl border border-gray-800"
					>
						<p class="font-bold">{{ model.name }}</p>
						<div class="flex items-center justify-center">
							<div class="font-bold mr-4 bg-blue-600 px-2 rounded-full" v-if="model.public">Public</div>
							<Button
								color="transparent"
								class="material-icons !px-0 text-2xl mr-2 h-auto"
								@click="openEditDiffusion(model)"
							>
								edit
							</Button>
							<Button
								color="transparent"
								class="material-icons !px-0 text-2xl mr-2 h-auto"
								@click="copyToClipboard(model.gatewayId)"
							>
								content_copy
							</Button>
							<Button
								color="transparent"
								class="material-icons !px-0 !text-2xl mr-2 h-auto !text-red-400"
								@click="handleDeleteDiffusion(model._id)"
								:isLoading="model.isDeleting"
							>
								delete
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Button, Input, Checkbox, Modal, Select } from '@/components/default'
import { AdminHeader, DataCreationTabs } from '@/components/admin'
export default {
	name: 'CreateDiffusionModel',
	data() {
		return {
			diffusionData: {
				name: '',
				gatewayName: '',
				gatewayId: '',
				baseModel: '',
				image: '',
				price: null,
				public: false,
				featured: false,
				enableLora: false,
				enableImage: false,
				enableMask: false,
				enableQuality: false,
				enableCustomResolution: false,
				enablePromptStrength: false,
				enablePromptImprovement: false,
				enableMagicPrompt: false,
				enableOutputQuality: false,
				allowedResolutions: ['512x512'],
				aspectRatios: [],
				schedulers: [],
				advancedInputs: [],
			},
			isCreating: false,
			isFetching: false,
			isEditing: false,
			showEditModal: false,
			diffusionModels: {
				results: [],
				hasNext: false,
				next: null,
			},
			baseModelOptions: [
				{
					label: 'Stable diffusion 15',
					value: 'stable-diffusion-15',
					disabled: false,
				},
				{
					label: 'stable Diffusion XL',
					value: 'stable-diffusion-xl',
					disabled: false,
				},
				{
					label: 'Flux',
					value: 'flux',
					disabled: false,
				},
				{
					label: 'Flux Dev',
					value: 'flux-dev',
					disabled: false,
				},
			],
			allowedResolution: '',
			aspectRatio: '',
			scheduler: '',
			previewImageBlob: null,
			imageData: null,
		}
	},
	created() {
		this.fetchDiffusionModels(true)
	},
	mounted() {
		window.addEventListener('scroll', this.onScroll)
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll)
	},
	methods: {
		handleDeleteImage() {
			this.diffusionData.image = ''
			this.previewImageBlob = null
			this.imageData = null
		},

		handleImageUpload() {
			if (this.$refs.fileInput) {
				this.$refs.fileInput.click()
			}
		},

		async onFileSelect(event) {
			const file = event.target.files[0]

			if (!file) return
			this.$refs.fileInput.value = ''

			this.captureEvent(`free_avatar_tool_image_upload_click`)
			this.previewImageBlob = URL.createObjectURL(file)
			this.imageData = file
		},

		handleRemoveResolution(resolution) {
			this.diffusionData.allowedResolutions = this.diffusionData.allowedResolutions.filter(
				allowedResolution => allowedResolution !== resolution
			)
		},

		handleRemoveAspectRatio(aspectRatio) {
			this.diffusionData.aspectRatios = this.diffusionData.aspectRatios.filter(ratio => ratio !== aspectRatio)
		},

		async handleEditDiffusion() {
			this.isCreating = true
			try {
				let image = this.diffusionData.image
				if (this.imageData) {
					const file = this.imageData
					const signedUrlResponse = await this.axios.get(`/files/sign-url/${file.name}`).then(res => res.data)

					await this.axios.put(signedUrlResponse.url, file)
					image = signedUrlResponse.key
				}

				const updatedDiffusionModel = await this.axios
					.put(`/diffusion-models/${this.diffusionData._id}`, {
						name: this.diffusionData.name,
						gatewayName: this.diffusionData.gatewayName,
						featured: this.diffusionData.featured,
						image: image,
						gatewayId: this.diffusionData.gatewayId,
						public: this.diffusionData.public,
						baseModel: this.diffusionData.baseModel,
						price: this.diffusionData.price,
						advancedInputs: this.diffusionData.advancedInputs,
						enableLora: this.diffusionData.enableLora,
						enableImage: this.diffusionData.enableImage,
						enableMask: this.diffusionData.enableMask,
						enableQuality: this.diffusionData.enableQuality,
						enableCustomResolution: this.diffusionData.enableCustomResolution,
						enablePromptStrength: this.diffusionData.enablePromptStrength,
						enablePromptImprovement: this.diffusionData.enablePromptImprovement,
						enableMagicPrompt: this.diffusionData.enableMagicPrompt,
						allowedResolutions: this.diffusionData.allowedResolutions,
						aspectRatios: this.diffusionData.aspectRatios,
						enableOutputQuality: this.diffusionData.enableOutputQuality,
						schedulers: this.diffusionData.schedulers,
					})
					.then(res => res.data)
				this.openEditDiffusion(updatedDiffusionModel)

				this.diffusionModels.results = this.diffusionModels.results.map(model => {
					if (model._id === updatedDiffusionModel._id) {
						return { ...updatedDiffusionModel }
					}
					return model
				})
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isCreating = false
		},
		handleAddAllowedResolution() {
			this.diffusionData.allowedResolutions.push(this.allowedResolution)
		},
		handleAddAspectRatio() {
			this.diffusionData.aspectRatios.push(this.aspectRatio)
		},
		handleAddScheduler() {
			this.diffusionData.schedulers.push(this.scheduler)
		},
		openEditDiffusion(model) {
			this.diffusionData = {
				_id: model._id,
				name: model.name,
				gatewayName: model.gatewayName,
				gatewayId: model.gatewayId,
				baseModel: model.baseModel,
				price: model.price,
				image: model.image,
				public: model.public,
				featured: model.featured,
				enableLora: model.config.enableLora,
				enableImage: model.config.enableImage,
				enableMask: model.config.enableMask,
				enableQuality: model.config.enableQuality,
				enableCustomResolution: model.config.enableCustomResolution,
				enablePromptStrength: model.config.enablePromptStrength,
				enablePromptImprovement: model.config.enablePromptImprovement,
				enableMagicPrompt: model.config.enableMagicPrompt,
				allowedResolutions: model.config.allowedResolutions,
				aspectRatios: model.config.aspectRatios ?? [],
				enableOutputQuality: model.config.enableOutputQuality,
				schedulers: model.config.schedulers,
				advancedInputs: model.config.advancedInputs,
			}
		},
		handleChangeTab(tab) {
			this.$router.push(`/admin/create?activeTab=${tab}`)
		},
		async handleCreateDiffusionModel() {
			if (this.isCreating) {
				return
			}

			this.isCreating = true
			try {
				const file = this.imageData
				const signedUrlResponse = await this.axios.get(`/files/sign-url/${file.name}`).then(res => res.data)

				await this.axios.put(signedUrlResponse.url, file)

				const { data } = await this.axios.post('/diffusion-models', {
					name: this.diffusionData.name,
					gatewayName: this.diffusionData.gatewayName,
					featured: this.diffusionData.featured,
					image: signedUrlResponse.key,
					gatewayId: this.diffusionData.gatewayId,
					public: this.diffusionData.public,
					baseModel: this.diffusionData.baseModel,
					price: this.diffusionData.price,
					advancedInputs: this.diffusionData.advancedInputs,
					enableLora: this.diffusionData.enableLora,
					enableImage: this.diffusionData.enableImage,
					enableMask: this.diffusionData.enableMask,
					enableQuality: this.diffusionData.enableQuality,
					enableCustomResolution: this.diffusionData.enableCustomResolution,
					enablePromptStrength: this.diffusionData.enablePromptStrength,
					enablePromptImprovement: this.diffusionData.enablePromptImprovement,
					enableMagicPrompt: this.diffusionData.enableMagicPrompt,
					allowedResolutions: this.diffusionData.allowedResolutions,
					aspectRatios: this.diffusionData.aspectRatios,
					enableOutputQuality: this.diffusionData.enableOutputQuality,
					schedulers: this.diffusionData.schedulers,
				})

				this.diffusionModels.results.push(data)
				this.openEditDiffusion(data)
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.isCreating = false
		},
		async fetchDiffusionModels(forceFetch) {
			if (this.isFetching || (!forceFetch && !this.diffusionModels.hasNext)) {
				return
			}

			this.isFetching = true

			try {
				const data = await this.axios
					.get(`/diffusion-models?next=${this.diffusionModels.next || ''}`)
					.then(res => res.data)

				this.diffusionModels.results = [...this.diffusionModels.results, ...data.results]
				this.diffusionModels.hasNext = data.hasNext
				this.diffusionModels.next = data.next
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetching = false
		},
		async handleDeleteDiffusion(id) {
			const model = this.diffusionModels.results.find(model => model._id === id)

			if (model?.isDeleting) {
				return
			}

			model.isDeleting = true

			try {
				await this.axios.delete('/diffusion-models/' + id)
				this.diffusionModels.results = this.diffusionModels.results.filter(model => model._id !== id)
			} catch (error) {
				this.sentryCaptureException(error)
				model.isDeleting = false
			}
		},
		onScroll() {
			const scrollHeight = document.documentElement.scrollHeight
			const clientHeight = document.documentElement.clientHeight
			const scrollTop = document.documentElement.scrollTop

			if (scrollHeight - scrollTop === clientHeight) {
				this.fetchDiffusionModels()
			}
		},
	},

	components: {
		Modal,
		AdminHeader,
		DataCreationTabs,
		Button,
		Input,
		Checkbox,
		Select,
	},
	head: {
		title: 'Diffusion models | RealityStudio',
	},
}
</script>
