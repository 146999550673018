<template>
	<div>
		<!-- <AdminHeader />
		<div class="page-container mt-6 mb-10">
			<Input>
				
			</Input>
		</div> -->
	</div>
</template>

<script>
// import { Input, Button } from '@/components/default'
// import { AdminHeader } from '@/components/admin'
// export default {
// 	components: {
// 		Input,
// 		Button,
// 		AdminHeader,
// 	},
// }
</script>
