<template>
	<div class="flex gap-4 items-center justify-center my-6" v-if="availableCycles.length">
		<button
			v-for="cycle in availableCycles"
			:key="cycle.label"
			class="border rounded-xl border-gray-500 py-4 px-6 font-bold"
			:class="{
				'text-white border-radial-1-card bg-blue-600': activeCycle === cycle.id,
			}"
			@click="handleChangeCycle(cycle)"
		>
			{{ cycle.label }}
		</button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isFetching: false,
			activeCycle: null,
			availableCycles: [
				{
					id: 'day',
					label: '24h',
				},
				{
					id: 'week',
					label: '7d',
				},
				{
					id: 'month',
					label: '30d',
				},
			],
		}
	},
	mounted() {
		this.fetchAnalytics()
	},
	methods: {
		handleChangeCycle(cycle) {
			this.activeCycle = cycle.id
			this.fetchAnalytics()
		},
		async fetchAnalytics() {
			this.isFetching = true

			try {
				const params = {
					type: this.activeCycle,
				}

				const { data } = this.axios.get('/admin/analytics/sales', {
					params,
				})
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetching = false
		},
	},
}
</script>
