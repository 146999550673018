<template>
	<div>
		<GoogleLogin class="mb-4" :redirectTo="redirectTo" />
		<Button @click="onCreateAccount">{{ $t('authenticationBox.createAccount') }}</Button>
		<div class="flex text-gray-300 font-bold text-lg mt-4 items-center justify-center">
			{{ $t('authenticationBox.description') }}
			<Button class="font-bold text-white ml-2" color="transparent" size="fit" @click="onLogin">
				{{ $t('authenticationBox.signIn') }}
			</Button>
		</div>
	</div>
</template>
<script>
import { Button } from '@/components/default'
import { GoogleLogin } from '@/components/auth'

export default {
	components: {
		GoogleLogin,
		Button,
	},
	props: {
		redirectTo: {
			type: String,
		},
		pageName: {
			type: String,
			required: true,
		},
	},
	methods: {
		onCreateAccount() {
			this.captureEvent(`${this.pageName}_logged_out_create_avatar_click`)

			this.$router.push({
				path: '/create-account',
				query: {
					redirectTo: this.redirectTo,
				},
			})
		},
		onLogin() {
			this.captureEvent(`${this.pageName}_logged_out_login_click`)

			this.$router.push({
				path: '/login',
				query: {
					redirectTo: this.redirectTo,
				},
			})
		},
	},
}
</script>
