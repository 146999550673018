<template>
	<div class="border-[1px] border-gray-900 py-8 px-6 rounded-xl relative">
		<div class="flex items-center flex-col">
			<span class="material-icons text-white text-4xl">{{ data.icon }}</span>
			<!-- <div
				:class="[
					' bg-opacity-40 rounded-xl py-2 mb-4 px-4 border-gray-500 border-opacity-5 w-fit flex items-center',
					negativeLabel ? negativeLabelClasses : positiveLabelClasses,
				]"
			>
				<span class="material-icons text-md">{{ negativeLabel ? 'arrow_drop_down' : 'arrow_drop_up' }}</span>
				<span class="font-bold">{{ negativeLabel ? '-20%' : '+20%' }}</span>
			</div> -->
			<h2 class="font-bold text-3xl mt-4">{{ data.value }}</h2>
			<span class="mt-4">{{ data.description }}</span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			icon: String,
			description: String,
			value: Number,
		},
	},
	data() {
		return {
			positiveLabelClasses: 'bg-emerald-500 text-green-200',
			negativeLabelClasses: 'bg-red-500 text-red-200',
		}
	},
}
</script>
