<template>
	<div class="border border-radial-1-card rounded-xl pt-4">
		<div
			:class="[
				'flex justify-between items-center pb-4 px-6 ',
				{ 'border-b-2 border-b-radial-1-card': isExpanded },
			]"
		>
			<div class="flex flex-col justify-center">
				<slot name="title"></slot>
			</div>
			<button
				v-if="isExpandable"
				@click="toggleAccordion"
				class="border rounded relative w-12 h-12 p-2 border-radial-1-card flex items-center justify-center"
			>
				<i :class="['material-icons text-3xl transition-transform', { 'rotate-180': isExpanded }]">
					expand_more
				</i>
			</button>
		</div>
		<div ref="contentSlot" class="overflow-hidden transition-all" v-show="isExpanded">
			<div class="px-6 pb-4">
				<slot name="content"></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		initiallyExpanded: {
			type: Boolean,
			default: false,
		},
		isExpandable: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			isExpanded: false,
		}
	},

	methods: {
		toggleAccordion() {
			this.isExpanded = !this.isExpanded
		},
	},

	mounted() {
		this.isExpanded = this.initiallyExpanded
	},
}
</script>
