<template>
	<section id="aiTools" class="mt-6 md:mt-16 page-container relative">
		<h2
			class="w-fit h-11 py-2 px-4 mx-auto bg-indigo-500 rounded-3xl text-white flex items-center justify-center font-bold lg:hidden"
		>
			{{ $t('landingPage.menuNav.aiTools') }}
		</h2>
		<h3 class="mt-6 font-bold text-3xl md:text-5xl text-center" v-motion-slide-visible-once-left>
			✨ {{ $t('landingPage.aiTools.title') }}.
		</h3>

		<!-- Desktop -->
		<div class="hidden lg:flex mt-14">
			<div class="swiper hero-swiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide group" v-for="(slide, i) in slideContents" :key="slide">
						<div class="h-[700px] w-[700px] relative mx-auto">
							<video
								:src="slide"
								class="rounded-3xl object-cover h-full w-full pointer-events-none"
								:class="{
									'opacity-20': i !== activeSwiperIndex,
									'opacity-100': i === activeSwiperIndex,
								}"
								:ref="'slide-' + i"
								:muted="true"
								preload="auto"
								autoplay
								playsinline
								:alt="i"
							/>
							<Button
								class="absolute top-4 right-4 opacity-0 pointer-events-none transition-all duration-300 group-hover:pointer-events-auto group-hover:opacity-100"
								size="fit"
								color="transparent"
								@click="toggleMute"
							>
								<span v-if="isMuted" class="material-icons text-4xl">volume_off</span>
								<span v-if="!isMuted" class="material-icons text-4xl">volume_up</span>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<BlueCircle width="400px" height="300px" blur="200px" class="hidden lg:block ms-[50%] top-[50%]"></BlueCircle>

		<!-- Mobile -->
		<div class="flex lg:hidden justify-center mt-8">
			<div class="grid grid-cols-2 gap-x-4">
				<video
					v-for="(src, index) in slideContents"
					:key="index"
					:ref="`video-` + index"
					@click="playThisVideo(index)"
					@ended="playNextVideo()"
					class="mb-5 h-72 w-44 rounded-3xl cursor-pointer transition-opacity duration-500 lazy bg-white"
					:class="{
						'opacity-20': index !== activeSwiperIndex,
						'opacity-100': index === activeSwiperIndex,
						'mt-11': index === 0,
						'bg-[#DDDDDD]': index === 1,
					}"
					playsinline
					preload="auto"
					:muted="true"
					:src="src"
					alt="Video"
				/>
			</div>
		</div>
		<p
			class="mt-8 mx-auto max-w-sm lg:max-w-xl w-full text-xl lg:text-4xl text-center text-gray-500"
			v-motion-slide-visible-once-right
		>
			{{ $t('landingPage.aiTools.description') }}.
		</p>
	</section>
</template>
<script>
import { Button } from '@/components/default'
import Swiper from 'swiper'
import BlueCircle from './BlueCircle.vue'

export default {
	name: 'Slides',
	components: { Button, BlueCircle },
	data() {
		return {
			slideContents: [
				'/web/images/landing-page/videos/inspireme2.mp4',
				'/web/images/landing-page/videos/reimagine.mp4',
				'/web/images/landing-page/videos/createimage.mp4',
			],
			swiperInstance: null,
			activeSwiperIndex: 0,
			isMuted: true,
		}
	},
	mounted() {
		if (this.isMobile) {
			this.$refs['video-0'][0].play()
		} else {
			this.swiperInstance = new Swiper('.hero-swiper', {
				// modules: [EffectCoverflow],
				// effect: 'coverflow',
				grabCursor: 'true',
				slidesPerView: 2,
				speed: 400,
				spaceBetween: 100,
				centeredSlides: true,
				initialSlide: 1,
				resistanceRatio: 0.1,
				on: {
					slideChange: this.onSlideChange,
				},
			})
		}
	},
	methods: {
		toggleMute() {
			this.isMuted = !this.isMuted
			this.currentVideoEl.muted = this.isMuted
		},
		onSlideChange(swiper) {
			if (this.activeSwiperIndex !== swiper.realIndex) {
				this.activeSwiperIndex = swiper.realIndex
			}

			const activeVideoEl = this.currentVideoEl
			for (let i = 0; i < this.slideContents.length; i++) {
				const videoEl = this.$refs[`slide-${i}`][0]

				if (videoEl !== activeVideoEl) {
					videoEl.pause()

					if (!videoEl.muted) {
						videoEl.muted = true
					}
				}
			}

			activeVideoEl.muted = this.isMuted
			activeVideoEl.play()
		},
		playThisVideo(index) {
			this.$refs[`video-` + this.activeSwiperIndex][0].pause()
			this.activeSwiperIndex = index
			this.$refs[`video-` + this.activeSwiperIndex][0].play()
		},
		playNextVideo() {
			this.$refs[`video-` + this.activeSwiperIndex][0].pause()
			this.$refs[`video-` + this.activeSwiperIndex][0].currentTime = 0

			if (this.activeSwiperIndex === this.slideContents.length - 1) {
				this.activeSwiperIndex = 0
			} else {
				this.activeSwiperIndex = this.activeSwiperIndex + 1
			}

			this.$refs[`video-` + this.activeSwiperIndex][0].play()
		},
	},
	beforeDestroy() {
		if (this.swiperInstance) {
			this.swiperInstance.destroy()
		}
	},
	computed: {
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		currentVideoEl() {
			if (!this.$refs) {
				return
			}

			return this.$refs[`slide-${this.activeSwiperIndex}`][0]
		},
	},
}
</script>

<style scoped>
.lazy::after {
	content: '';
	display: block;
	width: 100%;
	height: 0;
	padding-bottom: 8799%;
}
</style>
