<template>
	<div
		class="move-light absolute"
		:style="{
			width: width,
			height: height,
			filter: `blur(${blur})`,
		}"
	></div>
</template>

<script>
export default {
	name: 'BlueCircle',
	props: {
		width: {
			type: String,
		},
		height: {
			type: String,
		},
		blur: {
			type: String,
		},
	},
}
</script>

<style scoped>
.move-light {
	background-color: #5363fe;
	position: absolute;
	animation: moveLight 2s linear infinite;
}

@keyframes moveLight {
	0% {
		transform: translate(-50%, -50%) scale(1);
	}

	25% {
		transform: translate(-40%, -50%) scale(1.2);
	}

	50% {
		transform: translate(-50%, -65%) scale(1.3);
	}

	75% {
		transform: translate(-60%, -50%) scale(1.2);
	}

	100% {
		transform: translate(-50%, -50%) scale(1);
	}
}
</style>
