export default async function getCurrentUser({ axios, store, i18n }) {
	try {
		const user = await axios.get('/auth/me').then(res => res.data)

		if (user) {
			const [hasProcessingActions, hasUnreadNotifications] = await Promise.all([
				axios.get('/users/pending-jobs').then(res => res.data),
				axios.get('/notifications/has-unread').then(res => res.data),
			])

			store.commit('setUser', user)
			store.commit('setHasProcessingActions', hasProcessingActions)
			store.commit('setHasUnreadNotifications', hasUnreadNotifications)

			i18n.locale = user.lang
		}
	} catch (error) {
		// If the user is not logged in
		store.commit('setUser', null)
	}

	return true
}
