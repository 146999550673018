<template>
	<div class="page-container mt-8">
		<h1 class="text-xl font-bold">
			{{ $t('myAccountMenu.title') }}
		</h1>
		<div class="relative overflow-clip rounded-xl">
			<div class="border-2 border-radial-1-card rounded-xl p-6 mt-4 text-center" v-if="showcasedPlan">
				<img
					class="absolute h-full w-full top-1/2 start-1/2 -translate-x-1/2 object-cover -translate-y-1/2 -z-10 grayscale opacity-40"
					src="/web/images/settings/subscription-banner-bg.jpg"
					alt="Subscription Banner"
				/>
				<div
					class="-z-10 bg-gradient-to-t from-lime-300 to-transparent opacity-60 absolute bottom-0 start-0 w-full h-full"
				></div>
				<p class="text-xl leading-7 mb-4">
					<span
						v-html="
							$t(
								userHasPaidSubscription
									? 'myAccountMenu.subscriptionBannerUpgradeText'
									: 'myAccountMenu.subscriptionBannerText'
							)
						"
					></span>
					<br /><strong>{{ $formatCurrency(showcasedPlan.price) }}</strong>
				</p>
				<Button href="/checkout/plans" color="lime" size="compact">{{
					$t('myAccountMenu.menuOptions.viewSubscriptions')
				}}</Button>
			</div>
		</div>

		<div class="p-6 border border-radial-1-card rounded-xl mt-4 flex justify-between">
			<div class="flex items-center">
				<img
					class="w-[60px] h-[60px] rounded-full border-2 border-white mr-2"
					:src="loggedUser.avatar"
					:alt="loggedUser.userName"
				/>
				<p>
					{{ $t('myAccountMenu.greetings') }} <strong>{{ loggedUser.userName }}</strong>
				</p>
			</div>
			<button class="material-icons" @click="$router.push('/my-account/settings')">edit</button>
		</div>

		<div
			v-for="(itemGroup, index) in menuItemGroups"
			:key="`item-group-${index}`"
			class="border-radial-1-card border rounded-xl mt-4"
		>
			<button
				v-for="item in itemGroup"
				:key="item.id"
				:class="'p-4 flex justify-between w-full border-b-radial-1-card [&:not(:last-child)]:border-b hover:bg-slate-900 rounded-md transition duration-150 ease-in-out'"
				@click="handleClick(item)"
			>
				<div class="flex relative">
					<span class="material-icons mr-2">{{ item.icon }}</span>
					<p>{{ item.label }}</p>
					<div
						v-if="canViewNotificationCircle(item)"
						:class="`h-2 w-2 rounded-full absolute top-0 right-[-1rem] ${
							item.notificationCircleClass ?? ''
						}`"
					/>
				</div>
				<span class="material-icons self-center text-gray-600">arrow_forward_ios</span>
			</button>
		</div>
		<button
			@click="logout"
			class="p-4 flex justify-between w-full border border-radial-1-card mt-4 rounded-xl hover:bg-red-950 rounded-md transition duration-150 ease-in-out"
		>
			<div class="flex text-red-400">
				<span class="material-icons mr-2">logout</span>
				<p>{{ $t('myAccountMenu.menuOptions.logout') }}</p>
			</div>
			<span class="material-icons self-center text-gray-600">arrow_forward_ios</span>
		</button>
	</div>
</template>

<script>
import { FollowUs, Button } from '@/components/default'
import { inject } from 'vue'

export default {
	async setup() {
		const axios = inject('axios')
		const result = await axios.get('/plans/next-upgrade').then(res => res.data)

		return { showcasedPlan: result ? result : null }
	},

	data() {
		return {
			notificationsCount: 0,
			menuItemGroups: [
				[
					{
						id: 'notifications',
						label: this.$t('myAccountMenu.menuOptions.notifications'),
						href: '/my-account/notifications',
						notificationCircleClass: 'bg-red-500',
						icon: 'notifications',
					},
					{
						id: 'settings',
						label: this.$t('myAccountMenu.menuOptions.settings'),
						href: '/my-account/settings',
						icon: 'settings',
					},
					{
						id: 'language',
						label: this.$t('myAccountMenu.menuOptions.language'), // Language
						href: '/my-account/language',
						icon: 'translate',
					},
				],
				[
					{
						id: 'models',
						label: this.$t('myAccountMenu.menuOptions.models'), // Stored Models
						href: '/my-account/models',
						icon: 'photo_camera',
					},
					{
						id: 'library',
						label: this.$t('myAccountMenu.menuOptions.library'),
						href: '/my-account/library',
						notificationCircleClass: 'bg-gray-400 animate-pulse',
						icon: 'photo_library',
					},
				],

				[
					{
						id: 'my-subscription',
						label: this.$t('myAccountMenu.menuOptions.mySubscription'), // My subscription
						href: '/my-account/subscription',
						icon: 'local_mall',
					},
					{
						id: 'view-subscriptions',
						label: this.$t('myAccountMenu.menuOptions.viewSubscriptions'), // View subscriptions
						href: '/checkout/plans',
						icon: 'shop',
					},
					{
						id: 'points-extract',
						label: this.$t('myAccountMenu.menuOptions.extract'), // Points extract
						href: '/my-account/balance',
						icon: 'history',
					},
				],
				[
					{
						id: 'admin-panel',
						label: this.$t('myAccountMenu.menuOptions.adminPanel'),
						href: '/admin',
						onlyAdminCanView: true,
						icon: 'admin_panel_settings',
					},
				],
				// [
				// {
				// id: 'friends',
				// 	label: this.$t('myAccountMenu.menuOptions.friends'),
				// 	href: '/my-account/friends',
				// },
				// ],
			],
		}
	},
	methods: {
		handleClick(menuItem) {
			this.captureEvent(`menu_goto_${menuItem.id}`)

			if (menuItem.href.startsWith('https://')) {
				window.open(menuItem.href, '_blank')
			} else {
				this.$router.push(menuItem.href)
			}
		},
		async logout() {
			try {
				await this.axios.post('/auth/logout')
				await this.$store.dispatch('logout')
				this.captureEvent('logout_success')
				this.$posthog?.reset()
				location.reload()
			} catch (err) {
				this.sentryCaptureException(err)
				this.captureEvent('logout_error')
			}
		},
	},
	async created() {
		const { data } = await this.axios.get('/notifications/unread-count')
		this.notificationsCount = data.count
	},

	computed: {
		computedMenuItems() {
			return this.menuItems.filter(item => !item.onlyAdminCanView || this.isAdmin)
		},
		isAdmin() {
			return this.$store.getters.getUser?.role === 'admin'
		},
		canViewNotificationCircle() {
			return item => {
				const hasPendingJobs = this.hasProcessingActions.model || this.hasProcessingActions.prediction
				const menuItemsAvailable = ['notifications', 'library']

				switch (item.id) {
					case 'notifications':
						return this.notificationsCount > 0
				}

				return menuItemsAvailable.includes(item.id) && hasPendingJobs
			}
		},
		hasProcessingActions() {
			return this.$store.getters.getHasProcessingActions
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
		userHasPaidSubscription() {
			return this.loggedUser && this.loggedUser.plan && this.loggedUser.plan.free == false
		},
	},
	head: {
		title: `RealityStudio - Menu`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Menu`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
	components: { FollowUs, Button },
}
</script>
