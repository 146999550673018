<template>
	<div>
		<div
			class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-4 items-center justify-center my-6"
			v-if="availableCycles.length"
		>
			<button
				v-for="cycle in availableCycles"
				:key="cycle.label"
				class="border rounded-xl border-gray-500 py-4 px-6 font-bold"
				:class="{
					'text-white border-radial-1-card bg-blue-600': activeCycle === cycle.id,
				}"
				@click="handleChangeCycle(cycle)"
			>
				{{ cycle.label }}
			</button>
		</div>

		<div class="graph-card" v-show="!isFetching">
			<ChartCard
				v-if="analyticsData"
				:centerText="stringifyCycle"
				:biggerData="{
					label: $t('admin.analytics.tabs.sales'),
					value: analyticsData.salesCount,
					percentage: analyticsData.salesPercentage,
				}"
				:smallerData="{
					label: $t('admin.analytics.tabs.users'),
					value: analyticsData.usersCount,
				}"
			/>

			<ChartCard
				v-if="analyticsData"
				:centerText="stringifyCycle"
				:biggerData="{
					label: $t('admin.analytics.graph.chartCard.ActiveSubs'),
					value: analyticsData.subscriptions.activeCount,
					percentage: analyticsData.activeSubscriptionsPercentage,
				}"
				:smallerData="{
					label: $t('admin.analytics.graph.chartCard.inactiveSubs'),
					value: analyticsData.inactiveSubscriptionsCount,
					percentage: analyticsData.inactiveSubscriptionsPercentage,
				}"
			/>

			<div class="analytics-card h-[468px] border-2 border-radial-1-card">
				{{ $t('admin.analytics.graph.comingSoon') }}
			</div>
		</div>

		<div class="graph-card">
			<div v-for="card in analyticsCards" :key="card.id" class="analytics-card border-2" :class="card.extraClass">
				<span class="material-icons text-[32px]">
					{{ card.icon }}
				</span>
				<p class="font-bold text-3xl">
					{{ card.value ?? 'N/A' }}
				</p>
				<span>{{ card.label }} ({{ stringifyCycle }})</span>
			</div>
		</div>
		<div class="grid grid-cols-12 gap-8" v-if="isFetching">
			<div
				class="analytics-card border-none h-[468px] bg-radial-1-card animate-pulse"
				:key="index"
				v-for="(n, index) in 3"
			>
				<RealityAvatarIcon class="animate-pulse-with-scale w-[40px]" />
			</div>

			<div
				class="analytics-card border-none h-[190px] bg-radial-1-card animate-pulse"
				:key="index"
				v-for="(n, index) in 3"
			>
				<RealityAvatarIcon class="animate-pulse-with-scale w-[40px]" />
			</div>
		</div>
	</div>
</template>

<script>
import AnimatedSpinner from '@/components/default/AnimatedSpinner.vue'
import RealityAvatarIcon from '@/components/icons/RealityAvatarIcon.vue'
import ChartCard from '@/components/admin/analytics/ChartCard.vue'

/**
 * @todo: get the exchange rate from somewhere else so this can be as accurate as possible
 */
const USD_TO_BRL_EXCHANGE_RATE = 4.9

export default {
	data() {
		return {
			analyticsData: null,
			isFetching: false,
			activeCycle: null,
			availableCycles: [
				{
					id: null,
					label: this.$t('admin.analytics.graph.all'),
				},
				{
					id: 'day',
					label: '24h',
				},
				{
					id: 'week',
					label: '7d',
				},
				{
					id: 'month',
					label: '30d',
				},
			],
		}
	},
	created() {
		this.fetchAnalytics()
	},
	methods: {
		generateAnalyticsCardArray(analyticsData) {
			return [
				{
					id: 'purchases-attempts',
					label: this.$t('admin.analytics.graph.purchasesAttempts'),
					value: analyticsData.purchases.attemptsCount,
					icon: 'pending',
				},
				{
					id: 'purchases-success',
					label: this.$t('admin.analytics.graph.paymentsAccepted'),
					value: analyticsData.purchases.successCount,
					icon: 'check_circle',
					extraClass: 'border-lime-400',
				},
				{
					id: 'purchases-failed',
					label: this.$t('admin.analytics.graph.paymentsFailed'),
					value: analyticsData.purchases.failedCount,
					icon: 'cancel',
					extraClass: 'border-red-400',
				},
				{
					id: 'revenue',
					label: this.$t('admin.analytics.graph.revenue'),
					value: this.calculateTotalRevenueInBrl(analyticsData.revenues),
					icon: 'attach_money',
					extraClass: 'border-radial-1-card',
				},
				{
					id: 'subscriptions-new',
					label: this.$t('admin.analytics.graph.newSubscriptions'),
					value: analyticsData.subscriptions.newCount,
					icon: 'shopping_cart',
					extraClass: 'border-radial-1-card',
				},
				{
					id: 'subscriptions-active',
					label: this.$t('admin.analytics.graph.activeSubscriptions'),
					value: analyticsData.subscriptions.activeCount,
					icon: 'draw',
					extraClass: 'border-radial-1-card',
				},
				{
					id: 'nps-like-percentage',
					label: this.$t('admin.analytics.graph.positiveFeedbacks'),
					value: `${analyticsData.nps.likePercentage}%`,
					icon: 'brush',
					extraClass: 'border-radial-1-card',
				},
				{
					id: 'nps-share',
					label: this.$t('admin.analytics.graph.sharedAvatars'),
					value: analyticsData.nps.shareCount,
					icon: 'share',
					extraClass: 'border-radial-1-card',
				},
				{
					id: 'nps-avatars-created',
					label: this.$t('admin.analytics.graph.createdAvatars'),
					value: analyticsData.nps.avatarsCreatedCount,
					icon: 'auto_awesome',
					extraClass: 'border-radial-1-card',
				},
			]
		},
		handleChangeCycle(cycle) {
			this.activeCycle = cycle.id
			this.fetchAnalytics()
		},
		calculateTotalRevenueInBrl(revenues) {
			if (!revenues.length) return 'R$0'
			const [brlRevenue, usdRevenue] = [
				revenues.find(r => r.currency == 'brl')?.value || 0,
				revenues.find(r => r.currency == 'usd')?.value || 0,
			]
			return 'R$ ' + (brlRevenue + usdRevenue * USD_TO_BRL_EXCHANGE_RATE).toFixed(2)
		},
		async fetchAnalytics() {
			this.isFetching = true
			try {
				const params = {
					type: this.activeCycle,
				}
				const { data } = await this.axios.get('/admin/analytics/graph', { params })

				this.analyticsData = data
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.isFetching = false
		},
	},
	computed: {
		analyticsCards() {
			if (!this.analyticsData) return []
			return this.generateAnalyticsCardArray(this.analyticsData)
		},
		stringifyCycle() {
			const cycle = this.availableCycles.find(c => c.id === this.activeCycle)
			return cycle?.label || ''
		},
	},
	components: { ChartCard, AnimatedSpinner, RealityAvatarIcon },
}
</script>

<style scoped>
.analytics-card {
	@apply col-span-4 gap-4 rounded-xl border flex flex-col items-center justify-center py-8;
}

.graph-card {
	@apply grid grid-cols-1 mt-12 gap-8 md:grid-cols-8 lg:grid-cols-12;
}
</style>
