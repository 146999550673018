<template>
	<div class="lg:max-w-96">
		<form @submit.prevent="handleCreate">
			<Select
				class="my-6"
				:options="modelTypes"
				:value="modelData.modelType"
				:menuTitle="$t('admin.presetsPage.modelCreationForm.selectMenuTitle')"
				@onChange="modelData.modelType = $event"
			/>

			<Input
				id="modelName"
				type="text"
				:label="$t('admin.presetsPage.modelCreationForm.modelNameLabel')"
				:placeholder="$t('admin.presetsPage.modelCreationForm.chooseNamePlaceholder')"
				v-model="modelData.name"
			/>

			<div>
				<label
					class="mt-6 cursor-pointer flex flex-col items-center justify-center border border-dashed rounded-xl py-6"
					for="zippedImages"
					:class="{
						'border-lime-400': generatedZip,
						'border-gray-800': !generatedZip,
					}"
				>
					<Spinner v-if="isUploadingImages" />
					<div class="flex flex-col items-center justify-center" v-else>
						<span class="material-symbols-outlined text-3xl mb-2">
							{{ generatedZip ? 'done' : 'cloud_upload' }}
						</span>
						<p class="font-bold">
							{{
								generatedZip
									? $t('admin.presetsPage.modelCreationForm.imagesUploaded')
									: $t('admin.presetsPage.modelCreationForm.uploadImages')
							}}
						</p>
					</div>
				</label>
				<FileInput
					inputAccept=".zip"
					@onFileSelect="files => onFileSelect(Array.from(files)[0])"
					inputId="zippedImages"
					:style="{
						display: 'none',
					}"
				/>
			</div>

			<Button class="mt-6" @click="handleCreate" type="submit" :isLoading="isCreating" :disabled="!isValidForm">
				{{ $t('admin.presetsPage.modelCreationForm.createModel') }}
			</Button>
		</form>
	</div>
</template>

<script>
import { Input, Select, Button, FileInput, Spinner } from '@/components/default'
import JSZip from 'jszip'

export default {
	name: 'ModelCreationForm',
	data() {
		return {
			isCreating: false,
			isUploadingImages: false,
			modelTypes: [
				{
					label: this.$t('admin.presetsPage.modelCreationForm.face'),
					value: 'face',
				},
				{
					label: 'Object',
					value: 'object',
				},
				{
					label: this.$t('admin.presetsPage.modelCreationForm.style'),
					value: 'style',
				},
			],
			modelData: {
				name: '',
				modelType: '',
				fileKey: '',
			},
			generatedZip: null,
		}
	},
	methods: {
		async handleCreate() {
			if (this.isCreating) {
				return
			}

			this.isCreating = true

			try {
				const { url, key } = await this.axios
					.get(`/files/sign-url/${this.loggedUser?._id}.zip?generateModel=true`)
					.then(res => res.data)
				await this.axios.put(url, this.generatedZip)

				this.modelData.fileKey = key

				await this.axios.post('/models', this.modelData)
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isCreating = false
		},
		async onFileSelect(file) {
			if (this.isUploadingImages || !file) {
				return
			}

			this.isUploadingImages = true

			try {
				const zip = new JSZip()
				const zippedFile = await JSZip.loadAsync(file)

				await Promise.all(
					Object.values(zippedFile.files).map(async zipEntry => {
						const blob = await zipEntry.async('blob')
						zip.file(zipEntry.name, blob)
					})
				)

				const generatedZip = await zip.generateAsync({ type: 'blob' })

				this.generatedZip = generatedZip
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isUploadingImages = false
		},
	},
	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
		isValidForm() {
			return !!this.modelData.name && !!this.modelData.modelType && !!this.generatedZip
		},
	},
	components: {
		Input,
		Select,
		Button,
		Spinner,
		FileInput,
	},
}
</script>
