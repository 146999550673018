<template>
	<div class="h-full mb-24 flex flex-col relative page-container mt-8">
		<div class="text-center text-xl">
			{{ $t('researchResult.showResultFor') }} <br />
			<!-- <span class="font-bold">{{ searchParam }}</span> -->
			<form class="mt-6">
				<Input id="search" v-model="searchParam" :placeholder="$t('researchIndex.searchPlaceholder')" />
				<!-- <Button @click="(results = []); (makeSearch())">
					<span class="material-icons text-3xl">search</span>
				</Button> -->
			</form>
		</div>

		<div class="flex space-x-2 mt-6">
			<Button
				@click="addFilter('all')"
				class="text-white p-2"
				size="fit"
				:class="{
					'bg-primary': currentFilters.includes('all'),
					'bg-dark-gray': !currentFilters.includes('all'),
				}"
				>{{ $t('researchResult.filterAll') }}</Button
			>

			<Button
				v-for="filter in availableFilters"
				:key="'filter-' + filter"
				@click="addFilter(filter.toLowerCase())"
				class="text-white p-2"
				size="fit"
				:class="{
					'bg-primary': currentFilters.includes(filter.toLowerCase()),
					'bg-dark-gray': !currentFilters.includes(filter.toLowerCase()),
				}"
			>
				{{ filter }}</Button
			>
		</div>

		<template v-if="results.length">
			<MasonryGrid :items="filteredResults" v-slot="slotProps" class="mt-6">
				<ResearchCard
					:item="slotProps.item"
					@onAddBookmark="handleAddToBookmark"
					@onClickImage="openDetails"
				></ResearchCard>
			</MasonryGrid>
		</template>

		<template v-if="isSearching && !results.length">
			<MasonryGrid :items="[1, 0]" v-slot="slotProps" class="mt-6">
				<div
					class="rounded-xl w-full object-cover bg-slate-700 animate-pulse"
					:class="{
						'landscape-image': slotProps.item % 2 === 0,
						'portrait-image': slotProps.item % 2 !== 0,
					}"
				/>
			</MasonryGrid>
		</template>

		<div v-if="isSearching && results.length" class="w-full flex items-center justify-center mt-6">
			<Spinner />
		</div>

		<FloatingButton>
			<Button href="/research">
				{{ $t('researchResult.tryAgainBtn') }}
			</Button>
		</FloatingButton>
	</div>
	<div
		v-if="isBookmarkModalOpened"
		class="fixed flex items-center justify-center top-0 left-0 z-[90] w-screen h-screen"
		id="modal"
	>
		<div @click="() => (isBookmarkModalOpened = false)" class="bg-black/50 w-full h-full"></div>
		<div
			class="absolute w-full lg:w-2/6 border-t border-x py-6 border-[#1F262F] rounded-t-xl bottom-0 h-[500px] bg-black animate-fade-up animate-duration-300"
		>
			<div v-if="!createCollectionModalOpened">
				<div class="flex justify-between items-center border-b border-[#1F262F] px-4 pb-6">
					<h1 class="font-bold text-xl">
						{{ $t('researchResult.bookmarkModal.title') }}
					</h1>

					<Button size="fit" color="transparent" @click="() => (isBookmarkModalOpened = false)">
						<span class="material-icons text-3xl">close</span>
					</Button>
				</div>

				<div class="px-4 mt-4 space-y-4 overflow-y-scroll h-[300px]" ref="collectionsWrapper">
					<Button
						size="fit"
						color="transparent"
						@click="() => handleBookmarkContent(collection)"
						v-for="collection in collections.results"
						:key="collection._id"
						buttonClass="rounded-xl flex w-full items-center justify-between border border-[#1F262F] p-4"
					>
						<div class="flex justify-between">
							<div
								v-if="!collection.content.length"
								class="bg-zinc-400 w-[50px] h-[50px] rounded-xl"
							></div>
							<img
								v-else
								:src="collection.content[collection.content.length - 1].image"
								alt="Collection thumbnail"
								class="object-cover w-[50px] h-[50px] rounded-xl"
							/>
							<div class="ml-4 text-left">
								<p class="text-base font-medium">{{ collection.name }}</p>
								<span class="text-[#6B7280] text-sm mt-4 text-left"
									>{{ collection.content.length }} {{ $t('researchGallery.favorites') }}</span
								>
							</div>
						</div>
						<span class="material-icons text-3xl" v-if="isWithin(collection)">check_circle</span>
					</Button>
				</div>

				<div class="mx-4 grid gap-4 grid-cols-12">
					<Button class="col-span-9" @click="() => (isBookmarkModalOpened = false)">Done</Button>
					<Button
						color="transparent"
						class="border border-[#1F262F] rounded-xl col-span-3 w-full h-full flex items-center justify-center"
						@click="() => (createCollectionModalOpened = true)"
					>
						<span class="material-icons text-3xl"> add </span>
					</Button>
				</div>
			</div>

			<form @submit.prevent="createCollection" v-else>
				<div class="flex justify-between items-center border-b border-[#1F262F] px-4 pb-6">
					<h1 class="font-bold text-xl">
						{{ $t('researchResult.createCollectionModal.title') }}
					</h1>

					<Button @click="() => (isBookmarkModalOpened = false)" size="fit" color="transparent">
						<span class="material-icons text-3xl">close</span>
					</Button>
				</div>

				<div class="px-4">
					<Input
						id="collectionName"
						v-model="collectionData.name"
						class="mt-4"
						:placeholder="$t('researchResult.createCollectionModal.nameInputPlaceholder')"
						:label="$t('researchResult.createCollectionModal.nameInputLabel')"
					/>
				</div>

				<div class="absolute bottom-0 w-[calc(100%-32px)] mx-4 mb-6 grid gap-4 grid-cols-12">
					<Button
						color="transparent"
						class="border border-[#1F262F] rounded-xl col-span-3 w-full h-full flex items-center justify-center"
						@click=";(createCollectionModalOpened = false), (collectionData.name = '')"
					>
						<span class="material-icons text-3xl"> chevron_left </span>
					</Button>
					<Button class="col-span-9" @click="createCollection" :isLoading="isCreatingCollection">{{
						$t('researchResult.createCollectionModal.createCollectionBtn')
					}}</Button>
				</div>
			</form>
		</div>
	</div>
	<div
		v-if="isOpenDetails"
		class="fixed top-0 left-0 z-30 w-screen h-screen lg:w-2/4 lg:left-1/2 lg:-translate-x-1/2"
		id="modal"
	>
		<div class="mx-auto">
			<Button
				@click="closeDetails"
				size="fit"
				color="transparent"
				buttonClass="border-2 border-white/5 bg-[#2C3843]/80 rounded-full p-3 absolute top-4 right-4 z-20"
			>
				<span class="material-symbols-outlined">close</span>
			</Button>

			<div class="absolute top-0 left-0 bg-black w-screen h-screen -z-10" @click="closeDetails"></div>

			<div class="">
				<LazyImage
					class="h-screen w-full"
					:src="detailsData.image"
					:alt="detailsData.title"
					:allowZoom="true"
					:imgClass="[
						'cursor-pointer h-fit z-10',
						{
							'w-screen': isMobile,
							'!h-[75vh]': detailsData.dimension === 'portrait',
							'object-contain my-auto': detailsData.dimension === 'landscape' && isMobile,
							'w-full': detailsData.dimension === 'landscape' && !isMobile,
							'mx-auto': detailsData.dimension === 'portrait' && !isMobile,
						},
					]"
				/>
			</div>

			<Button target="_blank" :href="detailsData.link" class="absolute bottom-4 w-[calc(100%-32px)] ml-4 z-20">
				{{ $t('researchResult.visitPage') }}</Button
			>
		</div>
	</div>
</template>

<script>
import { Spinner, Button, Input, FloatingButton, MasonryGrid, LazyImage } from '@/components/default'
import ResearchCard from '../../components/research/ResearchCard.vue'

export default {
	name: 'ResearchResult',
	components: {
		ResearchCard,
		LazyImage,
		Button,
		Input,
		FloatingButton,
		Spinner,
		MasonryGrid,
	},
	data() {
		return {
			searchTimeout: null,
			searchParam: '',
			results: [],
			isSearching: false,
			pageNumber: 1,
			bookmarkData: {},
			isBookmarkModalOpened: false,
			collections: {
				hasNext: false,
				results: [],
				next: '',
			},
			isFetchingCollections: false,
			createCollectionModalOpened: false,
			isCreatingCollection: false,
			collectionData: {
				name: '',
			},

			pagination: {
				behance: '',
				dribbble: 1,
				pinterest: '',
			},
			mockupWindow: null,
			isOpenDetails: false,
			detailsData: null,
			currentFilters: ['all'],
			availableFilters: ['Dribbble', 'Behance', 'Pinterest'],
		}
	},
	async mounted() {
		this.searchParam = this.$route.params.search
		this.mockupWindow = this.getWindow()

		await Promise.all([this.makeSearch(), this.fetchCollections(true)])

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScrollPage)

		this.$refs.collectionsWrapper?.addEventListener('scroll', this.onScrollCollections)
	},
	beforeUnmount() {
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScrollPage)
		this.$refs.collectionsWrapper?.removeEventListener('scroll', this.onScrollCollections)
	},
	watch: {
		searchParam(newValue, oldValue) {
			if (newValue === oldValue) {
				return
			}

			const queryParams = new URLSearchParams(window.location.search)
			queryParams.set('search', newValue)
			const newUrl = `${window.location.origin + '/research/' + newValue}`
			history.replaceState(null, '', newUrl)

			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout)
			}

			this.searchTimeout = setTimeout(() => {
				this.results = []
				this.makeSearch()
			}, 1000)
		},
		isOpenDetails(newValue) {
			this.$store.commit('setIsDisplayingFooter', !newValue)
		},
	},
	methods: {
		addFilter(filter) {
			if (filter === 'all') {
				this.currentFilters = ['all']
				return
			}

			if (this.currentFilters.includes('all')) {
				this.currentFilters = [filter]
				return
			}

			if (this.currentFilters.includes(filter)) {
				this.currentFilters = this.currentFilters.filter(item => item !== filter)
			} else {
				this.currentFilters.push(filter)
			}

			if (!this.currentFilters.length) {
				this.currentFilters = ['all']
			}
		},
		closeDetails() {
			this.isOpenDetails = false
		},
		openDetails(item) {
			this.isOpenDetails = true
			this.detailsData = item
		},
		onScrollPage() {
			let isScrolledToBottom = false

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow
				const mockupMarginInPX = 96

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight - mockupMarginInPX
			}

			if (isScrolledToBottom) {
				this.makeSearch()
			}
		},
		onScrollCollections() {
			let isScrolledToBottom = false

			const { scrollTop, offsetHeight, scrollHeight } = this.$refs?.collectionsWrapper

			isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight

			if (isScrolledToBottom) {
				this.fetchCollections()
			}
		},
		shuffleArr(v, r = [...v]) {
			return r.map((x, i, _, j = ~~(Math.random() * (r.length - i)) + i) => (([x, r[j]] = [r[j], x]), x))
		},
		async handleBookmarkContent(collection) {
			collection.loading = true

			try {
				const isWithin = this.isWithin(collection)

				await this.axios.post(`/research-collections/${collection._id}/${!isWithin ? 'add' : 'remove'}`, {
					...this.bookmarkData,
					collection: collection._id,
				})

				if (!isWithin) {
					collection.content.push(this.bookmarkData)
				} else {
					collection.content = collection.content.filter(item => item.image !== this.bookmarkData.image)
				}
			} catch (error) {
				console.error(error)
			}

			collection.loading = false
		},
		async handleAddToBookmark(data) {
			this.bookmarkData = data
			this.createCollectionModalOpened = false
			this.isBookmarkModalOpened = true
		},
		async makeSearch() {
			if (this.isSearching) {
				return
			}

			this.isSearching = true

			try {
				const url = new URLSearchParams('')
				url.append('search', this.searchParam)
				url.append('limit', 5)

				if (this.pagination.dribbble?.nextPage) {
					url.append('dribbbleNextPage', this.pagination.dribbble.nextPage)
				}

				if (this.pagination.behance?.nextPage) {
					url.append('behanceNextPage', this.pagination.behance.nextPage)
				}

				if (this.pagination.pinterest?.nextPage) {
					url.append('pinterestNextPage', this.pagination.pinterest.nextPage)
				}

				const { data, pagination } = await this.axios.get(`/research?${url}`).then(res => res.data)

				this.pagination = pagination
				this.results = [...this.results, ...this.shuffleArr(data)]
			} catch (error) {
				console.error(error)
			}

			this.isSearching = false
		},
		async fetchCollections(forceFetch) {
			if (this.isFetchingCollections || (!forceFetch && !this.collections.hasNext)) {
				return
			}

			this.isFetchingCollections = true

			try {
				const { data } = await this.axios.get(
					`/research-collections?${this.collections.hasNext ? `next=${this.collections.next}` : ''}`
				)

				this.collections.hasNext = data.hasNext
				this.collections.next = data.next
				this.collections.results = [...this.collections.results, ...data.results]
			} catch (error) {
				console.error(error)
			}

			this.isFetchingCollections = false
		},
		async createCollection() {
			if (this.isCreatingCollection) {
				return
			}

			this.isCreatingCollection = true

			try {
				const { data } = await this.axios.post('/research-collections', this.collectionData)

				this.collections.results.push(data)
				await this.handleBookmarkContent(this.collections.results[this.collections.results.length - 1])

				this.createCollectionModalOpened = false
				this.collectionData.name = ''
			} catch (error) {
				console.error(error)
			}

			this.isCreatingCollection = false
		},
		isWithin(collection) {
			return collection.content.some(item => item.image === this.bookmarkData.image)
		},
	},
	computed: {
		filteredResults() {
			if (this.currentFilters.includes('all')) {
				return this.results
			}

			return this.results.filter(item => {
				return this.currentFilters.some(filter => item.site === filter)
			})
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
	},
	head: {
		title: `Reality Avatar - Research`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with Reality Avatar!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `Reality Avatar - Research`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with Reality Avatar!',
			},
		],
	},
}
</script>

<style scoped>
.landscape-image {
	height: 140px;
}

.portrait-image {
	height: 250px;
}
</style>
