<template>
	<div class="page-container">
		<AuthLogo />

		<h1 class="text-xl font-semibold text-center">
			{{ $t('redefinePassword.title') }}
		</h1>
		<p class="mt-4 text-gray-500 text-center">
			{{ $t('redefinePassword.subtitle') }}
		</p>

		<form class="mt-6" @submit.prevent="onSubmit">
			<Input
				id="password"
				v-model="newPassword"
				:label="$t('redefinePassword.newPasswordLabel')"
				:placeholder="$t('redefinePassword.newPasswordPlaceholder')"
				type="password"
				required
			/>
			<HCaptcha ref="hCaptcha" class="mt-6" @onVerify="token => (captchaToken = token)" />
			<Button type="submit" class="my-6" :isLoading="isRedefining">
				{{ $t('redefinePassword.submitButton') }}
			</Button>
		</form>
		<div class="text-lg leading-7 w-fit mx-auto">
			{{ $t('redefinePassword.alreadyHaveAccount') }}
			<router-link class="font-bold" to="login" @click.native="captureEvent('redefine_password_goto_login')">
				{{ $t('redefinePassword.loginLink') }}
			</router-link>
		</div>
	</div>
</template>

<script>
import { Button, Input } from '@/components/default'
import { HCaptcha } from '../components/default'
import { Logo as AuthLogo } from '@/components/auth'

export default {
	components: {
		Button,
		Input,
		HCaptcha,
		AuthLogo,
	},
	data() {
		return {
			isRedefining: false,
			newPassword: '',
			captchaToken: '',
		}
	},
	mounted() {
		if (!this.$route.query.token) {
			this.$router.push('/login')
		}
	},
	methods: {
		async onSubmit() {
			this.isRedefining = true

			try {
				await this.axios.post('/auth/reset-password', {
					redefineToken: this.$route.query.token,
					password: this.newPassword,
					token: this.captchaToken,
				})

				this.$toast({
					type: 'success',
					title: this.$t('common.toastTitle.success'),
					message: this.$t('redefinePassword.submitSuccess'),
				})

				this.$router.push('/login')

				this.captureEvent('redefine_password_submit_success')
			} catch (error) {
				this.$refs.hCaptcha.reset()
				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('redefinePassword.submitError'),
				})
				this.captureEvent('redefine_password_submit_error')
			}

			this.isRedefining = false
		},
	},
	head: {
		title: `RealityStudio - Redefine Password`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Redefine Password`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
