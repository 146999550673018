<template>
	<div class="w-full">
		<div :class="disabled ? 'opacity-30' : 'opacity-100'">
			<slot name="label" class="w-full"></slot>
			<div class="flex items-center justify-start my-4">
				<div class="relative flex items-center justify-start w-full">
					<div class="progress-bar bg-blue-600 absolute h-[16px] z-10 rounded-l-3xl" ref="progressBar" />
					<input
						:id="id"
						:name="name"
						type="range"
						:min="parsedMin"
						:max="parsedMax"
						:step="step"
						:value="parsedModelValue"
						:disabled="disabled"
						@input="onChange"
						class="slider bg-radial-2-card outline-none w-full appearance-none h-[16px] [&::-webkit-slider-thumb]:!z-20 p-0 rounded-3xl"
					/>
				</div>
				<input
					class="border text-center border-radial-1-card w-[60px] justify-center flex ml-2 rounded-md bg-transparent [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
					@change="e => handleInputChange(e.target.value)"
					:value="shownInputValue"
					:min="parsedMin"
					:max="parsedMax"
					type="number"
				/>
			</div>
		</div>
		<slot name="footer"></slot>
	</div>
</template>
<script>
export default {
	props: {
		min: {
			type: Number,
			required: true,
		},
		max: {
			type: Number,
			required: true,
		},
		modelValue: {
			type: Number,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		step: {
			type: Number,
			default: 0.01,
		},
	},
	watch: {
		parsedModelValue(newValue) {
			this.updateProgressBar(newValue)
		},
	},
	computed: {
		parsedMin() {
			return Number.isInteger(this.step) ? this.min : Math.round(this.min * 100)
		},
		parsedMax() {
			return Number.isInteger(this.step) ? this.max : Math.round(this.max * 100)
		},
		parsedModelValue() {
			return Number.isInteger(this.step) ? this.modelValue : Math.round(this.modelValue * 100)
		},
		checkValueTypes() {
			return Number.isInteger(this.min) && Number.isInteger(this.max)
		},
		shownInputValue() {
			return this.checkValueTypes ? this.parsedModelValue : parseFloat(this.parsedModelValue / 100)
		},
	},
	mounted() {
		this.updateProgressBar(this.parsedModelValue)
	},
	methods: {
		handleInputChange(value) {
			value = Number.isInteger(this.step) ? parseInt(value) : parseFloat(value * 100)
			if (value > this.parsedMax) {
				value = this.parsedMax
			} else if (value < this.parsedMin) {
				value = this.parsedMin
			}

			this.$emit(
				'update:modelValue',
				Number.isInteger(this.step) && this.checkValueTypes ? parseInt(value) : parseFloat(value / 100)
			)
		},
		updateProgressBar(value) {
			if (!this.parsedModelValue) return
			const element = this.$refs.progressBar
			const totalWidth = ((value - this.parsedMin) / (this.parsedMax - this.parsedMin)) * 100
			const thumbSize = (totalWidth / 100) * 23
			element.style.width = `calc(${totalWidth}% - ${thumbSize}px)`
		},
		onChange(e) {
			this.$emit(
				'update:modelValue',
				Number.isInteger(this.step) ? parseInt(e.target.value) : parseFloat(e.target.value / 100)
			)
		},
	},
}
</script>

<style scoped>
.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 24px;
	height: 24px;
	background: white;
	cursor: pointer;
	border-radius: 50%;
}

.slider::-moz-range-thumb {
	width: 24px;
	height: 24px;
	background: white;
	cursor: pointer;
	border-radius: 50%;
}
</style>