import { ref, onMounted, onUnmounted } from 'vue'

const MOBILE_BREAKPOINT = 768

export default function useDeviceDimensions() {
	const isMobile = ref(typeof window !== 'undefined' && window.innerWidth <= MOBILE_BREAKPOINT)

	const resizeListener = () => {
		isMobile.value = window.innerWidth <= MOBILE_BREAKPOINT
	}

	onMounted(() => {
		window.addEventListener('resize', resizeListener)
	})

	onUnmounted(() => {
		window.removeEventListener('resize', resizeListener)
	})

	return { isMobile }
}
