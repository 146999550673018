<template>
	<div>
		<div class="flex gap-4 items-center justify-center my-6" v-if="availableCycles.length">
			<button
				v-for="cycle in availableCycles"
				:key="cycle.label"
				class="border rounded-xl border-gray-500 py-4 px-6 font-bold"
				:class="{
					'text-white border-radial-1-card bg-blue-600': activeCycle === cycle.id,
				}"
				@click="handleChangeCycle(cycle)"
			>
				{{ cycle.label }}
			</button>
		</div>
		<div class="grid grid-cols-12 gap-6" v-if="!isFetching && sales?.results?.length">
			<div
				class="col-span-12 p-4 flex items-center rounded-xl border border-radial-1-card"
				v-for="sale in sales.results"
				:key="sale._id"
			>
				<img :src="sale.plan.image" alt="Image" class="rounded-xl w-[90px] h-[90px] object-cover mr-4" />
				<div class="flex items-center justify-between flex-1">
					<div>
						<div>
							<span class="text-gray-400">({{ $dayjs(sale.createdAt).format('DD/MM/YYYY') }})</span>
							<span>
								{{ $t('admin.analytics.sales.subscription') }}: <strong>{{ sale.plan.name }}</strong>
							</span>
						</div>
						<p class="font-white font-bold my-4">{{ $formatCurrency(sale.price) }}</p>
						<p class="text-gray-400">{{ $t('admin.analytics.sales.orderId') }}: {{ sale._id }}</p>
					</div>
					<img
						v-if="sale.status === 'paid'"
						src="/web/images/admin/sale_completed.png"
						alt="Sale Completed"
					/>
					<img v-if="sale.status === 'failed'" src="/web/images/admin/sale_failed.png" alt="Sale Failed" />
				</div>
			</div>
		</div>
		<div v-if="!isFetching && !sales?.results?.length">
			<h1>{{ $t('admin.analytics.sales.noSales') }}</h1>
		</div>

		<div class="grid grid-cols-12 gap-6" v-if="isFetching && !sales?.results?.length">
			<div
				v-for="i in 3"
				:key="i"
				class="col-span-12 h-[140px] w-full animate-pulse bg-radial-1-card rounded-xl flex items-center justify-center"
			>
				<RealityAvatarIcon class="animate-pulse-with-scale w-[40px]" />
			</div>
		</div>
	</div>
</template>

<script>
import RealityAvatarIcon from '@/components/icons/RealityAvatarIcon.vue'
export default {
	components: {
		RealityAvatarIcon,
	},
	data() {
		return {
			sales: null,
			isFetching: false,
			activeCycle: 'day',
			availableCycles: [
				{
					id: 'day',
					label: '24h',
				},
				{
					id: 'week',
					label: '7d',
				},
				{
					id: 'month',
					label: '30d',
				},
			],
		}
	},
	mounted() {
		this.fetchAnalytics()

		window.addEventListener('scroll', this.onScroll)
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.onScroll)
	},
	methods: {
		handleChangeCycle(cycle) {
			this.activeCycle = cycle.id
			this.fetchAnalytics()
		},
		onScroll() {
			let isScrolledToBottom = false

			const { scrollTop, offsetHeight, scrollHeight } = window

			isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight

			if (isScrolledToBottom) {
				this.fetchAnalytics(true)
			}
		},
		async fetchAnalytics(forceFetch) {
			if (this.isFetching || (!forceFetch && !this.sales?.hasNext)) {
				return
			}

			this.isFetching = true

			try {
				const params = {
					type: this.activeCycle,
					next: this.sales?.hasNext ? this.sales?.next : null,
				}

				const { data } = await this.axios.get('/admin/analytics/sales', {
					params,
				})

				this.sales = data
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetching = false
		},
	},
}
</script>
