<template>
	<form class="py-6 flex flex-col gap-4">
		<p class="text-xl">Update your password</p>
		<div class="flex flex-col gap-2">
			<Label>Your password:</Label>
			<input type="password" v-model="password" class="border leading-10 px-4 rounded" />
		</div>
		<div class="flex flex-col gap-2">
			<Label>New password:</Label>
			<input type="password" v-model="password" class="border leading-10 px-4 rounded" />
		</div>
		<div class="flex flex-col gap-2">
			<Label>Confirm password:</Label>
			<input type="password" v-model="password" class="border leading-10 px-4 rounded" />
		</div>
		<div class="flex justify-between">
			<span class="border px-4 cursor-pointer leading-10 rounded">Cancel</span>
			<span
				class="border px-4 cursor-pointer border-green-400 rounded text-green-400 hover:bg-green-400 hover:text-white leading-10"
				>Save</span
			>
		</div>
	</form>
</template>

<script>
export default {
	data() {
		return {
			password: '',
		}
	},
}
</script>
