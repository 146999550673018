<template>
	<div>
		<TextAndImage2ImageLayout
			localePrefix="drawingToArtTool"
			gtagPrefix="drawing_to_art_tool"
			toolType="drawing-to-art"
			apiSubmitEndpoint="/inferences/drawing-to-art"
			previewVideoSrc="https://cdn.realitystudio.ai/assets/videos/scribble.mp4"
			pricingKey="drawingToArt"
			:showTransparentImage="true"
		>
		</TextAndImage2ImageLayout>
	</div>
</template>

<script>
import { TextAndImage2ImageLayout } from '@/components/tools'

export default {
	components: {
		TextAndImage2ImageLayout,
	},
	head: {
		title: `RealityStudio - Drawing To Art`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Transform a drawing to art easily with RealityStudio. Upload your drawing image and get an art background in seconds.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Drawing to Art `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Transform a drawing to art easily with RealityStudio. Upload your drawing image and get an art background in seconds.',
			},
		],
	},
}
</script>
