<template>
	<div class="relative">
		<div class="absolute top-2 left-2 p-2 rounded-xl bg-black/40">
			<svg
				v-if="item.site === 'behance'"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M3 5C3 3.8955 3.8955 3 5 3H19C20.1045 3 21 3.8955 21 5V19C21 20.1045 20.1045 21 19 21H5C3.8955 21 3 20.1045 3 19V5Z"
					fill="#2196F3"
				/>
				<path
					d="M13.5 8.5H17V9.5H13.5V8.5ZM10.756 11.714C11.0485 11.5715 11.7075 11.247 11.7075 10.2855C11.7075 8.477 9.7315 8.5005 9.366 8.5005H6V15.5005H9.5125C9.805 15.5 12 15.4175 12 13.4995C12 12.262 11.195 11.857 10.756 11.714ZM7.5 9.714H8.964C9.11 9.714 10.0615 9.766 10.0615 10.5C10.0615 11.2335 9.33 11.357 9.1105 11.357H7.5V9.714ZM9.168 14.2855H7.4845V12.3575H9.168C9.5335 12.3575 10.3385 12.476 10.3385 13.3575C10.3385 14.238 9.314 14.2855 9.168 14.2855ZM16.219 14.1975C15.9865 14.342 15.7545 14.4155 15.4445 14.4155C14.2815 14.4155 14.05 13.435 14.05 13.0005H18C18 12.566 18 12.245 17.9225 11.8825C17.8445 11.52 17.315 10 15.367 10C12.6445 10 12.5 12.389 12.5 12.75C12.5 13.1115 12.578 13.5465 12.7335 13.8355C12.888 14.1975 13.043 14.4875 13.2755 14.7035C13.5075 14.921 13.817 15.1365 14.127 15.282C14.514 15.4275 14.9015 15.5 15.289 15.5C15.908 15.5 16.451 15.356 16.9155 15.066C17.381 14.7765 17.6905 14.342 17.9225 13.763H16.606C16.5285 13.908 16.451 14.052 16.219 14.1975ZM15.367 11.0135C16.126 11.0135 16.451 11.8095 16.528 12.1705H14.05C14.05 12.099 14.2355 11.0135 15.367 11.0135Z"
					fill="white"
				/>
			</svg>

			<svg
				v-if="item.site === 'dribbble'"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12 21.6875C17.3503 21.6875 21.6875 17.3503 21.6875 12C21.6875 6.64974 17.3503 2.3125 12 2.3125C6.64974 2.3125 2.3125 6.64974 2.3125 12C2.3125 17.3503 6.64974 21.6875 12 21.6875Z"
					fill="#ED3675"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M12 2C6.4795 2 2 6.4795 2 12C2 17.5205 6.4795 22 12 22C17.51 22 22 17.5205 22 12C22 6.4795 17.51 2 12 2ZM18.605 6.6095C19.798 8.063 20.514 9.9175 20.5355 11.924C20.2535 11.87 17.4335 11.295 14.592 11.653C14.527 11.512 14.4725 11.36 14.4075 11.2085C14.234 10.7965 14.0385 10.3735 13.8435 9.972C16.989 8.692 18.421 6.848 18.605 6.6095ZM12 3.475C14.169 3.475 16.154 4.2885 17.6615 5.6225C17.5095 5.8395 16.219 7.564 13.182 8.7025C11.783 6.132 10.232 4.028 9.9935 3.7025C10.6335 3.551 11.306 3.475 12 3.475ZM8.3665 4.2775C8.594 4.581 10.1125 6.696 11.5335 9.2125C7.542 10.2755 4.017 10.254 3.6375 10.254C4.191 7.6075 5.9805 5.4055 8.3665 4.2775ZM3.4535 12.011C3.4535 11.924 3.4535 11.8375 3.4535 11.7505C3.8225 11.7615 7.9655 11.8155 12.228 10.5355C12.4775 11.0125 12.705 11.501 12.922 11.989C12.8135 12.0215 12.694 12.054 12.586 12.0865C8.1825 13.5075 5.84 17.39 5.6445 17.7155C4.2885 16.2085 3.4535 14.2015 3.4535 12.011ZM12 20.5465C10.026 20.5465 8.204 19.874 6.7615 18.746C6.9135 18.4315 8.6485 15.091 13.4645 13.41C13.486 13.399 13.497 13.399 13.519 13.3885C14.723 16.5015 15.211 19.115 15.341 19.8635C14.31 20.308 13.182 20.5465 12 20.5465ZM16.7615 19.0825C16.6745 18.562 16.219 16.0675 15.102 12.998C17.781 12.575 20.1235 13.269 20.4165 13.367C20.048 15.742 18.681 17.7915 16.7615 19.0825Z"
					fill="#BD1949"
				/>
			</svg>

			<svg
				v-if="item.site === 'pinterest'"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
					fill="#E60023"
				/>
				<path
					d="M12.2229 5.70703C7.90677 5.70703 5.61523 8.60434 5.61523 11.7586C5.61523 13.2255 6.396 15.0513 7.64523 15.6328C7.83444 15.7209 7.936 15.6828 7.97946 15.4993C8.01291 15.3601 8.18137 14.6809 8.25714 14.3651C8.28137 14.2643 8.26947 14.1778 8.18793 14.0786C7.77444 13.577 7.4437 12.6555 7.4437 11.7963C7.4437 9.59048 9.11368 7.45627 11.9587 7.45627C14.4152 7.45627 16.1352 9.13049 16.1352 11.5247C16.1352 14.2297 14.7691 16.1039 12.9918 16.1039C12.0102 16.1039 11.2752 15.292 11.511 14.2963C11.7937 13.1078 12.3394 11.8255 12.3394 10.967C12.3394 10.1993 11.9279 9.55856 11.0745 9.55856C10.071 9.55856 9.26523 10.5963 9.26523 11.987C9.26523 12.872 9.56445 13.4713 9.56445 13.4713C9.56445 13.4713 8.5733 17.662 8.39175 18.442C8.19077 19.3032 8.2691 20.5128 8.35608 21.3037C8.58163 21.3922 8.80728 21.4806 9.04047 21.5528C9.44891 20.8889 10.058 19.7999 10.2837 18.9316C10.4056 18.4636 10.9071 16.5543 10.9071 16.5543C11.2329 17.1763 12.1852 17.7028 13.1987 17.7028C16.2144 17.7028 18.3879 14.9293 18.3879 11.4828C18.3879 8.17856 15.6921 5.70703 12.2229 5.70703Z"
					fill="white"
				/>
			</svg>
		</div>

		<Button
			v-if="allowDelete"
			size="fit"
			color="transparent"
			@click="() => $emit('onDelete', item)"
			class="absolute top-2 right-2 rounded-xl bg-black/40"
		>
			<span class="material-icons p-2"> delete </span>
		</Button>
		<Button
			v-if="allowBookmark"
			size="fit"
			color="transparent"
			@click="() => $emit('onAddBookmark', item)"
			class="absolute top-2 right-2 rounded-xl bg-black/40"
		>
			<span class="material-icons p-2">bookmark_add</span>
		</Button>
		<img
			@click="$emit('onClickImage', item)"
			:src="item.image"
			:alt="item.title"
			class="rounded-xl w-full object-cover cursor-pointer"
			:class="{
				'landscape-image': item.dimension === 'landscape',
				'portrait-image': item.dimension === 'portrait',
			}"
		/>

		<a :href="item.link" target="_blank" class="flex items-center mt-2" v-if="item.link">
			<span class="material-icons text-base">open_in_new</span>
			<span class="font-bold ml-2 text-base lowercase">
				{{ $t('researchResult.visitPage') }}
			</span>
		</a>
	</div>
</template>

<script>
import { Button } from '@/components/default'

export default {
	components: {
		Button,
	},
	props: {
		allowDelete: {
			type: Boolean,
			default: false,
		},
		item: {
			type: Object,
			required: true,
		},
		allowBookmark: {
			type: Boolean,
			default: true,
		},
	},
}
</script>
