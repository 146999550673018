t
<template>
	<div class="page-container">
		<div class="flex items-center py-6">
			<Button size="fit" color="transparent" @click="$router.push('/research')">
				<span class="material-icons">arrow_back_ios</span>
			</Button>
			<h1 class="font-bold text-xl">
				{{ $t('researchGallery.pageTitle') }}
			</h1>
		</div>

		<div
			v-if="!collections.results.length && isFetchingCollections"
			v-for="i in 4"
			class="rounded-xl bg-slate-500 h-20 animate-pulse mb-6"
		></div>
		<div v-if="!!collections.results.length && !isFetchingCollections" class="space-y-4">
			<div v-for="collection in collections.results" :key="collection._id" class="relative">
				<Button
					size="fit"
					color="transparent"
					class="flex items-center justify-center h-6 w-6 bg-red-500 rounded-[4px] absolute right-6 top-1/2 -translate-y-1/2"
					:isLoading="collection.isDeleting"
					@click="() => deleteCollection(collection)"
				>
					<span class="material-icons text-base">delete</span>
				</Button>
				<Button
					color="transparent"
					:href="'/research/gallery/' + collection._id"
					buttonClass="rounded-xl flex items-center justify-between border border-[#1F262F] p-4"
				>
					<div class="flex justify-between">
						<div v-if="!collection.content.length" class="bg-zinc-400 w-[50px] h-[50px] rounded-xl"></div>
						<img
							v-else
							:src="collection.content[collection.content.length - 1].image"
							alt="Collection thumbnail"
							class="object-cover w-[50px] h-[50px] rounded-xl"
						/>
						<div class="ml-4">
							<p class="text-base font-medium">{{ collection.name }}</p>
							<span class="text-[#6B7280] text-sm mt-4"
								>{{ collection.content.length }} {{ $t('researchGallery.favorites') }}</span
							>
						</div>
					</div>
				</Button>
			</div>
		</div>

		<div v-if="!collections.results.length && !isFetchingCollections">
			<p>
				{{ $t('researchGallery.emptyState') }}
			</p>
		</div>

		<div class="flex items-center justify-center mt-6" v-if="isFetchingCollections">
			<Spinner />
		</div>
	</div>
</template>

<script>
import { Button, Spinner } from '@/components/default'

export default {
	name: 'Gallery',
	components: {
		Button,
		Spinner,
	},
	data() {
		return {
			collections: {
				next: '',
				hasNext: '',
				results: [],
			},
			isFetchingCollections: false,
			mockupWindow: null,
		}
	},
	mounted() {
		this.mockupWindow = this.getWindow()

		this.fetchCollections(true)

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScroll)
	},
	beforeUnmount() {
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScroll)
	},
	methods: {
		onScroll() {
			const isScrolledToBottom = this.isMobile
				? window.innerHeight + window.scrollY >= document.body.offsetHeight
				: this.mockupWindow.scrollTop + this.mockupWindow.offsetHeight >= this.mockupWindow.scrollHeight

			if (isScrolledToBottom) {
				this.fetchCollections()
			}
		},
		async fetchCollections(forceFetch) {
			if (this.isFetchingCollections || (!forceFetch && !this.collections.hasNext)) {
				return
			}

			this.isFetchingCollections = true

			try {
				const { data } = await this.axios.get(
					`/research-collections?${this.collections.hasNext ? `next=${this.collections.next}` : ''}`
				)

				this.collections.hasNext = data.hasNext
				this.collections.next = data.next
				this.collections.results.push(...data.results)
			} catch (error) {
				console.error(error)
			}

			this.isFetchingCollections = false
		},
		async deleteCollection(collection) {
			if (collection.isDeleting) {
				return
			}

			collection.isDeleting = true

			try {
				// await this.axios.delete('/research-collections/' + collection._id)

				this.collections.results = this.collections.results.filter(item => item._id !== collection._id)
			} catch (error) {
				console.error(error)
			}

			collection.isDeleting = false
		},
	},
	computed: {
		isMobile() {
			return this.$store.getters.getIsMobile
		},
	},
	head: {
		title: `Reality Avatar - Your Gallery`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with Reality Avatar!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `Reality Avatar - Your Gallery`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with Reality Avatar!',
			},
		],
	},
}
</script>
