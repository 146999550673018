import axios from 'axios'
import https from 'https'

const axiosInstance = axios.create({
	baseURL: import.meta.env.SSR ? `http://localhost:${process.env.PORT || 3000}/api` : '/api',
	// baseURL: import.meta.env.SSR ? `http://127.0.0.1:${process.env.PORT || 3000}/api` : '/api',
})

export default function createAxios({ headers } = {}) {
	return {
		install(app) {
			if (import.meta.env.SSR && import.meta.env.PROD) {
				axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false })
			}

			if (import.meta.env.SSR && headers) {
				axiosInstance.interceptors.request.use(config => {
					config.headers = headers

					return config
				})
			}

			app.provide('axios', axiosInstance)
			app.config.globalProperties.axios = axiosInstance
		},
	}
}
