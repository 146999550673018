<template>
	<div
		v-if="displayModal"
		class="fixed z-[90] top-0 left-0 w-screen h-screen flex items-center justify-center"
		id="modal"
	>
		<div class="bg-black/60 fixed top-0 left-0 w-screen h-screen" @click="closeModal" />
		<div
			:class="[
				'bg-radial-2-card w-[90%] md:max-w-[500px] mx-auto px-4 pb-6 rounded-xl overflow-y-auto max-h-[90vh]',
				'animate-fade-up animate-duration-500',
				modalClass,
			]"
		>
			<div
				class="flex items-center justify-between sticky top-0 bg-radial-2-card z-50 pt-6 border-b-[1px] pb-6 mb-6 border-[#2C3843]"
			>
				<h1 class="text-white text-xl font-semibold">
					{{ title }}
				</h1>
				<button
					v-if="!hideClose"
					@click="closeModal"
					class="border-2 border-white/5 bg-[#2C3843] rounded-full p-3 flex items-center justify-center"
				>
					<span class="material-symbols-outlined text-[32px]">close</span>
				</button>
			</div>
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		displayModal: {
			type: Boolean,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		modalClass: {
			type: String,
			default: '',
		},
		hideClose: {
			type: Boolean,
			default: false,
		},
		canClose: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		closeModal() {
			if (!this.canClose) return
			
			this.$emit('onClose')
		},
	},
	emits: ['onClose'],
}
</script>
