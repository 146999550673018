<template>
	<div class="page-container">
		<div class="mt-6 flex items-center">
			<button class="material-icons text-4xl mr-2" @click="$router.back()">chevron_left</button>
			<p class="text-xl font-bold">{{ $t('newAvatar.allTools') }}</p>
		</div>
		<ToolsCard
			v-for="tool in tools"
			class="mt-6"
			:backdropClass="tool.backdropClass"
			:key="tool.title"
			:tool="tool"
			hasButton
		/>
	</div>
</template>

<script>
import { ToolsCard } from '@/components/new-avatar'

export default {
	components: {
		ToolsCard,
	},

	data() {
		return {
			tools: [
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/free-avatar.mp4',
					title: this.$t('freeAvatarTool.pageTitle'),
					description: this.$t('freeAvatarTool.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToFreeAvatar,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/baby.mp4',
					title: this.$t('tools.baby.title'),
					description: this.$t('tools.baby.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToBabyTransformation,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/restore_image.mp4',
					title: this.$t('restoreImageTool.pageTitle'),
					description: this.$t('restoreImageTool.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToRestoreImage,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/generate-stickers.mp4',
					title: this.$t('stickerTool.pageTitle'),
					description: this.$t('stickerTool.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToSticker,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/colorize.mp4',
					title: this.$t('colorizeImageTool.pageTitle'),
					description: this.$t('colorizeImageTool.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToColorizeImage,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/scribble.mp4',
					title: this.$t('drawingToArtTool.pageTitle'),
					description: this.$t('drawingToArtTool.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToDrawingToArt,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/remove-background.mp4',
					title: this.$t('removeBackgroundTool.pageTitle'),
					description: this.$t('removeBackgroundTool.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToRemoveBackground,
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/caption-generator.mp4',
					title: this.$t('generateCaptionTool.pageTitle'),
					description: this.$t('generateCaptionTool.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToGenerateCaption,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/images/logo-analysis.jpg',
					title: this.$t('generateLogoAnalysisTool.pageTitle'),
					description: this.$t('generateLogoAnalysisTool.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToLogoAnalysis,
					backdropClass: '!opacity-20',
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/images/social-media-post-analysis.jpg',
					title: this.$t('generateSocialMediaPostAnalysisTool.pageTitle'),
					description: this.$t('generateSocialMediaPostAnalysisTool.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToSocialMediaPostAnalysis,
					backdropClass: '!opacity-20',
					isNew: true,
				},
				{
					image: 'https://cdn.realitystudio.ai/assets/videos/generate-prompt-from-image.mp4',
					title: this.$t('generatePromptFromImageTool.pageTitle'),
					description: this.$t('generatePromptFromImageTool.description'),
					btnText: this.$t('common.viewTool'),
					btnAction: this.goToPromptFromCamera,
					isNew: true,
				},
				{
					image: '/web/images/presets-and-tools/random-generation-box.png',
					title: this.$t('tools.randomAvatar.title'),
					description: this.$t('tools.randomAvatar.description'),
					btnText: this.$t('tools.randomAvatar.btnText'),
					btnAction: this.goToRandomPresetCreation,
					isNew: true,
				},
			],
		}
	},

	methods: {
		goToSocialMediaPostAnalysis() {
			this.captureEvent('new_avatar_tools_social_media_post_analysis_click')
			this.$router.push('/tools/generate-social-media-post-analysis')
		},
		goToLogoAnalysis() {
			this.captureEvent('new_avatar_tools_logo_analysis_click')
			this.$router.push('/tools/generate-logo-analysis')
		},
		goToFreeAvatar() {
			this.captureEvent('new_avatar_tools_free_avatar_click')
			this.$router.push('/tools/free-avatar')
		},
		goToPromptFromCamera() {
			this.captureEvent('new_avatar_tools_prompt_from_camera_click')
			this.$router.push('/tools/generate-prompt-from-image')
		},
		goToDrawingToArt() {
			this.captureEvent('new_avatar_tools_drawing_to_art_click')
			this.$router.push('/tools/drawing-to-art')
		},
		goToSticker() {
			this.captureEvent('new_avatar_tools_sticker_click')
			this.$router.push('/tools/sticker')
		},
		goToGenerateCaption() {
			this.captureEvent('new_avatar_tools_generate_caption_click')

			this.$router.push('/tools/generate-caption')
		},
		goToRestoreImage() {
			this.captureEvent('new_avatar_tools_restore_image_click')

			this.$router.push('/tools/restore-image')
		},
		goToColorizeImage() {
			this.captureEvent('new_avatar_tools_colorize_click')

			this.$router.push('/tools/colorize-image')
		},
		goToRemoveBackground() {
			this.captureEvent('new_avatar_tools_remove_background_click')

			this.$router.push('/tools/remove-background')
		},
		async goToRandomPresetCreation() {
			this.captureEvent('new_avatar_tools_random_preset_click')

			try {
				const randomPreset = await this.axios.get('/presets/sample/1').then(res => res.data)

				if (!randomPreset.length) {
					return
				}

				this.captureEvent('new_avatar_tools_random_preset_click_success')

				this.$router.push(`/new-avatar/${randomPreset[0]._id}`)
			} catch (error) {
				this.sentryCaptureException(error)

				this.captureEvent('new_avatar_tools_random_preset_click_error')
			}
		},
		goToBabyTransformation() {
			this.captureEvent('new_avatar_tools_baby_click')
			this.$router.push('/tools/generate-baby')
		},
	},
	head: {
		title: `RealityStudio - Tools`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Transform your dreams into Reality with our powerful tools. Start creating your vision today!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Tools`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Transform your dreams into Reality with our powerful tools. Start creating your vision today!',
			},
		],
	},
}
</script>
