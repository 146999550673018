<template>
	<div class="relative">
		<div class="ml-4 mr-4 lg:m-0">
			<button
				@click="goBack"
				class="mt-4 bg-[#2C3843] rounded-full p-3 flex items-center justify-center h-[60px] w-[60px] ml-auto"
			>
				<span class="material-symbols-outlined text-[32px]">close</span>
			</button>

			<div class="mt-6 relative">
				<div class="space-x-6 flex">
					<button
						:class="{
							'active-tab': activeTab === 'monthly',
						}"
						@click="onChangeTab('monthly')"
					>
						{{ $t('common.monthly') }}
					</button>
					<button
						:class="{
							'active-tab': activeTab === 'yearly',
						}"
						@click="onChangeTab('yearly')"
					>
						{{ $t('common.yearly') }}
					</button>
				</div>

				<div class="bg-radial-1-card w-full h-px mt-4" />
			</div>

			<p class="mt-6 mb-4 font-bold">
				{{ $t('checkoutPlans.choosePlan') }}
			</p>
		</div>

		<div class="swiper ml-4 pr-4 lg:m-0 lg:pr-0" ref="plansSwiper">
			<div class="swiper-wrapper">
				<SimplePlanCard
					v-for="(plan, index) in plansByTab"
					@click="handleSelectPlan(plan, index)"
					:key="plan._id"
					:plan="plan"
					:isSelected="selectedPlan?._id === plan._id"
					class="swiper-slide h-auto"
				/>
			</div>
		</div>

		<div class="mt-4">
			<PlanCard
				v-if="selectedPlan"
				:plan="selectedPlan"
				class="aspect-square ml-4 mr-4 lg:m-0"
				:showButton="false"
			/>
		</div>
		<Button
			:isLoading="isCreatingSession"
			:disabled="selectedPlan?.isSubscribed"
			@click="handleSubscribe"
			class="w-[calc(100%-32px)] ml-4 fixed bottom-4 lg:ml-0 lg:w-full lg:absolute lg:-bottom-24"
			v-if="selectedPlan"
		>
			{{ subscribeButtonText }}
		</Button>
	</div>
</template>
<script>
import { Button, Spinner, PlanCard } from '@/components/default'
import CheckoutPlanTabs from '@/components/checkout/CheckoutPlanTabs.vue'
import Swiper from 'swiper'
import { SimplePlanCard } from '@/components/checkout'
import { useRoute } from 'vue-router'
export default {
	data() {
		return {
			activeTab: 'monthly',
			plans: [],
			fetchingPlans: false,
			swiperInstance: null,
			selectedPlan: null,
			isCreatingSession: false,
		}
	},
	setup() {
		const route = useRoute()
		if (route.query.redirect) {
			return { redirect: route.query.redirect }
		}

		return { redirect: null }
	},
	methods: {
		goBack() {
			this.captureEvent('plans_go_back')
			const hasPlan = !!this.loggedUser.plan

			if (hasPlan) {
				this.$router.go(-1)
				this.$router.replace('/my-account/subscription')
				return
			}

			this.$router.push('/my-account/menu')
		},
		handleSelectPlan(plan, indexByTab) {
			this.selectedPlan = plan
			this.swiperInstance.slideTo(indexByTab)
		},
		slideToPopular() {
			const popularPlanIndex = this.plansByTab.findIndex(plan => plan.mostPopular) || 0

			this.selectedPlan = this.plansByTab[popularPlanIndex]
			this.swiperInstance.slideTo(popularPlanIndex)
		},
		slideToNext() {
			const subscribedPlanIndex = this.plans.findIndex(plan => plan.isSubscribed) || 0

			if (this.plans[subscribedPlanIndex].isYearly) {
				this.onChangeTab('yearly')
			}

			if (
				this.plans[subscribedPlanIndex].isMonthly &&
				this.plans[subscribedPlanIndex].englishName === 'Ultimate'
			) {
				this.onChangeTab('yearly')
				this.selectedPlan = this.plansByTab[0]
				this.swiperInstance.slideTo(0)
				return
			}

			const tabPlanSubscribed = this.plansByTab.findIndex(plan => plan.isSubscribed) || 0
			this.selectedPlan = this.plansByTab[tabPlanSubscribed]
			this.swiperInstance.slideTo(tabPlanSubscribed + 1)
		},
		onChangeTab(tab) {
			this.activeTab = tab
			this.captureEvent('plans_change_tab')

			this.$nextTick(() => {
				this.swiperInstance.update()
			})

			this.slideToPopular()
		},
		async handleSubscribe() {
			const plan = this.selectedPlan
			this.isCreatingSession = true

			this.captureEvent(`plans_subscribe_click_${plan.englishName.toLowerCase()}`)

			const redirect = this.redirect ? `&redirect=${this.redirect}` : ''

			try {
				this.$router.push(`/checkout/payment-select?id=${plan._id}${redirect}`)
				this.captureEvent('plans_subscribe_intent')
				this.sendTiktokInitCheckoutEvent(this.loggedUser)
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('plans_subscribe_error')

				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('checkoutPlans.errorToastMessage'),
				})
			}

			this.isCreatingSession = false
		},
		async fetchPlans() {
			this.fetchingPlans = true

			try {
				const { data } = await this.axios.get('/plans')

				this.plans = data
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.fetchingPlans = false
		},
	},
	async mounted() {
		await this.fetchPlans()
		this.selectedPlan = this.plansByTab[0]

		if (typeof this.$route.query.paymentError !== 'undefined') {
			this.captureEvent('plans_payment_error')

			this.$toast({
				type: 'warning',
				title: this.$t('common.toastTitle.error'),
				message: this.$t('checkoutPlans.paymentError'),
				duration: 5000,
			})
		}

		this.swiperInstance = new Swiper(this.$refs.plansSwiper, {
			slidesPerView: 1.35,
			spaceBetween: 8,
			grabCursor: true,
			centeredSlides: true,
			centeredSlidesBounds: true,
		})

		this.swiperInstance.on('slideChange', () => {
			this.selectedPlan = this.plansByTab[this.swiperInstance.activeIndex]
		})

		this.slideToNext()
	},
	beforeDestroy() {
		this.swiperInstance?.destroy()
	},
	components: {
		Button,
		Spinner,
		PlanCard,
		CheckoutPlanTabs,
		SimplePlanCard,
	},
	computed: {
		subscribeButtonText() {
			if (this.selectedPlan?.isSubscribed) {
				return this.$t('checkoutPlans.acquired')
			}

			// const unit = this.$t(`common.${this.selectedPlan?.billingCycle.unit?.replace('ly', '')}`)
			const subscribeFor = this.$t('checkoutPlans.subscribeFor')
			// const price = this.selectedPlan?.isYearly ? this.selectedPlan?.yearlyPrice : this.selectedPlan?.monthlyPrice

			return `${subscribeFor} ${this.$formatCurrency(this.selectedPlan?.price)}`
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		plansByTab() {
			return this.plans.filter(plan => plan.billingCycle.unit === this.activeTab) || []
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
		yearlyPlans() {
			return this.plans.filter(plan => plan.billingCycle.unit === 'yearly')
		},
		monthlyPlans() {
			return this.plans.filter(plan => plan.billingCycle.unit === 'monthly')
		},
	},
	head: {
		title: `RealityStudio - Plans`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Plans`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>

<style scoped>
.active-tab {
	@apply text-blue-600 font-bold relative;
}

.active-tab::after {
	content: '';
	@apply bg-blue-600 w-full h-px mt-4 block absolute;
}

.bg-blue-gradient {
	background: linear-gradient(to bottom, rgba(2, 7, 13, 0) 0%, rgba(37, 99, 235, 0.32) 100%);
}
</style>
