<template>
	<div>
		<div class="flex border-b border-gray-900 mb-6 mt-4 px-4" v-if="showHeader">
			<button @click="$router.push('/images/create')" class="relative">
				<div class="flex-col flex pb-4 px-4">
					<span class="material-icons">auto_awesome</span>
					{{ $t('imageGeneration.tabs.create') }}
				</div>
			</button>
			<button class="mx-6 relative">
				<div class="flex-col flex pb-4 px-4 border-b-[2px] border-blue-900 text-blue-900">
					<span class="material-icons">photo_library</span>
					{{ $t('imageGeneration.tabs.library') }}
				</div>
			</button>
		</div>
		<div v-if="!comparePrompt && !showResults">
			<div class="page-container my-6">
				<TabSelection @onChangeTab="value => handleChangeTab(value)" :tabs="tabs" />

				<div>
					<div v-if="tab === 'Library'">
						<div
							v-if="inferences.results.length === 0"
							class="flex justify-center items-center flex-col h-[calc(100vh-22rem)]"
						>
							<span class="text-6xl material-icons text-white mb-6">auto_awesome</span>
							<p
								v-html="$t('imageGeneration.library.emptyInferences')"
								class="text-xl mb-8 text-center"
							></p>
							<Button @click="() => $router.push('/images/create')">{{
								$t('imageGeneration.library.creationNow')
							}}</Button>
						</div>
						<div
							class="divide-y-2 divide-radial-1-card"
							:class="{ 'pb-12': inferences.results.length > 0 }"
						>
							<div
								v-for="inference in inferences.results"
								:key="inference._id"
								:class="{ 'mb-6': inferences.results.length > 0 }"
							>
								<div>
									<div class="mt-4 flex justify-center items-center">
										<p class="text-gray-300 text-center line-clamp-2 text-ellipsis px-4">
											{{ inference.input.prompt }}
										</p>
									</div>
								</div>

								<p class="mt-4 text-center">
									{{
										$formatDate(
											inference.createdAt,
											$i18n.locale === 'en-US' ? 'MM/DD/YYYY [at] HH:mm' : 'DD/MM/YYYY [as] HH:mm'
										)
									}}
								</p>
								<div
									v-if="inference.status === 'processing' && inference.outputEntities.length < 1"
									class="grid grid-cols-2 gap-4 mt-4"
								>
									<div
										class="h-[300px] flex items-center bg-radial-1-card rounded-xl relative"
										v-for="index in inference.input.totalImages"
										:key="index"
									>
										<RealityAvatarIcon class="animate-pulse-with-scale h-[25%] w-full" />
										<Spinner class="top-4 right-4 absolute" />
									</div>
								</div>
								<div v-if="inference.status === 'failed'">
									<div class="my-4 w-full">
										<div
											class="border border-blue-800 bg-blue-800/40 p-6 text-center rounded-xl font-bold"
										>
											<p>There was an issue, but don't worry your points have been refunded.</p>
										</div>
									</div>
									<Button
										@click="handleDeletePrompt(inference._id)"
										color="delete"
										:isLoading="loading.delete"
										:disabled="loading.delete"
									>
										Delete prompt</Button
									>
								</div>
								<div v-if="inference.status === 'completed'" class="grid grid-cols-2 gap-4 mt-4">
									<LazyImage
										v-for="(image, index) in inference.outputEntities"
										:key="`image-${index}`"
										:src="image.url"
										:alt="inference.input.prompt"
										imgClass="rounded-xl h-[300px] w-full object-cover"
										class="cursor-pointer"
										@click="$router.push(`/images/${image._id}?index=${index}`)"
									/>
								</div>
								<div class="mt-4 grid grid-cols-4 gap-x-4">
									<Button
										color="outline"
										class="col-span-3"
										@click="handleGenerateMore(inference)"
										:isLoading="loading.generate"
									>
										<img class="mr-2" src="/web/icons/sparkles.svg" alt="Sparkles" />
										<p>{{ $t('imageGeneration.library.generateMore') }}</p>
									</Button>
									<Button
										color="outline"
										class="col-span-1"
										@click="handleFavorite(inference, true)"
										:isLoading="loading.favorite"
										:disabled="loading.favorite"
									>
										<i class="material-icons">{{
											inference.isFavorite ? 'bookmark' : 'bookmark_outline'
										}}</i>
									</Button>
								</div>
							</div>
						</div>
					</div>
					<div v-if="tab === 'Favorite'">
						<div
							v-if="favorites.results.length === 0"
							class="flex justify-center items-center flex-col h-[calc(100vh-22rem)]"
						>
							<span class="text-6xl material-icons text-gray-300 mb-6">bookmark</span>
							<p class="text-xl mb-8 text-center text-gray-300">
								{{ $t('imageGeneration.library.noFavorites') }}
							</p>
						</div>
						<div class="divide-y-2 divide-radial-1-card" :class="{ 'pb-12': favorites.results.length > 0 }">
							<div v-for="favorite in favorites.results" :key="favorite._id" class="mb-6">
								<div>
									<div class="mt-4 flex justify-center items-center">
										<p class="text-gray-300 text-center line-clamp-2 text-ellipsis px-4">
											{{ favorite.entity.input.prompt }}
										</p>
									</div>
								</div>

								<p class="mt-4 text-center">
									{{
										$formatDate(
											favorite.entity.createdAt,
											$i18n.locale === 'en-US' ? 'MM/DD/YYYY [at] HH:mm' : 'DD/MM/YYYY [as] HH:mm'
										)
									}}
								</p>

								<div class="grid grid-cols-2 gap-4 mt-4">
									<LazyImage
										v-for="(image, index) in favorite.entity.outputEntities"
										:key="`image-${index}`"
										:src="image.url"
										:alt="favorite.entity.input.prompt"
										imgClass="rounded-xl h-[300px] w-full object-cover"
										class="cursor-pointer"
										@click="$router.push(`/images/${favorite.entity._id}?index=${index}`)"
									/>
								</div>
								<div class="mt-4 grid grid-cols-4 gap-x-4">
									<Button
										color="outline"
										class="col-span-3"
										@click="handleGenerateMore(favorite.entity)"
										:isLoading="loading.generate"
									>
										<img class="mr-2" src="/web/icons/sparkles.svg" alt="Sparkles" />
										<p>{{ $t('imageGeneration.library.generateMore') }}</p>
									</Button>
									<Button
										color="outline"
										class="col-span-1"
										@click="handleFavorite(favorite.entity, false)"
										:isLoading="loading.favorite"
										:disabled="loading.favorite"
									>
										<i class="material-icons">{{
											favorite.entity.isFavorite ? 'bookmark' : 'bookmark_outline'
										}}</i>
									</Button>
								</div>
							</div>
						</div>
					</div>
					<Spinner class="relative flex justify-center" v-if="loading.fetching" />
				</div>
			</div>
			<div
				class="w-full md:left-1/2 md:-translate-x-1/2w-full md:!w-[418px] fixed mb-5 background-floating-button-height md:mb-12 floating-width z-20 md:-translate-x-1/2 md:-ml-1"
				v-if="inferences.results.length > 1 && (!loading.fetching || !loading.generating)"
			>
				<div class="px-4 py-2 bg-app-blue">
					<Button @click="comparePrompt = true"
						><img class="mr-2" src="/web/icons/comparison.svg" alt="Compare" />
						<p>{{ $t('imageGeneration.library.comparePrompt') }}</p>
					</Button>
				</div>
			</div>
		</div>
		<div v-if="comparePrompt && !showResults">
			<div
				class="page-container my-6"
				:class="selectedInferences.length < maxInferenceSelection ? 'pb-28' : 'pb-4'"
			>
				<div class="items-center flex mb-6">
					<Button
						size="fit"
						color="transparent"
						class="material-icons text-3xl mr-2 font-normal"
						@click="
							() => {
								comparePrompt = false
							}
						"
						>chevron_left</Button
					>
					<h2 class="text-xl leading-7 font-bold">{{ $t('imageGeneration.library.comparePrompt') }}</h2>
				</div>
				<div class="divide-y-2 divide-radial-1-card">
					<div v-for="inference in inferences.results" :key="inference._id" class="mb-6 pb-4">
						<Button
							class="border rounded-xl p-4 mt-6"
							:class="isPromptSelected(inference) ? 'border-blue-600' : 'border-radial-1-card'"
							color="none"
							@click="handleSelectComparePrompt(inference)"
						>
							<img
								src="/web/icons/image-creation/compare-prompt.svg"
								alt="model-image"
								class="object-cover max-w-[32px] max-h-[32px] mr-4"
							/>
							<p class="text-base font-normal">{{ $t('imageGeneration.library.comparePrompt') }}</p>
							<span class="material-icons text-2xl ml-auto">{{
								isPromptSelected(inference) ? 'check_box' : 'check_box_outline_blank'
							}}</span>
						</Button>
						<div
							class="bg-radial-2-card my-4 rounded-lg"
							:class="
								isPromptSelected(inference) ? 'border-blue-600 border' : 'border border-transparent'
							"
						>
							<div class="p-4 border-b border-radial-1-card">
								{{ $t('imageGeneration.library.prompt') }}
							</div>
							<div class="p-4">
								{{ inference.input.prompt }}
							</div>
						</div>
						<p class="mt-4 text-center">
							{{
								$formatDate(
									inference.createdAt,
									$i18n.locale === 'en-US' ? 'MM/DD/YYYY [at] HH:mm' : 'DD/MM/YYYY [as] HH:mm'
								)
							}}
						</p>

						<div class="grid grid-cols-2 gap-4 mt-4">
							<LazyImage
								v-for="(image, index) in inference.outputEntities"
								:key="`image-${index}`"
								:src="image.url"
								:alt="inference.input.prompt"
								imgClass="rounded-xl h-[300px] w-full object-cover"
								class="cursor-pointer border"
								:class="
									isPromptSelected(inference) ? 'border-blue-600 rounded-xl' : 'border-transparent'
								"
								@click="$router.push(`/images/${inference._id}?index=${index}`)"
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				class="w-full md:left-1/2 md:-translate-x-1/2w-full md:!w-[418px] fixed mb-5 background-floating-button-height md:mb-12 floating-width z-20 md:-translate-x-1/2 md:-ml-px"
			>
				<div class="px-4 mb-2 w-full">
					<div
						v-if="selectedInferences.length < maxInferenceSelection && comparePrompt"
						class="border border-transparent border-blue-600 bg-blue-600/40 p-6 text-center rounded-xl font-bold"
						v-html="
							$t('imageGeneration.library.comparePromptWarning', {
								number: maxInferenceSelection - selectedInferences.length,
							})
						"
					></div>
				</div>
				<div class="flex px-4 py-2 bg-app-blue">
					<Button @click="handleComparePrompts" :disabled="selectedInferences.length < 2" class="mr-4">
						<p>{{ $t('imageGeneration.create.confirm') }}</p>
					</Button>
					<Button
						@click="comparePrompt = false"
						size="fit"
						color="transparent"
						class="border border-radial-1-card rounded-xl p-4"
						><span class="material-icons text-5xl"> close</span>
					</Button>
				</div>
			</div>
		</div>
		<div v-if="showResults && !comparePrompt">
			<div class="page-container my-6">
				<div class="items-center flex mb-6">
					<Button
						size="fit"
						color="transparent"
						class="material-icons text-3xl mr-2 font-normal"
						@click="
							() => {
								showResults = false
								comparePrompt = true
							}
						"
						>chevron_left</Button
					>
					<h2 class="text-xl leading-7 font-bold">{{ $t('imageGeneration.library.comparePrompt') }}</h2>
				</div>
				<div
					class="border-b border-radial-1-card pb-6"
					v-for="(inference, index) in selectedInferences"
					:key="index"
				>
					<p class="mt-4 text-center">
						{{
							$formatDate(
								inference.createdAt,
								$i18n.locale === 'en-US' ? 'MM/DD/YYYY [at] HH:mm' : 'DD/MM/YYYY [as] HH:mm'
							)
						}}
					</p>
					<div class="bg-radial-2-card my-4 rounded-lg">
						<div
							class="p-4 flex items-center"
							:class="{ 'border-b border-radial-1-card': inference.showPrompt }"
						>
							<p>{{ $t('imageGeneration.library.prompt') }}</p>
							<Button
								size="fit"
								color="transparent"
								class="px-2 py-1 border border-radial-1-card rounded-md ml-auto"
								@click="inference.showPrompt = !inference.showPrompt"
								><span class="material-icons text-2xl">
									{{ inference.showPrompt ? 'expand_less' : 'expand_more' }}</span
								>
							</Button>
						</div>
						<div class="p-4" v-show="inference.showPrompt" v-html="inference.input.comparedPrompt"></div>
					</div>
					<div class="bg-radial-2-card mb-4 rounded-lg">
						<div
							class="px-4 py-2 flex items-center"
							:class="{ 'border-b border-radial-1-card': inference.showNegativePrompt }"
						>
							<p>{{ $t('imageGeneration.library.negativePrompt') }}</p>
							<Button
								size="fit"
								color="transparent"
								class="px-2 py-1 border border-radial-1-card rounded-md ml-auto"
								@click="inference.showNegativePrompt = !inference.showNegativePrompt"
								><span class="material-icons text-2xl">
									{{ inference.showNegativePrompt ? 'expand_less' : 'expand_more' }}</span
								>
							</Button>
						</div>
						<div
							class="p-4"
							v-show="inference.showNegativePrompt"
							v-html="inference.input.comparedNegativePrompt"
						></div>
					</div>
					<PresetImagesSwiper :images="inference.outputEntities" class="mb-4" />
					<Button class="py-2 w-full rounded-md" size="fit" @click="handleRemix(inference._id)">
						<span class="material-icons text-2xl mr-1">replay</span>
						<p class="font-bold text-xs text-gray-900">{{ $t('imageGeneration.library.remix') }}</p></Button
					>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Header, TabSelection, Button, LazyImage, Spinner } from '@/components/default'
import { PresetImagesSwiper } from '@/components/new-avatar'
import { RealityAvatarIcon } from '@/components/icons'
import { useRouter } from 'vue-router'
import { inject, ref } from 'vue'

export default {
	async setup() {
		const axios = inject('axios')
		const router = useRouter()
		const inferences = await axios.get(`/inferences?type=image-creation`).then(res => res.data)
		const favorites = await axios.get(`/favorites/inference`).then(res => res.data)

		if (!inferences) {
			router.push('/404')
		}

		return { inferences: ref(inferences), favorites: ref(favorites) }
	},
	head: {
		title: `RealityStudio - Image Creation Library`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Image Creation Library`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Explore lifelike 3D avatars and immerse yourself in virtual reality experiences with RealityStudio. Discover a new dimension of entertainment and interaction.',
			},
		],
	},

	components: {
		Header,
		TabSelection,
		Button,
		LazyImage,
		PresetImagesSwiper,
		Spinner,
		RealityAvatarIcon,
	},

	data() {
		return {
			tabs: [
				{
					name: this.$t('imageGeneration.tabs.library'),
					icon: 'image',
					value: 'Library',
				},
				{
					name: this.$t('imageGeneration.tabs.favorites'),
					icon: 'bookmark',
					value: 'Favorite',
				},
			],
			comparePrompt: false,
			showResults: false,
			selectedInferences: [],
			maxInferenceSelection: 2,
			poolingIntervalInMs: 5000,
			loading: {
				fetching: false,
				generate: false,
				favorite: false,
				delete: false,
			},
			tab: 'Library',
			showHeader: true,
			getInferencesIntervals: [],
		}
	},
	mounted() {
		this.mockupWindow = this.getWindow()

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScrollPage)
		if (this.inferences?.results?.length === 0) return
		
		const startPooling = this.inferences.results.some(inference => inference.status === 'processing')
		if (startPooling) {
			this.inferences.results.map(inference => {
				if (inference.status !== 'processing') return
				this.pooling(inference._id)
			})
		}
	},
	beforeUnmount() {
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScrollPage)

		this.getInferencesIntervals.forEach(interval => clearInterval(interval.interval))
	},
	methods: {
		async handleDeletePrompt(inferenceId) {
			if (this.loading.delete) return
			this.loading.delete = true
			try {
				await this.axios.delete(`/inferences/${inferenceId}`)
				this.inferences.results = this.inferences.results.filter(inference => inference._id !== inferenceId)
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}
			this.loading.delete = false
		},
		async handleGenerateMore(entity) {
			this.loading.generate = true
			try {
				const newInference = await this.axios
					.post(`/inferences/image-creation`, { inference: entity, generateMore: true })
					.then(res => res.data)

				this.inferences.results.unshift(newInference)

				this.pooling(newInference._id)
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.loading.generate = false
		},
		pooling(inferenceId) {
			const poolingInterval = setInterval(() => this.fetchInference(inferenceId), this.poolingIntervalInMs)

			this.getInferencesIntervals.push({
				interval: poolingInterval,
				inferenceId: inferenceId,
			})
		},

		handleSelectComparePrompt(inference) {
			if (this.isPromptSelected(inference)) {
				this.selectedInferences = this.selectedInferences.filter(
					selectedPrompt => selectedPrompt._id !== inference._id
				)
				return
			}

			this.selectedInferences.push(inference)
		},
		isPromptSelected(inference) {
			return this.selectedInferences.some(selectedPrompt => selectedPrompt._id === inference._id)
		},
		handleRemix(inferenceId) {
			this.$router.push(`/images/create?id=${inferenceId}`)
		},
		async handleChangeTab(tab) {
			this.tab = tab.value

			if (this.tab === 'Favorite') {
				this.favorites = {
					results: [],
					next: null,
					hasNext: false,
				}
				await this.fetchFavorites(true)
			}
		},
		async handleFavorite(entity, isFromLibrary) {
			if (this.loading.favorite) return
			this.loading.favorite = true
			try {
				let bookmarked = false
				if (!entity.isFavorite) {
					await this.axios.post(`/favorites`, {
						entity: entity._id,
						entityType: 'inference',
						searchQuery: null,
					})
					bookmarked = true
				} else {
					await this.axios.delete(`/favorites/${entity._id}`)
				}

				if (!isFromLibrary) {
					this.favorites.results.find(favorite => favorite.entity._id === entity._id).entity.isFavorite =
						bookmarked
					this.favorites.results.splice(
						this.favorites.results.findIndex(favorite => favorite.entity._id === entity._id),
						1
					)
				}

				this.inferences.results.find(inference => inference._id === entity._id).isFavorite = bookmarked
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.loading.favorite = false
		},
		async fetchFavorites(forceFetch = false) {
			if (this.loading.fetching || (!this.favorites.hasNext && !forceFetch)) return
			this.loading.fetching = true
			try {
				const result = await this.axios
					.get(`/favorites/inference`, {
						params: { next: this.favorites.next },
					})
					.then(res => res.data)
				this.favorites.results = [...this.favorites.results, ...result.results]
				this.favorites.next = result.next
				this.favorites.hasNext = result.hasNext
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.loading.fetching = false
		},
		handleComparePrompts() {
			this.showResults = true
			this.comparePrompt = false
			this.selectedInferences = this.selectedInferences.map(inference => {
				inference.showPrompt = true
				inference.showNegativePrompt = true
				return inference
			})

			const comparedPrompts = this.compareAndFormatText(
				this.selectedInferences[0].input.prompt,
				this.selectedInferences[1].input.prompt,
				'bg-yellow-700'
			)

			const comparedNegativePrompts = this.compareAndFormatText(
				this.selectedInferences[0].input.negativePrompt,
				this.selectedInferences[1].input.negativePrompt,
				'bg-red-700'
			)

			for (let i = 0; i < this.selectedInferences.length; i++) {
				this.selectedInferences[i].input.comparedPrompt = comparedPrompts[i]
				this.selectedInferences[i].input.comparedNegativePrompt = comparedNegativePrompts[i]
			}
		},
		checkTextDifference(text1, text2) {
			const words1 = text1.split(' ')
			const words2 = text2.split(' ')

			const differences = []

			let i = 0
			let originalIndexText1 = 0
			let originalIndexText2 = 0
			while (i < Math.max(words1.length, words2.length)) {
				const word1 = words1[i]
				const word2 = words2[i]
				if (word1 !== word2 && word1 != null && word2 != null) {
					if (words1.includes(word2)) {
						differences.push({
							word: words1.splice(i, 1)[0],
							index: originalIndexText1,
							text: 1,
						})
						i--
						originalIndexText2--
						continue
					}

					if (words2.includes(word1)) {
						differences.push({
							word: words2.splice(i, 1)[0],
							index: originalIndexText2,
							text: 2,
						})
						i--
						originalIndexText1--
						continue
					}

					differences.push({
						word: words1.splice(i, 1)[0],
						index: originalIndexText1,
						text: 1,
					})
					differences.push({
						word: words2.splice(i, 1)[0],
						index: originalIndexText2,
						text: 2,
					})
					i--
					originalIndexText1--
					originalIndexText2--
				}

				if (word1 == null && word2 != null) {
					differences.push({
						word: words2.splice(i, 1)[0],
						index: originalIndexText2,
						text: 2,
					})
					i--
					originalIndexText1--
				}

				if (word2 == null && word1 != null) {
					differences.push({
						word: words1.splice(i, 1)[0],
						index: originalIndexText1,
						text: 1,
					})
					i--
					originalIndexText2--
				}

				i++
				originalIndexText1++
				originalIndexText2++
			}
			return differences
		},
		highlightDifferences(differences, text1, text2, className = '') {
			const words1 = text1.split(' ')
			const words2 = text2.split(' ')

			let highlightedText1 = ''
			let highlightedText2 = ''

			let prevDiff1 = false
			let prevDiff2 = false

			const classAttr = className ? ` class="${className}"` : ''

			for (let i = 0; i < Math.max(words1.length, words2.length); i++) {
				const diff = differences.find(d => d.index === i)

				if (diff && diff.text === 1) {
					if (!prevDiff1) highlightedText1 += `<strong${classAttr}>`
					highlightedText1 += words1[i] + ' '
					prevDiff1 = true
				} else {
					if (prevDiff1) highlightedText1 += '</strong>'
					highlightedText1 += (words1[i] || '') + ' '
					prevDiff1 = false
				}

				if (diff && diff.text === 2) {
					if (!prevDiff2) highlightedText2 += `<strong${classAttr}>`
					highlightedText2 += words2[i] + ' '
					prevDiff2 = true
				} else {
					if (prevDiff2) highlightedText2 += '</strong>'
					highlightedText2 += (words2[i] || '') + ' '
					prevDiff2 = false
				}
			}

			if (prevDiff1) highlightedText1 += '</strong>'
			if (prevDiff2) highlightedText2 += '</strong>'

			return [highlightedText1, highlightedText2]
		},
		compareAndFormatText(text1, text2, className = '') {
			const differences = this.checkTextDifference(text1, text2)
			return this.highlightDifferences(differences, text1, text2, className)
		},
		async fetchInferences(forceFetch = false) {
			if ((this.loading.fetching || !this.inferences.hasNext) && !forceFetch) return
			this.loading.fetching = true
			try {
				const result = await this.axios
					.get(`/inferences?type=image-creation`, {
						params: { next: this.inferences.next },
					})
					.then(res => res.data)
				this.inferences.results = [...this.inferences.results, ...result.results]
				this.inferences.next = result.next
				this.inferences.hasNext = result.hasNext
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.loading.fetching = false
		},

		async fetchInference(inferenceId) {
			try {
				const inference = await this.axios.get(`/inferences/${inferenceId}`).then(res => res.data)

				this.inferences.results = this.inferences.results.map(_inference => {
					if (_inference._id === inferenceId) {
						return inference
					}

					return _inference
				})

				if (inference.status !== 'processing') {
					clearInterval(
						this.getInferencesIntervals.find(interval => interval.inferenceId === inferenceId)?.interval
					)

					this.$mitt.emit('openFeedbackModal', inference)
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		onScrollPage() {
			let isScrolledToBottom = false

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight
			}

			if (isScrolledToBottom) {
				this.tab === 'Library' ? this.fetchInferences() : this.fetchFavorites()
			}
		},
	},
	watch: {
		showResults(newValue) {
			this.showHeader = !newValue
		},
		comparePrompt(newValue) {
			if (!this.showResults) {
				this.showHeader = !newValue
			}
		},
	},
	computed: {
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
}
</script>
