<template>
	<div class="min-h-screen flex flex-col">
		<div class="page-container mt-9" v-if="isPixAvailable && plan._id">
			<Header :title="$t('checkoutPaymentSelect.title')" class="mb-6" />
			<div v-if="!showPixPayment">
				<p class="mb-4 text-lg leading-7 text-center text-gray-300">{{ $t('checkoutPaymentSelect.price') }}</p>
				<h2 class="text-3xl leading-9 font-bold text-center">
					{{ $formatCurrency(planPrice, loggedUser?.lang) }}/{{
						$t(`checkoutPaymentSelect.${plan.billingCycle.unit}`)
					}}
				</h2>
				<div class="my-4">
					<Button
						@click="() => (showPixPayment = true)"
						class="flex flex-row items-center justify-center mb-4"
					>
						<img src="/web/icons/pix-icon-black.svg" alt="pix-icon" class="mr-1" />
						<p>{{ $t('checkoutPaymentSelect.pix.pix') }}</p></Button
					>
					<Button @click="handleCardCheckout" class="flex flex-row items-center justify-center">
						<span class="mr-1 material-icons">credit_card</span>
						<p>{{ $t('checkoutPaymentSelect.card') }}</p></Button
					>
				</div>
				<PlanCard :plan="plan" :showButton="false" />
			</div>
			<div v-if="showPixPayment">
				<div v-if="!qrCodeBase64 && !qrCode">
					<img src="/web/icons/pix-icon.svg" alt="pix-icon" class="mx-auto" />
					<p class="mb-2 mt-6">{{ $t('checkoutPaymentSelect.pix.cpf') }}</p>
					<div
						class="mb-4 flex flex-row rounded-xl text-base leading-6 font-normal text-white placeholder:text-gray-700 bg-transparent border border-extended-blue-gray-900 py-6 px-4"
					>
						<Input
							id="cpf"
							type="text"
							inputmode="numeric"
							class="bg-transparent w-full outline-none pr-3"
							v-model="cpf"
							placeholder="123.456.789-00"
							required
							minlength="14"
							maxlength="14"
						/>
						<Button size="fit" color="transparent" @click="deleteCpf"
							><span class="pl-1 material-icons text-red-700">delete</span>
						</Button>
					</div>
					<Button @click="saveCpf" :disabled="!isValidDocument(cpf)" :isLoading="loadingPix"
						>{{ $t('checkoutPaymentSelect.pay') }} {{ $formatCurrency(planPrice, loggedUser?.lang) }}
					</Button>
				</div>
				<div v-if="qrCodeBase64 && qrCode">
					<div class="mb-4">
						<img
							class="max-w-[300px] h-full mx-auto rounded-xl"
							:src="`data:image/jpeg;charset=utf-8;base64,${qrCodeBase64}`"
						/>
					</div>
					<div class="text-center">
						<h2 class="text-lg leading-7 font-bold mb-2">{{ $t('checkoutPaymentSelect.pix.title') }}</h2>
						<p class="text-gray-300 leading-6">
							{{ $t('checkoutPaymentSelect.pix.description') }}
						</p>
					</div>
					<div
						class="my-4 flex flex-row rounded-xl text-base leading-6 font-normal text-white placeholder:text-gray-700 bg-transparent border border-extended-blue-gray-900 py-6 px-4 cursor-pointer"
						@click="copyPixQrCode"
					>
						<input
							type="text"
							class="bg-transparent w-full outline-none pr-3 cursor-pointer"
							:value="qrCode"
							readonly
						/>
						<span class="pl-1 material-icons cursor-pointer">content_copy</span>
					</div>
					<Button :color="pixCopied ? 'green' : 'primary'" @click="copyPixQrCode">
						<span class="pr-1 material-icons cursor-pointer">{{
							pixCopied ? 'check' : 'content_copy'
						}}</span>
						<p>
							{{
								pixCopied
									? $t('checkoutPaymentSelect.pix.buttonActive')
									: $t('checkoutPaymentSelect.pix.button')
							}}
						</p>
					</Button>
				</div>
			</div>
		</div>
		<Spinner
			v-if="!isPixAvailable"
			class="flex items-center justify-center my-auto"
			width="[100px]"
			height="[100px]"
		/>
	</div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Button, Input, PlanCard, Header, Spinner } from '@/components/default'
export default {
	components: {
		Button,
		Input,
		PlanCard,
		Header,
		Spinner,
	},
	async setup() {
		const route = useRoute()
		const router = useRouter()
		const store = useStore()

		if (!route.query || !route.query.id) {
			router.push('/checkout/plans')
		}

		return {
			redirect: route.query.redirect,
			planId: route.query.id,
			isPixAvailable: store.getters.getUser?.location === 'BR',
		}
	},
	data() {
		return {
			qrCode: '',
			qrCodeBase64: '',
			pixCopied: false,
			showPixPayment: false,
			plan: {},
			pixCopiedTimeoutInMs: 3000,
			loadingPix: false,
			cpf: '',
			intervalId: null,
			intervalInMs: 5000,
		}
	},
	async mounted() {
		if (this.isPixAvailable) {
			this.plan = await this.axios.get(`/plans/${this.planId}`).then(res => res.data)
			this.cpf = this.loggedUser?.document?.number
		} else {
			await this.handleCardCheckout()
		}
	},
	beforeUnmount() {
		clearInterval(this.intervalId)
	},
	methods: {
		handleCpfMask(value) {
			const numbers = value.split('')
			let formatted = ''
			for (let i = 0; i < numbers.length; i++) {
				if (numbers[i] >= '0' && numbers[i] <= '9') {
					if (formatted.length === 3 || formatted.length === 7) {
						formatted += '.'
					}
					if (formatted.length === 11) {
						formatted += '-'
					}
					formatted += numbers[i]
				}
			}

			return formatted
		},
		copyPixQrCode() {
			this.copyToClipboard(this.qrCode)
			this.pixCopied = true
		},
		async deleteCpf() {
			this.cpf = ''
			try {
				await this.axios.put('/users/document', { document: { number: null, docType: 'cpf' } })
				this.captureEvent('checkout_delete_cpf_success')
			} catch (error) {
				this.captureEvent('checkout_delete_cpf_failed')
				this.sentryCaptureException(error)
			}
		},
		async saveCpf() {
			if (this.loadingPix) return
			this.loadingPix = true

			let document = this.cpf

			document = document.split('.').join('')
			document = document.split('-').join('')

			try {
				await this.axios.put('/users/document', { document: { number: document, docType: 'cpf' } })
				this.captureEvent('checkout_save_cpf')
				await this.getPixCode()
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.loadingPix = false
		},
		async getPixCode() {
			try {
				const { qrCode, qrCodeBase64 } = await this.axios
					.post('/payments/mercadopago/get-pix-code', {
						entityId: this.planId,
					})
					.then(res => res.data)
				this.qrCode = qrCode
				this.qrCodeBase64 = qrCodeBase64
				this.intervalId = setInterval(this.pooling, this.intervalInMs)
				this.captureEvent('checkout_get_pix_code_success')
			} catch (error) {
				this.captureEvent('checkout_get_pix_code_failed')
				this.sentryCaptureException(error)
			}
			this.loadingPix = false
		},
		async handleCardCheckout() {
			try {
				const { data } = await this.axios.post('/payments/stripe/create-subscription-checkout-session', {
					planId: this.planId,
				})

				this.captureEvent('plan_card_subscribe_intent_success')
				this.sendTiktokInitCheckoutEvent(this.loggedUser)

				const redirect = this.redirect ? `&redirect=${this.redirect}` : ''

				this.$router.push(`/checkout?clientSecret=${data.clientSecret}&id=${this.planId}${redirect}`)
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('plan_card_subscribe_intent_failed')
			}
		},
		isValidDocument(document) {
			if (typeof document === 'undefined') {
				return false
			}

			document = typeof document === 'string' ? document : document.toString()

			document = document.split('.').join('')
			document = document.split('-').join('')

			if (document.length !== 11 || !!document.match(/(\d)\1{10}/)) {
				return false
			}

			document = document.split('')

			const getLastDigits = document
				.filter((digit, index, array) => index >= array.length - 2 && digit)
				.map(number => +number)

			const validateLastDigits = removeElements =>
				document
					.filter((digit, index, array) => index < array.length - removeElements && digit)
					.map(number => +number)

			const rest = (count, removeElements) =>
				((validateLastDigits(removeElements).reduce(
					(sum, current, index) => sum + current * (count - index),
					0
				) *
					10) %
					11) %
				10

			return !(rest(10, 2) !== getLastDigits[0] || rest(11, 1) !== getLastDigits[1])
		},
		async pooling() {
			try {
				this.order = await this.axios.get('/orders/latest').then(res => res.data)

				if (this.order && this.order.status === 'paid') {
					this.processing = false

					this.captureEvent(`plans_subscribe_success_${this.order?.plan?.englishName.toLowerCase()}`)
					this.sendTiktokCompletedPaymentEvent(this.loggedUser, this.order)
					clearInterval(this.intervalId)

					const redirect = this.redirect ? `&redirect=${this.redirect}` : ''

					this.$router.push(`/checkout/processing?id=${this.planId}${redirect}`)
				}
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('plans_subscribe_failed')
			}
		},
	},
	computed: {
		userHasCpf() {
			return this.loggedUser?.document?.number
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
		currency() {
			return this.loggedUser.lang === 'pt-BR' ? 'R$' : '$'
		},
		planPrice() {
			if (!this.plan) return 0

			return this.plan.isYearly ? this.plan.yearlyPrice : this.plan.price
		},
	},
	watch: {
		pixCopied(newValue, oldValue) {
			if (newValue) {
				setTimeout(() => {
					this.pixCopied = false
				}, this.pixCopiedTimeoutInMs)
			}
		},
		cpf(newValue, oldValue) {
			if (newValue === oldValue) {
				return
			}

			if (newValue?.length) {
				this.cpf = this.handleCpfMask(newValue)
			}
		},
	},
	head: {
		title: `RealityStudio - Checkout`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Checkout`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
