<template>
	<div>
		<div class="page-container pt-9 pb-6">
			<Header :title="$t('myAccountSettings.title')" />
			<div class="mt-6">
				<Input id="userName" type="text" :label="$t('myAccountSettings.userNameLabel')" v-model="userName" />
			</div>
			<div class="mt-6">
				<Input
					id="email"
					type="text"
					:label="$t('myAccountSettings.registeredEmailLabel')"
					disabled
					:modelValue="loggedUser?.email"
					class="opacity-50"
				/>
			</div>
			<form @submit.prevent="handleUpdateSettings">
				<div>
					<h1 class="text-xl font-medium mb-6 mt-8">
						{{ $t('myAccountSettings.changePassword') }}
					</h1>
					<div v-if="loggedUser.hasPassword">
						<PasswordInput
							:id="'currentPassword'"
							:label="$t('myAccountSettings.currentPasswordLabel')"
							@update:passwordValue="value => (currentPassword = value)"
						/>
					</div>
					<PasswordInput
						class="my-5"
						:id="'newPassword'"
						:label="$t('myAccountSettings.newPasswordLabel')"
						@update:passwordValue="value => (newPassword = value)"
					/>
					<PasswordInput
						:id="'confirmNewPassword'"
						:label="$t('myAccountSettings.confirmNewPasswordLabel')"
						@update:passwordValue="value => (confirmNewPassword = value)"
					/>
				</div>
				<SubscribeToNewsletter
					:isSubscribedToNewsletter="loggedUser.newsletterSubscription"
					class="mt-6"
					@onChange="value => (newsletterSubscription = value)"
				/>
				<Button class="mt-6" type="submit">
					<Spinner v-if="isSavingSettings" />
					<span v-if="!isSavingSettings"> {{ $t('myAccountSettings.saveButton') }}</span>
				</Button>
			</form>
			<p class="mt-8 cursor-pointer text-red-400 text-base font-bold text-center" @click="handleDeleteAccount">
				{{ $t('myAccountSettings.deleteAccountModal.title') }}
			</p>
		</div>
	</div>
</template>

<script>
import {
	UpdateName,
	DeactivateAccount,
	Funds,
	UpdatePassword,
	SubscribeToNewsletter,
} from '@/components/account/settings'
import { Header, Input, Button, Modal, Select, Spinner, PasswordInput } from '@/components/default'

export default {
	components: {
		Spinner,
		Select,
		Input,
		Modal,
		Button,
		UpdateName,
		DeactivateAccount,
		UpdatePassword,
		Funds,
		Header,
		PasswordInput,
		SubscribeToNewsletter,
	},
	created() {
		this.userName = this.$store.getters.getUser.userName
	},
	data() {
		return {
			isSavingSettings: false,
			userName: '',
			currentPassword: '',
			newPassword: '',
			confirmNewPassword: '',
			newsletterSubscription: false,
		}
	},

	methods: {
		handleDeleteAccount() {
			this.$router.push('/my-account/settings/delete')
		},
		async handleUpdateSettings() {
			if (this.isSavingSettings) return
			this.isSavingSettings = true

			try {
				await Promise.all([this.updateUserName(), this.updatePassword(), this.updateNewsletterSubscription()])

				this.$toast({
					title: this.$t('common.toastTitle.success'),
					message: this.$t('myAccountSettings.toastMessage.success'),
					type: 'success',
				})

				this.captureEvent('settings_updated_success')
			} catch (error) {
				this.sentryCaptureException(error)

				this.$toast({
					title: this.$t('common.toastTitle.error'),
					message: this.$t('myAccountSettings.toastMessage.error'),
					type: 'error',
				})
				this.captureEvent('settings_updated_error')
			}

			this.isSavingSettings = false
		},
		async updateUserName() {
			if (!this.userName || this.userName === this.loggedUser?.userName) {
				return
			}

			try {
				await this.axios.put('/users/username', {
					userName: this.userName,
				})

				this.$store.commit('setUser', {
					...this.loggedUser,
					userName: this.userName,
				})
				this.captureEvent('settings_change_username_success')
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('settings_change_username_error')
				throw error
			}
		},
		async updatePassword() {
			if ((!this.currentPassword && this.loggedUser.hasPassword) || !this.newPassword) {
				return
			}

			if (this.newPassword !== this.confirmNewPassword) {
				throw new Error('Passwords do not match')
			}

			try {
				await this.axios.put('/users/password', {
					currentPassword: this.currentPassword,
					newPassword: this.newPassword,
				})
				this.captureEvent('settings_password_success')
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('settings_password_error')
				throw error
			}

			this.confirmNewPassword = ''
			this.currentPassword = ''
			this.newPassword = ''
		},

		async updateNewsletterSubscription() {
			if (this.newsletterSubscription === this.loggedUser.newsletterSubscription) {
				return
			}

			try {
				await this.axios.put('/users/newsletter-subscription', {
					newsletterSubscription: this.newsletterSubscription,
				})

				this.$store.commit('setUser', {
					...this.loggedUser,
					newsletterSubscription: this.newsletterSubscription,
				})
				this.captureEvent('settings_change_newsletter_success')
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('settings_change_newsletter_error')
				throw error
			}
		},
	},
	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
	head: {
		title: `RealityStudio - Settings`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Settings`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
