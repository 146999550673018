<template>
	<div class="swiper plans-swiper">
		<div class="swiper-wrapper">
			<PlanCard v-for="plan in plans" :key="plan._id" :plan="plan" class="swiper-slide plan-card h-auto" />
		</div>
	</div>
</template>

<script>
import { Swiper } from 'swiper'
import { PlanCard } from '@/components/default'

export default {
	props: ['plans'],
	data() {
		return {
			swiperInstance: null,
		}
	},
	mounted() {
		this.initSwiper()
	},
	beforeUnmount() {
		if (this.swiperInstance) {
			this.swiperInstance.destroy()
		}
	},
	methods: {
		initSwiper() {
			this.swiperInstance = new Swiper('.plans-swiper', {
				direction: 'horizontal',
				spaceBetween: 8, // in pixels,
				slidesPerView: 1.15,
				grabCursor: true,
			})
		},
	},
	components: { PlanCard },
}
</script>

<style scoped>
.plan-card {
	background: linear-gradient(180deg, rgba(11, 9, 33, 0.4) 0%, rgba(2, 7, 13, 0) 80.74%);
}
</style>
