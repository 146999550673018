<template>
	<Image2TextLayout
		localePrefix="generateLogoAnalysisTool"
		gtagPrefix="generate_logo_analysis_tool"
		toolType="generate-logo-analysis"
		apiSubmitEndpoint="/inferences/generate-logo-analysis"
		previewVideoSrc="https://cdn.realitystudio.ai/assets/images/logo-analysis.jpg"
		pricingKey="generateLogoAnalysis"
	>
	</Image2TextLayout>
</template>

<script>
import { Image2TextLayout } from '@/components/tools'

export default {
	components: {
		Image2TextLayout,
	},
	head: {
		title: `RealityStudio - Generate Logo Analysis`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Generate a detailed logo analysis of your image easily with RealityStudio. Upload your image and get a analysis in seconds.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Generate Logo Analysis`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Generate a detailed logo analysis of your image easily with RealityStudio. Upload your image and get a analysis in seconds.',
			},
		],
	},
}
</script>
