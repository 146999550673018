<template>
	<div>
		<Image2ImageLayout
			localePrefix="restoreImageTool"
			gtagPrefix="restore_image_tool"
			toolType="restore-image"
			apiSubmitEndpoint="/inferences/restore-image"
			previewVideoSrc="https://cdn.realitystudio.ai/assets/videos/restore_image.mp4"
			pricingKey="restoreImage"
			:showTransparentImage="true"
		>
		</Image2ImageLayout>
	</div>
</template>

<script>
import { Image2ImageLayout } from '@/components/tools'

export default {
	components: {
		Image2ImageLayout,
	},
	head: {
		title: `RealityStudio - Restore Image`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Restore your Image easily with RealityStudio. Upload your image and restore your image in seconds.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Restore Image `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Restore your Image easily with RealityStudio. Upload your image and restore your image in seconds.',
			},
		],
	},
}
</script>
