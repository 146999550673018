<template>
	<header class="text-6xl md:text-7xl text-center md:mt-12 page-container pt-28 md:pt-20" id="home">
		<h1 class="w-80 md:w-full mx-auto">{{ $t('landingPage.header.title') }}</h1>

		<div class="mt-6 lg:mt-10 relative">
			<div v-motion-roll-visible-once-bottom>
				<div class="bg-indigo-500 mx-auto w-full lg:w-7/12 h-20 md:h-36 rotate-2"></div>
			</div>
			<p
				class="font-extrabold text-5xl md:text-7xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
			>
				<span ref="word"></span>
				<span class="input-cursor" v-if="currentLetterIndex > 0"></span>
			</p>

			<BlueCircle width="150px" height="150px" blur="100px" class="lg:hidden left-1/2 top-2/3 -z-10"></BlueCircle>
		</div>
		<div class="page-container">
			<Button href="/my-account" class="mx-auto lg:w-96 h-20 mt-10 rounded-3xl font-bold">
				{{ $t('landingPage.getStarted') }}
				<span class="material-icons ms-1 font-bold">open_in_new</span>
			</Button>
		</div>
	</header>
</template>
<script>
import BlueCircle from './BlueCircle.vue'
import Button from '@/components/default/Button.vue'
export default {
	components: {
		Button,
		BlueCircle,
	},
	name: 'LandingPageHeader',
	data() {
		return {
			currentWordIndex: 0,
			typing: true,
			currentLetterIndex: 0,
			speedInMs: 100,
		}
	},
	mounted() {
		this.startTypingEffect()
	},
	methods: {
		startTypingEffect() {
			if (!this.typing) {
				return
			}

			if (this.currentLetterIndex < this.wordList[this.currentWordIndex].length) {
				this.$refs.word.innerText += this.wordList[this.currentWordIndex].charAt(this.currentLetterIndex)
				this.currentLetterIndex++
				setTimeout(this.startTypingEffect, this.speedInMs)
			} else {
				this.typing = false
				setTimeout(this.clearWord, 500)
			}
		},
		clearWord() {
			if (this.typing) {
				return
			}

			if (this.currentLetterIndex > 0) {
				this.$refs.word.innerText = this.$refs.word.innerText.slice(0, -1)
				this.currentLetterIndex--
				setTimeout(this.clearWord, this.speedInMs)
			} else {
				this.typing = true
				this.currentWordIndex = (this.currentWordIndex + 1) % this.wordList.length
				this.currentLetterIndex = 0
				setTimeout(this.startTypingEffect, this.speedInMs)
			}
		},
	},
	computed: {
		wordList() {
			return [
				this.$t('landingPage.header.productive'),
				this.$t('landingPage.header.creative'),
				this.$t('landingPage.header.professional'),
				this.$t('landingPage.header.valuable'),
				this.$t('landingPage.header.skilled'),
				this.$t('landingPage.header.precise'),
			]
		},
	},
}
</script>

<style scoped>
.input-cursor {
	position: absolute;
	display: inline-block;
	width: 6px;
	right: -6px;
	height: 60px;
	bottom: 0;
	background-color: white;
	animation: blink 0.6s linear infinite alternate;
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
</style>
