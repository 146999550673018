<template>
	<div :class="`p-2 bg-radial-2-card rounded-xl grid grid-cols-${tabs.length} relative`">
		<div
			:class="['transition-all absolute gap-0  top-1/2 rounded-xl -translate-y-1/2 bg-[#242C34] py-6']"
			:style="{
				left: `calc(${selectedTab ? (tabs.indexOf(selectedTab) / tabs.length) * 100 : 0}% + 8px)`,
				width: `calc(${100 / tabs.length}% - 16px)`,
			}"
		></div>
		<button
			v-for="tab in tabs"
			:key="tab.name"
			@click="selectedTab = tab"
			class="p-2 flex items-center justify-center z-[1]"
			:class="{ 'opacity-50': tab.disabled }"
			:disabled="tab.disabled"
		>
			<img v-if="tab.iconSrc" class="h-full mr-2" :src="tab.iconSrc" :alt="tab.name" />
			<i v-if="tab.icon" class="material-icons mr-2">{{ tab.icon }}</i>
			<p class="text-xs font-bold">{{ tab.name }}</p>
		</button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			selectedTab: null,
		}
	},

	props: {
		tabs: {
			type: Array,
			default: [
				{ name: 'Tab 1', icon: 'image' },
				{ name: 'Tab 2', icon: 'bookmark' },
			],
		},
	},

	watch: {
		selectedTab(tab) {
			this.$emit('onChangeTab', tab)
		},
	},
}
</script>
