import posthog from 'posthog-js'

export default {
	install(app) {
		app.config.globalProperties.$posthog = !APP_ENV.VITE_ENABLE_POSTHOG
			? null
			: posthog.init(APP_ENV.VITE_POSTHOG_API_KEY, {
					api_host: APP_ENV.VITE_POSTHOG_HOST,
			  })

		app.config.globalProperties.captureEvent = (eventName, metadata = {}) => {
			if (!app.config.globalProperties.$posthog) {
				return
			}

			app.config.globalProperties.$posthog.capture(eventName, metadata)
		}

		app.config.globalProperties.identifyUser = async user => {
			if (!app.config.globalProperties.$posthog) {
				return
			}

			const { Capacitor } = await import('@capacitor/core')

			const userData = {
				email: user.email,
				name: user.userName,
				hasSubscription: user.hasSubscription,
				subscription: user.plan && user.plan.name['en-US'],
				subscriptionCycle: user.plan && user.plan.billingCycle.unit,
				createdAt: user.createdAt,
				platform: Capacitor.getPlatform(),
				points: user.points,
				role: user.role,
			}

			if (userData.platform !== 'web') {
				const { App } = await import('@capacitor/app')
				const { version: appVersion } = await App.getInfo()

				userData.appVersion = appVersion
			}

			app.config.globalProperties.$posthog.identify(user._id, userData)
		}
	},
}
