<template>
	<div class="page-container pt-9">
		<Header :title="$t('myAccountNotifications.title')" class="mb-6" />

		<div class="flex border-b border-gray-900 mb-6">
			<button @click="changeTab('avatarProcessing')" class="relative">
				<div
					v-show="avatarProcessing.unreadCount > 0"
					class="h-2 w-2 bg-red-600 rounded-full border-4 border-transparent ms-12 absolute top-0 left-0"
				></div>
				<div
					class="flex-col flex pb-4"
					:class="{
						'border-b-[2px] border-blue-900 text-blue-900': tabShown === 'avatarProcessing',
					}"
				>
					<span class="material-icons">auto_awesome</span>
					{{ $t('myAccountNotifications.btnCreation') }}
				</div>
			</button>
			<button @click="changeTab('avatarCompleted')" class="mx-6 relative">
				<div
					v-show="avatarCompleted.unreadCount > 0"
					class="h-2 w-2 bg-red-600 rounded-full border-4 border-transparent ms-12 absolute top-0 left-0"
				></div>
				<div
					class="flex-col flex pb-4"
					:class="{
						'border-b-[2px] border-blue-900 text-blue-900': tabShown === 'avatarCompleted',
					}"
				>
					<span class="material-icons">image</span>
					{{ $t('myAccountNotifications.btnAvatars') }}
				</div>
			</button>
			<button @click="changeTab('others')" class="relative">
				<div
					v-show="others.unreadCount > 0"
					class="h-2 w-2 bg-red-600 rounded-full border-4 border-transparent ms-12 absolute top-0 left-0"
				></div>
				<div
					class="flex-col flex pb-4"
					:class="{ 'border-b-[2px] border-blue-900 text-blue-900': tabShown === 'others' }"
				>
					<span class="material-icons">widgets</span>
					{{ $t('myAccountNotifications.btnOthers') }}
				</div>
			</button>
		</div>

		<div>
			<div class="flex" v-if="tabShown === 'avatarProcessing'">
				<span class="mr-1 material-icons">hourglass_bottom</span>
				<div class="relative">
					<strong class="text-xl leading-7 text-white mr-2">
						{{ $t('myAccountNotifications.subtitleCreationSection') }}
					</strong>
				</div>
			</div>

			<div class="mt-6 flex" v-if="tabShown === 'avatarCompleted'">
				<div class="relative">
					<strong class="text-xl leading-7 text-white mr-2">
						{{ $t('myAccountNotifications.subtitleAvatarSection') }}
					</strong>
				</div>
			</div>

			<div
				v-for="notification in notificationResults"
				:key="notification._id"
				class="border-b border-gray-900 pb-6 mt-4"
			>
				<NotificationCard :notification="notification" :tabSelected="tabShown" />
			</div>

			<AnyAvatarPlaceholder v-if="notificationsLength === 0" />

			<div v-if="isLoadingNotifications" class="w-full flex items-center justify-center mt-6">
				<AnimatedSpinner />
			</div>
		</div>
	</div>
</template>

<script>
import { Header, AnimatedSpinner, AnyAvatarPlaceholder } from '@/components/default'
import { NotificationCard } from '@/components/account/notification'

export default {
	components: {
		AnyAvatarPlaceholder,
		AnimatedSpinner,
		Header,
		NotificationCard,
	},
	data() {
		return {
			avatarProcessing: {
				results: [],
				hasNext: false,
				next: null,
				unreadCount: 0,
				isFetching: false,
			},
			avatarCompleted: {
				results: [],
				hasNext: false,
				next: null,
				unreadCount: 0,
				isFetching: false,
			},
			others: {
				results: [],
				hasNext: false,
				next: null,
				unreadCount: 0,
				isFetching: false,
			},

			promptMessageVisibility: false,

			mockupWindow: null,

			tabShown: 'avatarProcessing',
			notificationLimit: 10,
		}
	},
	methods: {
		changeTab(tabName) {
			this.tabShown = tabName

			this.readAll(tabName)

			if (this[tabName].results.length === 0) {
				this.fetchNotifications(tabName, true)
			}

			this[tabName].unreadCount = 0
		},

		async readAll(tab) {
			if (this[tab].unreadCount === 0) {
				return
			}

			try {
				await this.axios.put('/notifications/read-all', {
					params: {
						filter: tab,
					},
				})

				this.captureEvent('notifications_read_all_success')
			} catch (error) {
				this.captureEvent('notifications_read_all_error')
				this.sentryCaptureException(error)
			}
		},

		async contentCall() {
			const [avatarProcessingCount, avatarCompletedCount, othersNotificationsCount] = await Promise.all([
				this.axios.get('/notifications/unread-count?filter=avatarProcessing').then(res => res.data.count),

				this.axios.get('/notifications/unread-count?filter=avatarCompleted').then(res => res.data.count),

				this.axios.get('/notifications/unread-count?filter=others').then(res => res.data.count),
			])

			this.avatarProcessing.unreadCount = avatarProcessingCount
			this.avatarCompleted.unreadCount = avatarCompletedCount
			this.others.unreadCount = othersNotificationsCount
		},

		async fetchNotifications(tabName, forceFetch) {
			if ((!this[tabName].hasNext && !forceFetch) || this[tabName].isFetching) {
				return
			}

			this[tabName].isFetching = true
			this.promptMessageVisibility = false

			try {
				const data = await this.axios
					.get('/notifications', {
						params: {
							filter: tabName,
							limit: this.notificationLimit,
							next: this[tabName].hasNext ? this[tabName].next : undefined,
						},
					})
					.then(res => res.data)

				this[tabName].results = [...this[tabName].results, ...data.results]
				this[tabName].hasNext = data.hasNext
				this[tabName].next = data.next
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this[tabName].isFetching = false
			this.promptMessageVisibility = true
		},

		onScroll() {
			const isScrolledToBottom = this.isMobile
				? window.innerHeight + window.scrollY >= document.body.offsetHeight
				: this.mockupWindow.scrollTop === this.mockupWindow.scrollHeight - this.mockupWindow.offsetHeight

			if (isScrolledToBottom) {
				this.fetchNotifications(this.tabShown)
			}
		},
	},

	computed: {
		notificationResults() {
			return this[this.tabShown].results
		},
		notificationsLength() {
			if (this.promptMessageVisibility) {
				return this[this.tabShown].results.length
			}
		},
		isLoadingNotifications() {
			return this[this.tabShown].isFetching
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
	},
	async mounted() {
		await this.contentCall()
		this.fetchNotifications(this.tabShown, true)
		this.changeTab('avatarProcessing')
		this.mockupWindow = document.querySelector('#mockupWindow')

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScroll)
	},
	beforeUnmount() {
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScroll)
	},
	head: {
		title: `RealityStudio - Notifications`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Notifications`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
