import { createStore } from 'vuex'

export default createStore({
	state: {
		user: undefined,
		hasProcessingActions: {},
		hasUnreadNotifications: null,
		isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
		isDisplayingFooter: true,
		isLoading: false,
		browserLang: null,
	},
	mutations: {
		setBrowserLang(state, data) {
			state.browserLang = data
		},
		setUser(state, data) {
			state.user = data
		},
		setLanguage(state, data) {
			state.user = {
				...state.user,
				lang: data,
			}
		},
		setHasProcessingActions(state, data) {
			state.hasProcessingActions = data
		},
		setHasUnreadNotifications(state, data) {
			state.hasUnreadNotifications = data.hasUnreadNotifications
		},
		setIsMobile(state, data) {
			state.isMobile = data
		},
		setIsDisplayingFooter(state, data) {
			state.isDisplayingFooter = data
		},
		setIsLoading(state, data) {
			state.isLoading = data
		},
	},
	actions: {
		async logout() {
			this.commit('setUser', null)
			this.user = null
		},
	},
	getters: {
		getBrowserLang(state) {
			return state.browserLang
		},
		getUser(state) {
			return state.user
		},
		getHasProcessingActions(state) {
			return state.hasProcessingActions
		},
		getHasUnreadNotifications(state) {
			return state.hasUnreadNotifications
		},
		getIsMobile(state) {
			return state.isMobile
		},
		getIsDisplayingFooter(state) {
			return state.isDisplayingFooter
		},
		getIsLoading(state) {
			return state.isLoading
		},
	},
	plugins: [],
})
