<template>
	<div
		v-if="showFeatureModal"
		class="fixed z-[90] top-0 md:top-8 left-0 w-screen h-full flex items-center justify-center md:h-[90%]"
		id="modal"
	>
		<div class="bg-black/60 fixed top-0 left-0 w-screen h-full" @click="closeModal" />
		<div
			class="relative w-full md:max-w-[500px] mx-auto pb-6 md:rounded-xl overflow-y-auto overflow-x-hidden h-full animate-fade-up animate-duration-500"
		>
			<BeforeAfterSource
				firstSource="/web/images/new-feature/1.png"
				secondSource="/web/images/new-feature/2.png"
				class="absolute top-0 left-0 w-full h-full object-cover md:rounded-xl overflow-hidden z-10"
				sourceClass="h-screen object-cover "
				indicatorClass="z-10"
				blackBottomGradient
			/>
			<Button
				@click="closeModal"
				size="fit"
				color="transparent"
				class="bg-[#2C3843] rounded-md p-2 flex items-center justify-center absolute top-6 right-6 z-50"
				><span class="material-icons text-[32px]">close</span>
			</Button>

			<div class="bottom-0 pb-4 absolute w-full px-4 z-10">
				<h2 class="leading-8 text-2xl text-center mb-6" v-html="$t('featureModal.title')"></h2>
				<Button class="flex items-center justify-center" @click="goToFeature"
					><p v-html="$t('featureModal.button')"></p>
					<span class="material-icons text-2lx">chevron_right</span>
				</Button>
			</div>
		</div>
	</div>
</template>
<script>
import { Button, BeforeAfterSource } from '@/components/default'
export default {
	data() {
		return { showFeatureModal: false, currentFeature: 'free-avatar', currentFeatureRoute: '/tools/free-avatar' }
	},
	components: {
		Button,
		BeforeAfterSource,
	},
	mounted() {
		this.openModal()
	},
	methods: {
		async updateFeatureModal() {
			await this.axios.put('/users/update-feature-modal', {
				featured: this.currentFeature,
			})

			this.$store.commit('setUser', {
				...this.loggedUser,
				modals: {
					...this.loggedUser.modals,
					featured: this.currentFeature,
				},
			})
		},
		async goToFeature() {
			this.showFeatureModal = false
			this.$router.push(this.currentFeatureRoute)
		},
		openModal() {
			if (!this.loggedUser || this.loggedUser?.modals?.featured === this.currentFeature) return
			this.showFeatureModal = true
			this.updateFeatureModal()
		},
		closeModal() {
			this.showFeatureModal = false
			this.updateFeatureModal()
		},
	},
	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
	watch: {
		loggedUser() {
			if (this.showFeatureModal) return
			this.openModal()
		},
	},
}
</script>
