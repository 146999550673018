import * as Sentry from '@sentry/vue'

const isDev = import.meta.env.DEV

export const sentryCaptureException = error => {
	if (!isDev) {
		if (typeof Sentry.setExtra === 'function') {
			Sentry.setExtra('error', JSON.stringify(error))
		}

		return Sentry.captureException(error)
	}

	console.error(error)
}

export default {
	install(app) {
		app.config.globalProperties.sentryCaptureException = sentryCaptureException
	},
}
