<template>
	<div class="w-[120px] h-[120px]">
		<ProcessingNewAvatar
			v-if="tabSelected === 'avatarProcessing'"
			imgClass="w-full h-full rounded-xl object-cover"
			:src="image"
			alt="image"
		/>

		<LazyImage v-else imgClass="w-full h-full rounded-xl object-cover" :src="image" alt="image" />
	</div>
</template>

<script>
import { LazyImage, ProcessingNewAvatar } from '@/components/default'
export default {
	components: {
		LazyImage,
		ProcessingNewAvatar,
	},
	props: {
		image: {
			type: String,
			required: true,
		},
		tabSelected: {
			type: String,
		},
	},
	data() {
		return {
			creatingAvatarsTimerString: '',
			processingPhotosTimerString: '',
			timeoutInstance: null,
			timeNow: null,
		}
	},
	computed: {
		getPredictionText() {
			if (!this.notification['prediction']) return '(In queue)'
			const { status, createdAt } = this.notification['prediction']

			switch (status) {
				case 'failed':
					return `(${this.$t('common.failed')})`
				case 'completed':
					return `(${this.$t('common.completed')})`
				case 'processing':
					return this.calculateDate(createdAt)
				default:
					return 'N/A'
			}
		},
		getModelText() {
			if (!this.notification['model']) return `(${this.$t('common.inQueue')})`
			const { status, createdAt } = this.notification['model']

			switch (status) {
				case 'completed':
					return `(${this.$t('common.completed')})`
				case 'processing':
					return this.calculateDate(createdAt)
				default:
					return 'N/A'
			}
		},
	},
	methods: {
		calculateDate(createdAt) {
			const createdDate = this.$dayjs(createdAt)
			const diffInSeconds = this.timeNow.diff(createdDate, 'second')

			const minutes = Math.floor(diffInSeconds / 60)
			const seconds = diffInSeconds % 60

			return `${minutes}m ${seconds}s`
		},
		getTimerClassName(notification, key) {
			const classNameByStatus = {
				processing: 'text-yellow-500 font-bold',
				completed: 'text-gray-400',
				default: 'text-gray-400',
			}

			return classNameByStatus[notification[key]?.status] || classNameByStatus['default']
		},
	},
	created() {
		this.timeNow = this.$dayjs()
	},
	mounted() {
		this.timeoutInstance = setInterval(() => {
			this.timeNow = this.$dayjs()
		}, 1000)
	},
	unmounted() {
		clearTimeout(this.timeoutInstance)
	},
}
</script>
