<template>
	<div>
		<div class="flex justify-center items-center mt-8">
			<img src="/web/images/logo.svg" class="w-[200px]" alt="RealityStudio" />
		</div>
		<div class="page-container">
			<div class="px-4 flex flex-col gap-4 pt-12">
				<GoogleLogin :redirectTo="$route.query?.redirectTo" />
				<div class="flex items-center gap-2">
					<div class="flex-1 flex bg-gray-900 h-px rounded-xl"></div>
					<span class="text-sm leading-5 font-normal w-fit">
						{{ $t('loginPage.or') }}
					</span>
					<div class="flex-1 flex bg-gray-900 h-px rounded-xl"></div>
				</div>
				<form @submit.prevent="onSubmit">
					<Input
						class="mb-4"
						id="email"
						v-model="email"
						:label="$t('loginPage.email')"
						:placeholder="$t('loginPage.emailPlaceholder')"
						type="email"
						required
					/>
					<PasswordInput
						:id="'password'"
						:label="$t('loginPage.password')"
						:placeholder="$t('loginPage.passwordPlaceholder')"
						class="mb-2"
						@update:passwordValue="value => (password = value)"
						required
					/>
					<router-link
						@click="captureEvent('login_goto_forgot_password')"
						class="text-base leading-4 font-bold mb-6 flex w-fit ml-auto"
						to="forget-password"
					>
						{{ $t('loginPage.forgotPassword') }}</router-link
					>
					<HCaptcha
						ref="hCaptcha"
						class="mb-6"
						@onVerify="token => (captchaToken = token)"
						v-if="platform === 'web'"
					/>
					<Button type="submit" :isLoading="isLoading">
						{{ $t('loginPage.login') }}
					</Button>
				</form>
				<div class="text-lg leading-7 w-fit mx-auto">
					{{ $t('loginPage.noAccount') }}

					<router-link class="font-bold" to="create-account">
						{{ $t('loginPage.createAccount') }}
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Input, HCaptcha, Button, Spinner, PasswordInput } from '@/components/default'
import GoogleLogin from '@/components/auth/GoogleLogin.vue'

export default {
	name: 'Index',
	data() {
		return {
			email: '',
			password: '',
			error: false,
			message: '',
			isLoading: false,
			hidePassword: true,
		}
	},
	computed: {
		platform() {
			return this.$getPlatform()
		},
	},
	methods: {
		async onSubmit() {
			this.isLoading = true

			try {
				const { user } = await this.axios
					.post('/auth/login', {
						email: this.email,
						password: this.password,
						token: this.captchaToken,
						platform: this.platform,
					})
					.then(res => res.data)

				this.$store.commit('setUser', user)
				this.captureEvent('login_submit_success')

				const redirectTo = this.$route.query?.redirectTo

				if (redirectTo) {
					this.$router.push(redirectTo)
					return
				}

				this.$router.push('/')
				this.$requestPushNotifications()
			} catch (error) {
				if (this.platform === 'web') {
					this.$refs.hCaptcha.reset()
				}

				this.sentryCaptureException(error)
				this.captureEvent('login_submit_error')

				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('loginPage.loginError'),
				})
			}

			this.isLoading = false
		},
		showPassword() {
			this.hidePassword = !this.hidePassword
		},
	},
	components: {
		HCaptcha,
		Spinner,
		Button,
		Input,
		GoogleLogin,
		PasswordInput,
	},
	head: {
		title: `RealityStudio - Login`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Login`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
