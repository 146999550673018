<template>
	<div class="page-container mt-8">
		<Header :title="$t('myAccountBalance.title')" class="mb-6" />
		<div class="mb-6">
			<div v-if="!loggedUser?.hasSubscription">
				<div class="mb-4">
					<img
						src="/web/images/user-balance/empty-state-girl.gif"
						alt="user-no-subscription"
						class="mb-4 object-cover w-[100px] h-full mx-auto"
					/>
					<p class="text-gray-300 text-center">
						{{ $t('myAccountBalance.emptyState') }}
					</p>
				</div>
				<PlansSwiper :plans="plans" />
			</div>
			<PointsBalance v-if="loggedUser?.hasSubscription" />
		</div>
		<div>
			<div class="pb-4 border-b border-b-gray-900">
				<h2 class="text-lg leading-7 font-bold text-white">{{ $t('myAccountBalance.subtitle') }}</h2>
			</div>
			<div class="pb-8">
				<AnyAvatarPlaceholder v-if="!transactions.results.length" />
				<div
					class="py-4 border-b border-b-gray-900"
					v-for="transaction in transactions.results"
					:key="transaction._id"
				>
					<router-link :to="getTransactionRouter(transaction)" class="cursor-pointer">
						<h2 class="font-bold text-white">
							{{ getTransactionType(transaction) }}
						</h2>
						<div class="flex flex-row items-center justify-start mt-4">
							<img
								:src="getTransactionImage(transaction) || '/web/images/user-balance/deleted-image.png'"
								alt="transaction-image"
								class="object-cover w-[120px] h-[120px] rounded-xl mr-4 bg-white"
							/>
							<div class="flex flex-col">
								<p class="mb-1 text-gray-400 text-sm">
									{{
										$formatDate(
											transaction.createdAt,
											$i18n.locale === 'en-US' ? 'MM/DD/YYYY HH:mm' : 'DD/MM/YYYY HH:mm'
										)
									}}
								</p>
								<p class="mb-1 text-white">
									{{
										transaction.negative ? parseFloat(transaction.amount) * -1 : transaction.amount
									}}
								</p>
								<strong class="mb-1 leading-6 text-white">{{
									getTransactionPaymentType(transaction)
								}}</strong>
							</div>
						</div>
					</router-link>
				</div>
			</div>
			<div class="flex">
				<Spinner v-if="loadingTransactions" class="mx-auto" />
			</div>
		</div>
	</div>
</template>

<script>
import { PlansSwiper } from '@/components/new-avatar'
import { PointsBalance } from '@/components/subscription'
import { Header, AnyAvatarPlaceholder, Spinner } from '@/components/default'
import { inject, ref } from 'vue'

export default {
	components: {
		PointsBalance,
		PlansSwiper,
		Header,
		AnyAvatarPlaceholder,
		Spinner,
	},
	async setup() {
		const axios = inject('axios')
		const [plans, transactions] = await Promise.all([
			axios.get('/plans?unit=monthly').then(res => res.data),
			axios.get('/points-transactions').then(res => res.data),
		])

		return {
			plans: ref(plans),
			transactions: ref(transactions),
		}
	},
	data() {
		return { paginationLimit: 9 }
	},
	methods: {
		getTransactionRouter(transaction) {
			if (!transaction) {
				return ''
			}

			if (transaction.entityType === 'prediction') {
				return transaction.entity && transaction.entity?.images?.length > 0
					? `/images/${transaction.entity.images[0]._id}`
					: '/my-account/library'
			}

			const { entityType, entity } = transaction

			if (entityType === 'image-creation') {
				return entity ? `/images/${entity._id}` : '/images/library'
			}

			const routerUrl = {
				model: `/new-avatar/${entity?.preset?._id}`,
				prediction: `/new-avatar/${entity?.preset?._id}`,
				order: '/my-account/subscription',
				sticker: '/tools/sticker',
			}

			return routerUrl[entityType] ?? `/tools/${entityType}`
		},

		getTransactionType(transaction) {
			if (transaction.entityType === 'model' || transaction.entityType === 'prediction') {
				return transaction.entity && transaction.entity.preset
					? `${this.$t(`myAccountBalance.${transaction.entityType}`)}: ${transaction.entity?.preset.name}`
					: transaction.entityType
			}

			if (transaction.entityType === 'order') {
				return transaction.entity?.plan.name
			}

			if (transaction.entityType === 'image-creation') {
				return this.$t('imageGeneration.title')
			}

			return `${this.$t(
				`${transaction.entityType?.replace(/-(\w)/g, (_, letter) => letter.toUpperCase())}Tool.pageTitle`
			)}`
		},
		getTransactionImage(transaction) {
			if (transaction.entityType === 'model' || transaction.entityType === 'prediction') {
				return transaction.entity && transaction.entity.preset && transaction.entity.preset.images
					? transaction.entity?.preset.images[0]
					: '/web/images/user-balance/deleted-image.png'
			}

			if (transaction.entityType === 'order') {
				return transaction.entity?.plan.image
			}

			if (transaction.entityType === 'image-creation') {
				return '/web/images/presets-and-tools/image-creation.svg'
			}

			const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/[\w.-]*)*\/?$/i

			if (transaction.entity) {
				if (transaction.entity?.status === 'completed') {
					return transaction.entity.output && urlRegex.test(transaction.entity.output)
						? transaction.entity.output
						: transaction.entity.input
				}

				if (transaction.entity?.status === 'failed') {
					return urlRegex.test(transaction.entity.input)
						? transaction.entity.input
						: '/web/images/user-balance/deleted-image.png'
				}

				if (transaction.entity?.status === 'processing') {
					return urlRegex.test(transaction.entity.input)
						? transaction.entity.input
						: '/web/images/user-balance/processing-image.png'
				}
			}

			return '/web/images/user-balance/deleted-image.png'
		},
		getTransactionPaymentType(transaction) {
			if (transaction.entityType === 'order') {
				return this.$t('myAccountBalance.purchase')
			}

			return transaction.negative ? this.$t('myAccountBalance.spent') : this.$t('myAccountBalance.refund')
		},
		async fetchTransactions() {
			if (!this.transactions.hasNext || this.loadingTransactions) return
			this.loadingTransactions = true
			try {
				const params = {
					limit: this.paginationLimit,
					next: this.transactions.next,
				}

				const transactions = await this.axios.get('/points-transactions', { params }).then(res => res.data)
				this.transactions.next = transactions.next
				this.transactions.hasNext = transactions.hasNext
				this.transactions.results = [...this.transactions.results, ...transactions.results]
			} catch (error) {
				this.sentryCaptureException(error)
			}
			this.loadingTransactions = false
		},
		onScroll() {
			const isScrolledToBottom = this.isMobile
				? window.innerHeight + window.scrollY >= document.body.offsetHeight
				: this.mockupWindow.scrollTop + this.mockupWindow.offsetHeight >= this.mockupWindow.scrollHeight

			if (isScrolledToBottom) {
				const fetchTimerInMs = 500

				setTimeout(this.fetchTransactions(), fetchTimerInMs)
			}
		},
	},
	mounted() {
		this.mockupWindow = document.querySelector('#mockupWindow')
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScroll)
	},
	beforeUnmount() {
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScroll)
	},
	computed: {
		isMobile() {
			return this.$store.getters.getIsMobile
		},

		loggedUser() {
			return this.$store.getters.getUser
		},
	},
	head: {
		title: `RealityStudio - Balance`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Balance`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Start creating amazing avatars with RealityStudio!',
			},
		],
	},
}
</script>
