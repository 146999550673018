<template>
	<div>
		<AdminHeader />
		<div class="page-container">
			<div class="grid-cols-12 grid gap-8 mt-8">
				<Input
					id="searchQuery"
					class="col-span-12 lg:col-span-8"
					inputClass="h-full"
					:placeholder="$t('admin.myModelsPage.searchInputPlaceholder')"
					v-model="searchQuery"
				/>

				<Button @click="exportSample" class="lg:col-span-2 col-span-12">
					<span class="material-icons text-2xl">download</span>
					<p class="font-bold ml-2">
						{{ $t('admin.myModelsPage.exportSampleBtn') }}
					</p>
				</Button>

				<Button :isLoading="isExporting" class="col-span-12 lg:col-span-2" @click="exportModels">
					<span class="material-icons text-2xl">download</span>
					<p class="font-bold ml-2">{{ $t('admin.myModelsPage.exportAllBtn') }}</p>
				</Button>
			</div>

			<div class="mt-4 py-4 px-4 bg-radial-1-card rounded-lg" ref="modelsContainer">
				<div class="bg-radial-3-card px-4 py-4 mb-4" v-for="model in filteredModels" :key="model._id">
					<p class="text-lg font-bold">{{ model.name }}</p>
					<pre class="mt-2 whitespace-break-spaces break-words">{{ model }}</pre>
				</div>

				<div class="flex justify-center" v-if="isFetchingModels"><Spinner /></div>
			</div>
		</div>
	</div>
</template>

<script>
import { AdminHeader } from '@/components/admin'
import { Button, Input, Spinner } from '@/components/default'

const PAGINATION_LIMIT = 2

export default {
	data() {
		return {
			models: null,
			isFetchingModels: false,
			searchQuery: '',
			sampleModelData: {
				_id: '658c81aa2380665c0354e1a3',
				user: '65862a6ea435eeb8ce0d5abb',
				name: 'chags.v',
				gatewayId: 'cworz2jbezcfnevo347fqoh6um',
				modelType: 'face',
				images: [
					'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/processing/0628cbbe-bfee-40a9-b649-1bb53651ff4e.png',
					'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/processing/7e8ccb9f-ed86-4dbc-8acb-2333374dd0f3.png',
					'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/processing/8d4a1088-e15e-401c-9242-81674dd8492b.png',
					'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/processing/d2965c0b-da62-4175-af77-abc2e00363d2.png',
					'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/processing/4595581c-d7d9-4e77-9d9c-156805a870ec.png',
					'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/processing/8921863c-6180-43b5-afa5-bd3de78e9078.png',
					'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/processing/f9991bd5-13ae-431a-aab3-69fd3f34af90.png',
					'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/processing/d7ac0454-0280-4f94-8ebf-ec46a0d725c0.png',
					'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/processing/8f59b71f-e1c7-43e9-b3f9-fde3ab410046.png',
					'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/processing/6f148ea0-b92b-4154-8e5a-01f2631423ab.png',
				],
				presetId: '6588c9eed888d3f149bbdd7e',
				status: 'completed',
				updatedAt: '2023-12-27T19:57:30.955Z',
				createdAt: '2023-12-27T19:57:30.955Z',
				file: 'https://cdn.realitystudio.ai/65862a6ea435eeb8ce0d5abb/models/658c81aa2380665c0354e1a3/2458fd27-2e1f-4d4c-ad4a-219067e4a1cb.safetensors',
			},
			isExporting: false,
		}
	},

	created() {
		this.getInitialModels()
	},

	mounted() {
		window.addEventListener('scroll', this.handleScroll)
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	},

	methods: {
		async exportModels() {
			if (this.isExporting) {
				return
			}

			this.isExporting = true

			try {
				const models = await this.axios.get('/admin/my-models/all').then(res => res.data)

				if (models.length === 0) {
					this.$toast({
						type: 'error',
						title: this.$t('common.toastTitle.error'),
						message: this.$t('admin.myModelsPage.toastMessage.noModelsFound'),
					})
					this.isExporting = false
					return
				}

				const csvData = models.map(model => ({
					_id: model._id,
					name: model.name,
					modelType: model.modelType,
					images: model.images.map(image => image).join(';'),
					gatewayId: model.gatewayId,
					presetId: model.presetId,
					status: model.status,
					file: model.file,
					createdAt: model.createdAt,
					updatedAt: model.updatedAt,
					version: model.version,
				}))

				const titleKeys = Object.keys(csvData[0])
				const refinedData = [titleKeys, ...csvData.map(model => Object.values(model))]
				const csvContent = refinedData.map(row => row.join(',')).join('\n')

				const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
				const objUrl = URL.createObjectURL(blob)

				fetch(objUrl)
					.then(response => response.blob())
					.then(blob => this.$downloadFile(window.URL.createObjectURL(blob), 'models.csv'))
					.then(() => {
						this.$toast({
							type: 'success',
							title: this.$t('common.toastTitle.success'),
							message: this.$t('admin.myModelsPage.toastMessage.exportSuccess', {
								modelsCount: models.length,
							}),
						})
					})
					.catch(error => {
						this.$toast({
							type: 'error',
							title: this.$t('common.toastTitle.error'),
							message: this.$t('admin.myModelsPage.toastMessage.exportError', {
								modelsCount: models.length,
							}),
						})
						this.sentryCaptureException(error)
					})
			} catch (error) {
				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
				})
				this.sentryCaptureException(error)
			}

			this.isExporting = false
		},
		async getInitialModels() {
			const models = await this.axios.get('/admin/my-models').then(res => res.data)

			this.models = models
		},
		async exportSample() {
			try {
				const titleKeys = Object.keys(this.sampleModelData)
				const images = `"${this.sampleModelData.images.join(';')}"`
				const csvData = Object.values({ ...this.sampleModelData, images })

				const csvContent = [titleKeys, csvData].map(row => row.join(',')).join('\n')

				const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
				const objUrl = URL.createObjectURL(blob)

				const blobContent = await fetch(objUrl).then(async response => await response.blob())

				await this.$downloadFile(blobContent, 'models-sample.csv')

				this.$toast({
					type: 'success',
					title: this.$t('common.toastTitle.success'),
					message: this.$t('admin.myModelsPage.toastMessage.exportSampleSuccess'),
				})
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('admin.myModelsPage.toastMessage.exportSampleError'),
				})
			}
		},
		async getNextModels() {
			if (this.isFetchingModels || !this.models?.hasNext) return

			this.isFetchingModels = true

			try {
				const models = await this.axios
					.get('/admin/my-models', {
						params: {
							limit: PAGINATION_LIMIT,
							next: this.models?.next,
							search: this.searchQuery ? this.Query : undefined,
						},
					})
					.then(res => res.data)

				if (this.models?.results) {
					this.models = {
						...models,
						results: [...this.models.results, ...models.results],
					}
				} else {
					this.models = models
				}

				this.isFetchingModels = false
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},

		handleScroll() {
			if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
				this.getNextModels()
			}
		},
	},
	computed: {
		filteredModels() {
			return this.models?.results?.filter(model =>
				model.name.toLowerCase().includes(this.searchQuery.toLowerCase())
			)
		},
	},
	components: {
		Button,
		Input,
		AdminHeader,
		Spinner,
	},
}
</script>
