<template>
	<div>
		<div class="relative">
			<Button color="transparent" class="absolute top-4 left-4 !p-0 w-min z-10" href="/">
				<span class="material-symbols-rounded text-white text-3xl"> chevron_left </span>
			</Button>
			<Button
				color="transparent"
				class="absolute top-4 cursor-pointer right-4 !p-0 w-min z-10"
				@click="sharePreset"
			>
				<span class="material-icons text-white text-3xl"> share </span>
			</Button>

			<div class="absolute w-full h-full bg-gradient-to-t from-black to-transparent -bottom-px"></div>
			<LazyImage
				class="aspect-square object-center"
				imgClass="object-cover h-full w-full"
				:src="preset?.images[0] || ''"
				alt="Preset image"
			/>

			<div class="absolute bottom-0 left-1/2 -translate-x-1/2">
				<h1 class="text-3xl font-bold min-w-full text-center">
					{{ preset.name }}
				</h1>

				<div class="justify-center flex items-center my-4">
					<p class="align-middle text-sm" v-html="$t('presetDetails.madeByRealityAvatar')"></p>

					<span class="material-icons text-yellow-400 ml-1 text-base">verified</span>
				</div>
			</div>
		</div>

		<div class="page-container mt-4">
			<Button @click="goToPreset" :disabled="isLoading.preset"> {{ $t('beSurprised.createAvatar') }}</Button>
		</div>

		<div class="page-container text-center mt-4">
			<p class="font-bold">{{ $t('beSurprised.notPleased') }}</p>
			<p class="text-medium text-gray-300">{{ $t('beSurprised.letsTry') }}</p>

			<Button class="mt-4" color="outline" @click="refreshPreset" :isLoading="isLoading.preset">
				<span class="material-icons text-white mr-2 text-xl">refresh</span>
				<span>{{ $t('beSurprised.refresh') }} </span>
			</Button>
		</div>

		<div class="swiper showcase-swiper my-8 ml-4 lg:ml-0">
			<div class="swiper-wrapper">
				<LazyImage
					v-if="preset?.images?.length"
					v-for="imageUrl in preset?.images"
					:key="imageUrl"
					class="swiper-slide"
					imgClass="object-cover rounded-xl h-[400px] w-full"
					:src="imageUrl"
					alt="Image"
					lazyWrapperClass="h-[400px] bg-radial-1-card rounded-xl"
				/>
			</div>
		</div>

		<div class="page-container">
			<PlansSwiper v-if="!loggedUser?.plan || loggedUser?.plan?.price === 0" class="mt-4" :plans="monthlyPlans" />
		</div>
	</div>
</template>

<script>
import { LazyImage, Button } from '@/components/default'
import { PlansSwiper } from '@/components/new-avatar'
import { ref } from 'vue'
import Swiper from 'swiper'
import { inject } from 'vue'

export default {
	name: 'NewAvatarBeSurprised',
	data() {
		return {
			isLoading: {
				preset: false,
			},
			swiperInstance: null,
		}
	},
	async setup() {
		const axios = inject('axios')
		const preset = await axios.get('/presets/sample/1').then(res => res.data[0])
		const monthlyPlans = await axios.get('/plans?unit=monthly').then(res => res.data)

		return {
			preset: ref(preset),
			monthlyPlans,
		}
	},
	mounted() {
		this.swiperInstance = new Swiper('.showcase-swiper', {
			loop: true,
			slidesPerView: 1.5,
			spaceBetween: 16,
			grabCursor: true,
			autoplay: {
				delay: 3000, // in ms
			},
		})
	},
	methods: {
		async refreshPreset() {
			if (this.isLoading.preset) {
				return
			}

			this.isLoading.preset = true

			try {
				this.preset = await this.axios.get('/presets/sample/1').then(res => res.data[0])

				this.$nextTick(() => {
					this.swiperInstance.update()
					this.swiperInstance.slideTo(0)
				})
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isLoading.preset = false
		},
		goToPreset() {
			this.captureEvent('be_surprised_create_avatar_click')
			this.$router.push(`/new-avatar/${this.preset._id}`)
		},
		sharePreset() {
			this.captureEvent('be_surprised_share_preset_click')

			try {
				this.$share({
					title: 'RealityStudio',
					text: `I just created my avatar using RealityStudio!`,
					url: window.location.href,
				})
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
	},
	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
	},
	components: {
		LazyImage,
		Button,
		PlansSwiper,
	},
	head: {
		title: `Be Surprised with RealityStudio`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: 'Discover the unexpected with RealityStudio!',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `Be Surprised with RealityStudio`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: 'Discover the unexpected with RealityStudio!',
			},
		],
	},
}
</script>
