<template>
	<div class="flex h-full">
        <video 
            :class="imgClass"
			:src="src"
			:alt="alt"
            autoplay 
            loop 
            muted
			>
        </video>
		<!-- <div
			v-if="hasLoadError || !isLoaded"
			:class="lazyWrapperClass"
			:style="{
				height: `${height}px`,
			}"
		>
			<p class="material-icons" v-if="hasLoadError">broken_image</p>
			<RealityAvatarIcon class="animate-pulse-with-scale" v-else />
		</div> -->
	</div>
</template>

<script>
import { RealityAvatarIcon } from '@/components/icons'

export default {
	components: { RealityAvatarIcon },
	emits: ['onImageLoaded', 'onLoadError'],

	data() {
		return {
			isLoaded: false,
			hasLoadError: false,
		}
	},

	methods: {
		onImageLoaded() {
			this.isLoaded = true
			this.$emit('onImageLoaded')
		},

		onLoadError() {
			this.hasLoadError = true
			this.$emit('onLoadError')
		},
	},
	props: {
		imgClass: {
			default: 'w-full h-full',
		},
		lazyWrapperClass: {
			default: 'w-full h-full bg-radial-1-card rounded-xl flex items-center justify-center',
		},
		src: {
			type: String,
			required: true,
		},
		alt: {
			type: String,
			required: true,
		},
		height: {
			type: Number,
		},
	},
}
</script>