<template>
	<div
		class="rounded-xl border-radial-1-card border py-6 px-4 flex flex-col justify-between"
		:class="{
			'plan-card-gradient-bg': showGradient,
		}"
	>
		<div>
			<span class="text-sm">
				{{ $t('planCard.includedWith') }}
			</span>
			<div class="flex items-center mt-2 mb-6">
				<img
					:src="`/web/icons/plans/${plan.englishName?.toLowerCase()}.svg`"
					class="w-[24px] h-[24px]"
					alt="Icon"
				/>
				<h1 class="text-2xl font-bold ml-2">{{ plan.name }}</h1>
			</div>
			<div class="space-y-4">
				<PlanCardFeatureText :text="creditsBenefitText" :isHtml="true" />
				<PlanCardFeatureText v-for="item in plan.features" :key="item" :text="item" />
				<PlanCardFeatureText
					:text="
						$t('planCard.averageAvatarGeneration', {
							count: getAvatarGenerationCount(),
						})
					"
					:isHtml="true"
				/>
			</div>
			<p
				class="mt-6 text-sm"
				v-html="
					$t('planCard.generalWarning', {
						avatarsCount: plan.averageAvatarGenerationCount,
					})
				"
			></p>
		</div>

		<Button :isLoading="isCreatingSession" class="mt-11" @click="handleSubscribe" v-if="showButton">
			{{ $t('planCard.startNow') }} {{ $formatCurrency(plan.price) }}/{{ $t('common.month') }}
		</Button>
		<slot name="footer"></slot>
	</div>
</template>

<script>
import { Button } from '@/components/default'
import PlanCardFeatureText from './PlanCardFeatureText.vue'

export default {
	components: { PlanCardFeatureText, Button },
	data() {
		return {
			isCreatingSession: false,
		}
	},
	props: {
		plan: {
			type: Object,
			required: true,
		},
		showButton: {
			type: Boolean,
			default: true,
		},
		showGradient: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		async handleSubscribe() {
			this.isCreatingSession = true
			const planName = this.plan.englishName.toLowerCase()

			if (!this.loggedUser) {
				this.captureEvent(`plan_card_subscribe_intent_${planName}_not_logged_in`)
				this.$router.push('/create-account?redirectTo=/checkout/plans')
				return
			}

			this.captureEvent(`plan_card_subscribe_click_${planName}`)

			try {
				this.$router.push(`/checkout/payment-select?id=${this.plan._id}`)
				this.captureEvent('plans_subscribe_intent')
				this.sendTiktokInitCheckoutEvent(this.loggedUser)
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent('plan_card_subscribe_error')

				this.$toast({
					type: 'error',
					title: this.$t('common.toastTitle.error'),
					message: this.$t('checkoutPlans.errorToastMessage'),
				})
			}

			this.isCreatingSession = true
		},
		getAvatarGenerationCount() {
			const value = this.plan.averageAvatarGenerationCount

			if (value >= 1000) {
				return Math.floor(value / 1000) + 'K+'
			}

			return value
		},
	},
	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
		creditsBenefitText() {
			if (!this.plan) {
				return ''
			}

			const baseText = `<strong>${this.plan.points} ${this.$t('common.realityPoints')}</strong>`

			return this.plan.bonusPoints
				? `${baseText} (<strong>+${this.plan.bonusPoints} ${this.$t('common.realityPoints')}</strong>)`
				: baseText
		},
	},
}
</script>

<style>
.plan-card-gradient-bg {
	background: linear-gradient(to bottom, rgba(11, 9, 33, 0.4), rgba(2, 7, 13, 0));
}
</style>
