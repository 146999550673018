<template>
	<div>
		<div class="pb-8 mt-6 border-b border-radial-1-card flex items-center justify-center w-100">
			<img src="/web/images/full-logo-white.png" alt="reality-studio-logo" class="mx-auto w-[30%]" />
		</div>
		<template v-if="loggedUser">
			<div class="border-b border-radial-1-card py-8 px-4 mb-8">
				<div class="flex items-center">
					<div
						class="w-[48px] h-[48px] rounded-full border-2 border-white mr-2 overflow-hidden flex items-center justify-center"
					>
						<LazyImage
							class="w-full h-full"
							imgClass="w-full h-full object-cover"
							v-if="loggedUser.avatar"
							:src="loggedUser.avatar"
							:alt="loggedUser.userName"
						/>
						<i v-else class="material-icons text-white text-4xl">person</i>
					</div>
					<p>
						{{ $t('myAccountMenu.greetings') }} <strong>{{ loggedUser.userName }}</strong>
					</p>
					<div class="px-3 bg-green-800 rounded-lg ml-auto py-1 font-satoshi">
						<p class="text-sm font-bold leading-6">
							{{ userHasPaidSubscription ? $t('home.premium') : $t('home.free') }}
						</p>
					</div>
				</div>
				<Button
					v-if="showcasedPlan"
					class="mt-6"
					size="compact"
					href="/checkout/plans?redirect=/"
					buttonClass="rounded-xl text-md leading-5 font-bold disabled:opacity-50 disabled:cursor-not-allowed text-gray-900"
					>{{ $t('home.upgradePlan', { planName: showcasedPlan.name.toLowerCase() }) }}</Button
				>
			</div>
			<div class="mb-12 pl-4" v-if="userLibraryImages.length">
				<div>
					<Button class="w-full" size="none" color="transparent" href="/my-account/library"
						><p class="text-xl leading-8 text-white font-bold font-satoshi">{{ $t('home.myLibrary') }}</p>
						<span class="text-xl material-icons ml-auto font-bold">arrow_forward</span></Button
					>
				</div>
				<div class="mt-4">
					<div class="swiper library-images-swiper">
						<div class="swiper-wrapper">
							<a
								v-for="image in userLibraryImages"
								:href="`/images/${image._id}`"
								:key="image._id"
								class="swiper-slide slide select-none !w-[250px] cursor-pointer"
							>
								<LazyImage
									class="w-full h-full"
									imgClass="rounded-xl h-[300px] !w-[250px] object-cover"
									:src="image.url"
									:alt="image._id"
								/>
							</a>
						</div>
					</div>
				</div>
			</div>
		</template>
		<div class="mb-12 pl-4">
			<p class="font-satoshi leading-8 font-bold text-xl mb-4">{{ $t('home.suggestionsTitle') }}</p>
			<div class="swiper suggestions-swiper">
				<div class="swiper-wrapper">
					<a
						v-for="suggestion in suggestions"
						:href="suggestion.href"
						:key="suggestion.href"
						class="swiper-slide select-none cursor-pointer p-4 items-center flex rounded-xl shrink-0"
						:class="suggestion.bgColor"
					>
						<div class="p-3 rounded-md mr-6" :class="suggestion.bgColor">
							<img
								:src="suggestion.image"
								:alt="suggestion.label"
								class="w-[32px] h-[32px] object-contain"
							/>
						</div>
						<p class="text-lg font-satoshi leading-8 font-bold">{{ suggestion.label }}</p>
						<Button
							class="text-xl material-icons ml-2"
							size="none"
							color="transparent"
							:href="suggestions.href"
							>arrow_forward</Button
						>
					</a>
				</div>
			</div>
		</div>
		<div class="mb-12 pl-4">
			<div>
				<Button class="w-full" size="none" color="transparent" href="/tools"
					><p class="text-xl leading-8 text-white font-bold font-satoshi">
						{{ $t('home.freeSuggestionsTitle') }}
					</p>
					<span class="text-xl material-icons ml-auto font-bold">arrow_forward</span></Button
				>
			</div>
			<div class="mt-4">
				<div class="swiper free-suggestions-swiper">
					<div class="swiper-wrapper">
						<a
							v-for="tool in tools"
							:href="tool.href"
							:key="tool.href"
							class="swiper-slide select-none !w-[250px] cursor-pointer"
						>
							<LazyImage
								class="w-full h-full"
								imgClass="rounded-xl h-[300px] !w-[250px] object-cover mb-2"
								:src="tool.image || tool"
								:alt="tool.label"
							/>
							<div class="flex items-center">
								<p class="text-lg leading-8 text-white font-bold font-satoshi">{{ tool.label }}</p>
								<Button
									class="ml-2"
									buttonClass="text-xl material-icons"
									size="none"
									color="transparent"
									>arrow_forward</Button
								>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<form class="mb-12 px-4" @submit.prevent="$router.push('/research/' + inspirationText)">
			<p class="font-satoshi text-xl font-bold leading-8 mb-2">{{ $t('home.inspirationTitle') }}</p>
			<p class="font-satoshi leading-6 text-gray-500 mb-2">{{ $t('home.inspirationDescription') }}</p>
			<Input
				class="mb-4"
				v-model="inspirationText"
				name="inspiration-text"
				id="inspiration-text"
				:placeholder="$t('home.inspirationPlaceholder')"
			/>
			<Button
				size="compact"
				type="submit"
				:href="'/research/' + inspirationText"
				buttonClass="rounded-xl text-md leading-5 font-bold disabled:opacity-50 disabled:cursor-not-allowed text-gray-900"
				>{{ $t('home.inspirationButton') }}</Button
			>
		</form>
		<div class="pl-4" v-if="avatarImages.length">
			<div>
				<Button class="w-full" size="none" color="transparent" href="/my-account"
					><p class="text-xl leading-8 text-white font-bold font-satoshi">{{ $t('home.ourFilter') }}</p>
					<span class="text-xl material-icons ml-auto font-bold">arrow_forward</span></Button
				>
			</div>
			<div class="mt-4">
				<div class="swiper filter-swiper">
					<div class="swiper-wrapper">
						<a
							v-for="image in avatarImages"
							:href="`/new-avatar/${image._id}`"
							:key="image._id"
							class="swiper-slide select-none !w-[250px] cursor-pointer"
						>
							<img
								:src="image.url || image"
								:alt="image.name"
								class="rounded-xl h-[300px] !w-[300px] object-cover"
							/>
							<div
								class="bg-gradient-to-t from-black to-transparent bottom-0 left-0 rounded-xl absolute h-[45%] w-full flex items-end justify-center"
							>
								<p class="text-center text-lg leading-7 font-bold px-4 pb-6 font-satoshi">
									{{ image.name }}
								</p>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-12 pl-4" v-if="exploreImages.length">
			<div class="px-4">
				<Button class="w-full" size="none" color="transparent" href="/explore"
					><p class="text-xl leading-8 text-white font-bold font-satoshi">{{ $t('home.explore') }}</p>
					<span class="text-xl material-icons ml-auto font-bold">arrow_forward</span></Button
				>
			</div>
			<div class="pl-4 mt-4">
				<div class="swiper explore-swiper">
					<div class="swiper-wrapper">
						<a
							v-for="image in exploreImages"
							:href="`/images/${image._id}`"
							:key="image._id"
							class="swiper-slide select-none cursor-pointer items-center flex rounded-xl shrink-0"
						>
							<img
								:src="image.url"
								:alt="image._id"
								class="rounded-xl h-[300px] !w-[300px] object-cover"
							/>
							<div
								class="bg-gradient-to-t from-black to-transparent bottom-0 left-0 rounded-xl absolute h-[45%] text-center w-full flex items-end justify-center"
							>
								<p class="text-lg leading-7 px-4 pb-6 font-satoshi">
									{{ $t('home.madeBy') }}<strong class="ml-2">{{ image.author }}</strong>
								</p>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Button, Input } from '@/components/default'
import LazyImage from '@/components/default/LazyImage.vue'
import { inject } from 'vue'
import { Swiper } from 'swiper'
import { Autoplay } from 'swiper/modules'
const SLIDE_DELAY_IN_MS = 3000 // 3 seconds
export default {
	name: 'Home',
	components: {
		Button,
		LazyImage,
		Input,
	},
	async setup() {
		const axios = inject('axios')
		const userLibraryImages = await axios.get('/users/library?limit=6').then(res => res.data)
		const popularPresets = await axios.get('/presets/most-popular/5').then(res => res.data)
		const publicImages = await axios.get('/images/public?limit=6').then(res => res.data)
		const result = await axios.get('/plans/next-upgrade').then(res => res.data)
		const avatarImages = popularPresets.map(preset => {
			return {
				_id: preset._id,
				url: preset.images[0],
				name: preset.name,
			}
		})

		const exploreImages = publicImages.results.map(image => {
			return {
				_id: image._id,
				url: image.url,
				author: image.user?.userName,
			}
		})

		return {
			userLibraryImages: userLibraryImages.results,
			avatarImages,
			exploreImages,
			showcasedPlan: result ? result : null,
		}
	},
	computed: {
		loggedUser() {
			return this.$store.getters.getUser
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		userHasPaidSubscription() {
			return this.loggedUser && this.loggedUser.plan && this.loggedUser.plan.free == false
		},
	},
	data() {
		return {
			librarySwiperInstance: null,
			suggestionsSwiperInstance: null,
			freeSuggestionsSwiperInstance: null,
			filtersSwiperInstance: null,
			exploreSwiperInstance: null,
			tools: [
				{
					href: '/tools/free-avatar',
					image: '/web/images/presets-and-tools/free-avatar.jpg',
					label: this.$t('freeAvatarTool.pageTitle'),
				},
				{
					href: '/tools/generate-baby',
					image: '/web/images/presets-and-tools/baby.jpg',
					label: this.$t('tools.baby.title'),
				},
				{
					href: '/tools/restore-image',
					image: '/web/images/presets-and-tools/restore-image.jpg',
					label: this.$t('restoreImageTool.pageTitle'),
				},
				{
					href: '/tools/sticker',
					image: '/web/images/presets-and-tools/create-sticker.jpg',
					label: this.$t('stickerTool.pageTitle'),
				},
				{
					href: '/tools/colorize-image',
					image: '/web/images/presets-and-tools/colorize-image.jpg',
					label: this.$t('colorizeImageTool.pageTitle'),
				},
				{
					href: '/tools/drawing-to-art',
					image: '/web/images/presets-and-tools/drawing-to-art.jpg',
					label: this.$t('drawingToArtTool.pageTitle'),
				},
				{
					href: '/tools/remove-background',
					image: '/web/images/presets-and-tools/remove-background.jpg',
					label: this.$t('removeBackgroundTool.pageTitle'),
				},
				{
					href: '/tools/generate-caption',
					image: '/web/images/presets-and-tools/generate-captions.jpg',
					label: this.$t('generateCaptionTool.pageTitle'),
				},
				{
					href: '/tools/generate-logo-analysis',
					image: '/web/images/presets-and-tools/generate-logo-analysis.jpg',
					label: this.$t('generateLogoAnalysisTool.pageTitle'),
				},
				{
					href: '/tools/generate-social-media-post-analysis',
					image: '/web/images/presets-and-tools/generate-post-analysis.jpg',
					label: this.$t('generateSocialMediaPostAnalysisTool.pageTitle'),
				},
				{
					href: '/tools/generate-prompt-from-image',
					image: '/web/images/presets-and-tools/generate-prompt-from-photo.jpg',
					label: this.$t('generatePromptFromImageTool.pageTitle'),
				},
				{
					href: `/new-avatar/${this.avatarImages[Math.floor(Math.random() * this.avatarImages.length)]?._id}`,
					image: '/web/images/presets-and-tools/random-avatar.jpg',
					label: this.$t('tools.randomAvatar.title'),
				},
			],
			suggestions: [
				{
					href: '/images/create',
					label: this.$t('home.createImage'),
					bgColor: 'bg-yellow-700/20',
					image: '/web/images/home/create-image.svg',
				},
				{
					href: '/new-avatar/library',
					label: this.$t('home.createAvatar'),
					bgColor: 'bg-red-700/20',
					image: '/web/images/home/create-avatar.svg',
				},
				{
					href: '/tools/generate-logo-analysis',
					label: this.$t('home.logoAnalysis'),
					bgColor: 'bg-green-700/20',
					image: '/web/images/home/logo-analysis.svg',
				},
			],
			inspirationText: '',
			sliderResetTimeout: null,
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.initLibrarySwiper()
			this.initSuggestionsSwiper()
			this.initFreeSuggestionsSwiper()
			this.initFilterSwiper()

			this.initExploreSwiper()
		})
	},
	beforeUnmount() {
		if (this.librarySwiperInstance) {
			if (Array.isArray(this.librarySwiperInstance)) {
				this.librarySwiperInstance.forEach(swiper => {
					swiper.destroy()
				})
				return
			}

			this.librarySwiperInstance.destroy()
		}

		if (this.suggestionsSwiperInstance) {
			if (Array.isArray(this.suggestionsSwiperInstance)) {
				this.suggestionsSwiperInstance.forEach(swiper => {
					swiper.destroy()
				})
				return
			}

			this.suggestionsSwiperInstance.destroy()
		}

		if (this.freeSuggestionsSwiperInstance) {
			if (Array.isArray(this.freeSuggestionsSwiperInstance)) {
				this.freeSuggestionsSwiperInstance.forEach(swiper => {
					swiper.destroy()
				})
				return
			}

			this.freeSuggestionsSwiperInstance.destroy()
		}

		if (this.filtersSwiperInstance) {
			if (Array.isArray(this.filtersSwiperInstance)) {
				this.filtersSwiperInstance.forEach(swiper => {
					swiper.destroy()
				})
				return
			}

			this.filtersSwiperInstance.destroy()
		}

		if (this.exploreSwiperInstance) {
			if (Array.isArray(this.exploreSwiperInstance)) {
				this.exploreSwiperInstance.forEach(swiper => {
					swiper.destroy()
				})
				return
			}

			this.exploreSwiperInstance.destroy()
		}
	},
	methods: {
		initLibrarySwiper() {
			if (!this.loggedUser || !this.userLibraryImages.length) return

			this.librarySwiperInstance = new Swiper('.library-images-swiper', {
				direction: 'horizontal',
				spaceBetween: 16, // in pixels
				modules: [Autoplay],
				autoplay: {
					delay: SLIDE_DELAY_IN_MS,
					disableOnInteraction: true,
				},
				loop: true,
				slidesPerView: 1.4,
				resistanceRatio: 0.3,
				grabCursor: true,
				slidesPerGroupAuto: true,
			})
		},
		initSuggestionsSwiper() {
			this.suggestionsSwiperInstance = new Swiper('.suggestions-swiper', {
				direction: 'horizontal',
				spaceBetween: 16, // in pixels
				modules: [Autoplay],
				autoplay: {
					delay: SLIDE_DELAY_IN_MS,
					disableOnInteraction: true,
				},
				slidesPerView: this.isMobile ? 1.3 : 1.4,
				resistanceRatio: 0.3,
				grabCursor: true,
				slidesPerGroupAuto: true,
			})
		},
		initFreeSuggestionsSwiper() {
			this.freeSuggestionsSwiperInstance = new Swiper('.free-suggestions-swiper', {
				direction: 'horizontal',
				spaceBetween: 16, // in pixels
				modules: [Autoplay],
				autoplay: {
					delay: SLIDE_DELAY_IN_MS,
					disableOnInteraction: true,
				},
				loop: true,
				slidesPerView: 1.4,
				resistanceRatio: 0.3,
				grabCursor: true,
				slidesPerGroupAuto: true,
			})
		},
		initFilterSwiper() {
			this.filtersSwiperInstance = new Swiper('.filter-swiper', {
				direction: 'horizontal',
				spaceBetween: 16, // in pixels
				modules: [Autoplay],
				autoplay: {
					delay: SLIDE_DELAY_IN_MS,
					disableOnInteraction: true,
				},
				loop: true,
				slidesPerView: 1.4,
				resistanceRatio: 0.3,
				grabCursor: true,
				slidesPerGroupAuto: true,
			})
		},
		initExploreSwiper() {
			this.exploreSwiperInstance = new Swiper('.explore-swiper', {
				direction: 'horizontal',
				spaceBetween: 16, // in pixels
				modules: [Autoplay],
				autoplay: {
					delay: SLIDE_DELAY_IN_MS,
					disableOnInteraction: true,
				},
				slidesPerView: 1.4,
				resistanceRatio: 0.3,
				grabCursor: true,
				slidesPerGroupAuto: true,
			})
		},
	},
	head: {
		title: `Reality Studio`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					"Reality Studio is dedicated to transforming the creative process with the power of artificial intelligence. Our mission is to provide innovative tools that enable designers and creatives to reach their full potential. Your studio, your home, your reality. Let's innovate together.",
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `Reality Studio`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					"Reality Studio is dedicated to transforming the creative process with the power of artificial intelligence. Our mission is to provide innovative tools that enable designers and creatives to reach their full potential. Your studio, your home, your reality. Let's innovate together.",
			},
		],
	},
}
</script>
