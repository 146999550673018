<template>
	<div class="relative h-max">
		<div class="swiper showcase-swiper">
			<div ref="swiperWrapper" class="swiper-wrapper bg-radial-1-card md:rounded-xl">
				<LazyImage
					:allowZoom="true"
					@onZoomIn="onZoomIn"
					@onZoomOut="onZoomOut"
					v-for="(image, index) in images"
					:key="`image-${index}`"
					:alt="`image-${index}`"
					:src="image.url"
					lazyWrapperClass="w-full aspect-square rounded-xl flex items-center justify-center"
					class="w-full swiper-slide md:rounded-xl overflow-hidden max-h-[500px]"
				/>
			</div>
		</div>
		<div class="absolute left-1/2 -translate-x-1/2 bottom-0 z-[1] flex mb-6 pointer-events-none">
			<div
				v-for="(image, index) in images"
				:key="`indicator-${index}`"
				:class="[
					'h-3 rounded-full mx-2 transition-all',
					displayedImageIndex === index ? 'w-10 bg-white' : 'w-3 bg-radial-3-card',
				]"
			></div>
		</div>
	</div>
</template>

<script>
import { Swiper } from 'swiper'
import { LazyImage } from '@/components/default'

export default {
	props: {
		images: {
			type: Array,
		},
		initialIndex: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			swiperInstance: null,
			displayedImageIndex: 0,
		}
	},
	async mounted() {
		await this.initSwiper()
		if (this.swiperInstance) {
			this.swiperInstance.slideTo(this.initialIndex, 500, false)
		}
	},
	beforeUnmount() {
		if (this.swiperInstance) {
			this.swiperInstance.destroy()
		}
	},
	methods: {
		onZoomIn() {
			this.swiperInstance.disable()
		},
		onZoomOut() {
			this.swiperInstance.enable()
		},
		initSwiper() {
			this.swiperInstance = new Swiper('.showcase-swiper', {
				direction: 'horizontal',
				spaceBetween: 8, // in pixels,
				slidesPerView: 1,
				grabCursor: true,

				on: {
					activeIndexChange: () => {
						if (!this.swiperInstance) return
						this.displayedImageIndex = this.swiperInstance.activeIndex
						this.$emit('onChangeImage', this.displayedImageIndex)
					},
				},
			})
		},
	},
	components: { LazyImage },
}
</script>
