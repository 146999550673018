<template>
	<div class="flex flex-col gap-4 py-6">
		<div class="flex justify-between">
			<span class="text-xl">My funds</span>
			<span>U$ Vários dinheiros</span>
		</div>
		<button class="border border-sky-400 text-sky-400 hover:bg-sky-400 hover:text-white rounded px-4 leading-10">
			Check on stripe
		</button>
	</div>
</template>
