<template>
	<div class="page-container pt-6 px-4 relative">
		<Header class="mb-6" title="Explore Feed" />
		<template v-if="images.length">
			<MasonryGrid :items="images" v-slot="slotProps">
				<a :href="`/images/${slotProps.item._id}`">
					<LazyImage
						:alt="slotProps.item._id || 'image'"
						:src="slotProps.item.url"
						class="h-auto w-full rounded-lg cursor-pointer"
						imgClass="rounded-xl"
					/>
				</a>
			</MasonryGrid>
			<Spinner class="flex justify-center my-6" v-if="isFetchingImages" />
		</template>

		<template v-else>
			<div class="flex mt-12 flex-col items-center">
				<i class="material-icons text-6xl outlined">broken_image</i>
				<p class="text-center mt-4 text-xl">{{ $t('explore.emptyStateText') }}</p>
			</div>
		</template>
	</div>
</template>

<script>
import { LazyImage, Header, MasonryGrid, Spinner } from '@/components/default'
import { inject, ref } from 'vue'

export default {
	components: { LazyImage, Header, MasonryGrid, Spinner },

	async setup() {
		const IMAGES_PER_PAGE = 9
		const axios = inject('axios')
		const { results, hasNext, next } = await axios
			.get(`/images/public?limit=${IMAGES_PER_PAGE}`)
			.then(res => res.data)
		return {
			images: ref(results),
			hasNext: ref(hasNext),
			next: ref(next),
			limit: IMAGES_PER_PAGE,
		}
	},

	data() {
		return {
			isFetchingImages: false,
		}
	},

	mounted() {
		this.mockupWindow = this.getWindow()

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScroll)
	},

	beforeUnmount() {
		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScroll)
	},

	methods: {
		onScroll() {
			let isScrolledToBottom = false

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight
			}
			if (isScrolledToBottom) {
				this.fetchImages()
			}
		},

		async fetchImages() {
			if (this.isFetchingImages || !this.hasNext) return
			this.isFetchingImages = true

			try {
				const { results, hasNext, next } = await this.axios
					.get(`/images/public`, { params: { next: this.next, limit: this.limit } })
					.then(res => res.data)
				this.images = [...this.images, ...results]
				this.hasNext = hasNext
				this.next = next
			} catch (error) {
				this.sentryCaptureException(error)
			} finally {
				this.isFetchingImages = false
			}
		},
	},

	head: {
		title: `Reality Studio - Explore Feed`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content: "Explore other users' creations in Reality Studio!",
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio`,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content: "Explore other users' creations in Reality Studio!",
			},
		],
	},
}
</script>
