<template>
	<div :class="`grid grid-cols-${columns} gap-4`">
		<div class="row -my-4" v-for="(itemGroup, index) in groupedItems" :key="`item-group-${index}`">
			<div class="w-full my-4 rounded-lg" v-for="(item, i) in itemGroup" :key="item._id ?? i">
				<slot :item="item"></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		columns: {
			type: Number,
			default: 2,
		},

		items: Array,
	},

	computed: {
		groupedItems() {
			let itemGroups = []
			for (let i = 0; i < this.items.length; i++) {
				const item = this.items[i]

				const itemColumn = i % this.columns

				if (!itemGroups[itemColumn]) {
					itemGroups[itemColumn] = []
				}

				itemGroups[itemColumn].push(item)
			}

			return itemGroups
		},
	},
}
</script>
