async function middlewarePipeline(context, middleware, index) {
	const nextMiddleware = middleware[index]

	// If there's no more middleware
	if (!nextMiddleware) {
		return true
	}

	return () => nextMiddleware({ ...context, next: middlewarePipeline(context, middleware, index + 1) })
}

export default middlewarePipeline
