<template>
	<div>
		<div class="page-container pt-6 px-4 relative">
			<div v-show="!isImagesModalOpened && !uploadImages">
				<Header class="mb-6" :title="$t(`${localePrefix}.pageTitle`)" />
				<video
					class="mb-4 mx-auto w-[230px] h-[300px] object-cover rounded-xl"
					autoplay
					muted
					loop
					playsinline
					:controls="false"
				>
					<source :src="previewVideoSrc" type="video/mp4" />
				</video>
				<div class="text-center mb-4">
					<p
						class="text-base leading-6 mb-4"
						v-html="
							$tc(`${localePrefix}.priceDescription`, pointsPrice, {
								pointsAmount: pointsPrice,
							})
						"
					></p>
					<p class="text-lg leading-7" v-html="$t(`${localePrefix}.description`)"></p>
				</div>
				<Button
					v-if="userHasEnoughPoints"
					@click=";(uploadImages = true), (pictures = []), (selectedGenderIdentity = null)"
				>
					{{ $t(`${localePrefix}.uploadButton`) }}
				</Button>
				<div>
					<AuthenticationBox
						v-if="!loggedUser"
						:redirectTo="currentPathname"
						:pageName="gtagPrefix"
						class="mt-4"
					/>
					<PlansSwiper v-if="loggedUser && !userHasEnoughPoints" :plans="plans" class="mt-4" />
				</div>

				<div class="my-4" v-if="!!inferences.length">
					<p class="text-lg leading-7 font-bold mb-4">
						{{ $t(`${localePrefix}.createdTitle`) }}
					</p>
					<div class="grid grid-cols-2 gap-4">
						<div class="relative" v-for="inference in inferences" :key="inference._id">
							<div class="relative h-[214px] w-full" v-if="inference.outputs">
								<LazyImage
									:src="inference.outputs[0] ?? ''"
									:height="214"
									alt="Image without background"
									@click="handleOpenImage(inference)"
									imgClass="absolute top-0 left-0 rounded-xl h-full w-full object-cover cursor-pointer z-10"
								/>
								<img
									v-if="showTransparentImage"
									class="absolute top-0 left-0 h-full w-full object-cover rounded-xl"
									src="/web/images/presets-and-tools/transparent.jpg"
									alt="Transparent image"
								/>
							</div>
							<div
								class="rounded-xl h-[214px] w-full bg-radial-2-card flex items-center justify-center"
								v-if="!inference.outputs && inference.status === 'processing'"
							>
								<Spinner
									iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
									height="12"
									width="12"
								/>
							</div>
							<div
								class="relative rounded-xl h-[214px] w-full bg-radial-2-card flex items-center justify-center"
								v-if="!inference.outputs && inference.status === 'failed'"
							>
								<img
									@click="() => handleOpenImage(inference)"
									src="/web/images/presets-and-tools/tool-failed.png"
									alt="tool-failed"
									class="absolute rounded-xl max-w-[60px] h-full object-contain cursor-pointer z-1"
								/>
							</div>
							<button
								class="bg-red-700 flex items-center justify-center rounded-full h-8 w-8 absolute top-2 right-2 z-10"
								@click="handleDeleteImage(inference)"
								type="button"
							>
								<span v-if="deletingId !== inference._id" class="material-icons text-base">delete</span>
								<Spinner
									iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
									height="4"
									width="4"
									v-else
								/>
							</button>
						</div>
					</div>

					<Spinner v-if="isFetchingInferences" iconClass="mx-auto mt-4" height="12" width="12" />
				</div>
			</div>

			<div v-show="uploadImages && !isImagesModalOpened && !loading.verifyingPictures">
				<div>
					<Button
						color="transparent"
						size="fit"
						class="ms-auto mb-6"
						@click="() => ((uploadImages = false), (isImagesModalOpened = false))"
					>
						<span class="material-icons text-[32px]">close</span>
					</Button>
				</div>
				<p class="text-center text-lg leading-7">{{ $t(`${localePrefix}.toolExplaning`) }}</p>
				<p
					class="text-lg text-center leading-7 my-6"
					:class="{
						'text-red-500': pictures.length < totalPicturesNeeded,
						'text-lime-500': pictures.length === totalPicturesNeeded,
					}"
				>
					<span class="font-bold">{{ pictures.length }}/{{ totalPicturesNeeded }}</span>
					{{ $t('presetDetails.uploadPage.photosAdded') }}
				</p>
				<div :class="['grid gap-4', pictures.length > 0 ? 'grid-cols-2' : 'grid-cols-1']">
					<div v-if="pictures.length < totalPicturesNeeded">
						<label
							class="cursor-pointer flex items-center justify-center border border-dashed rounded-xl border-gray-800 h-[260px]"
							:class="pictures.length > 0 ? 'w-full' : 'w-1/2 mx-auto'"
							for="userPicturesInput"
						>
							<div class="flex flex-col items-center">
								<span class="material-symbols-outlined text-3xl mb-2">add_circle</span>
								<p class="font-bold">
									{{ $t('common.addPhotos') }}
								</p>
							</div>
						</label>
						<FileInput
							inputAccept="image/jpeg,image/png"
							@onFileSelect="files => onFileSelect(Array.from(files))"
							inputId="userPicturesInput"
							:style="{
								display: 'none',
							}"
						/>
					</div>
					<div v-for="(item, index) in pictures" :key="`uploaded-picture-${index}`" class="relative">
						<button
							class="w-[32px] h-[32px] bg-red-700 rounded-full absolute right-4 top-4 flex items-center justify-center cursor-pointer"
							@click="pictures.splice(index, 1)"
						>
							<span class="material-symbols-outlined text-base">delete</span>
						</button>
						<img class="object-cover h-[260px] rounded-xl w-full" :src="item.url" alt="Image" />
					</div>
				</div>
				<p class="my-6">{{ $t(`${localePrefix}.genderSelection`) }}</p>
				<div class="flex flex-col justify-center gap-4 mb-6">
					<button
						:class="[
							'border w-full p-6 text-left rounded-xl font-bold',
							selectedGenderIdentity?.id == gender.id ? 'border-white' : 'border-extended-blue-gray-900',
						]"
						v-for="gender in genderOptions"
						:key="`gender-option-${gender.id}`"
						@click="selectedGenderIdentity = gender"
					>
						<div>{{ $t(gender.text) }}</div>
					</button>
				</div>
				<Button
					:disabled="!totalPicturesValidation || !selectedGenderIdentity"
					class="disabled:opacity-60 disabled:cursor-not-allowed"
					@click="needsVerification ? verifyPictures() : handleSubmit()"
				>
					{{ $t(`${localePrefix}.submitButton`) }}
				</Button>
			</div>
			<div
				v-show="isImagesModalOpened && !uploadImages"
				class="h-full w-full rounded-t-xl md:px-0 top-0 left-0 flex flex-col gap-4"
			>
				<div class="flex justify-between">
					<Button color="transparent" size="fit" @click="() => (isImagesModalOpened = false)">
						<span class="material-icons text-[32px]">close</span>
					</Button>
					<Button
						v-if="currentInference"
						@click="handleDeleteImage(currentInference)"
						color="transparent"
						size="fit"
						:isLoading="deletingId === currentInference._id"
						:disabled="currentInference.status === 'processing'"
					>
						<span class="material-icons text-[32px]">delete</span>
					</Button>
				</div>
				<div class="flex flex-1">
					<div
						class="bg-radial-2-card flex items-center justify-center w-full rounded-xl h-[460px]"
						v-if="!currentInference || !currentInference.outputs"
					>
						<Spinner
							v-if="currentInference?.status === 'processing'"
							iconClass="text-gray-200 animate-spin dark:text-gray-600 w-[32px] h-[32px] fill-white"
							height="32"
							width="32"
						/>
						<div
							class="relative h-[460px] w-full text-center flex flex-col items-center justify-center px-8"
							v-if="currentInference?.status === 'failed'"
						>
							<h2 class="text-3xl color-white font-bold mb-8">
								{{ $t(`${localePrefix}.toolFailed.title`) }}
							</h2>
							<img src="/web/images/presets-and-tools/tool-failed.png" alt="tool-failed" />
							<p
								class="text-md leading-7 color-white my-6"
								v-html="$t(`${localePrefix}.toolFailed.description`)"
							></p>
							<Button
								:class="{
									'opacity-50': isUploading,
								}"
								@click="handleUploadMore"
								:isLoading="isUploading"
								>{{ $t(`${localePrefix}.toolFailed.button`) }}</Button
							>
						</div>
					</div>
					<div class="relative h-[460px] w-full" v-else>
						<BeforeAfterSource
							:firstSource="currentInference.input"
							:secondSource="currentInference.outputs[0]"
							sourceClass="rounded-xl h-[460px] w-full object-cover"
						/>
						<img
							v-if="showTransparentImage"
							class="absolute h-full w-full object-cover rounded-xl"
							src="/web/images/presets-and-tools/transparent.jpg"
							alt="Transparent image"
						/>
					</div>
				</div>
				<div>
					<div class="swiper inference-outputs-swiper">
						<div class="swiper-wrapper">
							<Button
								color="transparent"
								size="fit"
								@click="handleUploadMore"
								class="swiper-slide relative !h-[60px] !w-[60px]"
							>
								<div
									class="rounded-xl h-[60px] w-[60px] flex items-center justify-center bg-radial-2-card"
								>
									<span class="material-icons text-2xl">add_photo_alternate</span>
								</div>
							</Button>
							<div
								v-for="inference of inferences"
								:key="inference._id"
								class="swiper-slide relative !h-[60px] !w-[60px]"
							>
								<div
									class="flex items-center justify-center h-[60px] w-[60px] bg-radial-2-card rounded-xl absolute -z-10"
									v-if="!inference.outputs.length && inference.status === 'processing'"
								>
									<Spinner
										iconClass="text-gray-200 animate-spin dark:text-gray-600 fill-white"
										height="6"
										width="6"
									/>
								</div>
								<LazyImage
									v-if="inference.outputs.length && inference.status === 'completed'"
									:src="inference.outputs[0] || ''"
									lazyWrapperClass="absolute text-center top-0 left-0 z-10"
									:imgClass="`rounded-xl h-full w-full object-cover cursor-pointer absolute top-0 left-0 z-10 ${
										inference._id === currentInference?._id
											? 'border-2 border-blue-600'
											: 'border-2 border-transparent'
									}`"
									@click="() => handleOpenImage(inference)"
									alt="Image without background"
									:height="60"
								/>
								<div
									v-if="inference.status === 'failed'"
									:class="`rounded-xl h-[60px] w-[60px] object-cover cursor-pointer absolute top-0 left-0 z-10 ${
										inference._id === currentInference?._id
											? 'border-2 border-blue-600'
											: 'border-2 border-transparent'
									}`"
								>
									<img
										@click="() => handleOpenImage(inference)"
										src="/web/images/presets-and-tools/tool-failed.png"
										alt="tool-failed"
										class="p-1 h-full w-full"
									/>
								</div>
								<img
									v-if="inference.outputs && showTransparentImage"
									class="absolute top-0 left-0 h-[60px] w-[60px] object-cover rounded-xl"
									src="/web/images/presets-and-tools/transparent.jpg"
									alt="Transparent image"
								/>
							</div>
						</div>
					</div>
				</div>
				<Button
					color="outline"
					@click="downloadImage"
					:isLoading="isDownloading"
					:disabled="!currentInference || currentInference.status !== 'completed'"
				>
					<span class="material-icons text-2xl mr-2">download</span>
					{{ $t(`${localePrefix}.downloadButton`) }}
				</Button>
			</div>
			<Modal
				:displayModal="modal.invalidPictures"
				:title="$t('presetDetails.invalidPicturesTitle')"
				@onClose="onCloseInvalidPicturesModal"
			>
				<div class="flex items-center">
					<div class="bg-red-500 w-8 h-8 rounded-full flex items-center justify-center">
						<span class="material-symbols-outlined text-black">close</span>
					</div>
					<p class="text-white ml-2 text-xl font-medium">
						{{ `${currentInvalidPictureIndex + 1}/${invalidPictures.length}` }}
						{{ invalidPictures.length === 1 ? $t('presetDetails.photo') : $t('presetDetails.photos') }}
					</p>
				</div>
				<p class="mt-2 text-base text-center text-gray-300">{{ $t('presetDetails.reason') }}</p>
				<p class="mt-2 text-xl font-bold text-center text-white">
					{{ getInvalidPictureReason(currentInvalidPicture.reason) }}
				</p>
				<div class="grid grid-cols-12 gap-2 my-6 items-center">
					<button class="col-span-1 col-start-3 text-end">
						<span class="material-symbols-outlined text-[32px]" @click="goToPreviousInvalidPicture"
							>chevron_left</span
						>
					</button>
					<div class="relative col-span-6" ref="invalidPictureImage" v-if="currentInvalidPicture">
						<div
							class="absolute bg-red-500 flex items-center justify-center top-2 left-2 z-[1] rounded-full"
						>
							<span class="material-symbols-outlined text-[32px]">close</span>
						</div>
						<LazyImage
							:src="currentInvalidPicture.url"
							imgClass="h-[260px] object-cover w-full rounded-xl"
							:height="260"
							alt="Invalid image"
						/>
					</div>
					<button class="col-span-1" @click="goToNextInvalidPicture">
						<span class="material-symbols-outlined text-[32px]">chevron_right</span>
					</button>
				</div>

				<Button @click="tryUploadAgain">{{ $t('presetDetails.invalidPicturesTryAgain') }}</Button>
			</Modal>
			<Modal
				:displayModal="showInstructionsModal"
				@onClose="showInstructionsModal = false"
				:title="$t('presetDetails.instructionModal.title')"
				modalClass="max-h-[90vh] md:max-h-full"
			>
				<div>
					<div class="flex place-items-center text-xl font-medium">
						<span class="material-icons text-lime-500"> check_circle </span>
						<span class="ml-2">{{ $t('presetDetails.instructionModal.goodExampleTitle') }}</span>
					</div>

					<p class="text-gray-300 mt-4">
						{{ $t('presetDetails.instructionModal.goodExampleDescription') }}
					</p>

					<div class="grid grid-cols-3 gap-4 mt-4">
						<LazyImage
							v-for="(image, index) in goodImageExamples"
							:key="`good-image-example-${index}`"
							:src="image"
							alt="Good Examples"
							imgClass="rounded-xl h-full"
						/>
					</div>
				</div>

				<div class="mt-8">
					<div class="flex place-items-center text-xl font-medium">
						<span class="material-icons text-red-500"> cancel </span>
						<span class="ml-2">
							{{ $t('presetDetails.instructionModal.badExampleTitle') }}
						</span>
					</div>

					<p class="text-gray-300 mt-4">
						{{ $t('presetDetails.instructionModal.badExampleDescription') }}
					</p>

					<div class="grid grid-cols-3 gap-4 mt-4">
						<LazyImage
							v-for="(image, index) in badImageExamples"
							:key="`bad-image-example-${index}`"
							:src="image"
							alt="Bad Examples"
							imgClass="rounded-xl h-full"
						/>
					</div>
				</div>

				<div class="mt-4 bg-gray-800 rounded-xl p-4">
					<p class="font-medium text-center text-gray-200">
						{{ $t('presetDetails.instructionModal.warning') }}
					</p>
				</div>

				<div class="mt-6">
					<Button @click="handleCloseInstructions">
						{{ $t('presetDetails.instructionModal.okayBtn') }}
					</Button>
				</div>
			</Modal>
		</div>
		<div
			v-show="uploadImages && !isImagesModalOpened && loading.verifyingPictures"
			class="h-[calc(100vh-6rem)] md:h-[calc(100vh-12rem)]"
		>
			<div class="relative overflow-hidden">
				<video
					class="aspect-square object-cover w-100"
					src="https://cdn.realitystudio.ai/assets/videos/processing-loading.mp4"
					loop
					autoplay
					muted
					:controls="false"
					playsinline
				></video>
				<div class="w-full absolute left-0 h-full bg-gradient-to-t from-app-blue to-transparent top-1"></div>
			</div>
			<div v-if="!isFinishedVerifying">
				<p class="text-xl text-center font-bold mt-4">
					{{ $t('presetDetails.verifyingPictures') }}
				</p>

				<p class="text-gray-300 text-center w-10/12 mx-auto mt-4">
					{{ $t('presetDetails.verifyingPicturesSubtitle') }}
				</p>

				<p class="text-4xl font-bold text-center mt-4">{{ verifiedPictures }}/{{ totalPicturesNeeded }}</p>

				<div class="flex flex-col" v-if="showRetryUploadButton">
					<p class="mt-6 text-lg font-bold text-center">
						{{ $t('presetDetails.photoVerificationTakingTooLong') }}
					</p>
					<button @click="tryUploadAgain(false)" class="mt-4 text-lg font-bold text-red-500">
						{{ $t('common.tryAgain') }}
					</button>
				</div>
			</div>

			<div
				v-if="isFinishedVerifying && !hasInvalidPictures"
				class="flex justify-center items-center flex-col w-full mt-4"
			>
				<p class="text-xl font-bold">{{ $t('common.toastTitle.success') }}</p>

				<div class="bg-lime-500 rounded-full w-16 h-16 flex animate-jump mt-4">
					<span class="material-symbols-outlined text-[64px]">check</span>
				</div>
			</div>

			<div
				v-if="isFinishedVerifying && hasInvalidPictures"
				class="flex justify-center items-center flex-col w-full mt-4"
			>
				<p class="text-xl font-bold">{{ $t('presetDetails.invalidPicturesTitle') }}</p>
				<div class="bg-red-500 rounded-full w-16 h-16 flex animate-jump mt-4">
					<span class="material-symbols-outlined text-[64px]">close</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Header, Button, LazyImage, Spinner, FileInput, Modal, BeforeAfterSource } from '@/components/default'
import { AuthenticationBox } from '@/components/auth'
import { PlansSwiper } from '@/components/new-avatar'
import Swiper from 'swiper'
import { inject, ref } from 'vue'
import { useStore } from 'vuex'

export default {
	name: 'ToolLayout',
	components: {
		BeforeAfterSource,
		Header,
		Button,
		PlansSwiper,
		LazyImage,
		Spinner,
		AuthenticationBox,
		FileInput,
		Modal,
	},
	async setup(props) {
		const axios = inject('axios')
		const store = useStore()
		const loggedUser = store.getters.getUser

		const [pricing, plans, inferences] = await Promise.all([
			axios.get('/inferences/pricing').then(res => res.data),
			axios.get('/plans?unit=monthly').then(res => res.data),
			loggedUser && axios.get(`/inferences?type=${props.toolType}`).then(res => res.data),
		])

		return {
			pointsPrice: pricing[props.pricingKey] || 0,
			plans,
			inferences: ref((inferences && inferences.results) || []),
			hasNext: ref(inferences?.hasNext),
			next: ref(inferences?.next),
		}
	},
	data() {
		return {
			mockupWindow: null,
			deletingId: null,
			getInferencesIntervals: [],
			isImagesModalOpened: false,
			currentInference: null,
			isDownloading: false,
			fetchInferenceIntervalInMs: 5000,
			inferenceOutputsSwiperInstance: null,
			isFetchingInferences: false,
			uploadImages: false,
			pictures: [],
			mockupWindow: null,
			elapsedTimer: null,
			goodImageExamples: [
				'/web/images/photo-examples/good-example-1.png',
				'/web/images/photo-examples/good-example-2.png',
				'/web/images/photo-examples/good-example-3.png',
			],
			badImageExamples: [
				'/web/images/photo-examples/bad-example-1.png',
				'/web/images/photo-examples/bad-example-2.png',
				'/web/images/photo-examples/bad-example-3.png',
			],
			showInstructionsModal: false,
			picturesAreValid: false,
			currentInvalidPictureIndex: 0,
			hasInvalidPictures: false,
			verifiedPictures: 0,
			selectedGenderIdentity: null,
			loading: {
				verifyingPictures: false,
			},
			modal: {
				invalidPictures: false,
				validPictures: false,
			},
			genderOptions: [
				{
					id: 1,
					value: 'male',
					text: `${this.localePrefix}.gender.male`,
				},
				{
					id: 2,
					value: 'female',
					text: `${this.localePrefix}.gender.female`,
				},
				{
					id: 3,
					value: 'random',
					text: `${this.localePrefix}.gender.random`,
				},
			],
			showRetryUploadButton: false,
			retryPhotoUploadButtonDisplayTimeoutInMS: 20000, // 20 seconds
			retryPhotoUploadTimeoutId: null,
			verificationResultAnimationDurationInMS: 1500,
		}
	},
	props: {
		showTransparentImage: {
			type: Boolean,
			default: false,
		},
		pricingKey: {
			type: String,
		},
		previewVideoSrc: {
			type: String,
		},
		toolType: {
			type: String,
			required: true,
		},
		localePrefix: {
			type: String,
			required: true,
		},
		gtagPrefix: {
			type: String,
			required: true,
		},
		apiSubmitEndpoint: {
			type: String,
			required: true,
		},
		totalPicturesNeeded: {
			type: Number,
			default: 2,
		},
		needsVerification: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		async handleSubmit() {
			const files = this.pictures

			this.captureEvent(`${this.gtagPrefix}_submit_tool_intent`)
			try {
				if (!this.needsVerification) {
					for (let i = 0; i < files.length; i++) {
						const file = files[i]

						const signedUrlResponse = await this.axios
							.get(`/files/sign-url/${file.name}`)
							.then(res => res.data)
						await this.axios.put(signedUrlResponse.url, file)
						file.resourceKey = signedUrlResponse.key
					}
				}

				let body = {
					gender: this.selectedGenderIdentity.value,
				}

				for (let i = 0; i < files.length; i++) {
					body = { ...body, [`image${i + 1 === 1 ? '' : i + 1}`]: files[i].resourceKey }
				}

				const inference = await this.axios.post(this.apiSubmitEndpoint, body).then(res => res.data)

				this.inferences = [inference, ...this.inferences]

				this.$nextTick(() => {
					this.inferenceOutputsSwiperInstance?.update()
				})
				this.handleOpenImage(inference)

				const poolingInterval = setInterval(
					() => this.fetchInference(inference._id),
					this.fetchInferenceIntervalInMs
				)

				this.getInferencesIntervals.push({
					interval: poolingInterval,
					inferenceId: inference._id,
				})

				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.success'),
					message: this.$t(`${this.localePrefix}.successMessage`),
					type: 'success',
				})

				this.captureEvent(`${this.gtagPrefix}_submit_tool_success`)
			} catch (error) {
				this.sentryCaptureException(error)
				this.captureEvent(`${this.gtagPrefix}_submit_tool_error`)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t(`${this.localePrefix}.errorMessage`),
					type: 'error',
				})
			}

			this.uploadImages = false
			this.isImagesModalOpened = true
		},
		async onFileSelect(fileList) {
			this.picturesAreValid = false
			if (fileList.length > this.totalPicturesNeeded) {
				fileList.splice(this.totalPicturesNeeded)
			}

			for (let i = 0; i < fileList.length; i++) {
				const file = fileList[i]
				if (!file) return
				const url = URL.createObjectURL(file)

				const blob = await fetch(url).then(r => r.blob())
				const fileFromBlob = new File([blob], `${file.name.split('.')[0]}.png`, { type: 'image/png' })

				this.pictures.push({
					url,
					invalid: false,
					valid: false,
					data: fileFromBlob,
				})
			}

			this.pictures.splice(this.totalPicturesNeeded)
		},
		async verifyPictures() {
			if (this.loading.verifyingPictures || !this.needsVerification) return

			this.verifiedPictures = 0
			this.loading.verifyingPictures = true
			this.hasInvalidPictures = false
			this.showRetryUploadButton = false

			try {
				for (let i = 0; i < this.pictures.length; i++) {
					if (!this.loading.verifyingPictures) return

					clearTimeout(this.retryPhotoUploadTimeoutId)

					this.retryPhotoUploadTimeoutId = setTimeout(() => {
						this.showRetryUploadButton = true
					}, this.retryPhotoUploadButtonDisplayTimeoutInMS)

					const pictureData = this.pictures[i].data

					const { url, key } = await this.axios
						.get(`/files/sign-url/${pictureData.name}`)
						.then(res => res.data)
					await this.axios.put(url, pictureData)

					const { data } = await this.axios.post('/files/verify', { resourceKey: key })

					if (!data.valid) {
						this.pictures[i].data = data.data
						this.pictures[i].reason = data.error
						this.pictures[i].invalid = true
						this.hasInvalidPictures = true
					} else {
						this.pictures[i].resourceKey = key
						this.pictures[i].valid = true
					}

					this.verifiedPictures++
				}
				if (!this.loading.verifyingPictures) return

				if (this.hasInvalidPictures) {
					setTimeout(() => {
						this.modal.invalidPictures = true
					}, this.verificationResultAnimationDurationInMS)
					return
				}

				this.picturesAreValid = true

				this.loading.verifyingPictures = false
				this.handleSubmit()
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		getInvalidPictureReason(reason) {
			switch (reason) {
				case 'celebrity':
					const reasonData = this.currentInvalidPicture.data

					if (!reasonData) return ''

					const celebrityName = reasonData.celebrityName
					const celebrityConfidence = reasonData.celebrityConfidence

					return this.$t('presetDetails.imageErrorReasons.celebrity', {
						celebrityName,
						celebrityConfidence,
					})

				default:
					const reasonMessages = {
						'low-sharpness': this.$t('presetDetails.imageErrorReasons.lowSharpness'),
						'inappropriate-content': this.$t('presetDetails.imageErrorReasons.inappropriateContent'),
						'no-face': this.$t('presetDetails.imageErrorReasons.noFace'),
						'too-many-faces': this.$t('presetDetails.imageErrorReasons.tooManyFaces'),
					}

					return reasonMessages[reason] || this.$t('presetDetails.imageErrorReasons.unknown')
			}
		},
		onCloseInvalidPicturesModal() {
			this.pictures = this.pictures.filter(picture => picture.valid)
			this.modal.invalidPictures = false
			this.loading.verifyingPictures = false
			this.captureEvent('new_avatar_invalid_pictures_modal_close')
		},
		tryUploadAgain(clearUploads = true) {
			if (clearUploads) {
				this.pictures = this.pictures.filter(picture => picture.valid)
			}

			this.currentInvalidPictureIndex = 0
			this.loading.verifyingPictures = false
			this.hasInvalidPictures = false
			this.picturesAreValid = false
			this.modal.invalidPictures = false
		},
		goToPreviousInvalidPicture() {
			this.handleInvalidPicturesAnimation()
			if (this.currentInvalidPictureIndex <= 0) {
				this.currentInvalidPictureIndex = this.invalidPictures.length - 1
				return
			}
			this.currentInvalidPictureIndex--
		},
		goToNextInvalidPicture() {
			this.handleInvalidPicturesAnimation()
			if (this.currentInvalidPictureIndex >= this.invalidPictures.length - 1) {
				this.currentInvalidPictureIndex = 0
				return
			}

			this.currentInvalidPictureIndex++
		},
		handleInvalidPicturesAnimation() {
			const element = this.$refs.invalidPictureImage

			element.classList.remove('animate-jump')

			setTimeout(() => {
				element.classList.add('animate-jump')
			}, 100)
		},
		async handleCloseInstructions() {
			this.showInstructionsModal = false

			if (this.loggedUser?.viewedInstructions) {
				return
			}

			try {
				await this.axios.post('/users/viewed-instructions')
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		handleOpenImage(inference) {
			this.captureEvent(`${this.gtagPrefix}_open_image_click`)
			this.currentInference = inference
			this.isImagesModalOpened = true
			window.scrollTo({ top: 0, behavior: 'smooth' })
		},
		initInferencesSwiper() {
			this.inferenceOutputsSwiperInstance = new Swiper('.inference-outputs-swiper', {
				slidesPerView: 'auto',
				freeMode: true,
				spaceBetween: 16,
				grabCursor: true,
				mousewheel: true,
				on: {
					activeIndexChange: () => {
						if (!this.inferenceOutputsSwiperInstance || this.isFetchingInferences) return

						/**
						 * Load more items when the user it's close to the end of the list
						 */
						if (
							this.inferenceOutputsSwiperInstance.activeIndex * 2 > this.inferences.length &&
							this.hasNext
						) {
							this.fetchInferences()
						}
					},
				},
			})
		},
		handleUploadMore() {
			this.captureEvent(`${this.gtagPrefix}_upload_more_click`)

			this.isImagesModalOpened = false
			this.uploadImages = true
			this.pictures = []
		},
		onScrollPage() {
			let isScrolledToBottom = false

			if (this.isMobile) {
				const { innerHeight, scrollY } = window

				isScrolledToBottom = innerHeight + scrollY >= document.body.offsetHeight
			}

			if (!this.isMobile) {
				const { scrollTop, offsetHeight, scrollHeight } = this.mockupWindow

				isScrolledToBottom = scrollTop + offsetHeight >= scrollHeight
			}

			if (isScrolledToBottom) {
				this.fetchInferences()
			}
		},
		async handleDeleteImage(inference) {
			this.deletingId = inference._id
			try {
				await this.axios.delete(`/inferences/${inference._id}`).then(res => res.data)
				this.inferences = this.inferences.filter(_inference => _inference._id !== inference._id)

				if (this.currentInference?._id === inference._id) {
					const anotherInference = this.inferences?.[0]

					this.currentInference = anotherInference
				}

				if (this.isImagesModalOpened && this.inferences.length === 0) {
					this.isImagesModalOpened = false
				}

				this.$nextTick(() => {
					this.inferenceOutputsSwiperInstance?.update()
				})
			} catch (error) {
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}

			this.deletingId = null
		},
		async fetchInferences() {
			if (this.isFetchingInferences || !this.hasNext) {
				return
			}

			this.isFetchingInferences = true

			try {
				const { results, hasNext, next } = await this.axios
					.get(`/inferences?${this.hasNext ? `next=${this.next}` : ''}&type=${this.toolType}`)
					.then(res => res.data)

				const inferenceLength = this.inferences.length

				this.inferences = [...this.inferences, ...results]
				this.hasNext = hasNext
				this.next = next

				if (inferenceLength !== this.inferences.length) {
					this.$nextTick(() => {
						this.inferenceOutputsSwiperInstance?.update()
					})
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}

			this.isFetchingInferences = false
		},
		async fetchInference(inferenceId) {
			try {
				const inference = await this.axios.get(`/inferences/${inferenceId}`).then(res => res.data)

				this.inferences = this.inferences.map(_inference => {
					if (_inference._id === inferenceId) {
						return inference
					}

					return _inference
				})

				if (this.currentInference?._id === inferenceId) {
					this.currentInference = inference
				}

				if (inference.status !== 'processing') {
					clearInterval(
						this.getInferencesIntervals.find(interval => interval.inferenceId === inferenceId)?.interval
					)

					const displayOfferModal = this.checkShowSubscribeOfferModal()

					if (displayOfferModal) {
						this.$mitt.emit('openSubscribeOfferModal')
					}

					this.$mitt.emit('openFeedbackModal', inference)
				}
			} catch (error) {
				this.sentryCaptureException(error)
			}
		},
		async downloadImage() {
			this.isDownloading = true

			this.captureEvent(`${this.gtagPrefix}_image_download_intent`)

			try {
				await this.$downloadFile(this.currentInference.outputs[0], `${this.currentInference._id}.png`)

				this.captureEvent(`${this.gtagPrefix}_image_download_success`)
			} catch (error) {
				this.captureEvent(`${this.gtagPrefix}_image_download_error`)
				this.sentryCaptureException(error)
				this.$toast({
					duration: 5000,
					title: this.$t('common.toastTitle.error'),
					message: this.$t('common.toastMessage.error'),
					type: 'error',
				})
			}

			this.isDownloading = false
		},
		checkShowSubscribeOfferModal() {
			if (this.loggedUser && this.loggedUser.plan && this.loggedUser.plan.free) {
				return this.loggedUser.modals.subscriptionOfferModalDisplayedAt
					? this.$dayjs().isAfter(
							this.$dayjs(this.loggedUser.modals.subscriptionOfferModalDisplayedAt).add(30, 'minute')
					  )
					: true
			}

			return !this.loggedUser.hasSubscription
		},
	},
	computed: {
		currentPathname() {
			return this.$route.path
		},
		isMobile() {
			return this.$store.getters.getIsMobile
		},
		loggedUser() {
			return this.$store.getters.getUser
		},
		userHasEnoughPoints() {
			if (!this.loggedUser) {
				return false
			}

			return this.loggedUser?.points >= this.pointsPrice
		},
		currentInvalidPicture() {
			return this.invalidPictures[this.currentInvalidPictureIndex]
		},
		totalPicturesValidation() {
			return this.pictures.length === this.totalPicturesNeeded
		},
		invalidPictures() {
			return this.pictures.filter(picture => picture.invalid)
		},
		isFinishedVerifying() {
			return this.loading.verifyingPictures && this.verifiedPictures === this.totalPicturesNeeded
		},
	},
	mounted() {
		this.mockupWindow = this.getWindow()

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.addEventListener('scroll', this.onScrollPage)

		this.initInferencesSwiper()
	},
	beforeUnmount() {
		this.getInferencesIntervals.forEach(interval => clearInterval(interval.interval))

		if (this.inferenceOutputsSwiperInstance) {
			this.inferenceOutputsSwiperInstance.destroy()
		}

		const eventTarget = this.isMobile ? document : this.mockupWindow
		eventTarget.removeEventListener('scroll', this.onScrollPage)
	},
}
</script>
