import { Capacitor } from '@capacitor/core'

export default {
	install(app) {
		app.config.globalProperties.$getLocale = async () => {
			const locales = {
				en: 'en-US',
				es: 'es-ES',
				pt: 'pt-BR',
			}

			let languageLocale = 'en'

			if (Capacitor.getPlatform() === 'web') {
				const language = navigator.language
				languageLocale = language?.split('-')[0]
			} else {
				const capacitorDevice = await import('@capacitor/device')
				languageLocale = await capacitorDevice.Device.getLanguageCode().then(res => res.value)
			}

			return locales[languageLocale] || 'en-US'
		}
	},
}
