<template>
	<div
		class="rounded-xl border p-4 relative flex flex-col justify-between"
		:class="{
			'border-blue-600': isSelected,
			'border-radial-1-card': !isSelected,
		}"
	>
		<span class="material-icons mt-6 mr-4 text-blue-600 h-[32px] w-[32px] absolute top-0 right-0" v-if="isSelected">
			done
		</span>

		<div class="text-blue-600 flex items-center">
			<img
				:src="`/web/icons/plans/${plan?.englishName?.toLowerCase()}.svg`"
				class="w-[16px] h-[16px] inline-block filter-to-blue-600"
				alt="Icon"
			/>
			<span class="ml-2 text-sm">
				{{ plan?.name }}
			</span>
		</div>

		<p class="font-medium text-2xl mb-6">{{ plan?.billingCycle.frequency }} {{ billingCycleUnit }}</p>

		<p v-if="plan?.isMonthly" class="text-lg">
			<b class="text-2xl font-bold">{{ $formatCurrency(plan.monthlyPrice) }}</b
			>/{{ $t('common.month') }}
		</p>

		<div v-if="plan?.isYearly">
			<p class="text-red-500 line-through text-lg">
				{{ $formatCurrency(plan.monthlyPrice) }}/{{ $t('common.month') }}
			</p>
			<p class="text-lg">
				<b class="text-2xl font-bold">{{ $formatCurrency(plan.price) }}</b
				>/{{ $t('common.month') }}
			</p>
			<p class="text-gray-300 text-sm">{{ $formatCurrency(plan.yearlyPrice) }} {{ $t('common.yearly') }}</p>
		</div>

		<!-- <div v-if="plan.isMonthly">
			<p class="text-lg">
				<b class="text-2xl font-bold">{{ $formatCurrency(plan?.monthlyPrice) }}</b
				>/{{ this.$t('common.month') }}
			</p>
		</div>

		<div v-if="plan.isYearly">
			<p class="text-red-500 line-through">
				{{ $formatCurrency(plan?.monthlyPrice) }}/{{ this.$t('common.month') }}
			</p>
			<p class="text-lg">
				<b class="text-2xl font-bold">{{ $formatCurrency(plan?.price) }}</b
				>/{{ this.$t('common.month') }}
			</p>
			<p class="text-gray-300 text-sm" v-if="isYearly">
				{{ $formatCurrency(plan.yearlyPrice) }} {{ this.$t('common.yearly') }}
			</p>
		</div> -->
	</div>
</template>

<script>
export default {
	props: {
		plan: {
			type: Object,
			required: true,
		},
		isSelected: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		billingCycleUnit() {
			return this.$t('common.' + this.plan?.billingCycle.unit.replace('ly', ''))
		},
		isYearly() {
			return this.plan?.billingCycle.unit === 'yearly'
		},
	},
}
</script>

<style scoped>
.filter-to-blue-600 {
	filter: brightness(0) saturate(100%) invert(26%) sepia(89%) saturate(3006%) hue-rotate(217deg) brightness(98%)
		contrast(88%);
}
</style>
