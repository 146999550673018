<template>
	<div>
		<div
			:class="[
				'relative overflow-hidden aspect-2/3 border-2  inset rounded-xl',
				isSelected ? 'border-blue-600' : 'border-transparent',
			]"
		>
			<LazyImage
				:src="item.image || item.images[0]"
				@click="handleItemSelection(item)"
				:alt="item.name"
				imgClass="object-cover w-full h-full"
			/>
			<div class="top-0 flex w-full justify-between absolute mt-2 px-2">
				<div class="flex p-1 rounded bg-black/40">
					<img
						v-if="itemType === 'model'"
						class="w-[24px] h-[24px] object-cover"
						src="/web/icons/image-creation/model-icon.svg"
						alt="Model"
					/>
					<div class="flex items-center" v-if="itemType === 'lora'">
						<img class="w-4" src="/web/icons/image-creation/lora.svg" alt="Lora" />
						<p class="font-bold text-xs ml-2 leading-4">{{ $t('imageGeneration.create.lora') }}</p>
					</div>
				</div>

				<div class="flex items-center p-2 rounded bg-blue-600" v-if="itemType === 'model'">
					<img class="w-4" src="/web/icons/image-creation/pro-model.svg" alt="pro-model" />
					<p class="font-bold text-xs ml-2 leading-4">{{ $t('imageGeneration.create.pro') }}</p>
				</div>

				<Button
					class="rounded-md bg-black/40 px-1 items-center flex justify-center z-20"
					size="fit"
					color="transparent"
					@click="bookmarkItem"
				>
					<span class="material-icons-outlined text-2xl">{{
						item.isFavorite ? 'bookmark' : 'bookmark_border'
					}}</span>
				</Button>
			</div>

			<div
				class="w-full opacity-80 absolute top-0 left-0 h-full bg-gradient-to-t pointer-events-none from-black to-transparent"
			></div>
			<p class="absolute bottom-0 w-full font-bold flex justify-center pb-4">{{ item.name }}</p>
		</div>

		<div class="flex items-center w-full my-2">
			<div class="min-w-8 h-8 border-2 border-white rounded-full mr-2 overflow-hidden bg-white">
				<LazyImage
					:imgClass="['object-cover w-full', { '!w-auto m-auto h-full': item.public || !item.user }]"
					:src="item.public || !item.user ? '/web/icons/reality-studio-icon-black.svg' : item.user.avatar"
					:alt="item.public || !item.user ? 'reality-avatar' : item.user && item.user.userName"
				/>
			</div>
			<p class="ml-2 line-clamp-1 break-all overflow-ellipsis">
				{{ item.public || !item.user ? 'RealityStudio' : item.user.userName }}
			</p>
		</div>
	</div>
</template>

<script>
import { LazyImage, Button } from '@/components/default'
export default {
	components: { LazyImage, Button },
	props: {
		item: {
			type: Object,
			required: true,
		},
		itemType: {
			type: String,
			required: true,
		},
		isSelected: Boolean,
	},
	methods: {
		handleItemSelection() {
			this.$emit('onSelectItem', this.item)
		},
		bookmarkItem() {
			this.$emit('onBookmarkItem', this.item)
		},
	},
}
</script>
