<template>
	<div>
		<MultipleImages2ImageLayout
			localePrefix="generateBabyTool"
			gtagPrefix="baby_generator_tool"
			toolType="generate-baby"
			apiSubmitEndpoint="/inferences/generate-baby"
			previewVideoSrc="https://cdn.realitystudio.ai/assets/videos/baby.mp4"
			pricingKey="generateBaby"
			:needsVerification="true"
		>
		</MultipleImages2ImageLayout>
	</div>
</template>

<script>
import { MultipleImages2ImageLayout } from '@/components/tools'

export default {
	components: {
		MultipleImages2ImageLayout,
	},
	head: {
		title: `RealityStudio - Transform Baby`,
		meta: [
			{ hid: 'robots', name: 'robots', content: 'index, follow' },
			{
				name: 'description',
				content:
					'Transform into baby easily with RealityStudio. Upload your image and get transformed image in seconds.',
			},
			{
				hid: 'og:title',
				property: 'og:title',
				content: `RealityStudio - Transform into baby `,
			},
			{
				hid: 'og:description',
				property: 'og:description',
				content:
					'Transform into baby easily with RealityStudio. Upload your image and get transformed image in seconds.',
			},
		],
	},
}
</script>
