<template>
	<section class="w-full bg-primary py-14 lg:py-16 mt-24 lg:mt-36">
		<p
			class="mt-8 mx-auto max-w-sm lg:max-w-3xl w-full text-xl lg:text-4xl text-center font-bold"
			v-motion-slide-visible-once-bottom
		>
			{{ $t('landingPage.marketing') }}
		</p>
		<div class="mt-6 lg:mt-10 flex gap-x-4 justify-center">
			<!-- maintain this apple button for now -->
			<!-- <Button
				class="w-48 h-14 bg-black text-white rounded-2xl flex justify-center items-center opacity-60 cursor-default"
			>
				<img class="w-7 h-8 me-2" src="/web/images/landing-page/apple-logo.svg" alt="" />
				<div>
					<p class="text-xs font-normal">Download on the</p>
					<p class="text-sm font-bold">Apple Store</p>
				</div>
			</Button> -->
			<a
				href="https://play.google.com/store/apps/details?id=net.realityavatar.app&hl=pt_BR&gl=US&pli=1"
				target="_blank"
				class="w-48 h-14 bg-black text-white rounded-2xl flex justify-center items-center"
				v-motion-slide-visible-once-bottom
			>
				<img class="me-2 w-8" src="/web/images/landing-page/google-play-logo.svg" alt="Google Play Icon" />
				<div>
					<p class="text-xs font-normal">Download on the</p>
					<p class="text-sm font-bold">Google Play</p>
				</div>
			</a>
		</div>
		<div class="page-container">
			<Button
				href="/my-account"
				class="lg:w-96 h-20 mx-auto mt-10 rounded-3xl font-bold"
				v-motion-slide-visible-once-bottom
			>
				{{ $t('landingPage.getStarted') }}
				<span class="material-icons ms-1 font-bold">open_in_new</span>
			</Button>
		</div>
	</section>
</template>
<script>
import Button from '@/components/default/Button.vue'
export default {
	name: 'Marketing',
	components: {
		Button,
	},
}
</script>
