<template>
	<div class="border-[1px] border-gray-900 py-8 px-6 rounded-xl relative w-full">
		<div class="flex flex-col text-center items-center justify-between h-full">
			<span class="text-lg font-semibold">{{ data.title }}</span>
			<span class="mt-4">{{ data.description }}</span>
			<img v-if="data.image" class="mt-4" :class="data.imageClass" :src="data.image" :alt="data.title" />
			<Button class="mt-6" @click="data.btnAction">{{ data.btnText }}</Button>
		</div>
	</div>
</template>

<script>
import { Button } from '../../components/default/'

export default {
	props: {
		data: {
			btnAction: Function,
			btnText: String,
			description: String,
			title: String,
			image: String,
			imageClass: String,
		},
	},

	components: {
		Button,
	},
}
</script>
